.check-group-wrap {
    display: inline-flex;
    gap: 20px;
}
@media (max-width: 820px) {
    .check-group-wrap {
        display: block;
    }
}
.check-group-wrap.document-checkGroup {
    display: block;
}
.check-group-box {
    width: 100%;
    display: flex;
    border: 1px solid #CFD4D9;
    border-radius: 0.25rem;
    padding: 0.812rem 0;
    margin-bottom: 10px;
}

.check-group-box:hover {
    border: 1px solid #325CEB;
    cursor: pointer;
}
.circle.check {
    background: #325CEB;
    border: none;
}
.check-group-box-with-image {
    width: 100%;
    border: 1px solid #CFD4D9;
    border-radius: 0.25rem;
    padding: 0.812rem 0;
    margin-bottom: 10px;
}

.check-group-box-with-image:hover {
    border: 1px solid #325CEB;
    cursor: pointer;
}

.circle {
    margin-left: 0.812rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    line-height: 1.25rem;
    border: 1px solid #CFD4D9;
    background-color: #F1F1F1;
}

.dec {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 0.75rem;
    line-height: 1.25rem;
}