
.pricing-page .pricing-main-title {
    color: #070B3A;
    font-family: "Playfair Display";
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
}
.pricing-page .pricing-sub-title {
    color: #6E6E70;
    font-family: Inter;
    font-size: 16px;
    letter-spacing: 0.2px;
    margin-top: 12px;
}
.pricing-page .pricing-tab {
    font-family: Inter;
    font-size: 16px;
    width: 250px;
    height: 44px;
    padding: 3px 4px;
    border-radius: 4px;
    background-color: #EAEFFD;
    display: flex;
    justify-content: space-between;
    margin: 22px auto 32px;
}
.pricing-page .pricing-tab .tab {
    width: 118px;
    height: 100%;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.pricing-page .pricing-tab .tab.active-tab {
    color: #325CEB;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #ECECEC;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06);
}
.pricing-page .pricing-tab .tab .tab-tag {
    padding: 0 4px;
    border-radius: 4px;
    background-color: #1F2890;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 4px;
}
.pricing-page .pricing-plan-box .pricing-plan {
    font-family: Inter;
    height: 348px;
    padding: 24px 40px 0;
    border-radius: 6px;
    background-color: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 25, 76, 0.12);
    position: relative;
}
.pricing-page .pricing-plan-box .pricing-plan:hover {
    background-color: #EAEFFD;
    transition: all .3s;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-name {
    color: #070B3A;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}
.pricing-page .pricing-plan-box .pricing-plan .old-price {
    margin: 10px 0;
    color: #545459;
    font-size: 16px;
    font-weight: 400;
    height: 20px;
    line-height: normal;
    text-decoration: line-through;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-price {
    color: #333;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-price .plan-unit {
    color: #545459;
    font-size: 16px;
    font-weight: 400;
}
.pricing-page .pricing-plan-box .pricing-plan .free-day {
    margin: 12px 0 18px;
    color: #1F2890;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    line-height: 20px;
    text-transform: uppercase;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-des {
    height: 68px;
    color: #6E6E70;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:4;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-btn {
    width: 59%;
    min-width: 156px;
    height: 44px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #325CEB;
    background-color: #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto 0;
    cursor: pointer;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-btn.contact {
    background-color: #FFF;
    color: #325CEB;
}
.pricing-page .pricing-plan-box .pricing-plan .contact-us {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 12px;
}
.pricing-page .pricing-plan-box .pricing-plan .plan-tag {
    position: absolute;
    top: 26px;
    right: 0px;
    padding: 0 4px;
    border-radius: 4px 0px 0px 4px;
    background-color: #1F2890;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}
.pricing-page .features-list {
    margin-top: 36px;
}
.pricing-page .features-list .compare {
    display: flex;
}
.pricing-page .features-list .compare .compare-name {
    color: #333;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 26px;
    word-break: break-word;
    flex: 1;
}
.pricing-page .features-list .compare-features {
    width: 18%;
    color: #1F2890;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border-left: 1px solid #CFD4D9;
}
.pricing-page .features-list {
    font-family: Inter;
}
.pricing-page .features-list .service-name-box {
    display: flex;
}
.pricing-page .features-list .service-name {
    color: #1F2890;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding: 30px 0 35px;
    word-break: break-word;
    flex: 1;
}
.pricing-page .features-list .features-list-title {
    display: flex;
}
.pricing-page .features-list .features-list-title .features-header {
    width: 18%;
    padding: 20px 0;
    border-left: 1px solid #CFD4D9;
}
.pricing-page .features-list .features-bottom {
    display: flex;
    margin-top: 32px;
}
.pricing-page .features-list .features-bottom .features-bottom-title {
    color: #1F2890;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    word-break: break-word;
    flex: 1 1;
}
.pricing-page .features-list .features-bottom .features-bottom-content {
    width: 18%;
    color: #1F2890;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pricing-page .features-list .features-bottom .features-bottom-content img {
    margin-right: 6px;
}
.pricing-page .features-list .features-list-title .features-header .features-header-name {
    color: #070B3A;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    word-break: break-word;
}
.pricing-page .features-list .features-list-title .features-header .features-header-price {
    color: #545459;
    font-size: 16px;
    height: 20px;
    line-height: normal;
    margin-bottom: 13px;
}
.pricing-page .features-list .features-list-title .features-header .features-header-btn {
    width: 73%;
    height: 44px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    background-color: #325CEB;
    border: 1px solid #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin: 0 auto;
    cursor: pointer;
}
.pricing-page .features-list .features-list-title .features-header .features-header-btn.contact {
    background-color: #FFF;
    color: #325CEB;
}
@media (min-width: 576px) {
    .pricing-page {
        padding: 40px 20px;
    }
    .pricing-page .features-list .compare .compare-name {
        font-size: 12px;
    }
    .pricing-page .features-list .service-name {
        font-size: 16px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-name {
        font-size: 14px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-btn, .pricing-page .features-list .features-list-title .features-header .features-header-price, .pricing-page .features-list .compare-features, .pricing-page .features-list .features-bottom .features-bottom-content {
        font-size: 12px;
    }
    .pricing-page .features-list .features-bottom .features-bottom-content img {
        width: 18px;
    }
}
@media (min-width: 768px) {
    .pricing-page {
        padding: 40px 28px;
    }
    .pricing-page .features-list .compare .compare-name {
        font-size: 14px;
    }
    .pricing-page .features-list .service-name {
        font-size: 18px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-name {
        font-size: 16px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-btn, .pricing-page .features-list .features-list-title .features-header .features-header-price, .pricing-page .features-list .compare-features, .pricing-page .features-list .features-bottom .features-bottom-content {
        font-size: 14px;
    }
    .pricing-page .features-list .features-bottom .features-bottom-content img {
        width: 20px;
    }
}
@media (min-width: 992px) {
    .pricing-page .features-list .features-bottom .features-bottom-content img {
        width: 26px;
    }
}
@media (min-width: 1200px) {
    .pricing-page {
        padding: 40px 80px;
    }
    .pricing-page .features-list .compare .compare-name {
        font-size: 16px;
    }
    .pricing-page .features-list .service-name {
        font-size: 20px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-name {
        font-size: 18px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-btn, .pricing-page .features-list .features-list-title .features-header .features-header-price, .pricing-page .features-list .compare-features, .pricing-page .features-list .features-bottom .features-bottom-content {
        font-size: 16px;
    }
    .pricing-page .features-list .features-bottom .features-bottom-content img {
        width: 30px;
    }
}
@media (max-width: 575px) {
    .pricing-page {
        padding: 40px 16px;
    }
    .pricing-page .features-list .compare .compare-name {
        font-size: 12px;
    }
    .pricing-page .features-list .service-name {
        font-size: 16px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-name {
        font-size: 14px;
    }
    .pricing-page .features-list .features-list-title .features-header .features-header-btn, .pricing-page .features-list .features-list-title .features-header .features-header-price, .pricing-page .features-list .compare-features, .pricing-page .features-list .features-bottom .features-bottom-content {
        font-size: 10px;
    }
    .pricing-page .features-list .features-bottom .features-bottom-content img {
        width: 16px;
    }
}