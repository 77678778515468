/* Container */
.mortgage-rates-wrapper {
    background-color: #f7f8ff;
    padding: 30px 30px 44.39px 30px;
}

/* Header Section */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
}

.title-section {
    color: #081265;
}

.main-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
}

.sub-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 29.05px;
}

.calculator-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;
    margin-top: -12px;
}

.calculator-hint {
    color: #6e6e70;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
}

.calculator-button {
    border: 1px solid #1630ac !important;
    color: #1630ac !important;
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding: 14px 11px !important;
    border-radius: 8px !important;
    height: 31px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    transition: all 0.3s ease !important;
}

.calculator-button:hover {
    color: #4096ff !important;
    border-color: #4096ff !important;
    background-color: #fff !important;
}

/* Form Section */
.form-container {
    background-color: transparent;
    margin-bottom: 40px;
}

.rates-form .form-row {
    margin-bottom: 20px !important;
}

.rates-form .form-row:last-child {
    margin-bottom: 0 !important;
}

.rates-form .row {
    margin-left: -12px !important;
    margin-right: -12px !important;
}

.rates-form .col {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.form-label {
    display: block;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #222222;
}

.rates-form .ant-input-affix-wrapper > input.ant-input {
    font-size: 16px !important;
    border: none !important;
    padding: 0 !important;
    height: 38px !important;
}

.rates-form .ant-select-selector,
.rates-form .ant-input-affix-wrapper,
.rates-form .ant-input {
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 4px !important;
}

.rates-form .ant-select-selection-item,
.rates-form .ant-input,
.rates-form .ant-input-affix-wrapper input {
    font-size: 16px !important;
    color: #6e6e70 !important;
    font-weight: 400 !important;
}

.rates-form .ant-input-prefix,
.rates-form .ant-input-suffix {
    color: #6e6e70 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.rates-form .ant-select-selection-placeholder,
.rates-form .ant-input::placeholder {
    color: #6e6e70 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.rates-form .ant-select,
.rates-form .ant-input-affix-wrapper,
.rates-form .ant-input {
    width: 100% !important;
}

.rates-form .ant-select .ant-select-arrow {
    width: 33px !important;
    height: 33px !important;
    top: 50% !important;
    right: 0 !important;
    margin-top: -19px !important;
}

.rates-form .ant-select .ant-select-arrow .anticon {
    display: none !important;
}

.rates-form .ant-select .ant-select-arrow::after {
    content: '' !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    background: url('/images/antd-select-arrow.svg') center center no-repeat !important;
    background-size: contain !important;
}

/* Points Selector */
.points-selector {
    display: flex;
    background-color: #f6f6f6;
    border: 1px solid #cfd4d9;
    border-radius: 4px;
    position: relative;
    height: 40px;
    width: 100%;
}

.point-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 10;
    transition: all 0.2s;
    color: #6e6e70;
    font-size: 14px;
    font-weight: 400;
}

.point-item.selected {
    font-weight: 500;
}

.selector-highlight {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    height: calc(100% - 6px);
    width: calc(100% - 7px);
    transition: all 0.2s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    top: 3px;
    left: 3.5px;
}

/* Points Selector */
.points-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.points-selector-container .question-icon {
    color: #858585;
    font-size: 14px;
    margin-bottom: 12px;
}

/* Closing Cost */
.closing-cost-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.closing-cost-label,
.closing-cost-value {
    font-size: 14px;
    font-weight: 400;
    color: #858585;
}

/* Check Rates Button */
.check-rates-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.check-rates-button {
    height: 46px !important;
    background-color: #1630ac !important;
    color: white !important;
    border-radius: 6px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 13px 30px !important;
    border: none !important;
    line-height: 20px !important;
}

/* Rates Section */
.rates-section {
    background-color: transparent;
}

.rates-date {
    font-size: 12px;
    font-weight: 500;
    color: #222222;
}

.rates-disclaimer {
    font-size: 12px;
    font-weight: 500;
    color: #6e6e70;
    margin-top: 5px;
}

.rates-warning {
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #ffeecf;
    padding: 10px;
    min-width: 300px;
}

/* Terms Container */
.terms-container {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 19px !important;
    width: 100%;
    height: 40px;
    margin-top: 15px;
}

.term-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    white-space: nowrap;
    max-width: 92px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c1c1c1;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: white;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.term-item.selected {
    border-color: #325ceb;
    color: #325ceb;
}

.term-item:hover {
    border-color: #325ceb;
    color: #325ceb;
}

/* Rates Table */
.rates-table {
    margin-top: 15.27px;
    margin-bottom: 30px;
    width: 100%;
}

.rates-table .ant-table-thead > tr > th,
.rates-table .ant-table-thead > tr > th:hover {
    background: none !important;
    background-color: transparent !important;
}

/* Remove table background and border */
.rates-table,
.rates-table .ant-table,
.rates-table .ant-table-container,
.rates-table .ant-table-content,
.rates-table .ant-table-thead,
.rates-table .ant-table-thead > tr,
.rates-table .ant-table-tbody > tr > td {
    background: none !important;
    background-color: transparent !important;
    border: none !important;
}

.rates-table .ant-table-thead > tr > th:before,
.rates-table .ant-table-thead > tr > th:after {
    display: none !important;
}

.rates-table .ant-table-thead > tr {
    border-bottom: 1px solid #ececec !important;
}

.rates-table .ant-table-thead > tr > th {
    padding: 8px 0 !important;
    color: #858585 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
}

.rates-table .ant-table-tbody > tr > td {
    padding: 9.5px 0 !important;
    color: #222222 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    background: transparent !important;
}

.rates-table .ant-table-tbody > tr:last-child > td {
    padding-bottom: 0 !important;
}

.rates-table .ant-table-tbody > tr > td:last-child {
    padding-right: 40px !important;
}

.rates-table .ant-table-tbody > tr > td:last-child > button {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    min-width: fit-content !important;
    height: 28px !important;
    padding: 14px 11px !important;
    line-height: 1 !important;
    background-color: #325ceb !important;
    color: white !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border: none !important;
}

.apply-button {
    background-color: #325ceb;
    color: white;
    height: 28px;
    padding: 14px 11px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

/* Disclaimer */
.disclaimer-title,
.disclaimer-content {
    margin: 0 !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #6e6e70;
}

/* Down Payment Row */
.rates-form .down-payment-row {
    display: flex;
    gap: 8px;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.down-payment-row .form-slider-outline {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: white !important;
}

.form-slider-outline .dollar-sign {
    font-size: 14px !important;
    padding: 4px 8px !important;
    color: #6e6e70 !important;
}

.commaized-input {
    border: none;
    background-color: transparent;
}

.form-slider-outline:has(.commaized-input:hover),
.form-slider-outline:has(.commaized-input:focus) {
    border-right: none;
}

.percentage-input {
    font-size: 16px !important;
    height: 40px !important;
    color: #6e6e70 !important;
}

/* Hide the input arrows */
.percentage-input .ant-input {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
}

.percentage-input .ant-input::-webkit-inner-spin-button,
.percentage-input .ant-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.rates-form .numerical-input-wrapper {
    margin: 0 !important;
    padding: 0 !important;
}

.numerical-input-wrapper .input-group-text {
    border-right: none;
    background-color: #fff !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    color: #6e6e70;
    font-size: 16px;
    padding: 8px 11px;
    height: 40px !important;
}

.numerical-input-wrapper .form-control {
    border-left: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 40px !important;
    font-size: 16px;
    color: #6e6e70;
}

.numerical-input-wrapper .input-group {
    height: 40px !important;
}
