

.featured {
    margin: 40px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.featuredStatistics {
    flex: 1;
    padding: 20px;
    margin-left: 5px;
    margin-right: 30px;
    cursor: pointer;
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.62);
    -webkit-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.62);
    text-align: left;
}
.featuredChart {
    flex: 2;
    padding: 20px;
    margin-left: 5px;
    margin-right: 30px;
    cursor: pointer;
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.62);
    -webkit-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.62);
    text-align: left;
}

.featuredTitle {
    font-size: 18px;
    font-weight: bold;
    /* border-bottom: 1px solid black; */
}
.featuredContainer {
    margin-top: 10px;
    font-size: 16px;
}

.sub-list {
    margin-top: 40px;
    margin-left: 45px;
    width: 87%;
    padding: 20px;
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.62);
    -webkit-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.62);
    text-align: left;
}

.featuredRow {
    font-size: 15px;
    margin-bottom: 8px;
}