.grid {
  display: grid; 
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
}

.grid-element{
  height: 100%;
  overflow-y: auto;
}

.red{
  color: #87081D;
}

.green{
  color: #1C830C;
}

.disabled{
  color: rgb(83, 83, 83);
}

.bigger {
  line-height: 1.3;
  font-size: 1rem;
}

.dropdown{
  text-align: center;
  height: auto !important;
  max-height: 2000px;
}

/* Utilities */

.m-top-1{
  margin-top: 1rem;
}

.m-bottom-5px{
  margin-bottom: 5px;
}

.p-x-1{
  padding-right: 1rem;
  padding-left: 1rem;
}

.flexbox{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}