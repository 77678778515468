.loan-overview-document {
    padding: 0 32px;
}
.loan-overview-document .document-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}
.loan-overview-document .document-topbar .results-total {
    font-size: 18px;
    color: #6E6E70;
    font-weight: 500;
}
.loan-overview-document .document-topbar .verifiable-switch {
    display: flex;
    align-items: center;
}
.loan-overview-document .document-topbar .upload-btn {
    width: 90px;
    height: 46px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #F7F8FF;
    border: 1px solid #E3EAFD;
    color: #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.loan-overview-document .multiple-bar {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px 0 16px;
    border-radius: 23px;
    background-color: #F6F6F6;
    margin-bottom: 14px;
}
.loan-overview-document .multiple-bar .document-total {
    display: flex;
    align-items: center;
}
.loan-overview-document .multiple-bar .document-total .document-num {
    color: #6E6E70;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}
.loan-overview-document .multiple-bar .document-operate {
    display: flex;
    gap: 8px;
}
.loan-overview-document .multiple-bar .document-operate .document-operate-btn {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.loan-overview-document .multiple-bar .document-operate .document-operate-btn:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #F6F6F6;
}
.loan-overview-document .document-table .ant-table {
    font-size: 16px;
    color: #222;
    border: 1px solid #DBDCDE;
    border-radius: 8px;
    overflow: hidden;
}
.loan-overview-document .document-table .ant-table-cell-fix-right.ant-table-cell, .loan-overview-document .document-table .ant-table-tbody >tr >td.ant-table-cell-fix-left {
    background-color: #FFF !important;
}
.loan-overview-document .document-table .ant-table-container table>thead>tr:first-child >*:last-child, .loan-overview-document .document-table .ant-table-thead >tr>th.ant-table-cell-fix-left {
    background-color: #F5F5F7 !important;
}
.loan-overview-document .document-table .ant-table-thead {
    background-color: #F5F5F7;
}
.loan-overview-document .document-table .ant-table-thead >tr>th {
    padding: 10px 16px;
}
.loan-overview-document .document-table .ant-table-tbody >tr >td {
    padding: 22px 16px;
    border-top: 1px solid #DBDCDE;
    border-bottom: none;
}
.loan-overview-document .document-table .ant-table-tbody .ant-table-row.ant-table-row-selected {
    background-color: #EAEFFD;
}
.loan-overview-document .document-table .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell {
    background-color: #EAEFFD !important;
}
.loan-overview-document .document-table .table-action {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
}
.loan-overview-document .document-table .table-action .document-verify {
    height: 36px;
    font-size: 14px;
    color: #325CEB;
    font-weight: 500;
    padding: 0 16px;
    border-radius: 6px;
    border: 1px solid #E3EAFD;
    background-color: #F7F8FF;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.loan-overview-document .document-table .table-action .document-download {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.loan-overview-document .document-table .table-action .document-more {
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin-left: -12px;
}
.loan-overview-document .document-dropdown {
    width: 216px;
}
.loan-overview-document .document-table .document-filename{
    display: flex;
    align-items: center;
}
.loan-overview-document .document-table .document-filename .filename {
    margin: 0 2px 0 8px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.loan-overview-document .document-table .document-filename .filename div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.loan-overview-document .document-table .comment-add {
    width: 74px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #E3EAFD;
	background-color: #F7F8FF;
	font-family: Inter;
	font-size: 14px;
	color: #325CEB;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.loan-overview-document .document-table .table-comment {
	color: #4F4F4F;
	font-family: Inter;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	align-items: end;
}
.loan-overview-document .document-table .comment-text {
	width: calc(100% - 30px);
	display: -webkit-box; 
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}
.loan-overview-document .document-table .table-comment .comment-edit {
	color: #325CEB;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
}
.document-operate-modal .ant-modal-content {
	padding: 0;
}
.document-operate-modal .ant-modal-footer {
	height: 72px;
	padding: 0 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #ECECEC;
    margin-top: 0;
}
.document-operate-modal.upload-modal .ant-modal-footer {
    justify-content: end;
}
.document-operate-modal .document-operate-modal-content .operate-title {
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    color: #222;
    font-weight: 500;
    padding: 0 30px;
    border-bottom: 1px solid #ECECEC;
}
.document-operate-modal .document-operate-modal-content .operate-content {
    padding: 30px;
}
.document-operate-modal .ant-modal-close {
    top: 22px;
}
.document-operate-modal .ant-modal-footer .document-btn-save, .document-operate-modal .ant-modal-footer .document-btn-cancel {
	height: 46px;
    width: 90px;
	font-size: 16px;
	font-weight: 600;
}
.unverified-tooltip .ant-tooltip-inner {
    padding: 14px 32px 14px 12px;
    position: relative;
}