.chat-feedback {
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    display: inline-flex;
    stroke-width: 20;
    stroke: #6E6E70;
    font-size: 18px;
    margin-left: 30px;
    margin-right: 10px;
    overflow: hidden;
}

.chat-feedback-button {
    cursor: pointer;
    padding: 6px 10px;
    transition: background-color 0.3s ease;
}

.chat-feedback-button.active {
    background-color: #325CEB;
}

.chat-feedback-button.active img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.chat-feedback-content {
    border-radius: 8px;
    background: #d7e2fb;
    padding: 15px 20px;
    color: #222;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 25px 0 25px;
}

.chat-feedback-input-wrap {
    border-radius: 8px;
    background-color: #F6F6F6;
    margin: 10px 25px 0 25px;
    padding: 15px 20px;
}

.chat-feedback-input {
    width: 100%;
    border: none;
    padding: 5px 10px;
    min-width: 120px;
    margin-top: 10px;
    margin-bottom: 10px;
}
