.interview-mode {
    border-bottom: 3px solid #ECECEC;
    padding: 20px 48px;
    font-weight: 500;
    position: sticky;
    top: -1px;
    z-index: 100;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
}
.interview-mode-title {
    font-size: 16px;
}
@media (max-width: 767px) { 
    .interview-mode {
        padding: 20px 28px;
    }
}