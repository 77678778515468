.invalid-input label,
.invalid-input small{
  color: #721c24 !important;
}

.invalid-input input{
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.invalid-input input::placeholder{
  color: #721c24;
}

.valid-input label,
.valid-input small{
  color: #198754 !important;
}

.valid-input input{
  border-color: #198754;
  background-color: #daf8d7;
}

.valid-input input::placeholder{
  color: #198754;
}

.m-1{
  margin: 1rem;
}