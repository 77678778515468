.loansTableWrap thead.ant-table-thead {
    background-color: #F7F7F8;
}

.loansTableWrap.borrowerTableWrap .ant-table-wrapper .ant-table {
    border-radius: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
}

.loansTableWrap .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-radius: 0px;
    border-bottom: 1px solid #E8E8E8;
}

.loansTableWrap .ant-table-wrapper .ant-table-selection-column {
    padding-left: 25px;
}

.loansTableWrap .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-radius: 0px;
    padding-right: 60px;
}

.LoansTitle {
    height: 60px;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #DBDCDE;
}

.loansContent {
    margin: 35px 29px 0 29px;
}

.loansFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 72px;
    padding: 0 30px 0 11px;
    /* align-items: center; */

}
.loansFilter.borrowerFilter {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.emptyLoans {
    height: calc(100vh - 286px) ;display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loansStatus, .loansStage {
    display: flex;

}

.overflowScl {
    max-height: 400px;
    overflow-y: scroll;
}

.loansStatus .ant-tag,
.loansStage .ant-tag {
    width: 80%;
    font-weight: 600;
    border-radius: 6px 0 0 6px;
    padding: 5px 6px;
    margin-right: 2px;
    display: inline-flex;
    align-items: center;
}

.loanStatusSelect, .loanStageSelect {
    width: 2em;
}

.loansStatus .ant-btn-default,
.loansStage .ant-btn-default {
    border-radius: 0 6px 6px 0;
    padding: 0 6px;
    border: 1.2px solid #ECECEC;
    background-color: #FCFCFC;
}

.loansStatus:hover,
.loansStage:hover {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
}


.loansStatus .ant-tag.ant-tag-has-color,
.loansStage .ant-tag.ant-tag-has-color {
    color: #222222;
}

.ant-btn-default.loansAddLOButton {
    color: #325CEB;
    border-radius: 4px;
    border: 1px solid #E3EAFD;
    background: #F7F8FF;
    font-weight: 500;
}


@media (max-width: 576px) {
    .loansTableWrap {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media (min-width: 577px) {
    .allLoansTable .ant-pagination {
        margin-right: 60px !important;
    }
}

.ant-table-thead th.loansFixedColumn {
    background-color: #F5F5F7 !important;
}

.allLoansTable .ant-table-cell-fix-left {
    background-color: #ffffff !important;
}

.selectableBackground:hover .ant-table-cell-fix-left {
    background-color: #D6E2FB !important;
    transition: background-color 0.3s ease !important;
}

.unSelectableBackground:hover .ant-table-cell-fix-left {
    background-color: #DBDCDE !important;
    transition: background-color 0.3s ease !important;
}

.allLoansTable .ant-table-thead .ant-table-selection-column {
    background-color: #F5F5F7 !important;
}

.loansTableWrap .ant-btn-default {
    box-shadow: none;
}

.filtersWraper .ant-select-selector {
    border-radius: 4px;
    border: 2px solid #ECECEC !important;
    background-color: #FCFCFC !important;
    height: 42px;
    width: 137px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
}

.filtersWraper {
    flex-direction: column;
}

.filtersWraper .ant-select-selection-placeholder {
    color: #222222;
    font-weight: 500;
}

.filtersWraper .ant-input {
    color: #222222;
    font-weight: 500;
}

.filtersWraper .ant-input-group-addon .ant-btn {
    width: 42px;
    height: 42px;
}

.filtersWraper .ant-input-affix-wrapper {
    border: 2px solid #ECECEC !important;
}

.loansSearchbar {
    width: calc(100% - 40px);
    margin: 0 0 15px 20px;
}

.newLoansSearchbar {
    width: calc(100% - 40px);
    /* margin: 0 0 15px 20px; */
}

.loansFilters {
    width: calc(100% - 40px);
    height: 42px;
    margin: 0 0 15px 20px;
}

.newLoansFilters {
    width: calc(100% - 40px);
    height: 42px;
    margin-left: 15px;
    /* margin: 0 0 15px 20px; */
}

.newLoansTable .ant-table-thead {
    background: #DBDCDE !important;
}

.newLoansTable .ant-table-thead th.loansFixedColumn {
    background: #DBDCDE !important;
}

.newLoansTable .ant-table-thead>tr>th {
    color: #222;
    font-family: Inter;
    font-size: 14px;

    font-weight: 600;

}

.filtersWraper .ant-input::placeholder {
    color: #6E6E70;
    font-weight: 400;
}

.filtersWraper .search-bar {
    flex-direction: column;
}

.filtersWraper .viewMode-bar {
    display: flex;
}

.loansTable .multiple-bar {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px 0 16px;
    margin: 0 20px;
    border-radius: 23px;
    background-color: #F6F6F6;
    margin-bottom: 14px;
}

.loansTable .multiple-bar .document-total {
    display: flex;
    align-items: center;
}

.loansTable .multiple-bar .document-total .document-num {
    color: #6E6E70;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.loansTable .multiple-bar .document-operate {
    display: flex;
    gap: 8px;
}

.loansTable .multiple-bar .document-operate .document-operate-btn {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.loansTable .multiple-bar .document-operate .document-operate-btn:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #F6F6F6;
}

.unclickableText {
    color: #222;
}

.clickableText {
    cursor: pointer;
    color: #222;
}

.selectableBackground:hover {
    background-color: #D6E2FB;
    cursor: pointer;
    transition: background-color 0.3s ease !important;
}

.unSelectableBackground:hover {
    background-color: #DBDCDE !important;
    transition: background-color 0.3s ease !important;
}

.allLoansTable .ant-table-tbody>tr:hover {
    background-color: #D6E2FB;
    transition: background-color 0.3s ease !important;
}

@media (min-width: 1200px) {
    .filtersWraper {
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .filtersWraper .search-bar {
        flex-direction: row;
    }

    .loansSearchbar {
        width: 40%;
    }

    .loansFilters {
        width: 190px;
    }
}