.notsubmitted {
    margin-top: 100px;
    font-size: 20px;
}

.top_info {

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.downloadButton {

    color: #325CEB;
    height: 40px;
    font-weight: 600px;

}

.newLabel {
    color: #222;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* line-height: normal; */

}

.newLabelText {
    color: #222;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;


}

.ant-card-head {
    background-color: #F6F6F6 !important;
}

.ant-card-head-title {
    color: #6E6E70;

    font-size: 20px!important;
    font-style: normal;
    font-weight: 400;

    /* 100% */
}