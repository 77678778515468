.column {
  padding: 0.5rem;
  flex: 1 0 250px;
  background-color: rgb(235, 236, 240);
  border-radius: 0.5rem;
  margin: 1rem 1rem 1rem 0;
}

.header {
  color: var(--primary-pale-text);
  padding: 0.6rem 0 0 0.6rem;
  text-align: left;
  font-weight: bold;
  line-height: 18px;
  font-size: 1.1rem;
}