.document-preview-modal {
    top: 0;
}
.document-preview-modal .ant-modal-content {
    padding: 0;
    top: 60px;
}
.document-preview-modal .document-preview-content {
    max-height: calc(100vh - 60px);
    min-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.document-preview-modal .document-preview-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: #FFF;
    background: transparent;
    padding: 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.document-preview-modal .document-preview-bottom-bar {
    height: 48px;
    width: 330px;
    border-radius: 8px;
    color: #FFF;
    background: rgba(0, 0, 0, 0.80);
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -165px;
    display: flex;
    align-items: center;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination {
    flex: 1;
    padding: 9px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #FFF;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination-prev button, .document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination-next button {
    color: #FFF;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination {
    color: #FFF;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination .ant-pagination-slash {
    margin-inline-start: 0;
    margin-inline-end: 0;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: rgba(0, 0, 0, 0.90);
    width: 40px;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-scale {
    flex: 1;
    display: flex;
    gap: 8px;
    padding: 9px 12px;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-scale .zoom-min, .document-preview-modal .document-preview-bottom-bar .document-preview-scale .zoom-add {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-scale .zoom-value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-preview-modal .document-preview-top-bar .document-preview-top-bar-l {
    display: flex;
    align-items: center;
}
.document-preview-modal .document-preview-top-bar .document-preview-top-bar-l .document-preview-verify {
    width: 98px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #FFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    cursor: pointer;
}
.document-preview-modal .document-preview-top-bar .document-preview-top-name {
    font-size: 18px;
    font-weight: 500;
    margin-left: 12px;
}