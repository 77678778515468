.mortgagegpt {
    height: 100%
}


.guidelinegpt-limit-reached {
    padding: 40px 40px 32px;
}
.guidelinegpt-limit-reached .ant-btn-link {
    font-size: 16px;
    font-weight: 500;
    color: #3B3BC7;
}

@media (max-width: 450px) {
    .guidelinegpt-limit-reached {
        padding: 20px 20px 16px;
    }

    .guidelinegpt-limit-reached .ant-btn-link {
        font-size: 12px !important;
    }
}