.photo-cropper-modal {
    top: 0;
    padding-bottom: 0;
    height: 100%;
}
.photo-cropper-modal .ant-modal-content {
    padding: 0 0 24px 0;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.photo-cropper-modal .ant-modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.photo-cropper-modal .ant-modal-footer {
    padding: 0 28px 30px;
    display: flex;
    justify-content: space-between;
}
.photo-cropper-modal .zoom-slider-wrap {
    display: flex;
    align-items: center;
    margin: 6px 0 32px;
}
.photo-cropper-modal .zoom-slider-wrap .zoom-slider {
    margin: 0 12px;
    flex: 1;
}
.photo-cropper-modal .cropper-modal-btn {
    font-family: Roboto;
    width: 135px;
    height: 44px;
    border: 1px solid #325CEB;
}
.photo-cropper-modal .cropper-modal-btn.ghost-btn {
    color: #325CEB;
}
.photo-cropper-modal .cropper-setting {
    padding: 0 28px;
}
.photo-cropper-modal .circle-cropper .cropper-view-box {
    border-radius: 50%;
    outline: 4px solid #325CEB;
}
.photo-cropper-modal .circle-cropper .cropper-point {
    width: 10px;
    height: 10px;
    border: 2px solid #325CEB;
    background-color: #FFF;
    border-radius: 50%;
}
.photo-cropper-modal .circle-cropper .cropper-point.point-n {
    margin-left: -5px;
    top: -8px;
}
.photo-cropper-modal .circle-cropper .cropper-point.point-e {
    margin-top: -5px;
    right: -8px;
}
.photo-cropper-modal .circle-cropper .cropper-point.point-s {
    margin-left: -5px;
    bottom: -8px;
}
.photo-cropper-modal .circle-cropper .cropper-point.point-w {
    left: -8px;
    margin-top: -5px;
}
.photo-cropper-modal .circle-cropper .cropper-face {
    border-radius: 50%;
}
.photo-cropper-modal .circle-cropper .cropper-line {
    display: none;
}
.photo-cropper-modal .circle-cropper .point-ne, .photo-cropper-modal .circle-cropper .point-nw, .photo-cropper-modal .circle-cropper .point-sw, .photo-cropper-modal .circle-cropper .point-se {
    display: none;
}