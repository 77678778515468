.progress-box {
    display: flex;
    margin-bottom: 16px;
}
.progress-box .progress-box-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-right: 16px;
}
.progress-box .left-progress {
    flex: 1;
    margin-right: 10px;
}
.progress-box .left-progress .module-progress.undone .ant-progress-bg {
    background-color: #989898;
}
.progress-box .left-progress .module-progress.normal .ant-progress-bg {
    background-color: #fbbc04;
}
.progress-box
    .left-progress
    .module-progress.ant-progress-status-success
    .ant-progress-bg {
    background-color: #34a853;
}
.progress-box .percent {
    color: #000;
    font-family: ABC Ginto Nord Trial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.require-tag {
    height: 21px;
    border-right: 1px solid #000;
    margin-top: -12px;
    position: relative;
}
.require-tag span {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    position: absolute;
    right: -64px;
    top: 6px;
}
.ant-input-lg {
    padding: 9px 11px;
    border-radius: 4px;
}
.theme-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 12px;
}
.theme-subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 12px;
}
.basic-info-form {
    display: flex;
    flex-direction: column;
}
.basic-info-form .form-row {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-bottom: 10px;
}
.basic-info-form .form-row:last-child {
    margin-bottom: 0;
}
.basic-info-form .upload-section {
    flex: 0 0 auto;
    min-width: 251px;
}
.basic-info-form .form-section {
    flex: 1;
}
.basic-info-form .upload-section .ant-upload-wrapper {
    min-height: 314px;
    border-radius: 6px;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.basic-info-form .upload-section .ant-upload-wrapper:has(.upload-placeholder) {
    background: #f3f5f6;
}
.basic-info-form .form-section {
    flex: 1;
}
.basic-info-form .ant-form-item-label {
    padding: 0 0 4px;
}
.basic-info-form .ant-form-item-label > label {
    font-size: 16px;
    height: 20px !important;
    color: #586570;
    font-weight: 500;
}
.basic-info-form .form-item-title {
    color: #586570;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
}
.upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}
.upload-placeholder img {
    width: 69px;
    height: 69px;
}
.upload-placeholder .upload-btn {
    position: absolute;
    top: calc(50% + 80px);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 15px;
    width: 115px;
    height: 40px;
    padding: 12px 12px 12px 9px;
    background: #ffffff;
    border: 1px solid #325ceb;
    border-radius: 6px;
    cursor: pointer;
}

.upload-placeholder .upload-btn:hover {
    background: #f7f8ff;
}
.upload-placeholder .upload-btn img {
    width: 24px;
    height: 24px;
}

.upload-placeholder .upload-btn span {
    font-size: 14px;
    font-weight: 500;
    color: #325ceb;
}
.basic-info-form .profile-photo {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
}
.basic-info-form .profile-photo:hover {
    border: 2px solid #325ceb;
}
.basic-info-form .ant-select {
    height: auto;
}
.basic-info-form .remove-photo {
    margin-left: 12px;
    cursor: pointer;
}
.basic-info-form .optional {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 8px 0 16px 0;
}
.basic-info-form .optional span {
    margin-right: 8px;
}
.basic-info-form .optional .up {
    transform: rotate(-180deg);
}
.basic-info-form .link-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 0;
}
.basic-info-form .link-list .link-list-item {
    width: 132px;
    height: 44px;
    border-radius: 6px;
    padding: 6px 15px;
    background-color: #fcfcfc;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: #222;
    border: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.basic-info-form .link-list .link-list-item.link-completed {
    background-color: #f7f8ff;
    border: 1px solid #e3eafd;
    color: #325ceb;
    font-weight: 600;
}
.basic-info-form .link-edit-item {
    margin-bottom: 20px;
}
.basic-info-form .link-edit-item .link-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #586570;
    margin-bottom: 8px;
}
.basic-info-form .link-edit-item .link-edit {
    display: flex;
    align-items: center;
}
.basic-info-form .link-edit-item .link-edit .link-remove {
    margin-left: 22px;
    cursor: pointer;
}
.basic-info-form > .form-divider {
    width: calc(100% + 107px);
    height: 1px;
    background-color: #dbdcde;
    margin: 40px -53.5px;
    position: relative;
}
.tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.tag-item {
    height: 32px;
    border-radius: 6px;
    background-color: #eaeffd;
    padding: 2px 5px;
    gap: 10px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #222222;
    user-select: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-right: 11px;
}
.tag-item-add {
    font-weight: 400;
    color: #6e6e70;
    margin-bottom: 8px;
}
.tag-input.ant-input-sm {
    width: 200px;
    height: 32px;
    margin-inline-end: 8px;
    vertical-align: top;
}
.ant-tag .ant-tag-close-icon {
    color: #000;
    font-size: 12px;
}
.basic-info-form
    .ant-select-lg
    .ant-select-selector
    .ant-select-selection-item {
    background-color: #eaeffd !important;
    border-radius: 6px !important;
    padding: 0 12px !important;
    margin-right: 4px !important;
    min-height: 44px !important;
    height: auto !important;
    display: inline-flex !important;
    align-items: center !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #325ceb !important;
    line-height: 24px !important;
}
.ant-select-selection-item .ant-select-selection-item-remove {
    margin-left: 8px !important;
    color: #325ceb !important;
    font-size: 12px !important;
}
.basic-info-form .ant-select-lg .ant-select-selector {
    padding: 6px 8px !important;
    min-height: 44px !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    gap: 4px !important;
}
.basic-info-form .popular-tags {
    min-height: 30px;
}

.applyBtnTooltip .ant-popover-content {
    background-color: rgba(0, 0, 0, 0.88);
    width: 400px;
}

.applyBtnTooltip .ant-popover-inner {
    background-color: rgba(0, 0, 0, 0.88);
}

.applyBtnTooltip .ant-popover-inner-content {
    color: white;
}
