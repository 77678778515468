.btn-task-desc {
	height: 44px;
	border: 1px solid #ced4da !important;
	/* border-radius: 2px !important;
	color: #325CEB !important;
	font-size: 16px !important;	 */
}

.react-datepicker__input-container input {
    border: 1px solid #ced4da !important;
}
.currentChecklistWrap {
	text-align: left;
	padding: 24px 36px;
}
.currentChecklistWrap .ant-table .ant-table-thead .ant-table-cell-fix-right {
    background-color: #F5F5F7 !important;
}
.currentChecklistWrap .ant-table .ant-table-tbody .ant-table-cell-fix-right {
    background-color: #FFF !important;
}
.currentChecklistWrap .checklist-title {
	font-family: Inter;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 18px;
}
.currentChecklistWrap .checklist-title .checklist-title-l {
	font-size: 20px;
	color: #222;
	font-weight: 600;
}
.currentChecklistWrap .checklist-title .checklist-title-r {
	font-weight: 500;
	height: 30px;
	padding: 0 10px;
	border-radius: 15px;
	background-color: #E1F2E2;
	display: flex;
	align-items: center;
	gap: 10px;
}
.currentChecklistWrap .add-document-bar {
	display: flex;
	justify-content: space-between;
}
.currentChecklistWrap .add-document-bar .add-document-btn {
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	display: flex;
	width: 186px;
	height: 45px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 6px;
	border: 1px solid #325CEB;
	background-color: #325CEB;
	cursor: pointer;
}
.currentChecklistWrap .add-document-bar .aciton-bar {
	display: flex;
	gap: 6px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
}
.currentChecklistWrap .add-document-bar .aciton-bar .filter-btn {
	color: #325CEB;
	display: flex;
	height: 45px;
	width: 104px;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 8px;
	background-color: #F7F8FF;
	cursor: pointer;
	position: relative;
}
.currentChecklistWrap .add-document-bar .aciton-bar .filter-btn .select-count {
	font-size: 10px;
	color: #FFF;
	width: 18px;
	height: 18px;
	border-radius: 9px;
	border: 3px solid #FFF;
	background-color: #325CEB;
	position: absolute;
	right: -9px;
	top: -9px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.currentChecklistWrap .add-document-bar .aciton-bar .filter-btn.select-filter {
	border: 2px solid #325CEB;
	background-color: #FFF;
}
.currentChecklistWrap .add-document-bar .aciton-bar .filter-btn.filter-disable {
	background-color: #F5F5F7;
	color: #6E6E70;
	cursor: no-drop;
}
.currentChecklistWrap .add-document-bar .aciton-bar .ant-space-compact {
	width: auto;
}
.currentChecklistWrap .add-document-bar .aciton-bar .ant-space-compact .ant-btn-default {
	border: none;
	box-shadow: none;
}
.currentChecklistWrap .add-document-bar .aciton-bar .download-all-btn {
	display: flex;
	height: 45px;
	width: 45px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background-color: #F7F8FF;
	cursor: pointer;
}
.currentChecklistWrap .add-document-bar .aciton-bar .download-all-btn img {
	width: 20px;
	height: 20px;
}
.currentChecklistWrap .add-document-bar .aciton-bar .filter-btn.disable {
	color: #6E6E70;
	background-color: #DBDCDE;
}
.currentChecklistWrap .add-document-bar .aciton-bar .download-all-btn.download-all-disable {
	background-color: #DBDCDE;
	cursor: not-allowed;
}
.currentChecklistWrap .document-checklist {
	margin-top: 16px;
}

.currentChecklistWrap .document-checklist .document-checklist-item {
	height: 43px;
	padding: 0 32px 0 48px;
	border-radius: 8px;
	border: 1px solid #DBDCDE;
	background-color: #F5F5F7;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: Inter;
	font-size: 14px;
}
.checklist-item-title {
	font-family: Inter;
	color: #222;
	font-weight: 600;
}
.checklist-item-num {
	color: #545459;
	font-weight: 500;
}
.document-table-wrap {
	border: 1px solid #DBDCDE;
	overflow: hidden;
	border-radius: 8px;
	margin-bottom: 12px;
	transition: all .3s;
}
.document-table-wrap.expand {
	height: 44px;
}
.document-table-wrap.uncategorized-expand {
	height: 44px;
}
.table-wrap .document-request .ant-table-tbody >tr >td {
	padding: 16px 0 16px 10px;
}
.table-wrap .document-request .borrower-doc-select .ant-select-selector {
	border: 1px solid #325CEB;
}
.table-wrap .document-request .borrower-doc-select.hover .ant-select-selector {
	border: 1px solid #DBDCDE;
}
.table-wrap .document-request .borrower-doc-input .ant-select-selector {
	border: 1px solid #325CEB;
}
.table-wrap .document-request .borrower-doc-input.hover .ant-select-selector {
	border: 1px solid #DBDCDE;
}
.document-table-wrap .document-table .ant-table-thead>tr>.ant-table-cell {
	padding: 7px 16px;
	background-color: #F5F5F7;
}
.document-table-wrap .document-table.internalDocuments .ant-table-thead>tr>.ant-table-cell {
	background-color: #F2F7FF !important;
}
.document-table-wrap .document-table .ant-table-tbody >tr >td {
	border-bottom: none;
	background: none;
	border-top: 1px solid #DBDCDE;
}
.document-table-wrap .document-table .ant-table-tbody .ant-table-row.verified-document .ant-table-row-expand-icon-cell {
	border-left: 4px solid #95D6A3;
}
.document-table-wrap .document-table .ant-table-expanded-row .ant-table-tbody >tr:first-child >td {
	border-top: none;
}
.document-table-wrap .document-table .ant-table-expanded-row {
	background-color: #F5F5F7;
}
.document-table-wrap .document-table .ant-table-expanded-row-fixed {
	padding: 16px 0;
}
.document-table-wrap .ant-table-expanded-row > .ant-table-cell {
	padding: 0;
}
.document-table-wrap .document-table .ant-table-expanded-row .ant-table {
	margin-block: 0 !important;
    margin-inline: 0 !important;
}
.document-table-wrap .document-table .ant-table-expanded-row .ant-table-tbody >tr>td {
	background-color: #F5F5F7 !important;
	padding: 0 16px;
	height: 54px;
}
.document-table-wrap .document-table.uncategorized-doc .ant-table-thead>tr>.ant-table-cell {
	background-color: #EAEFFD !important;
}
.document-table-wrap .document-table .document-header-name {
	color: #222;
	font-weight: 600;
	margin-left: -16px;
}
.document-table-wrap .document-table .doc-processing .processing-text {
	color: #545459;
	margin-left: 16px;
}
.document-table-wrap .document-table .document-name {
	color: #222;
	font-family: Inter;
	font-weight: 500;
	line-height: 20px;
	margin-left: -16px;
	/* display: flex;
	align-items: center;
	gap: 6px; */
	margin-bottom: 0;
}
.document-table-wrap .document-table .document-name-wrap .document-name:hover {
	color: #325CEB;
	cursor: pointer;
}
.document-table-wrap .document-table .document-status {
	padding: 5px 10px;
	font-family: Inter;
	font-weight: 600;
	line-height: 20px;
	border-radius: 15px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: default;
	gap: 5px;
}
.document-table-wrap .document-table .document-action-wrap {
	display: flex;
	align-items: center;
	gap: 14px;
}
.document-table-wrap .document-table .ant-table-column-sorters {
	justify-content: initial;
}
.document-table-wrap .document-table .ant-table-column-sorter {
	margin-inline-start: 8px;
}
.document-table-wrap .document-table .ant-table-column-title {
	flex: none;
}
.document-table-wrap .document-table .preview-filename {
	font-family: Inter;
	color: #545459;
}
.document-table-wrap .document-table .preview-filename:hover {
	color: #325CEB;
	cursor: pointer;
}
.document-table-wrap .document-table .document-action-wrap .document-view {
	font-family: Inter;
	font-weight: 500;
	width: 75px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	cursor: pointer;
}
.document-table-wrap .document-table .collapse-header-arrow {
	display: flex;
	flex-direction: row-reverse;
}
.document-table-wrap .document-table .collapse-header-arrow .down {
	transform: rotate(-180deg);
	transition: all .3s;
}
.document-table-wrap .document-table .collapse-header-arrow .up {
	transform: rotate(0);
	transition: all .3s;
}
.document-table-wrap .document-table .collapse-header-arrow .down, .document-table-wrap .document-table .document-action-wrap.uncategorized-wrap .down {
	transform: rotate(-180deg);
	transition: all .3s;
}
.document-table-wrap .document-table .collapse-header-arrow .up, .document-table-wrap .document-table .document-action-wrap.uncategorized-wrap .up{
	transform: rotate(0);
	transition: all .3s;
}
.document-checklist-dropdown .ant-dropdown-menu {
	background-color: #F6F6F6;
	border-radius: 10px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 10px 4px;
}
.document-checklist-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
	color: #545459;
	font-weight: 500;
}
.document-checklist-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
	margin-inline-end: 10px;
}
.document-reminder-dropdown .ant-dropdown-menu {
	border-radius: 8px;
	background: #FFF;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.14);
	padding: 6px 0;
}
.document-reminder-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
	width: 268px;
	padding: 13px 0 13px 26px;
	font-family: Inter;
	font-size: 14px;
	color: #222;
}
.document-reminder-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
	color: #DBDCDE;
}
.document-reminder-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
	background-color: #D6E2FB;
}
.document-table-wrap .document-table .document-action-wrap .document-more {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	cursor: pointer;
}
.document-table-wrap .document-table .document-action-wrap .document-more:hover {
	background-color: rgba(162, 162, 162, 0.10);
}
.document-table-wrap .document-table .document-action-wrap .document-more img {
	width: 100%;
	height: 100%;
}
.currentChecklistWrap .task-topBar {
	display: flex;
	justify-content: space-between;
}
.currentChecklistWrap .task-topBar .search-bar {
	display: flex;
	margin-bottom: 20px;
}
.currentChecklistWrap .task-topBar .search-bar .statusFilters {
	height: 42px;
	min-width: 100px;
}
.currentChecklistWrap .task-topBar .search-bar .search-task {
	height: 42px;
}
.currentChecklistWrap .task-topBar .create-task {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 1px solid #E3EAFD;
	background-color: #F7F8FF;
	color: #325CEB;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	height: 42px;
	padding: 0 20px;
	cursor: pointer;
	margin-bottom: 20px;
}
.currentChecklistWrap .no-tasks {
	font-family: Inter;
	height: 236px;
	padding: 40px 0;
	border-radius: 8px;
	background-color: #F6F6F6;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.currentChecklistWrap .no-tasks img {
	width: 104px;
	height: auto;
}
.currentChecklistWrap .no-tasks .title-text {
	color: #000;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
}
.currentChecklistWrap .no-tasks .sub-text {
	color: #6E6E70;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header {
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 16px;
	color: #222;
	line-height: 20px;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-type {
	width: 25%;
	font-size: 16px;
	font-weight: 500;
	color: #222;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-update {
	width: 35%;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-completed {
	display: flex;
	align-items: center;
	font-weight: 500;
	width: 25%;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-completed .task-completed-num {
	margin-left: 8px;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header .no-tasks-text {
	color: #6E6E70;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-add {
	color: #325CEB;
	font-weight: 600;
	cursor: pointer;
	width: 15%;
	text-align: right;
	padding-right: 10px;
}
.currentChecklistWrap .task-content .task-collapse {
	background-color: #FFF;
	border: none;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse {
	background-color: #FFF;
}
.task-collapse>.ant-collapse-item {
	border: 1px solid #DBDCDE;
	transition: all .3s;
	margin-bottom: 12px;
	border-radius: 8px !important;
}
.task-collapse>.ant-collapse-item:hover {
	box-shadow: 0px 4px 8px 0px rgba(7, 13, 61, 0.12);
}

.task-collapse>.ant-collapse-item >.ant-collapse-header {
	padding: 18px 16px;
	align-items: center;
}
.task-collapse .ant-collapse-content {
	/* padding: 12px 0 22px; */
	margin: 0 16px;
}

.create-task-modal .ant-modal-content {
	padding: 15px 0 0;
}
.create-task-modal .ant-modal-close {
	right: 42px;
	top: 42px;
}
.create-task-modal .task-modal-wrap {
	font-family: Inter;
	height: 100%;
}
.create-task-modal .task-modal-wrap .ant-spin-nested-loading {
	height: 100%;
}
.create-task-modal .task-modal-wrap .ant-spin-nested-loading .task-spin {
	max-height: 100%;
}
.ant-spin-nested-loading >div>.task-spin .ant-spin-text {
	padding-top: 26px;
	color: #6E6E70;
	font-size: 16px;
}
.create-task-modal .task-modal-wrap .create-task-title {
	color: #222;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	padding: 26px 42px;
	border-bottom: 1px solid #DBDCDE;
}
.create-task-modal .task-modal-wrap .task-form {
	height: 100%;
	display: flex;
    flex-direction: column;
}
.create-task-modal .task-modal-wrap .create-task-content {
	padding: 0 42px;
	padding-top: 9px;
	overflow: auto;
}
.create-task-modal .task-modal-wrap .create-task-content .auto-generate-btn {
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	height: 60px;
	padding: 0 15px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 6px;
	border: 1px solid #325CEB;
	background-color: #325CEB;
	cursor: pointer;
	margin: 62px auto 24px;
}
.create-task-modal .task-modal-wrap .create-task-content .manually-add {
	color: #325CEB;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	display: inline-flex;
}
.create-task-modal .task-modal-wrap .create-task-content .select-file {
	display: inline-flex;
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: normal;
	padding: 13px 33px;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #325CEB;
	background: #325CEB;
	cursor: pointer;
}
.create-task-modal .task-modal-wrap .create-task-content .file-list {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}
.create-task-modal .task-modal-wrap .create-task-content .file-list .upload-doc-item {
	color: #000;
	font-weight: 500;
	font-family: Inter;
	height: 45px;
	font-size: 16px;
	padding: 0 10px 0 15px;
	align-items: center;
	gap: 25px;
	border-radius: 10px;
	border: 1px solid #DBDCDE;
	display: flex;
	align-items: center;
}
.create-task-modal .task-modal-wrap .create-task-content .file-list .upload-doc-item > div {
	display: flex;
	align-items: center;
}
.create-task-modal .task-modal-wrap .create-task-content .file-list .upload-doc-item .pdf-icon {
	width: 24px;
	height: 24px;
	margin-right: 6px;
}
.create-task-modal .task-modal-wrap .create-task-content .file-list .upload-doc-item .delete-doc {
	color: #6E6E70;
	cursor: pointer;
}
.create-task-modal .task-modal-wrap .create-task-content .form-item-label {
	padding: 8px 0;
	color: #586570;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
}
.create-task-modal .task-modal-wrap .create-task-content .document-input {
	background-color: #FCFCFC;
    border: 1px solid #ECECEC;
	font-size: 16px;
	border-radius: 6px;
}
.create-task-modal .task-modal-wrap .create-task-content .edit-wrap {
	font-size: 16px;
	color: #222;
	height: 52px;
	display: flex;
	align-items: center;
	padding-left: 20px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select .ant-select-selector {
	padding: 6px;
	height: 52px;
	background-color: #FCFCFC;
    border: 1px solid #ECECEC;
	font-size: 16px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-single {
	height: 52px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-single .ant-select-selector {
	padding: 0 20px;
	font-size: 16px;
	color: #222;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 50px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-date {
	height: 52px;
	padding: 4px 20px 4px;
	background-color: #FCFCFC;
    border: 1px solid #ECECEC;
}
.create-task-modal .task-modal-wrap .create-task-content .task-date .ant-picker-input >input {
	font-size: 16px;
	color: #222;
}
.create-task-modal .task-modal-wrap .create-task-content .checkbox-title {
	color: #000;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 32px;
	margin-bottom: 2px;
}
.create-task-modal .task-modal-wrap .create-task-content .checkbox-subtitle {
	color: #6E6E70;
	font-family: Inter;
	font-size: 13px;
	line-height: normal;
	padding-left: 30px;
}
.create-task-modal .ant-modal-footer {
	height: 72px;
	padding: 0 42px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #ECECEC;
	margin-top: 20px;
}
.create-task-modal .ant-modal-footer .task-btn-cancel {
	height: 42px;
	font-size: 16px;
	font-weight: 600;
	padding: 0 35px;
}
.create-task-modal .ant-modal-footer .task-btn-save {
	height: 42px;
	font-size: 16px;
	font-weight: 600;
	padding: 0 20px;
}

.ant-message .ant-message-notice.task-message .ant-message-notice-content {
	font-size: 16px;
	color: #222;
	background-color: #F7F8FF;
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.18);
}
.ant-message .ant-message-notice.task-message .ant-message-notice-content .ant-message-custom-content {
	display: flex;
}
.ant-message .ant-message-notice.task-message .ant-message-notice-content .ant-message-custom-content .anticon svg {
	color: #1630AC;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .table-wrap {
	border: 1px solid #ECECEC;
	border-radius: 8px;
	overflow: hidden;
}
.table-wrap .document-table .message-add {
	width: 74px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #E3EAFD;
	background-color: #F7F8FF;
	font-family: Inter;
	font-size: 14px;
	color: #325CEB;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.table-wrap .document-table .ant-table-thead {
	background-color: #F5F5F7;
}
.table-wrap .document-table .ant-table-cell-fix-right.ant-table-cell {
	background-color: #FFF !important;
}
.table-wrap .document-table .ant-table-thead .ant-table-cell-fix-right.ant-table-cell {
	background-color: #F5F5F7 !important;
}
.table-wrap .ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-thead >tr>td {
	padding: 10px 16px;
}

.table-wrap .document-table .document-save {
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	width: 72px;
	height: 40px;
	padding: 8px 15px;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #325CEB;
	background-color: #325CEB;
	cursor: pointer;
}
.table-wrap .table-document {
	color: #222;
	font-size: 16px;
	font-weight: 500;
	font-family: Inter;
}
.table-wrap .table-date {
	color: #222;
	font-size: 16px;
	text-decoration-line: underline;
	font-family: Inter;
	cursor: pointer;
}
.table-wrap .table-message {
	color: #4F4F4F;
	font-family: Inter;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	align-items: center;
    height: 40px;
}
.table-wrap .hover-mask {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.table-wrap .message-text {
	width: calc(100% - 30px);
	display: -webkit-box; 
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}
.table-wrap .msg-content {
	padding-left: 6px;
	color: #222;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.table-wrap .table-message .message-edit {
	color: #325CEB;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
}
.table-wrap .document-name-wrap {
	display: flex;
	align-items: center;
}
.table-wrap .document-name-wrap .table-document-file {
	width: 150px;
	height: 36px;
	border-radius: 4px;
	border: 1px solid #DBDCDE;
	background-color: #FCFCFC;
	margin-left: 12px;
	display: flex;
	align-items: center;
	padding: 0 8px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	color: #222;
	cursor: pointer;
}
.table-wrap .document-name-wrap .table-document-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.table-wrap .document-name-wrap .table-document-file img {
	width: 22px;
	height: 22px;
	margin-right: 8px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document-btn {
	color: #325CEB;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	margin: 22px 0;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document {
	border-radius: 10px;
	border: 1px solid #ECECEC;
	margin-top: 26px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-title {
	color: #222;
	font-size: 16px;
	font-weight: 500;
	padding: 0 24px;
	line-height: 20px;
	height: 54px;
	border-bottom: 1px solid #ECECEC;
	display: flex;
	align-items: center;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-content {
	padding: 18px 30px 20px 24px;
}
.create-task-modal .task-modal-wrap .ant-spin-container {
	height: 100%;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-operate {
	height: 72px;
	padding: 0 30px 0 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #ECECEC;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-operate .add-document-cancel {
	height: 44px;
	width: 111px;
	font-size: 16px;
	font-weight: 600;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-operate .add-document-save {
	height: 44px;
	width: 111px;
	font-size: 16px;
	font-weight: 600;
	border: 1px solid #325CEB;
	color: #325CEB;
	background-color: #EAEFFD;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-content .add-document-label {
	color: #586570;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 8px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .table-wrap .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-more-btn {
	height: auto;
	padding: 15px 34px;
	color: #325CEB;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-more-btn:disabled {
	color: #979797;
}
.task-collapse .ant-collapse-content>.ant-collapse-content-box {
	padding: 16px 0;
}
.viewfiles-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    padding: 0 22px 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.viewfiles-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
}
.viewfiles-prevPage {
    position: fixed;
    left: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-nextPage {
    position: fixed;
    right: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-top-bar > div {
    display: flex;
    align-items: center;
}
.viewfiles-top-bar .close-icon {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 20px;
}
.viewfiles-top-bar .viewfiles-top-name {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
}
.viewfiles-top-bar .page-info {
    position: absolute;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    justify-content: center;
}
.viewFiles-modal .viewFiles-content {
    max-height: calc(100vh - 160px);
    /* max-width: 1280px; */
    overflow: auto;
}
.viewFiles-modal .viewFiles-modal-btn {
    width: 135px;
    height: 44px;
    font-weight: 500;
    border-radius: 4px;
    padding: 0;
}
.success-wrap  {
	padding: 64px 64px 120px;
    text-align: center;
}
.success-wrap img {
	height: 124px;
	width: 102px;
	margin-bottom: 32px;
}
.success-wrap .success-title {
	color: #222;
	font-family: "Playfair Display";
	font-size: 26px;
	font-weight: 700;
	margin-bottom: 12px;
}
.success-wrap .success-subtitle {
	color: #6E6E70;
	font-family: Inter;
	font-size: 16px;
	line-height: 22px;
}
.success-wrap .success-subtitle .email {
	color: #222;
	font-weight: 500;
	text-decoration-line: underline;
}
.signature-modal .ant-modal-content {
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 0;
}
.signature-modal .ant-modal-content .ant-modal-body {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-top {
	font-size: 18px;
	color: #222;
	font-weight: 500;
	height: 60px;
	border-bottom: 1px solid #DBDCDE;
	display: flex;
	align-items: center;
	padding: 0 42px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap {
	flex: 1;
	background-color: #E8E8EB;
	position: relative;
	overflow: hidden;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document {
	height: 100%;
	overflow: auto;
	display: flex;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom {
	width: 110px;
	height: 30px;
	border-radius: 6px;
	border: 1px solid #CFD4D9;
	display: flex;
	background-color: #FFF;
	position: absolute;
	right: 42px;
	bottom: 10px;
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom .zoom-min, .signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom .zoom-add {
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6E6E70;
	font-weight: bold;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom .zoom-value {
	color: #222;
	font-size: 14px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid #CFD4D9;
	border-right: 1px solid #CFD4D9;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document .signature-document-pdf {
	margin: 0 auto;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document .signature-document-pdf .rectangle-sign {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	background-color: rgba(50, 92, 235, 0.5);
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document .signature-document-pdf .rectangle-sign:hover {
	border: 1px solid #325CEB;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-bar {
	border-top: 1px solid #DBDCDE;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 42px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn {
	display: flex;
	align-items: center;
	justify-content: end;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .signatures-num {
	font-size: 16px;
	color: #6E6E70;
	margin-right: 16px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .add-signature {
	font-size: 16px;
	font-weight: 600;
	height: 46px;
	width: 90px;
	color: #325CEB;
	background-color: #EAEFFD;
	border-color: #325CEB;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .divider {
	width: 1px;
	height: 46px;
	background-color: #E8E8EB;
	margin: 0 16px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .save-signature {
	font-size: 16px;
	font-weight: 600;
	height: 46px;
	padding: 0 32px;
}
.document-checklist-modal.action-modal .ant-modal-content {
	padding: 100px 82px;
}
.document-checklist-modal.delete-modal .ant-modal-content {
	padding: 66px 68px 48px;
}
.transition-modal .ant-modal-content {
	padding: 100px 84px 70px;
}
.transition-modal .ant-modal-content .transition-title {
	margin-bottom: 76px;
	display: flex;
	align-items: center;
	gap: 20px;
}
.transition-modal .ant-modal-content .transition-title .transition-title-text {
	color: #081265;
	font-family: Inter;
	font-size: 24px;
	font-weight: 700;
}
.transition-modal .ant-modal-content .transition-content {
	color: #222;
	font-family: Inter;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 74px;
}
.transition-modal .ant-modal-content .transition-content .content-bold {
	font-weight: 600;
}
.transition-modal .ant-modal-content .transition-btn {
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	width: 240px;
	height: 50px;
	border-radius: 6px !important;
}
.document-checklist-modal.delete-modal .send-reminder {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.document-reminder-modal .document-reminder-wrap {
	font-family: Inter;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.document-reminder-modal .document-reminder-wrap img {
	height: 43px;
}
.document-checklist-modal.delete-modal .send-reminder > div {
	font-family: Inter;
	color: #000;
	line-height: 22px;
	font-size: 16px;
}
.document-checklist-modal.delete-modal .send-reminder img {
	height: 46px;
	margin-bottom: 26px;
}
.document-checklist-modal.delete-modal .delete-wrap .delete-title {
	color: #222;
	font-size: 20px;
	font-weight: 600;
	text-align: center;
}
.document-checklist-modal.delete-modal .delete-wrap .delete-content {
	color: #222;
	font-family: Inter;
	font-size: 16px;
	line-height: 24px;
	margin: 42px 0;
}
.document-checklist-modal.delete-modal .ant-modal-content .ant-modal-footer {
	display: flex;
	gap: 30px;
	justify-content: center;
}
.document-reminder-modal .ant-modal-content .document-reminder-btn {
	display: flex;
	justify-content: center;
	margin-top: 0;
}
.document-reminder-modal .ant-modal-content .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
	margin-inline-start: 0;
}
.document-reminder-modal .ant-modal-content .document-reminder-btn .doc-btn-cancel {
	font-size: 16px;
	color: #325CEB;
	font-weight: 500;
	height: 46px;
	border-radius: 6px;
	border: 1px solid #325CEB;
}
.document-reminder-modal .ant-modal-content .document-reminder-btn .doc-btn-confirm {
	font-size: 16px;
	color: #FFF;
	font-weight: 500;
	width: 128px;
	height: 46px;
	border-radius: 6px !important;
	background-color: #325CEB;
}
.document-checklist-modal.delete-modal .ant-modal-content .ant-modal-footer .doc-btn-cancel {
	font-size: 16px;
	color: #325CEB;
	font-weight: 500;
	height: 45px;
	border-radius: 6px;
	border: 1px solid #325CEB;
}
.document-checklist-modal.delete-modal .ant-modal-content .ant-modal-footer .doc-btn-confirm {
	font-size: 16px;
	color: #FFF;
	font-weight: 500;
	width: 128px;
	height: 45px;
	border-radius: 6px !important;
	background-color: #325CEB;
}
.document-checklist-modal .btn-tips {
	color: #545459;
	font-family: Inter;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 15px;
}
.document-checklist-modal .document-checklist-btn {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	display: flex;
	height: 60px;
	padding: 0 15px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 6px;
	border: 1px solid #325CEB;
	cursor: pointer;
}
.document-checklist-modal .document-checklist-btn.add {
	color: #FFF;
	background-color: #325CEB;
}
.document-checklist-modal .document-checklist-btn.upload {
	color: #325CEB;
}
.document-checklist-modal .document-divider.ant-divider-horizontal.ant-divider-with-text {
	margin: 32px 0;
}
.document-filter-content {
	border-radius: 10px;
	border: 1px solid #F6F6F6;
	background-color: #FFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	max-width: 70vw;
}
.document-filter-content .filter-title {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	font-family: Inter;
	border-bottom: 1px solid #F6F6F6;
}
.document-filter-content .filter-title span {
	color: #222;
	font-weight: 600;
}
.document-filter-content .filter-title .close-filter {
	font-weight: bold;
	color: #325CEB;
	cursor: pointer;
	position: absolute;
	right: 18px;
}
.document-filter-content .filter-checkbox-wrap {
	font-family: Inter;
	padding: 20px 38px 24px;
}
.document-filter-content .filter-checkbox-wrap .filter-checkbox-label {
	font-family: Inter;
	color: #545459;
	font-weight: 500;
}
.document-filter-content .filter-action {
	font-family: Inter;
	height: 60px;
	padding: 0 38px;
	border-top: 1px solid #F6F6F6;
}
.document-filter-content .filter-action .clear-filter {
	color: #325CEB;
	font-weight: 500;
	cursor: pointer;
}
.document-filter-content .filter-action .apply-filter {
	color: #FFF;
	font-weight: 500;
	width: 188px;
	height: 46px;
	border-radius: 6px;
	border: 1px solid #325CEB;
	background-color: #325CEB;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.create-task-modal.full-modal {
	height: 100vh;
}
.create-task-modal.full-modal {
	height: 100vh;
}
.create-task-modal.full-modal .ant-modal-content {
	border-radius: 0;
	height: 100vh;
	display: flex;
	flex-direction: column;
}
.create-task-modal.full-modal .ant-modal-content .ant-modal-body {
	flex: 1;
	overflow: auto;
}
.ant-tooltip.move-doc-tooltip {
	max-width: 164px;
	font-family: Inter;
	font-size: 12px;
	line-height: 20px;
}
.ant-message-notice.doucument-sent-success .ant-message-notice-content {
	font-family: Inter;
	font-size: 16px;
	color: #2E605A;
	line-height: 20px;
	border-radius: 20px;
	background-color: #E1F2E2;
	padding: 10px 20px;
}
.ant-message-notice.auto-reminder-off .ant-message-notice-content {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	color: #FFF;
	line-height: 20px;
	border-radius: 20px;
	background-color: #434343;
	padding: 10px 15px;
}
@media (max-width: 575px) {
	.document-table-wrap .document-table .document-header-name, .document-table-wrap .document-table .document-name {
		padding-left: 0;
	}
	.checklist-item-title {
		padding-left: 16px;
	}
}
@media (max-width: 767px) {
	.currentChecklistWrap {
		padding: 24px 16px;
	}
	.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-completed .task-completed-num {
		display: none;
	}
	.currentChecklistWrap .task-content .task-collapse .task-collapse-header {
		font-size: 12px;
	}
	.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-type {
		width: 40%;
	}
	.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-update {
		width: 40%;
	}
	.currentChecklistWrap .task-content .task-collapse .task-collapse-header .task-completed {
		width: 20%;
	}
	.currentChecklistWrap .task-topBar {
		flex-direction: column;
	}
}
@media (max-width: 991px) { 
	.signature-modal .ant-modal-content .ant-modal-body .signature-bar {
		padding: 12px 16px;
	}
	.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .add-signature {
		padding: 0 8px;
	}
	.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .save-signature {
		padding: 0 12px;
	}
}