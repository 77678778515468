.company-info-form .upload-btn {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #A5A5A5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-info-form .profile-photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #A5A5A5;
    object-fit: contain;
    cursor: pointer;
    box-sizing: border-box;
}

.company-info-form .profile-photo:hover {
    border: 2px solid #325CEB;
}

.company-info-form .ant-upload {
    margin-top: 6px;
}

.company-info-form .remove-photo {
    margin-left: 12px;
    cursor: pointer;
}

.company-info-form .ant-form-item {
    margin-bottom: 30px;
}

.company-info-form .ant-form-item-label {
    padding: 0 0 4px;
}

.company-info-form .ant-form-item-label>label {
    font-size: 16px;
    height: 20px !important;
    color: #586570;
    font-weight: 500;
}
.company-info-form-popUp .ant-form-item {
    margin-bottom: 10px;
}
.company-info-form-popUp .ant-form-item-label>label {
    color: #586570;
    font-family: Inter;
    height: 40px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
   

}

.company-info-form-popUp .ant-input {
    border-radius: 6px;
    border: 1px solid #ECECEC;

    background: #FCFCFC;
}

.company-info-form .optional {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 8px 0 16px 0;
}

.company-info-form .optional span {
    margin-right: 8px;
}

.company-info-form .optional .up {
    transform: rotate(-180deg);
}