.mobile_info_title {

    color: #1F3988;

    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.mobile_info {
    background: #fff;
    padding: 22px;
    border-radius: 6px 6px 0px 0px;
}

.mobile_avatar {
    height: 160px;
    width: 160px;
    border-radius:50%;
    flex-shrink: 0;
}

.mobile-landing-name{
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
    color: #222222;
    line-height: 36px;
    text-align: left;
}

.mobile_avatar_name {
    display: flex;
    align-items: center;
    position: absolute;
    top: 59%;
    width: 100%;
    /* width: 180px; */
    /* height: 57px; */
    flex-shrink: 0;
    font-size: 15px;
    border-radius: 17px;
    padding: 10px;
    background: #FFF;
    box-shadow: 147px 68px 45px 0px rgba(31, 57, 136, 0.00), 94px 43px 42px 0px rgba(31, 57, 136, 0.01), 53px 24px 35px 0px rgba(31, 57, 136, 0.05), 24px 11px 26px 0px rgba(31, 57, 136, 0.09), 6px 3px 14px 0px rgba(31, 57, 136, 0.10);
}

.mobile_name {
    color: #0F0F0F;

    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
}

.mobile_user {
    display: flex;
    padding: 12px 13px;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    border-radius: 17px;
    background: #FFF;
    box-shadow: 147px 68px 45px 0px rgba(31, 57, 136, 0.00), 94px 43px 42px 0px rgba(31, 57, 136, 0.01), 53px 24px 35px 0px rgba(31, 57, 136, 0.05), 24px 11px 26px 0px rgba(31, 57, 136, 0.09), 6px 3px 14px 0px rgba(31, 57, 136, 0.10);
}

.mobile_NMLS {
    color: #171717;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 20px;
    /* 150% */
}

.mobile_introduction {
    color: #3C3C3C;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    min-height: 160px;
    margin-bottom: 9px;
}

.mobile-navList .contact-btn {
    height: 44px;
    line-height: 1;
    width: 100%;
    border-color: #325CEB !important;
    background-color: #325CEB !important;
    color: #FFF !important;
    border-radius: 6px !important;
    font-weight: 600;
}

.mobile_contact-btn {
    height: 44px;
    line-height: 1;
    width: 100%;
    font-weight: 600;
    margin-top: 10px;
    color: #325CEB !important;
    background-color: #F7F8FF !important;
    border-radius: 6px !important;
    border: 1px solid #E3EAFD !important;
}

.mobile_company {
    margin-top: 20px;
    text-align: center;
    border-radius: 20px;
    background: #F1F3FA;
    padding: 20px 28px;
}

.mobile_company img {

    width: 181px;
    height: 137px;
    flex-shrink: 0;
    display: none;
}

.mobile_title {

    color: #1F3988;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.mobile_companyWebsite {

    color: #0F0F0F;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 140% */
}

.mobile-app-list {
    width: 100%;
    cursor: pointer;
}

.mobile-app-list .list {
    display: flex;
    gap: 24px;
    align-items: center;
}
.mobile-app-list .list img {
    width: 16px;
    height: 16px;
    margin: 9px 0;
}
.mobile-tag-list-new {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.mobile-tag-list-new .tag {
    display: flex;
    align-items: center;
    color: #222222;
    font-size: 9px;
    height: 22px;
    padding: 2px 10px;
    border-radius: 6px;
    background: #EAEFFD;
}
.mobile_info_wrap .companyWraper {
    min-height: 322px;
    padding-left: 0 !important;
    padding: 0;
}
.mobile_info_wrap .companyWraper .companyContainer {
    padding: 30px 18px 40px;
}
.mobile_info_wrap .companyWraper .companyContainer .index_right {
    padding: 0;
}
.mobile_info_wrap .companyWraper .companyContainer .index_right_title {
    font-size: 12px;
    line-height: 18px;
}
.mobile_info_wrap .companyWraper .companyContainer .index_right_companybanner {
    font-size: 16px;
    line-height: 24px;
    padding-right: 0;
}
.mobile_info_wrap .companyWraper .companyContainer .index_right_companyWebsite {
    font-size: 12px;
    line-height: 18px;
    min-height: 80px;
    margin-bottom: 40px;
}