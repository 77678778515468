.chat {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    background-color: #f6f6f6;
}

.width70 {
    width: 70%;
}

.chat-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 10px;
}

.page-reference {
    display: inline-block;
    border-radius: 13.5px;
    border: 1px solid #e3eafd;
    color: #325ceb;
    background-color: #f7f8ff;
    padding: 0px 11px;
    margin-right: 6px;
    margin-bottom: 4px;
    cursor: pointer;
}

.prompt {
    border: 1px solid #ececec;
    background-color: #fcfcfc;
    padding: 10px 15px;
    cursor: pointer;
    justify-content: space-between;
    min-height: 60px;
    align-items: center;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

.chat-header {
    display: flex;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 850;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    padding: 5px 10px;
}

.back-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.chat-title {
    font-size: 1.5em;
}

.greeting-prompt {
    display: inline-block;
    width: auto;
    color: #325ceb;
    cursor: pointer;
    border: 1px solid #e3eafd;
    border-radius: 8px;
    background-color: #f7f8ff;
    padding: 8px 15px;
    margin: 6px 0;
    transition: background-color 0.3s ease;
}

.greeting-prompt-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.greeting-prompt:hover {
    background-color: #e3eafd;
}

.markdown-body ul,
.markdown-body ol {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
}

.markdown-body li ul,
.markdown-body li ol {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
}

.markdown-body li ul {
    list-style-type: disc;
}

.markdown-body p {
    margin-bottom: 0.5rem;
}

.markdown-body li p {
    margin-bottom: 0.25rem;
}

.markdown-body li p:has(+ ul),
.markdown-body li p:has(+ ol),
.markdown-body li p:has(+ li) {
    margin-bottom: 0;
}

.markdown-body li p + ul,
.markdown-body li p + ol,
.markdown-body li p + li {
    margin-top: 0;
}

@media (max-width: 576px) {
    .chat {
        background-color: #ffffff;
    }

    .chat-app {
        width: 100%;
        margin-left: 0;
    }

    .history-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1000;
    }

    .history-panel {
        position: fixed;
        top: 0;
        right: 0;
        width: 80%;
        height: 100vh;
        overflow-y: auto;
        background-color: #ffffff;
        z-index: 1001;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        -webkit-overflow-scrolling: touch;
    }

    .history-panel.open {
        transform: translateX(0);
    }

    .chat-input-container {
        padding: 12px 20px 27px 20px;
        border-top: 1px solid #dbdcde;
    }
}
