.settings {
    margin: 30px;
    width: 95%;
    display: flex;
    justify-content: space-between;
}

.settingsForm {
    flex: 1;
    margin: 10px;
    width: 100%;
}

.producttype {
    font-weight: bold;
}

.preview {
    flex: 1;
    width: 100%;
    padding: 10px;
}

.previewTitle {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    margin-bottom: -8px;
}

/* .settingsFormCheckbox {
    margin: 1px;
} */

.configForm {
    padding: 15px;
    cursor: pointer;
    margin-bottom: 15px;
    text-align: left;
    background-color: rgb(239, 238, 238);
}

.btn-updateproducttype {
    background-color: rgb(0, 115, 255);
    color: white;
    border-radius: 15px;
    width: 100px;
}

.customizeColor {
    display: flex;
    flex: 1;
    text-align: left;
}

.customizeColor span{
    width: 100%;
}

.cardcolor {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}

.termtypecheckbox {
    font-size: 12px;
}

@media (max-width: 576px){
    .settings {
        margin: 0px;
        width: 95%;
        display: flex;
        flex-direction: column;

    }
}