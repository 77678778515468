.prequal_plan_container {
  margin: 10px 0 0 0 !important;
  border: 1px solid #f0f0f0;
  border-radius: 1em;
  padding: 1.5em;
}

.prequal_plan_controls,
.prequal_plan_controls input {
  vertical-align: middle;
  width: 100%;
  background-color: #f5f5f5;
  font-weight: 300;
  height: 100%;
  font-size: 16px;
}

.prequal_plan_labels {
  font-weight: 300;
  font-size: 16px;
}

.prequal_plan_labels_row {
  margin: 2em 0 0.5em 0;
}

.ant-space-item {
  height: 100%;
}

.ant-modal-footer {
  height: 3em;
}

.ant-modal-content {
  width: 100%;
}
