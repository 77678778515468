.spinner{
  width: 50px;
  height: 50px;
  position: relative;
  left: 45%;
}

.loader {
  color: #222b44;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 54px;
  opacity: 0.3;
  margin: 0px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: round 1.7s infinite ease;
  animation: round 1.7s infinite ease;

  -webkit-filter: drop-shadow(2px 2px 2px rgba(149, 173, 208, 1));
  filter: drop-shadow(2px 2px 2px rgba(149, 173, 208, 1));
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}