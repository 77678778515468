.request {

    display: flex;
}

.request_left {
    width: 60%;
    padding: 40px 6vw;
}

.request_right {
    position: relative;
    width: 50%;
    background: #1630AC;
    padding: 50px;
}

.request_right .green {
    width: 56px;
    height: 56px;
}
.quote {
    color: #000;
    font-family: Roboto;
    font-size: 64px;
}

.Dream {
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    margin: 51px 0 0 34px;
    /* 100% */
}

.request_info {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5% 0 15% 12%;
}

.request_info .line {
    height: 210px;
    width: 1px;
    background-color: #FFF;
    /* min-height:30 ; */
}

.touch {
    display: flex;
    align-items: center;
    width: 100%;
}

.width_line {
    width: 24px;
    height: 1px;
    background-color: #FFF;
}

.touch_text {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    line-height: 40px;
    margin-left: 22px;
}

.information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    left: 46px;
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 175% */
}
.information .information-right {
    margin-left: 18px;
}
.info_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

}

.info_span {
    margin-left: 18px;
}

.contactmeModalForm .form-control {
    padding: 12px .75rem;
    height: 56px !important;
}

.contactmeModalForm label {
    font-size: 16px;
}
.contactmeForm .callbackTitle-2 {
    font-size: 16px;
    line-height: 20px;
    color: #586570;
    font-weight: 500;
    margin-bottom: 8px !important;
}
.contactmeForm-title {
    color: #222;
    font-family: "Playfair Display";
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}
.contactmeForm-title-mb {
    margin-bottom: 32px;
}
@media (max-width: 1600px) {
}

@media (max-width: 1330px) {

    .request_right {
        padding: 5% 5% 10%;
    }
}

@media (max-width: 1330px) {
    .request {
        display: block;
    }

    .request_left,
    .request_right {

        width: 100%;
    }
}

@media (max-width: 576px) {
    .Dream {
        width: 100%;
        font-size: 30px;
        line-height: 0;
        margin: 35px 0 0 0;
    }

    .information {
        font-size: 14px;
        left: 24px;
    }
    .information .information-right {
        margin-left: 4px;
    }
    .request_right {
        width: calc(100% - 64px);
        margin: 0 auto;
        padding: 5% 10px 10% 5%;
        border-radius: 15px;
    }

    .request_info {

        margin: 6% 0 0 12px
    }

    .touch{
        width: 100%;
    }
    .touch_text{
        margin-left: 12px;
        font-size:14px;
    }

    .line{
        height: 184px!important;
    }
    .request_right .green {
        width: 36px;
        height: 36px;
    }
    .request_left {
        padding: 0 17px;
    }
    .width_line {
        width: 12px;
    }
}
@media (max-width: 992px) {

}