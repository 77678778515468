.about-me-wrapper {
    background-color: white;
    padding: 30px 30px 41px 30px;
}

.about-me-title {
    color: #081265;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
    margin-bottom: 27px;
}

.about-me-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #222222;
}

.about-me-text.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.read-more-button {
    color: #325ceb;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.sections-container {
    display: flex;
    margin-top: 44px;
    gap: 120px;
}

.section {
    min-width: 76px;
}

.section-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #222222;
    margin-bottom: 12px;
    display: block;
}

.section-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
}

.state-item,
.language-item {
    color: #545f71;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
}

.state-item.primary {
    font-size: 14px;
    font-weight: 600;
    background-color: #eef1f4;
    display: inline-block;
    padding: 1px 2px;
    width: fit-content;
}

.state-item.secondary {
    font-weight: 400;
    display: block;
}

.show-more-button {
    background: none;
    border: none;
    padding: 0;
    color: #545f71;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    cursor: pointer;
    text-align: left;
    text-decoration: underline;
}

.show-more-button:hover {
    color: #545f71;
}

.highlight-tag {
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    background-color: #eef1f4;
    color: #081265;
    height: 27px;
    padding: 5px 8px;
}
