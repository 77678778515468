.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.leted {
    color: #CB8600;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    float: inline-end;
}

.ExclamationCircleOutlined {

    color: #CB8600;
    position: absolute;
    right: 10px;
    top: 16px;

}

.ExclamationCircleOutlinedAdd {

    color: #CB8600;
    position: absolute;
    right: 24px;
    top: 43px;

}

.editable-row .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 32px;
}

.editable-row {
    /* background-color: #FCFCFC; */
    border: 1px solid #ECECEC;
}

.editable-row .ant-table-cell-fix-right {
    background-color: #FFF !important;
}

.ant-btn-form1003AddRow {
    background: #EBF1FA;
    text-align: center;
    border-radius: 0 0 4px 4px;
    color: #325CEB;
    font-weight: 500;
    width: 100%;
}

.incomeSourcesTable {
    width: 100%;
}

.incomeSourcesTable .ant-table-thead>tr>th {
    background: #F5F5F7 !important;
    border: 1px solid #ECECEC;
}

.expanded {
    height: calc(100vh - 94px - 67px);
    overflow: auto;
    transition: max-width 0.2s ease;

}

.removable {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 4px;
    margin: 12px 20px 15px 30px;
}

.editing-btn {
    padding-right: 100px;
}

.loanInterview-tabs-wrap {
    display: none;
    position: sticky;
    top: 324px;
    z-index: 999;
    background-color: #FFF
}

@media (max-width: 575px) {
    .title1003-mobile {
        padding: 20px 12px;
    }
}
.loans-left-anchor.document-left-anchor {
    height: calc(100vh - 194px);
}
/* .loans-rigth-anchor {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}
.quick-guide-title {
    position: sticky;
    top: 0;
    background-color: #FFF;
    padding: 10px 0;
    z-index: 2;
}
.loans-rigth-anchor .ant-anchor-wrapper {
    position: sticky;
    top: 50px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
} */
.loans-rigth-anchor.document-rigth-anchor {
    height: calc(100vh - 194px);
    max-width: 62px;
}
.loans-rigth-anchor.document-rigth-anchor::-webkit-scrollbar {
    width: 6px; 
}
.loans-rigth-anchor.document-rigth-anchor::-webkit-scrollbar-track {
    background: transparent; 
}
.loans-rigth-anchor.document-rigth-anchor::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.multiCheckboxWrapper {
    border: 1px solid #DBDCDE;
    border-radius: 4px;
    padding: 10px;
    padding-left: 15px;
    width: calc((100% - 40px) / 3) !important;
}

.multiCheckboxWrapper:hover {
    border: 1px solid #325CEB;
}

.otherEthinicityInput{
    padding: 10px;
    width: 100%
}

.multiCheckboxWrapperVertical {
    border: 1px solid #DBDCDE;
    border-radius: 4px;
    padding: 15px;
}

.multiCheckboxWrapperVertical:hover {
    border: 1px solid #325CEB;
}

.militaryServiceMultiCheckWrap {
    border: 1px solid #DBDCDE;
    border-radius: 4px;
    padding: 10px;
    padding-left: 15px;
    margin-bottom: 20px;
}

.militaryServiceMultiCheckWrap:hover {
    border: 1px solid #325CEB;
}

.multiCheckboxWrapperVertical .ant-checkbox-wrapper {
    font-weight: 400 !important;
    font-size: 1rem !important;
    font-family: Inter !important;
}

.militaryServiceMultiCheckWrap .ant-checkbox-wrapper {
    font-weight: 400 !important;
    font-size: 1rem !important;
    font-family: Inter !important;
}
.income-calculation-btn {
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #325CEB;
    padding: 4px 11px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #325CEB;
    margin-left: 20px;
    cursor: pointer;
}
@media (max-width: 767px) {
    .loans-left-anchor {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .loans-rigth-anchor {
        display: none;
    }

    .loanInterview-tabs-wrap {
        display: none;
    }
}

@media (max-width: 1199px) {
    .editing-btn {
        padding-right: 0;
    }
}