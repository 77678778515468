.chat-input {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 11px 23px 17px 16px;
    background-color: transparent;
}

.prompt-text-wrapper {
    background-color: #fcfcfc;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    color: #6e6e70;
    padding: 1px 20px;
    border-top: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
    border-radius: 12px 12px 0 0;
}

.chat-textarea {
    background-color: #fcfcfc;
    width: 100%;
    min-height: 56px;
    max-height: 208px;
    border-radius: 12px;
    border: 1px solid #ECECEC;
    padding: 12px 50px 12px 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    color: #222222;
    transition: all 0.3s ease;
    resize: none;
    overflow-y: auto;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #C1C1C1 #F1F1F1;
}

.chat-textarea.has-prompt {
    border-top: none;
    border-radius: 0 0 12px 12px;
}

.chat-textarea::-webkit-scrollbar {
    width: 8px;
}

.chat-textarea::-webkit-scrollbar-track {
    background: #F1F1F1;
    border-radius: 4px;
}

.chat-textarea::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
    border: 2px solid #F1F1F1;
}

.chat-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #A8A8A8;
}

.chat-textarea:not(.scrollable)::-webkit-scrollbar {
    display: none;
}

.chat-textarea:not(.scrollable) {
    overflow-y: hidden;
}

.chat-textarea:focus {
    outline: none;
    box-shadow: none;
}

.chat-textarea:focus-visible {
    outline: none;
}

.chat-textarea:-moz-focusring {
    outline: none;
}

@keyframes smooth-blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.chat-textarea:focus::after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 20px;
    background-color: #1890ff;
    animation: smooth-blink 1s infinite;
}

.chat-textarea::placeholder {
    white-space: pre-line;
    color: #6e6e70;
    transition: opacity 0.3s ease;
}

.chat-textarea:disabled::placeholder {
    color: #d9d9d9;
}

.chat-textarea:focus::placeholder {
    opacity: 0;
}

.send-button {
    position: absolute;
    right: 14px;
    bottom: 12px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.send-button.active {
    background-color: #1890ff;
    border-radius: 50%;
}

.gpt-limit-reached-text {
    text-align: center;
    margin-top: 4px;
    color: #6E6E70;
    font-size: 12px;
    font-weight: 400;
}

@media (max-width: 576px) {
    .chat-input {
        padding: 0;
    }

    .gpt-limit-reached-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;
    }
}

.prompt-question {
    color: #325ceb;
    cursor: pointer;
    display: inline;
}

.prompt-question:hover {
    text-decoration: underline;
}
