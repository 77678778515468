.underwriting-checklist {
    
}
.underwriting-checklist .overWrite-modal {

}
.underwriting-checklist .viewFiles-modal {

}
.viewfiles-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    padding: 0 22px 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.viewfiles-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
}
.viewfiles-prevPage {
    position: fixed;
    left: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-nextPage {
    position: fixed;
    right: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-top-bar > div {
    display: flex;
    align-items: center;
}
.viewfiles-top-bar .close-icon {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 20px;
}
.viewfiles-top-bar .viewfiles-top-name {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
}
.viewfiles-top-bar .page-info {
    position: absolute;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    justify-content: center;
}
.viewFiles-modal .viewFiles-content {
    max-height: calc(100vh - 160px);
    /* max-width: 1280px; */
    overflow: auto;
}
.underwriting-checklist .overWrite-modal .overWrite-modal-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.underwriting-checklist .overWrite-modal .approval-text {
    font-size: 14px;
    color: #000;
    padding: 44px 30px 40px;
}
.underwriting-checklist .overWrite-modal-btn {
    width: 135px;
    height: 44px;
    font-weight: 500;
    border-radius: 4px;
    padding: 0;
}
.viewFiles-modal .viewFiles-modal-btn {
    width: 135px;
    height: 44px;
    font-weight: 500;
    border-radius: 4px;
    padding: 0;
}
.viewFiles-modal .viewFiles-modal-btn.btn-line {
    border-color: #325CEB;
    color: #325CEB;
}
.underwriting-checklist .overWrite-modal-btn.btn-line {
    border-color: #325CEB;
    color: #325CEB;
}
.underwriting-checklist .overWrite-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-inline-start: 20px;
}
.underwriting-checklist .large-modal .large-modal-title {
    text-align: center;
    margin-top: 74px;
}
.underwriting-checklist .large-modal .large-modal-title .sub-title {
    font-size: 20px;
    color: #6E6E70;
}
.underwriting-checklist .large-modal .large-modal-title .main-title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
}
.underwriting-checklist .large-modal .ant-modal-footer {
    text-align: center;
    margin-bottom: 74px;
}
.underwriting-checklist .large-modal .file-table {
    margin: 30px auto 40px;
    max-width: 442px;
}
.underwriting-checklist .large-modal .file-table .file-table-header {
    font-size: 14px;
    color: #545459;
    font-weight: bold;
    display: flex;
    padding: 12px 0;
    border-bottom: 2px solid #6E6E70;
}
.underwriting-checklist .large-modal .file-table .checkBox-wrap {
    width: 10%;
}
.underwriting-checklist .large-modal .file-table .file-name {
    width: 50%;
}
.underwriting-checklist .large-modal .file-table .type {
    width: 40%;
}
.underwriting-checklist .large-modal .file-table .file-table-content .file-table-row {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CFD4D9;
}
.underwriting-checklist .large-modal .file-table .file-table-content .file-table-row .file-img {
    width: 29px;
    height: 36px;
    margin-right: 18px;
}
.underwriting-checklist .large-modal .file-table .file-table-content .file-table-row .file-info {
    font-size: 14px;
    line-height: normal;
}
.underwriting-checklist .large-modal .file-table .file-table-content .file-table-row .file-name {
    display: flex;
    align-items: center;
}
.underwriting-checklist .large-modal .file-table .file-table-content .file-table-row .type {
    color: #6E6E70;
}
.underwriting-checklist .large-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-inline-start: 16px;
}

.underwriting-checklist .overWrite-modal-btn.btn-default {

}
.underwriting-checklist .overWrite-modal .ant-modal-content {
    padding: 42px 24px 28px;
}
.underwriting-checklist .overWrite-modal .ant-modal-footer {
    text-align: center;
}
.underwriting-checklist .overWrite-modal .overWhite-group {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 36px 0 50px;
}
.underwriting-checklist .overWrite-modal .radio-item {
    height: 44px;
    display: flex;
    align-items: center;
}
.underwriting-checklist .overWrite-modal .radio-item .radio-text {
    font-size: 16px;
    color: #000;
    width: 200px;
    margin-left: 16px;
}
.underwriting-checklist .overWrite-modal .radio-item .radio-text.select-radio {
    font-weight: 500;
}
.underwriting-checklist .underwriting-topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 28px 10px 38px;
    border-bottom: 1px solid #ECECEC;
}
.underwriting-checklist .underwriting-topBar .underwriting-title {
    font-size: 24px;
    color: #6E6E70;
    margin-bottom: 12px;
    text-align: center;
}
.underwriting-checklist .underwriting-topBar .underwriting-operate {
    text-align: center;
}
.underwriting-checklist .checklist {
    padding: 0 5vw 0 36px;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item {
    border-bottom: 1px solid #ECECEC;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item .ant-collapse-header {
    padding: 26px 24px 26px 0;
    display: flex;
    align-items: center;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item .ant-collapse-header .checkList-collapse-header {
    display: flex;
    align-items: center;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item .ant-collapse-header .checkList-collapse-header .collapse-title {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    width: 50%;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item .ant-collapse-header .checkList-collapse-header .link-file {
    font-size: 16px;
    color: #325CEB;
    width: 30%;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item .ant-collapse-header .checkList-collapse-header .link-file .link-disabled {
    color: #979797;
    cursor: not-allowed;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-item .ant-collapse-header .checkList-collapse-header .link-file span {
    cursor: pointer;
}
.underwriting-checklist .checklist .checkList-collapse .checkList-panel-item {
    display: flex;
    line-height: 20px;
    font-size: 15px;
    color: #222;
    margin-bottom: 16px;
}
.underwriting-checklist .checklist .checkList-collapse .checkList-panel-item:last-child {
    margin-bottom: 25px;
}
.underwriting-checklist .checklist .checkList-collapse .checkList-panel-item .checkList-name {
    display: flex;
    width: calc(80% - 38px);
}
.underwriting-checklist .checklist .checkList-collapse .checkList-panel-item .checkList-name .dot {
    width: 4px;
    height: 4px;
    background-color: #222;
    border-radius: 50%;
    margin: 9px 8px 0;
}
.underwriting-checklist .checklist .checkList-collapse .checkList-checkbox-wrap {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.underwriting-checklist .checkList-checkbox .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}
.underwriting-checklist .checklist .checkList-collapse .ant-collapse-content .ant-collapse-content-box {
    padding-block: 0;
    padding: 0;
}
.underwriting-checklist .checkList-checkbox .ant-checkbox .ant-checkbox-inner {
    background-color: #CFD4D9;
}
.underwriting-checklist .checkList-checkbox .ant-checkbox .ant-checkbox-inner:after {
    opacity: 1;
    transform: rotate(45deg) scale(1) translate(-50%,-50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}
.underwriting-checklist .checkList-checkbox .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #325CEB;
}
.large-modal .comment-wrap {
    padding: 116px 0 10px;
}
.comment-form.ant-form-large .ant-form-item {
    margin-bottom: 36px;
}
.comment-form.ant-form-large .ant-form-item .ant-form-item-label>label {
    height: auto;
    font-size: 14px;
    color: #222;
    font-weight: bold;
}
.comment-form.ant-form-vertical .ant-form-item-label {
    padding: 0 0 10px;
}
.comment-form .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.large-modal .confirm-loan-wrap .confirm-loan-title {
    font-family: "Playfair Display";
    font-size: 24px;
    color: #222;
    font-weight: 500;
    line-height: normal;
    padding: 40px 10% 30px;
    border-bottom: 1px solid #ECECEC;
}
.large-modal .confirm-loan-wrap .confirm-loan-tips {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    padding: 20px 10% 0;
}
.large-modal .confirm-loan-wrap .loan-terms-card {
    width: 322px;
    max-width: 90%;
    margin: 40px auto 30px;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    padding: 18px 26px;
    border-radius: 8px;
    margin-bottom: 5px;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item > div {
    display: flex;
    justify-content: space-between;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item > div:not(:last-child) {
    margin-bottom: 12px;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item > div > div {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-align: right;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item .explain {
    font-size: 11px;
    font-weight: 500;
    color: #979797;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item.top {
    background-color: #F4F7FE;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item.middle {
    background-color: #E7FFF4;
}
.large-modal .confirm-loan-wrap .loan-terms-card .loan-terms-item.bottom {
    background-color: rgba(234, 234, 234, 0.60);
}
.large-modal.loan-terms-modal .ant-modal-content {
    padding: 20px 0;
}
@media (min-width: 576px) {
    .large-modal .comment-wrap {
        padding: 116px 40px 10px;
    }
    .underwriting-checklist .large-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
        margin-inline-start: 38px;
    }
}
@media (min-width: 768px) {
    .large-modal .comment-wrap {
        padding: 116px 76px 10px;
    }
}
@media (min-width: 1200px) {
    .underwriting-checklist .underwriting-topBar .underwriting-operate {
        text-align: right;
    }
    .underwriting-checklist .underwriting-topBar .underwriting-title {
        margin-bottom: 0;
        text-align: left;
    }
}