.document-verify-modal {
    top: 0;
    padding-bottom: 0;
}
.document-verify-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
    top: 0;
    border-radius: 0;
}
.document-verify-modal .ant-modal-content .ant-modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.document-verify-modal .ant-modal-content .ant-modal-footer {
    height: 70px;
    padding: 0 42px 0 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    border-top: 1px solid #DBDCDE;
}
.document-verify-modal .verify-modal-top {
    height: 60px;
    line-height: 60px;
    background-color: #FFF;
    color: #222;
    font-size: 18px;
    font-weight: 500;
    padding:0 120px 0 46px;
    border-bottom: 1px solid #DBDCDE;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.document-verify-modal .verify-modal-content {
    padding: 20px 46px 14px;
    font-family: Inter;
    height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
}
.document-verify-modal .manual-modal-content {
    font-family: Inter;
    height: calc(100vh - 130px);
}
.document-verify-modal .manual-modal-content .document-preview-content {
    height: 100%;
    background-color: #DADBDD;
    padding: 4px 8px 0;
    overflow: auto;
    max-height: calc(100vh - 130px);
}
.document-verify-modal .manual-modal-content .document-preview-content .preview-box {
    margin: 0 auto;
}
.document-verify-modal .document-preview-top-bar {
    position: absolute;
    height: 100px;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    /* top: 16px;
    left: 50%;
    margin-left: -195px; */
}
.document-verify-modal .document-preview-top-bar .change-file {
    opacity: 0;
    width: 390px;
    height: 38px;
    width: 390px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.80);
    border-radius: 10px;
    border: 1px solid #B1B2B7;
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.document-verify-modal .document-preview-top-bar .change-file.visible {
    opacity: 1;
}
.document-verify-modal .document-preview-top-bar .change-file .prev-file, .document-verify-modal .document-preview-top-bar .change-file .next-file {
    cursor: pointer;
}
.document-verify-modal .document-preview-top-bar .change-file .prev-file.arrow-disabled, .document-verify-modal .document-preview-top-bar .change-file .next-file.arrow-disabled {
    cursor: no-drop;
}
.document-verify-modal .document-preview-top-bar .change-file .flie-total {
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 16px;
}
.document-verify-modal .document-preview-top-bar .change-file .flie-total span {
    font-weight: 500;
    color: #FFF;
    margin-left: 12px;
}
.document-verify-modal .document-preview-top-bar .change-file .separate {
    height: 28px;
    width: 1px;
    background-color: #ECECEC;
}
.document-verify-modal .document-preview-top-bar .change-file .file-name {
    color: #FFF;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1;
    overflow: hidden;
}
.document-verify-modal .document-preview-top-bar .change-file .file-name div {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.document-verify-modal .document-preview-content .preview-box .pdf-verify-anchor {
    margin-bottom: 12px;
    min-height: calc(100vh - 130px);
}
.document-verify-modal .document-preview-bottom-bar {
    height: 48px;
    width: 330px;
    border-radius: 8px;
    color: #FFF;
    background: rgba(0, 0, 0, 0.80);
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -165px;
    display: flex;
    align-items: center;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-pagination {
    flex: 1;
    padding: 9px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #FFF;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination-prev button, .document-verify-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination-next button {
    color: #FFF;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination {
    color: #FFF;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination .ant-pagination-slash {
    margin-inline-start: 0;
    margin-inline-end: 0;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: rgba(0, 0, 0, 0.90);
    width: 40px;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-scale {
    flex: 1;
    display: flex;
    gap: 8px;
    padding: 9px 12px;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-scale .zoom-min, .document-verify-modal .document-preview-bottom-bar .document-preview-scale .zoom-add {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-verify-modal .document-preview-bottom-bar .document-preview-scale .zoom-value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-verify-modal .manual-modal-content .form-1003-title {
    height: 64px;
    line-height: 64px;
    padding: 0 54px 0 24px;
    color: #222;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.document-verify-modal .verify-modal-content .verify-title {
    color: #6E6E70;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
}
.document-verify-modal .verify-modal-content .verify-table {
    flex: 1;
    overflow: auto;
    position: relative;
}
.document-verify-modal .verify-modal-content .update-action-bar {
    height: 62px;
    padding: 0 22px 0 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #EAEFFD;
    margin-bottom: 10px;
}
.document-verify-modal .verify-modal-content .update-action-bar .action-bar-l {
    display: flex;
    align-items: center;
    color: #1F2890;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}
.document-verify-modal .verify-modal-content .update-action-bar .action-bar-l img {
    margin-right: 8px;
}
.document-verify-modal .verify-modal-content .update-action-bar .update-all, .document-verify-modal .verify-modal-content .update-action-bar .undo-all .undo {
    color: #325CEB;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.document-verify-modal .verify-modal-content .update-action-bar .undo-all {
    display: flex;
    line-height: 20px;
}
.document-verify-modal .verify-modal-content .update-action-bar .undo-all span {
    color: #2E605A;
    font-size: 16px;
    font-weight: 600;
}
.document-verify-modal .verify-modal-content .update-action-bar .undo-all .undo{
    margin-left: 34px;
    font-weight: 600;
}
.document-verify-modal .verify-modal-content .update-table {
    flex: 1;
    overflow: auto;
}
.document-verify-modal .verify-modal-content .update-table::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.document-verify-modal .verify-modal-content .update-table {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.document-verify-modal .verify-modal-content .update-table-header {
    height: 42px;
    padding: 0 14px;
    background-color: #F5F5F7;
    display: flex;
    gap: 24px;
    align-items: center;
    position: sticky;
    top: 0;
}
.document-verify-modal .verify-modal-content .update-table-header .header-col {
    flex: 1;
    display: flex;
    /* padding: 0 24px 0 0; */
}
.document-verify-modal .verify-modal-content .update-table-header .application-col {
    display: flex;
}
.document-verify-modal .verify-modal-content .update-table-header .header-col .file-name {
    font-size: 16px;
    color: #586570;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #DBDCDE;
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    gap: 6px;
}
.document-verify-modal .verify-modal-content .update-table-header .header-col .file-name div {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.document-verify-modal .verify-modal-content .update-table-header .header-col.doc {
    color: #586570;
    font-size: 16px;
    font-weight: 500;
}
.document-verify-modal .verify-modal-content .update-table-header .header-col.application {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
}
.document-verify-modal .verify-modal-content .update-table .field-row {
    padding: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DBDCDE;
    gap: 24px;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-key {
    color: #586570;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    flex: 1;
}
.document-verify-modal .verify-modal-content .update-table .title-row .field-key {
    font-weight: 600;
}
.document-verify-modal .verify-modal-content .update-table .update-row {
    background-color: #EAEFFD;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-value {
    color: #222;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-value .application-row {
    flex: 1;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-value .undo {
    color: #2E605A;
    font-weight: 500;
    padding: 4px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: 6px;
    background-color: #E1F2E2;
    margin-top: 4px;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-value .undo span {
    color: #325CEB;
    cursor: pointer;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-action {
    width: 95px;
}
.document-verify-modal .verify-modal-content .update-table .field-row .field-action .update-btn {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    width: 95px;
    height: 45px;
    border-radius: 6px;
    background-color: #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.document-verify-modal .ant-modal-content .ant-modal-footer .change-saved {
    color: #2E605A;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    height: 32px;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #E1F2E2;
    margin-right: 46px;
}
.document-verify-modal .ant-modal-content .ant-modal-footer .verify-cancel-btn {
    height: 46px;
    width: 100px;
    color: #325CEB;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid #E3EAFD;
    background-color: #F7F8FF;
}
.document-verify-modal .ant-modal-content .ant-modal-footer .verify-complete-btn {
    height: 46px;
    padding: 0 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
}
.document-verify-modal .verify-skip-btn {
    color: #325CEB;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
}

.verify-confirm-modal .ant-modal-content {
    padding: 84px 68px 64px;
}
.verify-confirm-modal .ant-modal-content .ant-modal-body .verify-confirm-title {
    color: #222;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 60px;
}
.verify-confirm-modal .ant-modal-content .ant-modal-body .verify-confirm-text {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 66px;
}
.verify-confirm-modal .ant-modal-content .ant-modal-body .verify-confirm-action {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.verify-confirm-modal .ant-modal-content .ant-modal-body .verify-confirm-action .confirm-btn {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid #325CEB;
    display: flex;
    width: 318px;
    height: 46px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.verify-confirm-modal .ant-modal-content .ant-modal-body .verify-confirm-action .confirm-btn.theme-btn {
    color: #FFF;
    background-color: #325CEB;
    margin-bottom: 20px;
}
.verify-confirm-modal .ant-modal-content .ant-modal-body .verify-confirm-action .confirm-btn.default-btn {
    color: #325CEB;
}