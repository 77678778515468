.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e2e2e2;
    position: relative;
    height: calc(100vh - 20px);
    border: 1px solid #ececec;
    border-radius: 16px;
    margin: 10px 0 10px 6px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover::-webkit-scrollbar-thumb {
        opacity: 1;
    }

    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &:hover {
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }
}

.pdf-controls {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 313px;
    height: 48px;
    border-radius: 8px;
    z-index: 1000;
}

.pdf-controls-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.pdf-controls-button:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: default;
}

.pdf-controls-input {
    width: 54px;
    text-align: right;
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    border: none;
    outline: none;
    border-radius: 6px;
}

.pdf-controls-divider {
    width: 1px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0 10px;
}

.react-pdf__Page__textContent {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    line-height: 1.2;
}

.react-pdf__Page__textContent span {
    background-color: transparent;
    transition: background-color 0.1s ease;
}

.react-pdf__Page__textContent ::selection {
    background-color: rgba(0, 0, 255, 0.3);
    color: inherit;
}

.react-pdf__Page__textContent span::selection {
    background-color: rgba(0, 0, 255, 0.3);
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.3);
    border-radius: 2px;
}

.react-pdf__Page__textContent a::selection {
    background-color: rgba(0, 0, 255, 0.3);
}

.react-pdf__Page {
    position: relative;
}

@media (max-width: 576px) {
    .pdf-container {
        height: calc(100vh - 20px);
        border: none !important;
        border-radius: 0;
        margin: 0;
    }

    .pdf-controls-mobile {
        height: 47px !important;
        min-height: 47px !important;
        padding: 0 20px;
        background-color: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .pdf-controls-mobile .guideline-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        color: #222222;
    }

    .pdf-controls-mobile .page-navigation {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .page-navigation .pdf-controls-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
    }

    .page-navigation .pdf-page-number {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #222222;
    }

    .page-navigation .page-input {
        background-color: #ffffff;
        border-radius: 6px;
        border: 1px solid #cfd4d9;
        width: 50px;
        height: 30px;
        padding: 5px 8px;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #222222;
    }

    .floating-button-wrapper {
        position: absolute;
        bottom: 45px;
        right: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;
        background-color: transparent;
    }

    .ai-chat-button {
        display: flex;
        align-items: center;
        gap: 4px;
        background: #fcfcfc;
        padding: 14px 22px;
        border: 1px solid #ececec;
        border-radius: 30.5px;
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.14);
        margin-right: 20px;
        cursor: pointer;
        width: fit-content;
        pointer-events: auto;
    }

    .ai-chat-button img {
        width: 24px;
        height: 24px;
    }

    .ai-chat-button span {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #222222;
    }
}
