.recommendRatesNoRatesTip {
    border-radius: 12px;
    background-color: #FBFBFB;
    color: #6E6E70;
    padding: 16px 15px;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}
.filterTitle {
    color: #6E6E70;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    flex: inherit;
}
.productPickerFiltersWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 20px;
    gap: 16px;
    max-width: 100%;
    overflow-x: auto;
}
.productPickerFiltersWrap::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.rate-filter-scroll-button {
    position: absolute;
    top: 32px;
    cursor: pointer;
}
.rate-filter-scroll-button.left-scroll-button {
    left: -39px;
    transform: rotate(180deg);
}
.rate-filter-scroll-button.right-scroll-button {
    right: -39px;
}
.productPickerFiltersWrap .filter-item {
    padding: 9px 16px;
    background-color: #FCFCFC;
    border: 1px solid #DBDCDE;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 14px;
}
.productPickerTable.ant-table-wrapper .ant-table {
    border: 1px solid #DBDCDE;
    margin-top: 20px;
}

.productPickerFiltersWrap .scroll-bar {
    background-color: white;
}


.loSelectedRateWrap {
    padding: 16px 30px;
    border-radius: 12px;
    border: 1px solid #DBDCDE;
}
.loSelectedRateWrap:not(:last-child) {
    margin-bottom: 16px;
}
.loSelectedRateWrap .rate-info {
    padding: 13px 22px;
    border-radius: 8px;
    background-color: #F5F5F7;
}
.loSelectedRateWrap .rate-info > div {
    width: 20%;
}
.chosenRateWrap {
    border: 1px solid #1F3988;
}

.productPickerFiltersSelect {
    width: 14%;
}

.productPickerFiltersScroll {
    width: 20%;
}
.loSelectedRateWrap .rate-remove {
    color: #EB3232;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    margin-left: 16px;
}
.loSelectedRateWrap .rate-info-label {
    color: #62707C;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
}
.loSelectedRateWrap .rate-info-value {
    color: #222;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}
.rate-recommendation-all .compare-rates {
    font-family: Inter;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 17px;
    margin-bottom: 0;
}
.rate-recommendation-all .compare-rates .rate-select-count {
    display: flex;
    gap: 16px;
}
.rate-recommendation-all .compare-rates .rate-select-count .select-count {
    color: #222;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
}
.rate-recommendation-all .compare-rates .rate-select-count .clear-btn {
    color: #C21818;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    cursor: pointer;
}
.rate-recommendation-all .compare-rates .compare-rates-line {
    height: 32px;
    width: 1px;
    background-color: #D9D9D9;
}

.rate-recommendation-all {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #DBDCDE;
}
.rate-recommendation-all .compare-rates .rate-compare-btn {
    padding: 0 16px 0 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #474849;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
}
.rate-recommendation-all .compare-rates .rate-compare-btn.compare-disabled {
    color: #979797;
    cursor: no-drop;
}
.rate-recommendation-all .compare-rates .rate-recommend-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    width: 160px;
    height: 40px;
    border-radius: 6px !important;
}
.rate-normal-btn {
    width: 72px;
    height: 36px;
    padding: 0;
    color: #325CEB;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    border-radius: 6px;
    border: 1px solid #E3EAFD;
    background-color: #F7F8FF;
}
.rate-selected-btn {
    border: 1px solid #325CEB;
    background-color: #E0E7FF;
}
.recommend-rates-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.recommend-rates-top .recommend-rates-title {
    color: #222;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.rates-recommended-table .ant-table {
    border: 1px solid #DBDCDE;
    border-radius: 8px;
    overflow: hidden;
}

.rates-recommended-table.ant-table-wrapper .ant-table-thead >tr>th {
    font-family: Inter;
    color: #586570;
    padding: 9px 16px;
    background-color: #F5F5F7;
}
.rates-recommended-table.ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 12px 16px;
    font-family: Inter;
    font-weight: 500;
    color: #222;
}
.rates-recommended-table.ant-table-wrapper .ant-table-column-title {
    flex: none;
}
.rates-recommended-table.ant-table-wrapper .ant-table-column-sorters {
    justify-content: flex-start;
}
.rates-recommended-table.ant-table-wrapper .ant-table-column-sorter {
    margin-inline-start: 16px;
}
.rates-recommended-table.ant-table-wrapper .ant-table .ant-table-header {
    border-bottom: none;
}
.productPickerFiltersWrap .all-rates-select .ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-inline-end: 0;
}
.productPickerFiltersWrap .all-rates-select .ant-select-selector {
    border: none;
}
.productPickerFiltersWrap .all-rates-input {
    border: none;
    color: #222;
    font-size: 16px;
    font-family: Inter;
}
.productPickerFiltersWrap .all-rates-input .ant-input-number-input {
    height: 30px;
}