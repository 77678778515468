.card {
  background-color: #fff;
  box-shadow: 0 1px 0 #33333366;
  color: #333;
  padding: 1rem;
  border-radius: 5px;
  user-select: none;
}

.padding{
  padding: 0.6rem;
}

.header {
  text-align: left;
  border-bottom: 1px solid #bbb;
  font-weight: bold;
}

.header > p {
  margin-bottom: 0;
}

.title {
  margin: 0;
}

.main{
  text-align: left;
  padding: 0.5rem 0;
  border-bottom: 1px solid #bbb;
  margin-bottom: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer > * {
  margin-right: 0.35rem;
}

.footer > *:last-child {
  margin-right: 0;
}

.displayNone{
  display: none;
}