.programs {
    background: #fff;
    min-height: 546px;
    padding: 50px 6vw 60px 6vw;
    box-sizing: border-box;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.programs_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Empower {

    color: #6E6E70;
    font-family: "Playfair Display";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;

    margin-right: 20px;
    /* 150% */
}

.Financial {
    width: 100%;
    color: #222;
    font-family: "Playfair Display";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

.right_title {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
}

.right_content {

    color: #0F0F0F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

.right-active {
    border-radius: 10px;
    background: #D6E2FB;
}

.right-list {
    /* cursor: pointer; */
    display: flex;
    /* justify-content: space-between; */
    padding: 20px 20px;
    min-height: 139px;

    box-sizing: border-box;

}

.programs-btn {
    margin-top: 30px;
    padding: 18px 40px;
    text-align: center;
    line-height: 20px;
    border-radius: 6px;
    background: #325CEB;
    color: #FFF;
    cursor: pointer;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.programs_right {
    width: 100%;
    /* padding: 0 100px; */
}

@media (max-width: 1550px) {
    .right-list {
        padding: 16px 16px;

    }
}

@media (max-width: 1350px) {

    /* .programs_right{
        padding: 0 0 0 137px;

    } */
}

@media (max-width: 991px) {

    .narrorScreenAdjustor{
        margin-top: 10px !important;
    }


    .programs_right {
        margin-top: 15px;
    }

    .Financial {
        /* width: 88vw; */
        text-align: center;
        font-size: 25px;
    }

    .Empower {
        font-size: 25px;
    }

    .programs-btn {
        width: 200px;
        margin: 10px auto 0;
    }

    /* .programs_right{
        padding: 0 0 0 137px;

    } */
}

@media (max-width: 575px) {
    .programs {
        padding: 20px 22px;
    }
    .right_title {
        font-size: 20px
    }
    .right_content{

        font-size: 16px;
    }

}
