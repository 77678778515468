.sidenav
{
	background-color: #F7F7F7;
  color: #1F3988 !important;
	height: 100% !important;
	left: 0;
	max-width: 85px;
	overflow-x: hidden;
	padding-top: 20px;
	position: sticky;
	top: 0;
	width: 100%;
	/* z-index: 1; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.mainSiderbarItem {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin-left: 5px;
}
.mainSidebarText {
  font-size: 12px;
  color: #1F3988;
}
.mainSiderbarImg {
  max-height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mainSiderbarDropdownImg {
  height: 20px;
  margin-right: 10px;
}
.mainSiderbarItemSelected {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #c4d5fe;
  position: relative;
}
.sidebarIcon {
  width: 36px;
  height: 36px;
}
.sidesection {
  padding: 2px 4px 12px 40px;
  margin-left : 3px;
  margin-top: -4px;
  text-align: left;
  color: rgba(255,255,255,1);
  font-size: 14px;
}
/* Style the sidenav links and the dropdown button */
.sidenav a {
  text-decoration: none;
  font-size: 14px;
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.fa-caret-down {
  margin-top: 4px !important;
}
.sidenav .notalink {
  padding: 8px 13px 8px 25px;
  text-decoration: none;
  font-size: 14px;
  color: rgb(235, 235, 235);
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: not-allowed;
  outline: none;
}
.hover-sidebar {
  margin: 3px 0 0 4px;
  padding-left: 4px;
  padding-top: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}
/* On mouse-over */
.hover-sidebar:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--primary-pale-text) !important;
  background-color: var(--primary-pale) !important;
  text-decoration: none;
}

.selectionoffset {
  padding: 2px 0;
  text-align: center;
}
  /* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;    
}

.sidesection {
  color: white !important;
}

.selectsign {
  background-color: var(--primary-pale) !important;
  max-width: 3px;
  padding-left: 0px !important;  
  padding-right: 0px !important; 
  margin-right: 18px !important;  
}
.nonselectsign {
  max-width:3px;
  padding-left: 0px !important;  
  padding-right: 0px !important;
  margin-right: 18px !important;  
}
.customerselect {
  width: 100%;
  padding: 0 0 0 6px !important;
  color: white;
  margin: 3px 0 3px 3px;
  border: none;
  border-radius: 0px;
  font-size: 14px;
  /* border-bottom: 0.5px solid var(--primary-color-3); */
}

.flex-row{
  display: flex;
}

.flex-row span{
  flex: 1;
}

.flex-row span:nth-child(2){
  flex: 5;
}

.past-customers-checkbox{
  width: 13px !important;
  height: 13px !important;
  margin-left: 25px;
  margin-top: 15px;
  background-size: 1.1rem 1.1rem !important;
}

.past-customers-label{
  font-size: 0.6em;
  margin: 0 0 0 2px;
}

.tool {
  padding-left: 25px;
  font-size: 14px !important;
}

.icon {
  font-size: 12px;
  margin-left: 3px;
  margin-right: 4px;
}

.search-customers {
  margin: 10px 0 0 25px;
  padding-left: 5px;
  width: 118px;
  height: 23px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
}