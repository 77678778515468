.credit-check-modal {
    top: 0;
    padding-bottom: 0;
}
.consent-confirmation-modal .ant-modal-content {
    padding: 0;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-header {
    margin-bottom: 0;
    padding: 23px 19px 15px;
    border-bottom: 1px solid #D9D9D9;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-footer {
    margin-top: 0;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D9D9D9;
    height: 65px;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-footer .consent-confirmation-btn {
    font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	height: 42px;
    width: 112px;
	border-radius: 6px !important;
    line-height: normal;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #222;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 23px;
    right: 16px;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-body .consent-confirmation-content .consent-confirmation-content-tips {
    padding: 15px 16px;
    background-color: #EAEFFD;
    color: #1F3988;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-body .consent-confirmation-content .consent-confirmation-content-select {
    padding: 18px 16px 32px;
}
.consent-confirmation-modal .ant-modal-content .ant-modal-body .consent-confirmation-content .consent-confirmation-content-select .consent-select-label {
    color: #586570;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
}
.credit-check-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
    top: 0;
    border-radius: 0;
    position: relative;
}
.credit-check-modal .ant-modal-content .ant-modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 28px 42px 0;
    overflow: auto;
}
.credit-check-modal .ant-modal-content .ant-modal-body .consent-confirmed-notice {
    height: 46px;
    width: 375px;
    padding: 0 58px 0 18px;
    font-family: Inter;
    font-size: 16px;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
    letter-spacing: 0.2px;
    border-radius: 6px;
    background: rgba(34, 34, 34, 0.90);
    position: absolute;
    bottom: 60px;
    transition: opacity 0.3s ease-in-out;
}
.credit-check-modal .ant-modal-content .ant-modal-body .consent-confirmed-notice.showNotice {
    opacity: 1;
}
.credit-check-modal .ant-modal-content .ant-modal-body .consent-confirmed-notice.hideNotice {
    opacity: 0;
}
.credit-check-modal .ant-modal-content .ant-modal-body .consent-confirmed-notice .consent-confirmed-notice-btn {
    font-weight: 600;
    cursor: pointer;
}
.credit-check-modal .ant-modal-content .ant-modal-body .consent-confirmed-notice  .consent-confirmed-notice-close {
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 11px;
}
.credit-check-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 42px;
    right: 42px;
}
.credit-check-modal .ant-modal-content .ant-modal-header {
    margin-bottom: 0;
    padding: 42px 42px 26px;
    border-bottom: 1px solid #DBDCDE;
}
.credit-check-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #222;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
.credit-check-modal .ant-modal-content .ant-modal-footer {
    margin-top: 0;
    height: 70px;
}
.credit-check-modal .ant-modal-content .ant-modal-footer .credit-check-btn {
    font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	height: 46px;
    width: 132px;
	border-radius: 6px !important;
    line-height: 20px;
}
.credit-check-modal .ant-modal-content .ant-modal-footer .btn-wrap {
    padding: 10px 42px 0 0;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ECECEC;
}
.credit-check-title {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
}
.credit-check-subtitle {
    color: #707070;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 22px;
}
.credit-check-consented-title {
    margin: 10px 0 12px;
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
}
.credit-check-borrowers-box {
    font-family: Inter;
    padding: 20px 23px;
    background-color: #FCFCFC;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    margin-bottom: 16px;
}
.credit-check-select-label {
    margin-top: 30px;
    color: #62707C;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}
.credit-check-select-radio {
    display: flex;
    align-items: center;
    padding: 15px;
    height: 50px;
    width: 284px;
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background: #FCFCFC;
    cursor: pointer;
    margin-right: 20px;
}
.credit-check-account {
    border-radius: 6px;
    background: #F5F5F7;
    padding: 9px 10px 9px 17px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: 16px;
}
.credit-check-select-radio .ant-radio-wrapper {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
}
.credit-check-select-radio .ant-radio-wrapper span.ant-radio+* {
    padding-inline-start: 12px;
}
.credit-check-select-radio .ant-radio-wrapper .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: #FFF;
}
.credit-check-select-radio .ant-radio-wrapper .ant-radio-inner::after {
    background-color: #325CEB;
}
.credit-check-select-radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #325CEB;
}
.credit-check-select-radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
}
.credit-check-borrowers-box .credit-check-borrowers-top {
    display: flex;
    justify-content: space-between;
}
.credit-check-borrowers-box  .credit-check-borrowers-top .personal-information {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}
.credit-check-borrowers-box  .credit-check-borrowers-top .credit-status {
    color: #6E6E70;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}
.credit-check-borrowers-box .credit-check-info-item {
    display: flex;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 9px;
}
.credit-check-borrowers-box .credit-check-info-item .info-item-label {
    width: 152px;
    color: #586570;
    font-weight: 500;
}
.credit-check-borrowers-box .credit-check-borrowers-bottom {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 12px;
}
.credit-check-borrowers-box .credit-check-borrowers-bottom .credit-consent-status {
    width: 173px;
    height: 40px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding-left: 9px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 20px;
}
.credit-check-borrowers-box .credit-check-borrowers-bottom .credit-consent-btn {
    color: #325CEB;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}
.credit-report-top-bar {
    margin-bottom: 20px;
}
.credit-report-top-bar .check-date-select {
    width: 100%;
    max-width: 340px;
    height: 40px;
}
.credit-report-top-bar .check-date-select .ant-select-selector {
    border: 1px solid #ECECEC;
    background: #FCFCFC;
}
.credit-report-top-bar .credit-report-btn {
    height: 40px;
    padding: 0 11px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px !important;
    box-shadow: none;
}
.credit-report-top-bar .credit-report-download {
    border: 1px solid #E3EAFD;
    background-color: #F7F8FF!important;
    color: #325CEB;
}
.credit-report-top-bar .credit-report-download:disabled {
    border: 1px solid #ECECEC;
    background-color: #FCFCFC!important;
    color: #DBDCDE;
}
.credit-report-top-bar .credit-report-download:hover {
    color: #325CEB !important;
}
.credit-report-top-bar .credit-report-download:disabled:hover {
    color: #DBDCDE !important;
}
.credit-report-top-bar .credit-report-check {

}
.credit-history-card .ant-card-body {
    padding: 12px 0;
}
.credit-history-card .credit-history-table .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0;
}
.credit-history-card .credit-history-table .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0;
}
.credit-report-tabs.ant-tabs .ant-tabs-tab {
    padding: 9px 0;
    color: #6E6E70;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}
.credit-report-tabs.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 24px;
}
.credit-report-tabs.ant-tabs-top >.ant-tabs-nav::before {
    border-bottom: 1px solid #DBDCDE;
}
.credit-report-tabs.ant-tabs .ant-tabs-ink-bar {
    height: 3px;
    background: #325CEB;
}
.credit-report-tabs.ant-tabs-top >.ant-tabs-nav {
    margin-bottom: 0;
}
.credit-history-select {
    height: 38px;
}
.credit-history-select .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #ECECEC;
}