.documentTitle{
  display: inline-block;
  color: rgb(74, 74, 74);
  font-size: 1.5rem;
  font-weight: 500;
  width: 80%;
  text-align: center;
  padding: 3px;
}

.documentHeader{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}