.customer-review-setup {
    padding: 40px 0 100px 0;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.lender-btn-normal {
    height: 40px;
    border-radius: 4px !important;
}

.form-wrap {
    padding-right: 0;
}

.form-wrap .theme-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 12px;
}

.customer-review-form .ant-form-item-label>label {

    font-size: 18px;
    font-weight: 600;
}

.customer-review-form .ant-form-item {
    margin-bottom: 0;
}

.customer-review-form .remove-photo {
    cursor: pointer;
}

.customer-review-form .testimonial-title {
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    line-height: 28px;
    margin-right: 24px;
}
.customer-review-form .testimonial-rate {
    height: 48px;
    display: flex;
    align-items: center;
}
.testimonial-label {
    padding: 0 0 12px 0;
    display: flex;
    align-items: center;
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    line-height: 28px;
    margin-right: 24px;
}
.customer-review-form .add-review {
    font-family: Inter;
    color: #325CEB;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}

.bullet {
    color: #000;
    margin-left: 10px;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}