.documentTitle{
  display: inline-block;
  color: rgb(74, 74, 74);
  font-size: 1.5rem;
  font-weight: 500;
  width: 80%;
  text-align: center;
  padding: 3px;
}

.documentHeader{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.imageContainer{
  position: relative;
  overflow: hidden;
}

.image{
  position: relative;
  width: 100%;
}

.customSpinnerStyles{
  top: 30vh;
}

.cursorMove{
  cursor: move;
}

.hr{
  margin: 0 0;
  position: relative;
  border: 2px dashed rgb(255, 0, 0);
}

.bbox{
  position: absolute;
  border-radius: 2px;
  border: 2px solid #325CEB;
  background: rgba(119, 163, 255, 0.30);
  /* border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid rgb(255, 0, 0); */
}
.pdfDocuments {
  margin: 0 auto;
}