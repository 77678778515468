.ModalTitle {
    color: #222;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;

}

.custom-modal-type .ant-modal-content {
    padding: 25px 7px;
}

.custom-modal-type .ant-modal-close {
    margin-top: 10px;
}

.custom-input.ant-input-status-error {
    border-color: #C21818;
    ;
    /* 自定义边框颜色 */
    color: #C21818;
    ;
    /* 自定义文字颜色 */
}

.boldText {
    color: #6E6E70;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;

}

.StepTitle {
    color: #222;
    font-family: Inter;
    font-size: 18px;

    font-weight: 700;

}

.GailPersonal {
    /* padding-left: 1px;
     */
    padding-inline-start: 20px;
}

.GailPersonal li {
    margin-top: 5px;
    /* list-style: none; */

}

.custom-table .ant-table-thead>tr>th {
    color: #586570;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal_warp .ant-modal {
    top: 0 !important;
    height: 100%;
    padding-bottom: 0;
}

.modal_warp .ant-modal-content {
    border-radius: 0 !important;
    height: 100%;
}

.modal_content {
    height: 100vh !important;
}

.onboarding {
    height: 610px;
    /* max-height: 620px;
    overflow: auto; */
    padding-left: 40px !important;
    padding-right: 40px !important
}

.ModalShow {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 23rem;
    height: 268px;
    border-radius: 8px;
    border: 2px solid #D6E2FB;
    background: #EAEFFD;
    margin: auto;
    margin-top: 38px
}

.StepTwoContent {
    height: 400px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    column-gap: 33px;
    /* 列之间的间距 */
    row-gap: 20px;
    margin-top: 20px;



}

.StepTwoData {
    color: #474849;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    display: grid;
    align-items: center;
    /* 垂直居中 */
    justify-items: center;
    text-align: center;
    width: 272px;
    height: 170px;
    border-radius: 8px;
    border: 2px solid #D6E2FB;
    background: #EAEFFD;
}

.disabled {
    border-radius: 8px;
    border: 2px solid #B1B2B7;
    background: #E0E0E0;
}

.StepTwoData img {

    width: 102px;
    height: 102px;

}

.AchievementRoundF {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: auto;
}



.AchievementRound {
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #325CEB;


}

::-webkit-scrollbar {
    z-index: -1;
    width: 8px;
    /* 控制滚动条的宽度 */
    height: 8px;
    margin-top: 20px;
    /* 控制垂直滚动条的高度 */
}

/* 修改滚动条轨道的样式 */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* 轨道背景颜色 */
    border-radius: 6px;
    /* 轨道圆角 */
}

/* 修改滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
    background-color: #888;
    /* 滑块颜色 */
    border-radius: 6px;
    /* 滑块圆角 */
}

/* 当鼠标悬停在滑块上时的样式 */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* 悬停时的颜色 */
}

.stepFiveImg {
    text-align: center;


    border-radius: 16px;
    border: 1px solid #dbdcde;
    overflow: hidden;
    width: 75%;
    margin: auto;
    margin-top: 10px;
}

@media (max-width: 584px) {
    .StepTwoContent {
        display: block;
        width: 250px;
        margin: auto;
    }

    .stepFiveImg {
        width: 100%;
    }

    .StepTwoData {
        margin-top: 15px;
    }
}

@media (max-width: 624px) {
    .stepFiveImg {
        width: 100%;
    }
}