.css-13cymwt-control,
.css-t3ipsp-control {
	min-height: 44px !important;
}

/* .css-1p3m7a8-multiValue {
	background-color: #325CEB !important;
} */

.css-wsp0cs-MultiValueGeneric {
	color: #fff !important;
}

.css-12a83d4-MultiValueRemove:hover {
	color: #000000 !important;
	background-color: #597cee !important;
}

.css-12a83d4-MultiValueRemove {
	color: #fff !important;
}

/* .css-b62m3t-container {
	width: 266px;
} */

.btn-primarys {
	height: 3.125rem;
	min-width: 8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none !important;
	border-radius: 2px !important;
	color: #fff !important;
	background-color: #325CEB !important;
	font-size: 1.125rem !important;
}

.btn-primarys:hover {
	background: #284ABC !important;
	color: #fff !important;
}

.btn-secondary {
	height: 2.75rem;
	min-width: 8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold !important;
	font-size: 1rem !important;
	line-height: 1.2rem !important;
	border-radius: 0.25rem !important;
	color: #325CEB !important;
	background-color: #fff !important;
	border: 1px solid #325CEB !important;
}

.btn-secondary:hover {
	background-color: #EAEFFD !important;
	color: #325CEB !important;
}

/* @media screen and (min-width:768px) { */
	.scroll-bar {
		/* width: 100%; */
		height: 3.23rem;
		border: 1px solid #CFD4D9;
		display: flex;
		align-items: center;
		padding-right: 1rem;
		border-radius: 0.25rem;
	}

	.input-text {
		border: none !important;
		/* width: 8.125rem; */
		width: 36%;
		font-size: 16px;
	}

	.bar-sign {
		margin: 1rem;
		font-size: 16px;
	}

	.custom-range {
		margin-left: 10px;
		/* width: 10rem !important; */
		width: 56% !important;

	}

	.rc-slider-track {
		background-color: #325CEB !important;
		height: 0.5rem;
	}

	.rc-slider-handle {
		border: solid 2px #325CEB !important;
		background-color: #fff !important;
		opacity: 1 !important;
		width: 1.5rem !important;
		height: 1.5rem !important;
		margin-top: -0.5625rem !important;
	}

	.rc-slider-rail,
	.rc-slider-track {
		height: 0.5rem !important;
	}
/* } */

/* @media screen and (min-width:375px) and (max-width:767px) {
	.scroll-bar {
		width: 14.72rem;
		height: 2.08rem;
		border: 1px solid #CFD4D9;
		display: flex;
		align-items: center;
	}

	.input-text {
		border: none !important;
		width: 5.2rem;
		font-size: 0.312rem;
	}

	.bar-sign {
		margin: 0.312rem;
		font-size: 0.312rem;
	}

	.custom-range {
		margin-left: 10px;
		width: 6.4rem !important;
	}

	.rc-slider-track {
		background-color: #325CEB !important;
		height: 0.32rem !important;
	}

	.rc-slider-handle {
		border: solid 2px #325CEB !important;
		background-color: #fff !important;
		opacity: 1 !important;
		width: 0.64rem !important;
		height: 0.64rem !important;
		margin-top: -0.18rem !important;
	}

	.rc-slider-rail,
	.rc-slider-track {
		height: 0.32rem !important;
	}
} */

.input-text:focus,
.input-text:focus-visible {
	border: 1px solid #0d99ff !important;
	border-radius: 0.25rem !important;
	outline: 1px solid #0d99ff !important;;
}

.radio-test:hover {
	border: 1px solid #325CEB;
}

.rc-slider-handle:focus-visible,
.rc-slider-handle-dragging {
	box-shadow: unset !important;
}

.form-control:hover,
input.form-control:focus {
	border: 1px solid #0d99ff !important;
	border-radius: 4px !important;
}

.form-check {
	/* display: flex !important; */
	align-items: center;
}

.btn:focus {
	box-shadow: unset !important;
}

.btn-selections:not(:disabled):not(.disabled):hover {
	border: 1px solid #325CEB !important;
	background-color: #fff !important;
	color: #000000 !important;
}

input[type=radio] {
	box-sizing: border-box;
	padding: 0;
	margin-right: 0.75rem;
	width: 1.125rem;
	height: 1.125rem;
	appearance: none;
	position: relative;
}

input[type=radio]::before {
	content: "";
	position: absolute;
	top: 4px;
	left: 0;
	border: 1px solid #CFD4D9 !important;
	background-color: #F1F1F1;
	border-radius: 1.125rem;
	width: 1.125rem;
	height: 1.125rem;
}

input[type=radio]:checked::before {
	content: "";
	background: #325CEB;
	position: absolute;
	top: 13px;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1.125rem;
	height: 1.125rem;
	border: none !important;
	border-radius: 50%;
}

.btn-selections:not(:disabled):not(.disabled) {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	padding: 0.812rem 1rem 0.812rem 0.812rem !important;
	border: 1px solid #CFD4D9 !important;
	background-color: #fff !important;
	color: #222222 !important;
	border-radius: 0.25rem !important;
	font-weight: 400 !important;
	font-size: 1rem !important;
	line-height: unset !important;
}

.btn-selections:not(:disabled):not(.disabled).active {
	box-shadow: unset !important;
	padding: 0.812rem 1rem 0.812rem 0.812rem !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	border: 1px solid #325CEB !important;
	background-color: #fff !important;
	color: #222222 !important;
	border-radius: 0.25rem !important;
}

.input-groups>.form-control {
	height: 2.75rem !important;
	font-size: 0.875rem;
}