.craSpin .ant-spin .ant-spin-dot-item {
    background-color: white;
}

.mdaPropertyInfo {
    background-color: #D6E2FB;
    padding: 5px 10px;
    margin-bottom: 8px;
    color:#325CEB;
}

.callbackTitle-1 {
    font-size: 22px;
    font-weight: 500;
}

.callback-lo {
    background-color: #D6E2FB;
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.callbackTitle-3 {
    font-size: 16px;
    /* color:#325CEB; */
    font-weight: 500;
}
.callbackTitle-4 {
    font-size: 16px;
    line-height: 1.7;
}

.callback-loinfo {
    display: flex;
    justify-content: space-between;
}

.craWrap {
    display: flex;
    justify-content: center;
    padding: 0 50px;
}

.tipsMarginAdjuster {
    margin-left: 15px !important;
    margin-right: 15px !important;
}


@media (max-width: 767px){
    .craWrap {
        padding: 0 10px;
    }
  }

@media (max-width: 767px){
    .craMarginAdjuster {
        margin-top: 18px;
    }
  }

@media (max-width: 1300px){
    .callback-loinfo {
        display: block;
        margin-left: 30px;
    }
}

.programHighlights {
    background-color: #EAEFFD;
    border-radius: 4px;
    padding: 10px;
    color: #1F3988;

}

