.product {
    margin-bottom: 20px;
}

.productTitle {
    text-align: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 30px;
}

.productAssumption {
    text-align: center;
    color: rgb(163, 163, 163);
    font-size: 13px;
}

.productRates {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.ratesCard {
    margin: 10px;
    padding-top: 20px;
    /* background-color: rgb(239, 239, 239); */
    border-radius: 10px;
    width: 250px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.termType {
    text-align: center;
}

.termType span {
    padding: 2px 10px 2px 10px;
    background-color: rgb(178, 221, 252);
    border-radius: 10px;
}

.costsRow {
    display: flex;
    border-top: 1px rgb(211, 211, 211) solid;
}


.rateApr {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 27px;
    text-align: center;
}

.rateAprTitle {
    font-weight: bold;
    font-size: 13px;
    color: rgb(163, 163, 163);
    text-align: center;
    margin-bottom: 25px;
}

.costsCol {
    flex: 1;
}

.rightBorder {
    border-right: 1px rgb(211, 211, 211) solid;
}

.costs {
    font-size: large;
    text-align: left;
    padding-left: 13px;
    padding-bottom: 10px;
}

.costsColTitle {
    font-weight: bold;
    font-size: 12px;
    padding-left: 13px;
    color: rgb(163, 163, 163);
    text-align: left;
}