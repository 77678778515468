.income-calculation-modal.check-list {
    top: 0;
    padding-bottom: 0;
}
.income-calculation-modal.check-list .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
    top: 0;
    border-radius: 10px;
    overflow: hidden;
}
.income-calculation-modal.check-list .ant-modal-content .ant-modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.income-calculation-modal.check-list .ant-modal-footer {
    height: 80px;
    padding: 0 64px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ECECEC;
    margin-top: 0;
}
.income-calculation-modal.check-list .ant-modal-footer .income-btn-back {
    min-width: 122px;
    padding: 0 20px;
    font-family: Inter;
    color: #325CEB;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.income-calculation-modal.check-list .ant-modal-footer .income-btn-back:disabled {
    color: #6E6E70;
}
.income-calculation-modal.check-list .ant-modal-footer .income-btn-confirm {
    min-width: 122px;
    padding: 0 20px;
    height: 46px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px !important;
}
.income-calculation-modal.check-list .income-preview-wrap {
    height: 100%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file {
    padding: 4px 10px;
    border-radius: 10px;
    border: 1px solid #B1B2B7;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    width: 70%;
}
.income-calculation-modal.check-list .income-preview-wrap .income-file-select {
    height: 48px;
}
.income-calculation-modal.check-list .income-preview-wrap .income-file-select .ant-select-selector {
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
    border-radius: 8px;
}
.income-calculation-modal.check-list .income-calculation-select-file {
    width: 38px;
    height: 38px;
    border-radius: 8px;
    background-color: #E0E7FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .prev-file, .income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .next-file {
    cursor: pointer;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .flie-total {
    display: flex;
    align-items: center;
    color: #545459;
    font-size: 16px;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .flie-total span {
    font-weight: 500;
    color: #325CEB;
    margin-left: 12px;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .separate {
    height: 28px;
    width: 1px;
    background-color: #ECECEC;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .file-name {
    color: #545459;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 1;
    overflow: hidden;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .file-name .file-unavailable {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #222;
    font-size: 16px;
    font-weight: 500;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .file-name .file-unavailable div {
    width: 22px;
    height: 22px;
    border-radius: 3px;
    background-color: #D9D9D9;
}
.income-calculation-modal.check-list .income-preview-wrap .income-calculation-change-file .file-name .file-name-text {
    flex: 1 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.income-calculation-modal.check-list .document-preview-content {
    flex: 1;
    overflow-y: auto;
    background-color: #D9D9D9;
    padding: 8px;
    max-height: calc(100vh - 278px);
}
.income-calculation-modal.check-list .document-preview-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.income-calculation-modal.check-list .document-preview-content::-webkit-scrollbar-track {
    background: transparent; 
}
.income-calculation-modal.check-list .document-preview-content::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 5px;
}
.income-calculation-modal.check-list .income-calculation-content {
    height: 100%;
    max-height: calc(100vh - 228px);
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list {
    display: flex;
    font-family: Inter;
    gap: 20px;
    padding: 0 30px;
    margin-top: 38px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-label {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;
    gap: 15px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-label div {
    color: #6E6E70;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select {
    flex: 1;
    display: flex;
    gap: 12px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item {
    padding: 22px 10px;
    border: 1px solid #FFF;
    width: 20%;
    margin-bottom: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item:hover {
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background-color: #F6F6F6;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item.income-calculation-item-selected {
    border-radius: 6px;
    border: 1px solid #D8EAFF;
    background-color: #F7F8FF;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-radio .ant-radio-inner {
    width: 18px;
    height: 18px;
    border-width: 2px;
    background-color: #FFF;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-radio span.ant-radio+* {
    padding-inline-start: 20px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-radio .ant-radio-disabled .ant-radio-inner {
    border: 2px solid #DBDCDE;
    background-color: #FFF;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-radio .ant-radio-inner::after {
    background-color: #325CEB;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-radio .ant-radio-disabled .ant-radio-inner::after {
    background-color: #DBDCDE;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-radio .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-item-name {
    color: #222;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 2px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-item-description {
    color: #6E6E70;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    min-height: 30px;
    margin-bottom: 10px;
    padding-left: 26px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-item-value {
    color: #6E6E70;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding-left: 26px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-item-value:not(:last-child) {
    margin-bottom: 12px;
}
.income-calculation-modal.check-list .income-calculation-content .income-calculation-list .income-calculation-select .income-calculation-item .income-calculation-item-value.bold-value {
    color: #222;
    font-weight: 700;
}
.income-calculation-modal.check-list .income-calculation-content .income-item {
    margin-bottom: 40px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item-title {
    color: #081265;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item-nodata {
    color: #6E6E70;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
}
.income-calculation-modal.check-list .income-calculation-content .income-item-subtitle {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-item-content {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-item-content .income-date {
    height: 34px;
    padding: 0 6px;
    background-color: #FCFCFC;;
    border: 1px solid #ECECEC;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-item-content .income-date.wages-date .ant-picker-input {
    flex-direction: row-reverse;
    gap: 10px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-inputNumber.income-inputNumber-w2 input.ant-input-number-input {
    padding: 4px 0;
    font-size: 16px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-inputNumber input.ant-input-number-input {
    padding: 6px 0;
    font-size: 14px;
    font-family: Inter;
    color: #222;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-select {
    height: 34px;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-select .ant-select-selector {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: normal;
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-item-content
    .income-date
    .ant-picker-input
    > input {
    font-family: Inter;
    font-size: 16px;
    color: #222;
}
.income-calculation-modal.check-list .income-calculation-content .income-item .income-item-content .income-item-label {
    color: #6E6E70;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    width: 128px;
}
.income-calculation-modal.check-list .income-calculation-steps-wrapper {
    font-family: Inter;
    padding: 25px 40px 25px 50px;
    display: flex;
    justify-content: space-between;
}
.income-calculation-modal.check-list .income-calculation-steps {
    width: 278px;
    align-items: center;
}
.income-calculation-modal.check-list .income-calculation-steps.ant-steps-small .ant-steps-item-icon {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    font-family: Inter;
}
.income-calculation-modal.check-list .income-calculation-steps.ant-steps-small .ant-steps-item-title {
    padding-inline-end: 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: Inter;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-container {
    display: flex;
    align-items: center;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-process .ant-steps-item-icon {
    width: 22px;
    height: 22px;
    background-color: #EAEFFD;
    border: 1px solid #325CEB;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #D9D9D9;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-finish .ant-steps-item-icon {
    width: 22px;
    height: 22px;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon{ 
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-wait .ant-steps-item-icon {
    width: 22px;
    height: 22px;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon {
    color: #325CEB;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon {
    color: #FFF;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #325CEB;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #6E6E70;
}
.income-calculation-modal.check-list .income-calculation-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #6E6E70;
}
.income-calculation-modal.check-list .income-calculation-steps.ant-steps-small .ant-steps-item-title::after {
    top: 10px;
    height: 2px;
}
.income-calculation-modal.check-list .document-preview-content .preview-box {
    max-height: calc(100vh - 278px);
    margin: 0 auto;
    position: relative;
}
.income-calculation-modal.check-list .document-preview-content .preview-box .pdf-anchor {
    margin-bottom: 12px;
}
.income-calculation-modal.check-list .preview-modal-top {
    height: 76px;
    line-height: 76px;
    background-color: #FFF;
    color: #222;
    font-size: 18px;
    font-weight: 600;
    padding-left: 46px;
    border-bottom: 2px solid #ECECEC;
}
.income-calculation-modal.check-list .ant-modal-close {
    color: #6E6E70;
    right: 42px;
    top: 26px;
}
.income-calculation-modal.check-list .file-action {
    height: 45px;
    border-top: 1px solid #DBDCDE;
    border-bottom: 1px solid #DBDCDE;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    position: relative;
    gap: 12px;
}
.income-calculation-modal.check-list .file-action .change-file {
    padding: 4px 10px;
    border-radius: 10px;
    border: 1px solid #B1B2B7;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    width: 33.33%;
}
.income-calculation-modal.check-list .file-action .right-action {
    width: 33.33%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    gap: 24px;
}
.income-calculation-modal.check-list .file-action .right-action .details-btn {
    height: 37px;
    padding: 0 5px 0 15px;
    display: flex;
    color: #325CEB;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
}
.income-calculation-modal.check-list .file-action .right-action .details-btn.show {
    background-color: #FFF;
}
.income-calculation-modal.check-list .file-action .right-action .details-btn.show img {
    transform: rotate(180deg);
    transition: all .3s;
}
.income-calculation-modal.check-list .file-action .right-action .details-btn.hidden img {
    transform: rotate(0);
    transition: all .3s;
}
.income-calculation-modal.check-list .file-action .right-action .details-btn span {
    margin-right: 5px;
}
.income-calculation-modal.check-list .file-action .download-directory {
    font-family: Inter;
    color: #1F2890;
    font-size: 16px;
    font-weight: 500;
    width: 33.33%;
}
.income-calculation-modal.check-list .file-action .download-directory > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.income-calculation-modal.check-list .file-action .change-file .prev-file, .income-calculation-modal.check-list .file-action .change-file .next-file {
    cursor: pointer;
}
.income-calculation-modal.check-list .file-action .change-file .prev-file.arrow-disabled, .income-calculation-modal.check-list .file-action .change-file .next-file.arrow-disabled {
    cursor: no-drop;
}
.income-calculation-modal.check-list .file-action .change-file .flie-total {
    display: flex;
    align-items: center;
    color: #545459;
    font-size: 16px;
}
.income-calculation-modal.check-list .file-action .change-file .flie-total span {
    font-weight: 500;
    color: #325CEB;
    margin-left: 12px;
}
.income-calculation-modal.check-list .file-action .change-file .separate {
    height: 28px;
    width: 1px;
    background-color: #ECECEC;
}
.income-calculation-modal.check-list .file-action .change-file .file-name {
    color: #545459;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1;
    overflow: hidden;
}
.income-calculation-modal.check-list .file-action .change-file .file-name div {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.income-calculation-modal.check-list .document-preview-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: #FFF;
    background: transparent;
    padding: 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.income-calculation-modal.check-list .document-preview-bottom-bar {
    height: 48px;
    width: 330px;
    border-radius: 8px;
    color: #FFF;
    background: rgba(0, 0, 0, 0.80);
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -165px;
    display: flex;
    align-items: center;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination {
    flex: 1;
    padding: 9px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #FFF;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination .ant-pagination-prev button, .income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination .ant-pagination-next button {
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination .ant-pagination {
    color: #FFF;
    display: flex;
    align-items: center;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination .ant-pagination .ant-pagination-slash {
    margin-inline-start: 0;
    margin-inline-end: 0;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: rgba(0, 0, 0, 0.90);
    color: #FFF !important;
    width: 40px;
    border: 1px solid rgba(0, 0, 0, 0.90);
    margin-right: 8px;
    font-size: 14px !important;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
    height: 30px;
    display: flex;
    align-items: center;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-scale {
    flex: 1;
    display: flex;
    gap: 8px;
    padding: 9px 12px;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-scale .zoom-min, .income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-scale .zoom-add {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.income-calculation-modal.check-list .document-preview-bottom-bar .document-preview-scale .zoom-value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.income-calculation-modal.check-list .document-preview-top-bar .document-preview-top-bar-l {
    display: flex;
    align-items: center;
}
.income-calculation-modal.check-list .document-preview-top-bar .document-preview-top-bar-l .document-preview-verify {
    width: 98px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #FFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    cursor: pointer;
}
.income-calculation-modal.check-list .document-preview-top-bar .document-preview-top-name {
    font-size: 18px;
    font-weight: 500;
    margin-left: 12px;
}
.income-calculation-collapse {
    background-color: #FFF;
    border: none;
}
.income-item .paystubs-table .paystubs-table-row {
    font-family: Inter;
    font-size: 14px;
    height: 34px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.income-item .paystubs-table .paystubs-table-add {
    display: flex;
}
.income-item .paystubs-table .paystubs-table-add-btn {
    height: 38px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.income-item .paystubs-table .paystubs-table-add-btn div {
    color: #325CEB;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}
.income-item .paystubs-table .paystubs-table-row .paystubs-table-form {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.income-item .paystubs-table .paystubs-table-row .paystubs-table-form div {
    color: #6E6E70;
    font-weight: 600;
    line-height: normal;
}
.income-item .paystubs-table .paystubs-table-row .paystubs-table-delete {
    width: 24px;
}
.income-calculation-collapse>.ant-collapse-item {
	border: 1px solid #DBDCDE;
	transition: all .3s;
	margin-bottom: 12px;
	border-radius: 8px !important;
}
.income-calculation-collapse>.ant-collapse-item:hover {
	box-shadow: 0px 4px 8px 0px rgba(7, 13, 61, 0.12);
}

.income-calculation-collapse>.ant-collapse-item >.ant-collapse-header {
	padding: 20px 16px;
	align-items: center;
}
.income-calculation-collapse>.ant-collapse-item >.ant-collapse-header .income-calculation-collapse-header {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}
.income-calculation-collapse .ant-collapse-content {
	margin: 0 16px;
}
.income-calculation-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 14px 10px;
}
.income-calculation-collapse .ant-collapse-expand-icon .collapse-icon {
    transition: all .3s;
    cursor: pointer;
}
.income-calculation-collapse .ant-collapse-expand-icon .collapse-icon.expand {
    transform: rotate(180deg);
}