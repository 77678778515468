.require-callback-container {
    background-color: #f7f8ff;
    padding: 37px 30px 136px 30px;
}

.require-callback-title {
    color: #081265;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
    margin-bottom: 49px;
}

.require-callback-form {
    width: 533px;
}

.require-callback-form .ant-select .ant-select-arrow {
    width: 33px !important;
    height: 33px !important;
    top: 50% !important;
    right: 10px !important;
    margin-top: -16.5px !important;
}

.require-callback-form .ant-select .ant-select-arrow .anticon {
    display: none !important;
}

.require-callback-form .ant-select .ant-select-arrow::after {
    content: '' !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    background: url('/images/antd-select-arrow.svg') center center no-repeat !important;
    background-size: contain !important;
}

.name-fields,
.time-fields {
    display: flex;
    gap: 50px;
}

.form-item {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #586570;
}

.input-field,
.select-field {
    height: 56px;
    border-radius: 8px !important;
}

.checkbox-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #222222;
}

.submit-button {
    width: 100%;
    height: 56px;
    margin-bottom: 18px;
    background-color: #1630ac !important;
    color: #fcfcfc;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #1428a0;
}

.disclaimer-text {
    color: #6e6e70;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
}

/* Ant Design Custom Styles Overrides */
.require-callback-form .ant-input,
.require-callback-form .ant-input-affix-wrapper,
.require-callback-form .ant-select-selector,
.require-callback-form .ant-input-textarea {
    background-color: #fcfcfc !important;
    border-radius: 8px !important;
    border: 1px solid #ececec !important;
    padding: 12px 20px !important;
}

.require-callback-form .ant-input:focus,
.require-callback-form .ant-input:hover,
.require-callback-form .ant-input-affix-wrapper:focus,
.require-callback-form .ant-input-affix-wrapper:hover,
.require-callback-form .ant-input-textarea textarea:focus,
.require-callback-form .ant-input-textarea textarea:hover,
.require-callback-form .ant-select-focused .ant-select-selector,
.require-callback-form .ant-select-selector:focus,
.require-callback-form .ant-select-selector:hover {
    border-color: #1630ac !important;
    box-shadow: 0 0 0 2px rgba(22, 48, 172, 0.1) !important;
}

.require-callback-form .ant-input::placeholder,
.require-callback-form .ant-input-textarea textarea::placeholder,
.require-callback-form .ant-select-selection-placeholder {
    color: #858585 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
}

.require-callback-form .ant-input,
.require-callback-form .ant-input-textarea textarea,
.require-callback-form .ant-select-selection-item {
    color: #222222 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
}

.require-callback-form .ant-input-textarea textarea {
  padding: 12px 20px !important;
  height: 124px !important;
  min-height: 124px !important;
  resize: none !important;
}

.require-callback-form .ant-form-item:last-of-type {
  margin-bottom: 15px !important;
}

.require-callback-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1630AC !important;
  border-color: #1630AC !important;
}

.require-callback-form .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.require-callback-form .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #1630AC !important;
}

/* Remove required asterisk */
.require-callback-form .ant-form-item-required::before {
    display: none !important;
}
