.nonqm-rate-quoter-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    border: 1px solid #CFD4D9;
    border-radius: 4px;
}
.nonqm-rate-quoter-form .form-control {
    background-color: #FCFCFC;
}
.nonqm-calculator-modal {
    height: 100vh;
}
.nonqm-calculator-modal .ant-modal-body {
    margin-top: 5px;
}

.nonqm-calculator-modal .ant-modal-content {
    height: 100%;
    border-radius: 0px;
}
.nonqm-calculator-modal .ant-modal {
    padding-bottom: 0px;
}
.nonqm-calculator-modal .ant-modal-footer {
    display: none;  
}
