.dti-calculator-modal-right {
    pointer-events: none;
}
.dti-calculator-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    top: 0;
}
.dti-calculator-modal.normal-modal .ant-modal-content {
    height: 96vh;
    border-radius: 10px;
}
.dti-calculator-modal.edit-modal {
    position: fixed;
    top: 0;
    right: 0;
    padding-bottom: 0;
    /* border-radius: 8px 0px 0px 0px; */
}
.dti-calculator-modal.edit-modal .ant-modal-content {
    height: 100vh;
    border-radius: 8px 0px 0px 0px;
}
.dti-calculator-modal {
    transition: bottom 0.3s ease, right 0.3s ease;
}
.dti-calculator-modal.dti-calculator-modal-minimize {
    position: fixed;
    padding-bottom: 0;
    top: auto;
    bottom: 0;
    right: 0;
    border-radius: 8px 0px 0px 0px;
}
.dti-calculator-modal.dti-calculator-modal-minimize .ant-modal-content {
    height: auto;
    border-radius: 8px 0px 0px 0px;
}
.dti-calculator-modal.dti-calculator-modal-minimize .ant-modal-content .ant-modal-body .dti-calculator-content {
    display: none;
}
.dti-calculator-modal.dti-calculator-modal-minimize .ant-modal-content .ant-modal-body .dti-calculator-top {
    height: 70px;
    border-bottom: none;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body {
    font-family: Inter;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.dti-calculator-modal .ant-modal-footer {
    height: 80px;
    padding: 0 64px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ECECEC;
    margin-top: 0;
}
.dti-calculator-modal.edit-modal .ant-modal-footer {
    color: #222;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    height: 64px;
    padding: 0 22px 0 26px;
}
.dti-calculator-modal.edit-modal .ant-modal-footer .dti-card-statistics {
    display: flex;
    gap: 12px;
}
.dti-calculator-modal .ant-modal-footer .dti-btn-back {
    width: 92px;
    height: 42px;
    font-family: Inter;
    color: #325CEB;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.dti-calculator-modal .ant-modal-footer .dti-btn-confirm {
    padding: 0 20px;
    height: 42px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px !important;
}
.dti-calculator-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 22px;
    right: 50px;
}
.dti-calculator-modal-right .dti-calculator-modal .ant-modal-content .ant-modal-close {
    right: 22px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top {
    height: 62px;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid #ECECEC;
    cursor: move;
    position: relative;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-title {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-top: 24px;
    margin-bottom: 0;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-tabs {
    position: absolute;
    left: 200px;
    right: 120px;
    bottom: 0;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-tabs .ant-tabs >.ant-tabs-nav { 
    margin: 0;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-tabs .ant-tabs .ant-tabs-tab {
    min-width: 126px;
    padding: 14px 0;
    justify-content: center;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-tabs .ant-tabs .ant-tabs-tab-btn {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-minimize {
    color: #6E6E70;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 22px;
    right: 90px;
    border-radius: 4px;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-top .dti-calculator-minimize:hover {
    background-color: rgba(0, 0, 0, 0.06);
    text-decoration: none;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content {
    padding: 38px 50px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    scrollbar-gutter: stable both-edges;
}
.dti-calculator-modal.edit-modal .ant-modal-content .ant-modal-body .dti-calculator-content {
    padding: 8px 16px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group {
    display: flex;
    gap: 32px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item {
    width: 270px;
    height: 74px;
    padding: 28px 0 0 24px;
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background-color: #FCFCFC;
    cursor: pointer;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item.select-item {
    border: 1px solid #D8EAFF;
    background-color: #F7F8FF;
}

.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio {
    line-height: 20px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .ant-radio {
    align-self: flex-start;
    margin-top: 1px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .dti-warning-text {
    color: #C21818;
    font-family: Inter;
    font-size: 12px;
    line-height: normal;
    margin-top: 2px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .ant-radio-inner {
    width: 18px;
    height: 18px;
    border-width: 2px;
    background-color: #FFF;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .ant-radio-disabled .ant-radio-inner {
    border: 2px solid #DBDCDE;
    background-color: #FFF;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .ant-radio-inner::after {
    background-color: #325CEB;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .ant-radio-disabled .ant-radio-inner::after {
    background-color: #DBDCDE;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .radio-text {
    display: flex;
    align-items: center;
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-group .radio-item .dti-radio .radio-text .overLimit {
    color: #C21818;
    font-weight: 600;
}
.dti-calculator-tooltip {
    max-width: 262px;
}
.dti-calculator-tooltip .ant-tooltip-arrow:before {
    background-color: #D6E2FB;
}
.dti-calculator-tooltip .ant-tooltip-inner {
    padding: 12px;
    font-family: Inter;
    font-size: 12px;
    color: #545459;
    line-height: 18px;
    border-radius: 8px;
    background-color: #D6E2FB;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-credit-status {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin: 8px 0 26px;
    display: flex;
    gap: 6px;
}

.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-header {
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 16px;
	color: #222;
	line-height: 20px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-header .dti-calculator-item {
    color: #222;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse {
	background-color: #FFF;
	border: none;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-collapse-item .dti-calculator-card-wrap {
    column-count: 2;
    column-gap: 24px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-collapse-item {
    border-radius: 8px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-collapse-item.retract-collapse {
    margin-bottom: 0;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-collapse-item.retract-collapse-last {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .ant-collapse-expand-icon .collapse-icon {
    transition: all .3s;
    cursor: pointer;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .ant-collapse-expand-icon .collapse-icon.expand {
    transform: rotate(180deg);
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-card  {
    box-sizing: border-box;
    break-inside: avoid;
    margin-bottom: 24px;
    padding: 24px 16px;
    border-radius: 8px;
    background: #F6F6F6;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-tooltip-card {
    padding: 12px 26px 16px 12px;
    border-radius: 6px;
    background-color: #F8E8D3;
    color: #6E6E70;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin: 8px 0 12px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-tooltip-card .dti-tooltip-card-title {
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 14px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-tooltip-card .dti-tooltip-card-content {
    padding-left: 32px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-card .dti-calculator-card-title {
    color: #222;
    font-weight: bold;
    line-height: normal;
    display: flex;
    justify-content: space-between;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-card .dti-calculator-card-subtitle {
    line-height: normal;
    color: #222;
    padding-right: 80px;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-card .dti-calculator-price {
    color: #6E6E70;
    line-height: normal;
    display: flex;
    justify-content: space-between;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-card .dti-calculator-edit {
    display: flex;
    align-items: center;
    color: #325CEB;
    line-height: normal;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-collapse-item .dti-calculator-card-wrap .dti-calculator-card .dti-calculator-edit > div {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}
.dti-calculator-modal .ant-modal-content .ant-modal-body .dti-calculator-content .dti-calculator-collapse-wrap .dti-calculator-collapse .dti-calculator-collapse-item .dti-calculator-card-wrap .dti-calculator-card .dti-calculator-edit img {
    width: 16px;
    height: 16px;
}
.dti-calculator-collapse>.ant-collapse-item {
	border: 1px solid #DBDCDE;
	transition: all .3s;
	margin-bottom: 32px;
}
.dti-calculator-collapse>.ant-collapse-item:hover {
	box-shadow: 0px 4px 8px 0px rgba(7, 13, 61, 0.12);
}

.dti-calculator-collapse>.ant-collapse-item >.ant-collapse-header {
	padding: 19px 28px;
	align-items: center;
}
.dti-calculator-collapse .ant-collapse-content {
	margin: 0 28px;
}
.dti-calculator-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 26px 0 30px;
}