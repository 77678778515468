.side-drawer { 
    height: 100%;
    background: white;
    position: fixed;
    bottom: 50px;
    left:  0;
    width: 100%;
    height: 90%;
    z-index: 200;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
 }
 .side-drawer.open {
    transform: translateY(100px);
 }