.welcome-guideline-page {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    width: 100%;
    min-height: 100vh;
    padding: 9px 12px;
    position: relative;
}

.welcome-guideline-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.welcome-guideline-content {
    margin-top: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-guideline-title {
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 38.73px !important;
    background-image: linear-gradient(
        to right,
        #222222 0%,
        #1630ac 100%
    ) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    color: transparent !important;
    display: inline-block !important;
    -moz-text-fill-color: transparent !important;
}

.welcome-guideline-subtitle {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: #6e6e70;
}

.new-chat-button {
    width: 95px;
    height: 42px;
    padding: 11px;
    color: #ffffff;
    background-color: #325ceb;
    border: 1px solid #ffffff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.guideline-sections-container {
    margin-top: 43px;
    margin-left: auto;
    margin-right: auto;
}

.guideline-section-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #62707c;
}

.guideline-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 8px;
}

.guideline-item {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 10px;
    width: fit-content;
    min-width: 0;
    height: 48px;
    background: #ffffff;
    cursor: pointer;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #ececec;
    transition: all 0.2s ease-in-out;
}

.guideline-item:hover {
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}

.guideline-item-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.guideline-item-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    color: #222222;
    white-space: nowrap;
}

.last-used-badge {
    background: #d8eaff;
    padding: 4px 6px;
    border-radius: 21px;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #133580;
    margin-left: 10px;
    white-space: nowrap;
}

@media (max-width: 575px) {
    .welcome-guideline-header {
        height: 57px;
        background-color: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #dbdcde;
        justify-content: space-between;
    }

    .welcome-guideline-page {
        background-color: #ffffff;
        height: calc(100vh - 57px - 116px);
        min-height: calc(100vh - 57px - 116px);
        padding: 35px 20px;
    }

    .welcome-guideline-header .chat-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 21.78px;
        color: #222222;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .welcome-guideline-header .action-icon {
        width: 42px;
        height: 42px;
        cursor: pointer;
    }

    .welcome-guideline-content {
        margin-top: 0;
        align-items: flex-start;
    }

    .welcome-guideline-title {
        font-size: 24px !important;
        font-weight: 700 !important;
        line-height: 29.05px !important;
    }

    .guideline-sections-container {
        margin-top: 26px;
    }

    .guideline-item {
        gap: 6px;
    }

    .welcome-guideline-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 116px;
        padding: 8px 20px 27px 20px;
        border-top: 1px solid #dbdcde;
        z-index: 1000;
    }
}
