.btn-task-desc {
	height: 44px;
	border: 1px solid #ced4da !important;
	/* border-radius: 2px !important;
	color: #325CEB !important;
	font-size: 16px !important;	 */
}

.react-datepicker__input-container input {
    border: 1px solid #ced4da !important;
}
.currentTasksWrap {
	text-align: left;
	padding: 24px 36px;
}


.currentTasksWrap .ant-table .ant-table-cell-fix-right {
    background-color: #FFF !important;
}
.currentTasksWrap .task-num {
	color: #545459;
	font-family: Inter;
	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 20px;
}
.currentTasksWrap .task-topBar {
	display: flex;
	justify-content: space-between;
}
.currentTasksWrap .task-topBar .search-bar {
	display: flex;
	margin-bottom: 20px;
}
.currentTasksWrap .task-topBar .search-bar .statusFilters {
	height: 42px;
	min-width: 100px;
}
.currentTasksWrap .task-topBar .search-bar .search-task {
	height: 42px;
}
.currentTasksWrap .task-topBar .create-task {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 1px solid #E3EAFD;
	background-color: #F7F8FF;
	color: #325CEB;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	height: 42px;
	padding: 0 20px;
	cursor: pointer;
	margin-bottom: 20px;
}
.currentTasksWrap .no-tasks {
	font-family: Inter;
	height: 236px;
	padding: 40px 0;
	border-radius: 8px;
	background-color: #F6F6F6;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.currentTasksWrap .no-tasks img {
	width: 104px;
	height: auto;
}
.currentTasksWrap .no-tasks .title-text {
	color: #000;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
}
.currentTasksWrap .no-tasks .sub-text {
	color: #6E6E70;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header {
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 16px;
	color: #222;
	line-height: 20px;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-type {
	width: 25%;
	font-size: 16px;
	font-weight: 500;
	color: #222;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-update {
	width: 35%;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-completed {
	display: flex;
	align-items: center;
	font-weight: 500;
	width: 25%;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-completed .task-completed-num {
	margin-left: 8px;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .no-tasks-text {
	color: #6E6E70;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-add {
	color: #325CEB;
	font-weight: 600;
	cursor: pointer;
	width: 15%;
	text-align: right;
	padding-right: 10px;
}
.currentTasksWrap .task-content .task-collapse {
	background-color: #FFF;
	border: none;
}
.currentTasksWrap .task-content .task-collapse .task-collapse {
	background-color: #FFF;
}
.task-collapse>.ant-collapse-item {
	border: 1px solid #DBDCDE;
	transition: all .3s;
	margin-bottom: 12px;
	border-radius: 8px !important;
}
.task-collapse>.ant-collapse-item:hover {
	box-shadow: 0px 4px 8px 0px rgba(7, 13, 61, 0.12);
}

.task-collapse>.ant-collapse-item >.ant-collapse-header {
	padding: 18px 16px;
	align-items: center;
}
.task-collapse .ant-collapse-content {
	/* padding: 12px 0 22px; */
	margin: 0 16px;
}

.create-task-modal .ant-modal-content {
	padding: 15px 0 0;
}
.create-task-modal .ant-modal-close {
	right: 42px;
	top: 42px;
}
.create-task-modal .task-modal-wrap {
	font-family: Inter;
	height: 100%;
}
.create-task-modal .task-modal-wrap .ant-spin-nested-loading {
	height: 100%;
}
.create-task-modal .task-modal-wrap .ant-spin-nested-loading .task-spin {
	max-height: 100%;
}
.ant-spin-nested-loading >div>.task-spin .ant-spin-text {
	padding-top: 26px;
	color: #6E6E70;
	font-size: 16px;
}
.create-task-modal .task-modal-wrap .create-task-title {
	color: #222;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	padding: 26px 42px;
	border-bottom: 1px solid #DBDCDE;
}
.create-task-modal .task-modal-wrap .task-form {
	height: 100%;
	display: flex;
    flex-direction: column;
}
.create-task-modal .task-modal-wrap .create-task-content {
	padding: 0 42px;
	padding-top: 9px;
	overflow: auto;
}
.create-task-modal .task-modal-wrap .create-task-content .form-item-label {
	padding: 8px 0;
	color: #586570;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
}
.create-task-modal .task-modal-wrap .create-task-content .task-input {
	height: 52px;
	background-color: #FCFCFC;
    border: 1px solid #ECECEC;
	font-size: 16px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select {
	height: 52px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select .ant-select-selector {
	padding: 6px;
	background-color: #FCFCFC;
    border: 1px solid #ECECEC;
	font-size: 16px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-single {
	height: 52px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-single .ant-select-selector {
	padding: 0 20px;
	font-size: 16px;
	color: #222;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 50px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-select.ant-select-multiple .ant-select-selection-item {
	height: 40px;
	font-size: 16px;
	font-weight: 500;
	background-color: #EAEFFD;
	color: #325CEB;
	display: flex;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
}
.create-task-modal .task-modal-wrap .create-task-content .task-date {
	height: 52px;
	padding: 4px 20px 4px;
	background-color: #FCFCFC;
    border: 1px solid #ECECEC;
}
.create-task-modal .task-modal-wrap .create-task-content .task-date .ant-picker-input >input {
	font-size: 16px;
	color: #222;
}
.create-task-modal .ant-modal-footer {
	height: 72px;
	padding: 0 42px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #ECECEC;
	margin-top: 20px;
}
.create-task-modal .ant-modal-footer .task-btn-cancel {
	height: 42px;
	font-size: 16px;
	font-weight: 600;
	padding: 0 35px;
}
.create-task-modal .ant-modal-footer .task-btn-save {
	height: 42px;
	font-size: 16px;
	font-weight: 600;
	padding: 0 20px;
}

.ant-message .ant-message-notice.task-message .ant-message-notice-content {
	font-size: 16px;
	color: #222;
	background-color: #F7F8FF;
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.18);
}
.ant-message .ant-message-notice.task-message .ant-message-notice-content .ant-message-custom-content {
	display: flex;
}
.ant-message .ant-message-notice.task-message .ant-message-notice-content .ant-message-custom-content .anticon svg {
	color: #1630AC;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .table-wrap {
	border: 1px solid #ECECEC;
	border-radius: 8px;
	overflow: hidden;
}
.table-wrap .task-table .message-add {
	width: 74px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #E3EAFD;
	background-color: #F7F8FF;
	font-family: Inter;
	font-size: 14px;
	color: #325CEB;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.table-wrap .task-table .ant-table-thead {
	background-color: #F5F5F7;
}
.table-wrap .task-table .ant-table-cell-fix-right.ant-table-cell {
	background-color: #FFF !important;
}
.table-wrap .task-table .ant-table-thead .ant-table-cell-fix-right.ant-table-cell {
	background-color: #F5F5F7 !important;
}
.table-wrap .ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-thead >tr>td {
	padding: 10px 16px;
}
.table-wrap .table-document {
	color: #222;
	font-size: 16px;
	font-weight: 500;
	font-family: Inter;
}
.table-wrap .table-date {
	color: #222;
	font-size: 16px;
	text-decoration-line: underline;
	font-family: Inter;
	cursor: pointer;
}
.table-wrap .table-message {
	color: #4F4F4F;
	font-family: Inter;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	align-items: end;
}
.table-wrap .message-text {
	width: calc(100% - 30px);
	display: -webkit-box; 
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}
.table-wrap .table-message .message-edit {
	color: #325CEB;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
}
.table-wrap .document-name-wrap {
	display: flex;
	align-items: center;
}
.table-wrap .document-name-wrap .table-document-file {
	width: 150px;
	height: 36px;
	border-radius: 4px;
	border: 1px solid #DBDCDE;
	background-color: #FCFCFC;
	margin-left: 12px;
	display: flex;
	align-items: center;
	padding: 0 8px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	color: #222;
	cursor: pointer;
}
.table-wrap .document-name-wrap .table-document-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.table-wrap .document-name-wrap .table-document-file img {
	width: 22px;
	height: 22px;
	margin-right: 8px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document-btn {
	color: #325CEB;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	margin: 22px 0;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document {
	border-radius: 10px;
	border: 1px solid #ECECEC;
	margin-top: 26px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-title {
	color: #222;
	font-size: 16px;
	font-weight: 500;
	padding: 0 24px;
	line-height: 20px;
	height: 54px;
	border-bottom: 1px solid #ECECEC;
	display: flex;
	align-items: center;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-content {
	padding: 18px 30px 20px 24px;
}
.create-task-modal .task-modal-wrap .ant-spin-container {
	height: 100%;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-operate {
	height: 72px;
	padding: 0 30px 0 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #ECECEC;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-operate .add-document-cancel {
	height: 44px;
	width: 111px;
	font-size: 16px;
	font-weight: 600;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-operate .add-document-save {
	height: 44px;
	width: 111px;
	font-size: 16px;
	font-weight: 600;
	border: 1px solid #325CEB;
	color: #325CEB;
	background-color: #EAEFFD;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .add-document .add-document-content .add-document-label {
	color: #586570;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 8px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-documents .table-wrap .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}
.task-collapse .ant-collapse-content>.ant-collapse-content-box {
	padding: 16px 0;
}
.viewfiles-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    padding: 0 22px 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.viewfiles-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
}
.viewfiles-prevPage {
    position: fixed;
    left: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-nextPage {
    position: fixed;
    right: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-top-bar > div {
    display: flex;
    align-items: center;
}
.viewfiles-top-bar .close-icon {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 20px;
}
.viewfiles-top-bar .viewfiles-top-name {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
}
.viewfiles-top-bar .page-info {
    position: absolute;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    justify-content: center;
}
.viewFiles-modal .viewFiles-content {
    max-height: calc(100vh - 160px);
    /* max-width: 1280px; */
    overflow: auto;
}
.viewFiles-modal .viewFiles-modal-btn {
    width: 135px;
    height: 44px;
    font-weight: 500;
    border-radius: 4px;
    padding: 0;
}
.success-wrap  {
	padding: 64px 64px 120px;
    text-align: center;
}
.success-wrap img {
	height: 124px;
	width: 102px;
	margin-bottom: 32px;
}
.success-wrap .success-title {
	color: #222;
	font-family: "Playfair Display";
	font-size: 26px;
	font-weight: 700;
	margin-bottom: 12px;
}
.success-wrap .success-subtitle {
	color: #6E6E70;
	font-family: Inter;
	font-size: 16px;
	line-height: 22px;
}
.success-wrap .success-subtitle .email {
	color: #222;
	font-weight: 500;
	text-decoration-line: underline;
}
.signature-modal .ant-modal-content {
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 0;
}
.signature-modal .ant-modal-content .ant-modal-body {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-top {
	font-size: 18px;
	color: #222;
	font-weight: 500;
	height: 60px;
	border-bottom: 1px solid #DBDCDE;
	display: flex;
	align-items: center;
	padding: 0 42px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap {
	flex: 1;
	background-color: #E8E8EB;
	position: relative;
	overflow: hidden;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document {
	height: 100%;
	overflow: auto;
	display: flex;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom {
	width: 110px;
	height: 30px;
	border-radius: 6px;
	border: 1px solid #CFD4D9;
	display: flex;
	background-color: #FFF;
	position: absolute;
	right: 42px;
	bottom: 10px;
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom .zoom-min, .signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom .zoom-add {
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6E6E70;
	font-weight: bold;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap .document-pdf-zoom .zoom-value {
	color: #222;
	font-size: 14px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid #CFD4D9;
	border-right: 1px solid #CFD4D9;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document .signature-document-pdf {
	margin: 0 auto;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document .signature-document-pdf .rectangle-sign {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	background-color: rgba(50, 92, 235, 0.5);
}

.signature-modal .ant-modal-content .ant-modal-body .signature-document .signature-document-pdf .rectangle-sign:hover {
	border: 1px solid #325CEB;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-bar {
	border-top: 1px solid #DBDCDE;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 42px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn {
	display: flex;
	align-items: center;
	justify-content: end;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .signatures-num {
	font-size: 16px;
	color: #6E6E70;
	margin-right: 16px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .add-signature {
	font-size: 16px;
	font-weight: 600;
	height: 46px;
	width: 90px;
	color: #325CEB;
	background-color: #EAEFFD;
	border-color: #325CEB;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .divider {
	width: 1px;
	height: 46px;
	background-color: #E8E8EB;
	margin: 0 16px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .save-signature {
	font-size: 16px;
	font-weight: 600;
	height: 46px;
	padding: 0 32px;
}
.transition-modal .ant-modal-content {
	padding: 100px 84px 70px;
}
.transition-modal .ant-modal-content .transition-title {
	margin-bottom: 76px;
	display: flex;
	align-items: center;
	gap: 20px;
}
.transition-modal .ant-modal-content .transition-title .transition-title-text {
	color: #081265;
	font-family: Inter;
	font-size: 24px;
	font-weight: 700;
}
.transition-modal .ant-modal-content .transition-content {
	color: #222;
	font-family: Inter;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 74px;
}
.transition-modal .ant-modal-content .transition-content .content-bold {
	font-weight: 600;
}
.transition-modal .ant-modal-content .transition-btn {
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	width: 240px;
	height: 50px;
	border-radius: 6px !important;
}
@media (max-width: 991px) { 
	.signature-modal .ant-modal-content .ant-modal-body .signature-bar {
		padding: 12px 16px;
	}
	.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .add-signature {
		padding: 0 8px;
	}
	.signature-modal .ant-modal-content .ant-modal-body .signature-bar .signature-bar-btn .save-signature {
		padding: 0 12px;
	}
}
@media (max-width: 767px) {
	.currentTasksWrap {
		padding: 24px 16px;
	}
	.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-completed .task-completed-num {
		display: none;
	}
	.currentTasksWrap .task-content .task-collapse .task-collapse-header {
		font-size: 12px;
	}
	.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-type {
		width: 40%;
	}
	.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-update {
		width: 40%;
	}
	.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-completed {
		width: 20%;
	}
	.currentTasksWrap .task-topBar {
		flex-direction: column;
	}
}