.reviews-container {
  background-color: #F7F8FF;
  padding: 52px 30px 78px 30px;
}

.reviews-title {
  color: #081265;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  margin-bottom: 43px;
}

.reviews-cards-container {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  margin-bottom: 26px;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.reviews-cards-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.review-card {
  width: 320px;
  min-width: 320px;
  height: 325px;
  background-color: white;
  border: 1px solid #D9D9D9;
  border-radius: 16px;
  padding: 66px 21.5px 40px 31.5px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.review-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.quotation-mark {
  position: absolute;
  top: 33px;
  left: 26px;
}

.review-comment {
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.reviewer-info {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 12px;
}

.reviewer-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.reviewer-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: black;
  display: block;
}

.reviewer-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #6E6E70;
}

.review-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.review-indicator {
  width: 26px;
  height: 2px;
  border: none;
  background-color: #D6E2FB;
  transition: background-color 0.3s;
  cursor: pointer;
  padding: 0;
}

.review-indicator.active {
  background-color: #081265;
}