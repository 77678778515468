.alert-toast-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    animation: alert-toast-slide-down 0.3s ease forwards;
}

.alert-toast-container.closing {
    animation: alert-toast-fade-out 0.3s ease forwards;
}

.alert-toast {
    display: flex;
    align-items: center;
    padding: 11px 18px 11px 14px;
    border-radius: 6px;
    background-color: rgba(34, 34, 34, 0.9);
}

.alert-toast-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.alert-toast-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
}

@keyframes alert-toast-slide-down {
    0% {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes alert-toast-fade-out {
    0% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
}