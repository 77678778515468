.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.hauto {
  display: block;
  height:auto !important;
}
.upload-doc-check input[type=radio]:checked::before {
  top: 4px;
  left: 0;
  transform: none;
}
@media (max-width: 767px) {
  .Content {
    flex-direction: column;
  }
  .dropzone-wrap {
    display: flex;
    justify-content: center;
  }
}