.message-list {
    flex: 1;
    min-height: 0;
    padding-top: 16px;
    padding-right: 23px;
    padding-left: 17px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover::-webkit-scrollbar-thumb {
        opacity: 1;
    }

    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &:hover {
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }
}

.bot-message {
    display: flex;
    margin-bottom: 10px;
    text-align: left;
    clear: both;
    color: #222222;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
    padding: 12px 0;
}

.user-message {
    display: flex;
    max-width: 97%;
    margin-bottom: 10px;
    float: right;
    text-align: left;
    color: #222222;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px 16px;
    word-break: break-word;
}

.bot-message .text-bubble {
    position: relative;
}

.bot-message .text-bubble::before {
    left: -6px;
    border-right: 6px solid #eaeaea;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    top: 6px;
}

.avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.typing-indicator .dot {
    display: inline-block;
    animation: blink 1.4s infinite;
    font-weight: 700;
}

.typing-indicator .dot:nth-child(1) {
    animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(2) {
    animation-delay: 0.4s;
}

.typing-indicator .dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-4px);
    }
}

@media (max-width: 576px) {
    .user-message {
        background-color: #f6f6f6;
        padding: 8px 10px;
    }

    .floating-button-wrapper {
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;
        background-color: transparent;
    }

    .floating-guideline-button {
        display: flex;
        align-items: center;
        gap: 4px;
        background: #fcfcfc;
        padding: 14px 22px;
        border: 1px solid #ececec;
        border-radius: 30.5px;
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.14);
        margin-right: 20px;
        cursor: pointer;
        width: fit-content;
        pointer-events: auto;
    }

    .floating-guideline-button img {
        width: 24px;
        height: 24px;
    }

    .floating-guideline-button span {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #222222;
    }
}
