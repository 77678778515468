.lenders-num {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 40px 0 16px;
}
.lender-item {
    border-radius: 4px;
    border: 1px solid #C7C7C7;
    background: #FFF;
    margin-bottom: 16px;
    padding: 32px 17px;
}
.lender-item .lender-item-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 20px;
}
.lender-item .operate-btn {
    width: 158px;
    height: 32px;
    color: #1E1E1C;
    background-color: #FFF;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border: 1px solid #C1C1BF;
    margin-right: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lenders-form-item {
    height: 40px !important;
}
.add-lender-btn {
    width: 122px;
    height: 32px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 4px;
    color: #FFF;
    background: #0C67FF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}
.lenders-tips {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.07px;
    text-decoration-line: underline;
    cursor: pointer;
}
.lenders-form .form-group {
    margin-bottom: 28px;
}
.lender-item label {
    margin-bottom: 4px;
}
.lender-item .form-control-sm {
    height: 40px !important;
}
.lender-item .input-group-text {
    height: 40px;
}
.lender-item .input-group-prepend {
    height: 40px !important;
}
.lenders-form .btn {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 4px !important;
}
.lenders-form .btn.moduleSave {
    width: 111px;
    background-color: #0C67FF !important;
    color: #FFF;
    margin-right: 22px !important;
    border: 1px solid #0C67FF !important;
}
.lenders-form .btn.moduleCancel {
    color: #1E1E1C !important;
    background-color: #FFF !important;
    width: 115px;
    border: 1px solid #C1C1BF !important;
}
.promotion-dialog {
    max-width: 650px;
    width: 90%;
    margin: 0.5rem auto;
}
@media (max-width: 575px) {
    .add-lender-btn {
        margin-left: 0px;
        margin-top: 12px;
    }
}
@media (max-width: 767px) {
    .lender-item .operate-btn {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 8px;
    }
}