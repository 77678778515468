#addlenderModal-backdrop {
    z-index: 1040 !important;
}

.LendersHeader,
.modal-header {
    border: none
}

.lendersLine {
    height: 1px;
    width: 100%;
    background: #DBDCDE;
    position: absolute;
    right: 0px;
    flex-shrink: 0;
}

.programEnginePane {
    width: calc(100% - 250px);
}

/* .showManager
.ant-input {
    height: 44px !important;
} */

.managementWrap {
    display: flex;
    height: 100%;
}

.managementMenu {
    width: 250px;
    height: 100%;
    text-align: left;
}

.managementWrap .ant-menu .ant-menu-item-selected {
    background-color: #D6E2FB;
}

.managementWrap .ant-menu .ant-menu-item-selected {
    color: #325CEB;
}

.ant-menu-submenu-title {
    padding-left: 22px !important;
}

.managementWrap .ant-menu .ant-menu-item,
.managementWrap .ant-menu .ant-menu-submenu,
.managementWrap .ant-menu .ant-menu-submenu-title {
    border-radius: 0px;

}

.managementWrap .ant-menu-inline .ant-menu-item {
    margin-inline: 0px;
    width: 100%;
}

.mobileManagementMenu .ant-menu-item,
.mobileManagementMenu .ant-menu-submenu-title {
    display: inline-block;
    white-space: nowrap;
}

.mobileManagementMenu {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    /* Hide scrollbar for Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.mobileManagementWrap .ant-menu .ant-menu-item,
.managementWrap .ant-menu .ant-menu-submenu,
.managementWrap .ant-menu .ant-menu-submenu-title {
    border-radius: 0px;
}

.mobileManagementWrap .ant-menu .ant-menu-item-selected {
    border-bottom: #325CEB 2px solid;
}

.mobileManagementWrap .ant-menu .ant-menu-item-selected {
    background-color: transparent;
}

.mobileManagementWrap .ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item {
    padding-inline: 15px;
}

.managementLendersWrap .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-weight: 500;
    padding: 14px 16px;
}

.managementLendersWrap .ant-collapse-expand-icon {
    color: #325CEB;
}

.lendersHeadingWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addLenderBtnOutline {
    border: 1px solid #E3EAFD;
    background-color: #F7F8FF;
    color: #325CEB;
    border-radius: 8px;
    padding: 14px 40px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        background-color: #325CEB;
        color: #fff;
        transition: all .2s;
    }
}

.lendersViewWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lendersViewWrap .ant-input-affix-wrapper {
    background-color: #FCFCFC;
    height: 44px;
}

.lendersViewWrap .ant-input-group-addon .ant-input-search-button {
    height: 48px;
    background-color: #FCFCFC;
}

.lendersViewWrap .ant-input-group-wrapper .ant-input {
    background-color: #FCFCFC;
}


.lendersCardWrap {
    border: 1px solid #DBDCDE;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
}

.managementLendersName {
    display: flex;
    align-items: center;
}

.lendersLogo {
    margin-right: 5px;
    width: 44px;
    height: 44px;
}

.cardButtonsWrap {
    display: flex;
    align-items: center;
}

.inEditButtons {
    background-color: #325CEB !important;
    color: white !important;
    border-radius: 4px !important;
    border: none !important;
    font-weight: 400;
    /* padding: 10px 30px; */
    cursor: pointer !important;
}

.closeEdit {
    color: #325CEB;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.addLenderModal {
    display: flex !important;
    justify-content: center;
}

.addAdjustmentModal {
    display: flex !important;
    justify-content: center;
    z-index: 2000;
}

.addPromotionModal {
    display: flex !important;
    justify-content: center;
    z-index: 2000;
}

.addLenderModal .modal-header {
    padding-top: 30px;
}

.addAdjustmentModal .modal-header {
    padding-top: 50px;
}

.addPromotionModal .modal-header {
    padding-top: 50px;
}

.addAdjustmentModal .modal-dialog {
    margin: 0;
    height: 100%;
    max-width: 100%;
    width: 80%;
}

.addPromotionModal .modal-dialog {
    margin: 0;
    height: 100%;
    max-width: 100%;
    width: 80%;
}

.addLenderModal .modal-content {
    height: 100%;
}

.addAdjustmentModal .modal-content {
    height: 100%;
}

.addPromotionModal .modal-content {
    height: 100%;
}

.addLenderModal .addLenderModalForm {
    height: 80%
}

.addAdjustmentModal .addAdjustmentModalForm {
    height: 75%
}

.addPromotionModal .addPromotionModalForm {
    height: 80%
}

.addLenderModal .modal-body {
    height: 100%;
    overflow-y: auto;
}

.addAdjustmentModal .modal-body {
    height: 100%;
    overflow-y: auto;
}

.addPromotionModal .modal-body {
    height: 100%;
    overflow-y: auto;
}

.addLenderModal .modal-footer {
    padding: 10px 20px 0px 20px;
}

.addAdjustmentModal .modal-footer {
    padding: 20px 20px 0px 20px;
}

.addPromotionModal .modal-footer {
    padding: 20px 20px 0px 20px;
}

.ant-popover {
    z-index: 3000;
}

.addLenderButton {
    font-size: 14px !important;
    font-weight: 500;
    width: 120px;
    height: 40px;
    background-color: #325CEB !important;
    border: none !important;
    color: white !important;
    border-radius: 8px !important;
    cursor: pointer !important;

    &:hover {
        background-color: #1A3A8B;
    }
}

.unselectedLenders {
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 20px;
    margin-bottom: 15px;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    cursor: pointer;
}

.selectedLenders {
    background-color: #EAEFFD;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 20px;
    margin-bottom: 15px;
    border: 1px solid #325CEB;
    border-radius: 4px;
    cursor: pointer;
    ;
}

.addLenderModal .ant-input-group-addon .ant-input-search-button {
    height: 48px;
    background-color: #FCFCFC;
}

.cardStatus {
    text-align: right;
    font-weight: 500;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.cardStatusHide {
    display: none;
}

.formInputPercent .input-group .form-control {
    height: 50px !important;
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #FCFCFC
}

.formInputPercent .input-group-append .input-group-text {
    background-color: #FCFCFC !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-left: none;
    height: 50px;
}

.formInputDollar .input-group .form-control {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 50px !important;
    background-color: #FCFCFC;
    border-left: none;
}

.formInputDollar .input-group .input-group-text {
    background-color: #FCFCFC !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    height: 50px;
}

.formInputFees .input-group .form-control {
    height: 50px !important;
    background-color: #FCFCFC;
    border-left: none;
}

.formInputFees .input-group .input-group-text {
    background-color: #FCFCFC !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-right: none;
    height: 50px;
}

.formInputStates .form-control {
    height: 50px !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-left: 20px;
    background-color: #FCFCFC;
}

.formInputStates {
    margin-left: -20px;
}

.addFeeIcon {
    margin-left: 10px;
}

.removeFeeIcon {
    margin-left: 10px;
    margin-right: 10px;
}

.formInputCloseOver .form-control {
    height: 50px !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #FCFCFC;
    border-right: none;
}

.formInputCloseOver .input-group .input-group-text {
    background-color: #FCFCFC !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-left: none;
    height: 50px;
}

.formInputRelation .form-control {
    height: 50px !important;
    border-radius: 8px;
    background-color: #FCFCFC;
}

.addPromotionButton {
    text-decoration: underline;
    color: #325CEB;
    cursor: pointer;
}

.promotionSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rulesHeader {
    padding: 5px;
    background-color: #D6E2FB;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #081265;
    font-weight: 600;
    font-size: 12px;
}

.rulecard {
    background-color: #F6F6F6;
    width: 100%;
    align-items: center;
}

.rulesWraper {
    display: flex;
    justify-content: space-between;
}

.lendersTable .ant-table-thead .ant-table-cell {
    border-top: 1px solid #DBDCDE !important;
    border-bottom: 1px solid #DBDCDE !important;
}

.lendersTable .ant-table-thead>tr> :first-child {
    border-left: 1px solid #DBDCDE !important;
}

.lendersTable .ant-table-thead>tr> :last-child {
    border-right: 1px solid #DBDCDE !important;
    color: #F5F5F7 !important;
}

.lendersTable .ant-table-thead>tr>th {
    background-color: #F5F5F7 !important;
    color: #545459 !important;
}

.lendersTable .ant-table-row> :first-child {
    border-left: 1px solid #DBDCDE !important;
}

.lendersTable .ant-table-row> :last-child {
    border-right: 1px solid #DBDCDE !important;
}

.addAdjustmentModal .modal-body {
    padding: 0px;
}

.addPromotionModal .modal-body {
    padding: 0px;
}

.conditionSection {
    width: 60%;
    background-color: #F6F6F6;
    padding: 20px;
}

.actionSection {
    width: 40%;
    padding: 20px;
}

.adjustmentSubmitBtn {
    color: white;
    background-color: #325CEB !important;
    border: none !important;
    border-radius: 8px !important;
    /* padding: 10px 30px !important; */
    font-size: 14px !important;
    font-weight: 500;
    cursor: pointer !important;
    width: 125px;
    height: 45px;
}

.adjustmentSubmitBtn:hover {
    background-color: #1A3A8B;
}

.Achievement {
    margin: 0px -20px;
}

.adjustmentCancelBtn {
    color: #325CEB;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: underline;
    justify-content: left;
}

.addAdjustmentModal .modal-footer {
    padding: 10px 20px;
}

.addPromotionModal .modal-footer {
    padding: 10px 20px;
}

.lendersPane {
    height: calc(90vh - 30px);
    overflow-y: auto;
    padding: 0 40px;
    margin-top: 30px;
}

.lenderNames {
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
}

.lenderCardNames {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
}

.cardRowWrap {
    gap: 20px;
}

.lenderTimeStamp {
    font-size: 16px;
    font-weight: 400;
    color: #6E6E70;
    margin-left: 10px;
}

.lenderText {
    font-size: 16px;
    font-weight: 400;
    color: #6E6E70;
}

.lenderFeeStates .ant-select-selector {
    border: 1px solid #DBDCDE !important;
    height: 50px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.revertSearch {
    color: #325CEB;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    text-decoration: underline;
}

.addLenderModal .ant-input-group-wrapper .ant-input {
    height: 44px;
    background-color: #FCFCFC;
}

.lenderFeeStates .ant-select-selector {
    background-color: #FCFCFC !important;
}

.lendersTable .ant-table-column-sorter {
    position: absolute;
    margin-left: 100px;
}

.addLenderModal .modal-dialog {
    margin: 0;
    height: 100%;
    max-width: 100%;
    width: 90%;
}

@media (max-width: 575px) {
    .cardRowWrap {
        flex-direction: column;
    }

    .lendersPane {

        padding-left: 0;
        padding-right: 0;
    }

    .unselectedLenders {
        width: 250px;
        word-wrap: break-word;
    }

    .programEnginePane {
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .lendersViewWrap {
        flex-direction: column;
        align-items: start;
    }
}

@media (min-width: 576px) {
    .lendersCardWrap {
        flex-basis: calc(50% - 20px);
    }
}

@media (min-width: 768px) {
    .addLenderModal .modal-dialog {
        width: 80%;
    }

    .lendersCardWrap {
        flex-basis: calc(33% - 20px);
    }
}

@media (min-width: 1400px) {
    .lenderCardNames {
        font-size: 18px;
    }

    .lendersCardWrap {
        height: 100px;
        width: 450px;
    }

    .addLenderModal .modal-dialog {
        width: 65%;
    }
}

@media (min-width: 1600px) {
    .lenderCardNames {
        font-size: 18px;
    }
}

@media (min-width: 1900px) {
    .lenderCardNames {
        font-size: 18px;
    }
}