.CheckerWrap{
    height: 100%;
}

.CheckerHeader{
    padding-left: 40px;
    padding-right: 40px;
    height: 64px;
    border-bottom: 1px solid #DBDCDE;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CheckerTitle{
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.CheckerSub{
    font-size: 14px;
    color: #666;
}

.CheckerPane{
    height: calc(100vh - 64px);
    /* padding: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
}

.CheckerContent{
    border-radius: 4px;
    border: 1px solid #DBDCDE;
    background-color: rgb(247, 248, 252);
    padding: 40px;
    width: 80%;
    height: 80%;
}

.CheckerInfoPreview{
    background-color: #EAEFFD;
    border-radius: 4px;
    color:#1f3988;
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    width: 50%;
    height: 350px;
}

.CheckerInfoWrap{
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SpinnerWrap{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .CheckerHeader{
        padding-left: 20px;
        padding-right: 20px;
    }
    .CheckerTitle{
        font-size: 16px;
    }
    .CheckerSub{
        font-size: 12px;
    }
    .CheckerContent{
        width: 100%;
        height: 100%;
    }
    .CheckerInfoPreview{
        width: 100%;
        height: auto;
    }
}