.landing-page-footer {
  padding: 30px 24px 16px 24px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 24px;
  font-family: "Inter", sans-serif !important;
}

.landing-page-footer .ant-row {
  margin-bottom: 16px;
}

.landing-page-footer .footer-header {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  font-family: "Inter", sans-serif !important;
}

.landing-page-footer .footer-link,
.landing-page-footer .footer-un-link {
  font-size: 16px;
  font-weight: 500;
  color: #6e6e70;
  font-family: "Inter", sans-serif !important;
}

.landing-page-footer .footer-link {
  cursor: pointer;
}

.landing-page-footer .social-media-icons img {
  width: 30px;
  height: 30px;
}
