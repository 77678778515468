.Board {
  display: flex;
  min-height: 95vh;
  overflow-x: scroll;
}

.Board>div:first-child {
  margin-left: 0.7rem;
}

.Board>div:last-child {
  margin-right: 0.7rem;
}