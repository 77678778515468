.textAlignLeft * {
  text-align: left;
  font-size: 1rem;
}

.warningText {
  color: #fd0019;
  font-weight: 700;
  margin: 0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination > span {
  margin: 0 1rem;
  font-size: 1.7rem;
}

.noteGeneral {
  padding: 1rem;
  border-radius: 10px;
}

.noteHeaderGeneral {
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.info {
  background-color: rgb(209, 236, 241); 
  color: rgb(12, 84, 96);
}

.infoHeader {
  border-bottom: 1px solid rgb(12, 84, 96);
}

.warning {
  background-color: rgba(253, 244, 242, 0.8);
  color: rgba(223, 51, 18);
}

.warningHeader {
  border-bottom: 1px solid rgba(223, 51, 18);
}

.flex-row{
  display: flex;
  justify-content: space-around;
  align-items: center;
}