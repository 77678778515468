.menu-icon {
    display: none;
}
.nav-block {
    height: 56px;
}
.menu-top-popup .adm-popup-body-position-top {
    top: 56px;
    overflow: auto;
}
.menu-top-popup .mainSiderbarItemSelected {
    margin-left: 5px;
}
.menu-top-popup .mobile-menu {
    padding: 6px 15px;
}
.menu-top-popup .mobile-menu .mainSidebarText {
    margin-left: 12px;
}
.menu-top-popup .adm-list-default .adm-list-body {
    border-top: none;
}
.menu-top-popup .menu-collapse .adm-collapse-panel-header .adm-list-item-content {
    border-top: none;
}
.menu-top-popup .menu-collapse .adm-collapse-panel-header .adm-list-item-content-main {
    padding: 6px 0;
}
.menu-top-popup .menu-collapse .adm-list-item {
    padding: 0 15px;
}
.menu-top-popup .menu-collapse .menu-collapse-item {
    padding: 6px 20px;
}
.menu-top-popup .menu-collapse .menu-collapse-item  div {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.88);
}
.top-menu .navbar-text {
    margin-left: 0;
}
@media (max-width: 575px) {
    .menu-icon {
        display: inline;
    }
    .top-menu .nav-link {
        padding: 0 0 0 1rem;
    }
    .top-menu .navbar-text {
        padding-left: 0 !important;
        font-size: 0.8rem;
        margin-left: 8px;
    }
}