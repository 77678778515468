.full-height-modal .ant-modal {
    padding-bottom: 0px;
}

.full-height-modal .ant-modal-body {
    margin-top: 45px;
    flex: 1;
}

.full-height-modal .ant-modal .ant-modal-content {
    height: 100vh;
    overflow: auto;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
}

.full-height-modal .ant-modal .ant-modal-title {
    font-size: 20px;
}

.Full_modal_content {
    padding: 42px !important;
}

.Full_modal_body {
    height: 74vh;
    margin-top: 35px;
}
@media (max-width: 1300px) {
    .Full_modal_body {
        height: 70vh;
    }
}
