.scrollBar {
	width: 368px;
	height: 52px;
	border: 1px solid #CFD4D9;
	display: flex;
	align-items: center;
}

.scrollBarInputText {
	border: none !important;
	width: 130px;
}

.scrollBarInputText:focus {
	border: 1px solid #0d99ff !important;
}

.scrollBarRange {
	margin-left: 10px;
	width: 160px !important;
}


.radio-test {
	width: 139px;
	height: 44px;
	border: 1px solid #CFD4D9;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	cursor: pointer;
}

.radio-test:hover {
	border: 1px solid #325CEB;
}

.rc-slider-track {
	background-color: #325CEB !important;
}

.rc-slider-handle {
	border: solid 2px #325CEB !important;
	background-color: #fff !important;
	opacity: 1 !important;
	width: 24px !important;
	height: 24px !important;
	margin-top: -9px !important;
}

.rc-slider-handle:focus-visible,
.rc-slider-handle-dragging {
	box-shadow: unset !important;
}

.collapsibleHeader {
	background: #F7F8FC;
	border-radius: 8px;
	padding: 15px;
}

.zeitroTips {
	max-width: 15px;
	max-height: 15px;
	margin-left: 5px;
}

@media (max-width: 520px) {
	.zeitroTips {
		max-width: 10px;
		max-height: 10px;
		margin-left: 2px;
	}
}