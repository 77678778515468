.welcomPageWrap {
    height: 100%;
    padding: 33px 43px 33px 9px;
    background-color: #F6F6F6;
    overflow: auto;
}

.welcomePageTasks {
    text-align: left;
    height: 100%;
    overflow-y: scroll;
}

.welcomePageTaskTexts {
    background-color: #F7F7F7;
    border-radius: 4px;
    padding: 20px;
}

.welcomePageDashboard {
    text-align: left;

}



.welcomePagePlot {
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 25, 76, 0.1);
    border-radius: 8px;
    margin: 10px;
    text-align: left;
    padding: 20px 20px;
}

.welcomePagePlotText1 {
    font-size: 16px;
    color: #6E6E70;
}

.dashboardIcon {
    height: 48px;
    width: 48px;
    margin-bottom: 10px;
}

.welcomePageDate {
    /* color: #6E6E70; */
    font-size: 14px;
    font-weight: 500;
    margin-top: 25px;
    margin-bottom: 10px;
}

.welcomePageDashboard .systemFeatures {
    margin: 0 15px;
    padding: 18px 23px;
    border-radius: 16px;
    border: 1px solid #ECECEC;
    background: #FFF;
    margin-bottom: 40px;
}

.welcomePageDashboard .systemFeatures .systemFeatures-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcomePageDashboard .systemFeatures .systemFeatures-top .systemFeatures-top-text div:first-child {
    font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
}

.welcomePageDashboard .systemFeatures .systemFeatures-top .systemFeatures-top-text div:last-child {
    font-size: 14px;
    color: #858585;
    line-height: normal;
}

.welcomePageDashboard .systemFeatures .systemFeatures-top .systemFeatures-top-btn {
    font-size: 14px;
    color: #325CEB;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.welcomePageDashboard .recentLoans {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.welcomePageDashboard .noApplication {
    border-radius: 8px;
    border: 1px solid #ECECEC;
    background: #FCFCFC;
    padding: 23px 30px 26px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcomePageDashboard .loan-list {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 25, 76, 0.12);
}

.welcomePageDashboard .loan-list .loan-item {
    display: flex;
    padding: 20px 20px 0 18px;
}

.welcomePageDashboard .loan-list .loan-icon {
    background-color: #F5F5F7;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
}

.welcomePageDashboard .loan-list .loan-info {
    padding-left: 6px;
    padding-bottom: 21px;
    flex: 1;
}

.welcomePageDashboard .loan-list .loan-item:not(:last-child) .loan-info {
    border-bottom: 1px solid #D9D9D9;
}

.welcomePageDashboard .loan-list .loan-info .loan-info-top {
    color: #222;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
}

.welcomePageDashboard .loan-list .loan-info .loan-info-top div:first-child {
    flex: 1;
}

.welcomePageDashboard .loan-list .loan-info .loan-info-top .loans-view-btn {
    color: #325CEB;
    font-weight: 500;
    cursor: pointer;
}

.welcomePageDashboard .loan-list .loan-info .loan-info-bottom {
    color: #000;
    font-size: 14px;
    line-height: normal;
    display: flex;
    justify-content: space-between;
}

.systemFeatures-list {
    position: relative;
}

.systemManager {






    border: 1px solid #D6E2FB !important;
    background: #EAEFFD !important;
}

.systemFeatures-list .systemFeatures-item {
    height: 115px;
    border-radius: 12px;
    border: 1px solid #ECECEC;
    background: #FCFCFC;
    padding: 15px 18px 14px 20px;
    display: flex;
    justify-content: space-between;
}

.systemFeatures-list .swiper-btn {
    position: absolute;
    top: -2px;
    height: 120px;
    width: 10.5%;
    min-width: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.systemFeatures-list .swiper-btn img {
    cursor: pointer;
}

.systemFeatures-list .prev-btn-box img {
    transform: rotate(180deg);
}

.prev-btn-box {
    left: -23px;
    background: linear-gradient(90deg, #FFF 65.38%, rgba(255, 255, 255, 0.57) 80.2%, rgba(255, 255, 255, 0.00) 99.48%);
}

.next-btn-box {
    right: -23px;
    background: linear-gradient(271deg, #FFF 65.38%, rgba(255, 255, 255, 0.57) 80.2%, rgba(255, 255, 255, 0.00) 99.48%);
}

.systemFeatures-list .systemFeatures-item .features-item-left {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.systemFeatures-list .swiper-container {
    margin-top: 20px;
}

.systemFeatures-list .systemFeatures-item .status {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.systemFeatures-list .systemFeatures-item .status .status-text {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.systemFeatures-list .systemFeatures-item .status .status-mark.incomplete {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #E0E0E0;
    margin-right: 6px;
}

.systemFeatures-list .systemFeatures-item .status .status-text.incomplete {
    color: #858585;
}

.systemFeatures-list .systemFeatures-item .status .status-mark.complete {
    width: 12px;
    height: 12px;
    margin-right: 6px;
}

.systemFeatures-list .systemFeatures-item .status .status-text.complete {
    color: #52c41a;
}

.systemFeatures-list .systemFeatures-item .features-name {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.systemFeatures-list .systemFeatures-item .features-operate {
    color: #325CEB;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.systemFeatures-list .systemFeatures-item .features-item-right {
    display: flex;
    align-items: center;
}

.systemFeatures-list .systemFeatures-item .features-item-right .features-img {
    width: 60px;
    height: 60px;
}

.welcomePageDashboard .overview {
    position: sticky;
    top: 0;
}

.welcomePageDashboard .overview-title {
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

.welcomePageDashboard .overview .TCD-wrap {
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(0, 25, 76, 0.12);
    margin-bottom: 18px;
}

.welcomePageDashboard .overview .TCD-wrap .divider {
    height: 1px;
    background-color: #D9D9D9;
    margin: 0 25px;
}

.rate-selection {
    /* margin-bottom: 20px; */
}

.dashboard-collapse .down {
    transform: rotate(270deg);
    transition: all .3s;
}

.dashboard-collapse .up {
    transform: rotate(90deg);
    transition: all .3s;
}

.dashboard-card-header {
    background-color: #FFF;
}

.dashboard-card-header .header-title {
    color: #62707C;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 9px;
    line-height: 14px;
}

.dashboard-card-header .num {
    font-family: 'Inter' !important;
    font-size: 40px;
    color: #000;
    font-weight: 600;
    line-height: normal;
    margin-right: 8px;
}

.dashboard-card-header .unit {
    font-family: 'Inter' !important;
    color: #000;
    font-size: 16px;
    line-height: 14px;
    font-weight: 500;
}

.dashboard-card-header .sub-title {
    font-family: 'Inter' !important;
    color: #6E6E70;
    font-size: 14px;
    line-height: 14px;
    margin-top: 4px;
}

.dashboard-collapse.ant-collapse {
    background-color: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 25, 76, 0.12);
    border: none;
}

.dashboard-collapse.ant-collapse.dashboard-collapse.inside {
    background-color: #FFF;
    box-shadow: none;
}

.dashboard-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 17px 25px 20px;
}

.dashboard-collapse.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 4px 25px 21px;
}

.dashboard-collapse.ant-collapse .ant-collapse-content {
    border-top: none;
}

.dashboard-collapse .dashboard-card-content .content-item {
    font-size: 16px;
    line-height: normal;
    height: 40px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background: #FCFCFC;
    margin-bottom: 6px;
}

.dashboard-collapse .dashboard-card-content .content-item .name {
    color: #000;
}

.dashboard-collapse .dashboard-card-content .content-item .num {
    color: #222;
    font-weight: 500;
}

.dashboard-collapse.ant-collapse .ant-collapse-header {
    align-items: center;
}

.dashboard-collapse.processing-collapse {
    border-radius: 0;
}

.dashboard-collapse.underwriting-collapse {
    border-radius: 0;
}

@media (max-width: 768px) {
    .welcomPageWrap {
        padding: 30px 0 0 0;
    }
}

@media (max-width: 992px) {
    .swiper-btn {
        background: transparent;
    }
}

@media (min-width: 992px) {
    .systemFeatures-list .swiper-slide {
        /* width: 28%; */
    }

    /* .systemFeatures-list .systemFeatures-item {
        width: 28%;
    } */
}