.faq-container {
    background-color: white;
    padding: 69px 65px 116px 30px;
}

.faq-title {
    color: #081265;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
}

.faq-collapse {
    margin-top: 46px;
    background-color: white;
}

.faq-collapse .ant-collapse-header {
    display: flex;
    align-items: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #222222 !important;
}

.faq-collapse .ant-collapse-content-box{
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 24px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    color: #6e6e70 !important;
}

.faq-collapse .ant-collapse-item {
    border-bottom: 1px solid #979797 !important;
}

.more-faqs-button {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 54px;
    color: #222222;
    text-decoration-line: underline;
    text-decoration-color: #222222;
    text-underline-offset: 2px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
}

@media (max-width: 576px) {
    .faq-container {
        padding: 20px 32px;
    }
    .faq-title{
        font-size:24px;
    }
    .faq-collapse {
        margin-top: 0;
    }
    .faq-collapse .ant-collapse-header {
        font-size: 16px;
    }
    .faq-collapse .ant-collapse-content-box {
        font-size: 16px;
    }
    .more-faqs-button {
        font-size: 16px;
    }
}
