.progress-box {
    display: flex;
    margin-bottom: 16px;
}
.progress-box .left-progress {
    flex: 1;
    margin-right: 10px;
}
.progress-box .left-progress .module-progress.undone .ant-progress-bg {
    background-color: #989898;
}
.progress-box .left-progress .module-progress.normal .ant-progress-bg {
    background-color: #FBBC04;
}
.progress-box .left-progress .module-progress.ant-progress-status-success .ant-progress-bg{
    background-color: #34A853;
}
.progress-box .percent {
    color: #000;
    font-family: ABC Ginto Nord Trial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.require-tag{
    height: 21px;
    border-right: 1px solid #000;
    margin-top: -12px;
    position: relative;
}
.require-tag span {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    position: absolute;
    right: -64px;
    top: 6px;
}
.about-me-form .ant-form-item {
    margin-bottom: 30px;
}
.about-me-form .ant-form-item-label {
    padding: 0 0 4px;
}
.about-me-form .ant-form-item-label >label {
    font-size: 16px;
    height: 20px !important;
    color: #586570;
    font-weight: 500;
}
.about-me-form .ant-form-item-label >label .ant-form-item-tooltip {
    margin-inline-start: 8px;
}
.about-me-form .optional {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 8px 0 16px 0;
}
.about-me-form .optional span {
    margin-right: 8px;
}
.about-me-form .optional .up {
    transform: rotate(-180deg);
}
.auto-generate {
    position: absolute;
    right: 20px;
    bottom: 44px;
    background-color: #F7F7F5;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
.auto-generate:hover {
    color: rgba(0, 0, 0, 0.88) !important;
}

.form-divider {
    width: calc(100% + 107px);
    height: 1px;
    background-color: #DBDCDE;
    margin: 10px -53.5px;
    position: relative;
}