/* Ant Design Modal Overrides */
.affordability-modal .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0 47px;
    min-height: 573px;
    height: auto;
    border-radius: 25px;
}

.affordability-modal .ant-modal-close {
    top: 20px;
    right: 20px;
}

.affordability-modal .ant-modal-close-x {
    font-size: 20px;
    color: #6e6e70;
}

.affordability-modal .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.75);
}

/* Switch styles */
.affordability-modal .ant-switch {
    min-width: 44px !important;
    height: 24px !important;
    background-color: #e5e6e7 !important;
}

.affordability-modal .ant-switch.ant-switch-checked {
    background-color: #325ceb !important;
}

.affordability-modal .ant-switch .ant-switch-handle {
    width: 20px !important;
    height: 20px !important;
    top: 2px !important;
}

.affordability-modal .ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 22px) !important;
}

.affordability-modal .ant-select .ant-select-arrow {
    width: 33px !important;
    height: 33px !important;
    top: 50% !important;
    right: 0 !important;
    margin-top: -16.5px !important;
}

.affordability-modal .ant-select .ant-select-arrow .anticon {
    display: none !important;
}

.affordability-modal .ant-select .ant-select-arrow::after {
    content: '' !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    background: url('/images/antd-select-arrow.svg') center center no-repeat !important;
    background-size: contain !important;
}

/* Layout and Typography */
.modal-container {
    padding: 55px 0;
}

.modal-title {
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    color: #081265;
    line-height: 42.66px;
}

.modal-description {
    font-size: 14px;
    font-weight: normal;
    color: #222222;
    line-height: 20px;
    margin-bottom: 32px;
}

/* Form Elements */
.form-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #222222;
}

/* FICO Score Container */
.fico-score-container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
}

/* Tax and Fees Section */
.tax-fees-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.basic-info-title,
.tax-fees-title {
    font-size: 20px;
    font-weight: 600;
    display: block;
}

.tax-fees-description {
    font-weight: 500;
    line-height: 10px;
    color: #62707c;
}

.taxes-period {
    font-weight: 400;
    color: #8a8c90;
}

/* Result Card */
.result-card {
    background-color: #f7f8ff;
    border-radius: 10px;
    padding: 24px;
    position: sticky;
}

.result-container {
    text-align: center;
}

.result-preview-text {
    font-size: 14px;
    color: #222222;
    margin-bottom: 4px;
}

.result-preview-amount {
    font-size: 32px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 16px;
}

.result-divider {
    width: 100%;
    height: 1px;
    background-color: #ececec;
    margin-bottom: 16px;
}

/* Payment Details */
.payment-details-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.payment-detail-item {
    flex: 1;
    text-align: center;
}

.payment-detail-label {
    font-size: 13px;
    color: #6e6e70;
    margin-bottom: 4px;
    white-space: nowrap;
}

.payment-detail-value {
    font-size: 17px;
    font-weight: 600;
    color: #222222;
    white-space: nowrap;
}

.dti-explanation {
    font-size: 12px;
    color: #222222;
    margin-top: 24px;
    margin-bottom: 16px;
}

/* Button */
.approve-button {
    width: 100%;
    height: 32px;
    background-color: #325ceb;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
}

.question-icon {
    color: #6e6e70;
    font-size: 12px;
}

/* Utility Classes */
.w-full {
    width: 100%;
}

.alert-message {
    background-color: #f5f7ff;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 0;
}

.alert-content {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.alert-icon {
    font-size: 20px;
    flex-shrink: 0;
    margin-top: 4px;
}

.alert-icon.bulb {
    color: #1939b7;
}

.alert-icon.warning {
    color: #faad14;
}

.alert-content span {
    font-size: 14px;
    line-height: 1.5;
    color: #1f2937;
    text-align: left;
}

.house-price-details-wrap {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
}

.house-price-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 14px;
    color: #1f2937;
}

.house-price-details:last-child {
    margin-bottom: 0;
}

.house-price-details .sub-value {
    font-weight: 500;
    color: #1f2937;
}
