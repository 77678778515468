
.boldParameters{
    font-weight: 600;
    font-size: 14px;
}

.totalrowsStyle{
    background-color: #F6F6F6 !important;
}

.callReportStepWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.callReportStepWrapper .stepper{
    width: 40%;
    margin-right: 30%;
}

.callReportStepWrapper .backButton{
    width: 80px;
    font-weight: 500;
    background-color: #F7F8FF !important;
    color: #325CEB !important;
    border: 1px solid #D8EAFF !important;
    box-shadow: none !important;
    margin-left: 10px;
}

.callReportStepWrapper .backButton:hover{
    border: 1px solid #000000 !important;
    cursor: pointer;
}

.callReportSubHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.callReportSubHeader .subHeaderLeftBox{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
    margin-left: 10px
}

.callReportSubHeader .subHeaderLeftBox:nth-child(1){
    font-size: 16px;
    font-weight: 600;
}

.callReportSubHeader .subHeaderLeftBox .subHeaderTitle{
    font-size: 16px;
    font-weight: 600;
}

.callReportSubHeader .subHeaderLeftBox .versionTip{
    font-size: 14px;
    font-weight: 600;
    background-color: #ECECEC;
    padding: 5px 10px;
    border-radius: 15px;
}


.callReportSubHeader .subHeaderRightBox{
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 15px;
    margin-right: 10px;
}

.callReportSubHeader .subHeaderRightBox .saveEditButton{
    width: 105px;
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    background-color: #FCFCFC !important;
    color: #325CEB !important;
    border: 1px solid #325CEB !important;
    box-shadow: none !important;
}

.callReportSubHeader .subHeaderRightBox .saveEditButton:hover{
    border: 1px solid #000000 !important;
    cursor: pointer;
}

.callReportSubHeader .subHeaderRightBox .exportAllButton{
    width: 105px;
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    background-color: #325CEB !important;
    color: #FFFFFF !important;
    border: none !important;
    box-shadow: none !important;
}

.callReportSubHeader .subHeaderRightBox .exportAllButton:hover{
    cursor: pointer;
    border: 1px solid #000000 !important;
}

.callReportContentWrapper{
    display: flex;
    border-top: 1px solid #ECECEC;
    margin-top: 20px;
    height: calc(100vh - 125px);
}

.callReportTableWrapper{
    display: flex;
    flex-direction: column;
    height: 780px;
    overflow-y: auto;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
}

.callreportTable .ant-table-content .ant-table-thead .ant-table-cell{
    border: 1px solid #ECECEC;
    color: #586570 !important;
}

.callReportContentWrapper .ant-menu-sub{
    background: none !important;
}

.tableInputWithPrefix{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
}

.tableInputWithPrefix .ant-input{
    border: none !important;
    padding: 0px !important;
    background-color: #FCFCFC !important;
}

.tableInputWithPrefix .ant-input:focus{
    border: none !important;
    box-shadow: none !important;
}

.tableInputWithPrefix:hover{
    border: 1px solid #000000 !important;
}

.tableInputWithPrefix:focus-within{
    border: 1px solid #325CEB !important;
}

.callReportMainHeader{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;

}

.callReportStepOneWrap{
    padding: 30px;
}

.descriptionText{
    font-size: 12px;
    font-weight: 400;
    color: #6E6E70;
    margin-bottom: 30px;
}

.SpecificationInputs .ant-form-item-row{
    display: flex;
    flex-direction: column;
}

.SpecificationInputs{
    align-items: end;
}

.SpecificationInputs .selectFields{
    /* width: 200px; */
    height: 45px;
}

.SpecificationInputs .multiselectFields{
    width: fit-content;
    min-width: 200px;
    height: 45px;
    margin-right: 10px;
}

.SpecificationInputs .selectFields .ant-select-selector{
    background-color: #FCFCFC !important;
}

.SpecificationInputs .multiselectFields .ant-select-selector{
    background-color: #FCFCFC !important;
}

.SpecificationInputs .multiselectFields .ant-select-selection-item{
    background-color: #EAEFFD;
}

.SpecificationInputs .multiselectFields .ant-select-selection-item-remove{
    color: #325CEB !important;
}

.SpecificationInputs .multiselectFields .ant-select-selection-item-content{
    color: #325CEB !important;
}

.SpecificationInputs .multiselectFields .ant-select-selection-overflow-item{
    background-color: #EAEFFD !important;
    border-radius: 6px;
    padding: 3px 8px;
    font-weight: 600 !important;
    color: #325CEB !important;
    margin-left: 5px;
}

.SpecificationInputs .multiselectFields .ant-select-selection-overflow-item-suffix{
    display: none;
}

.SpecificationInputs .multiselectFields .ant-select-selection-overflow-item .statesTagsClose{
    font-size: 18px;
    font-weight: 400 !important;
    cursor: pointer;
    margin-left: 10px !important;
}

.generateButton{
    width: 160px;
    height: 45px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
}

.loansViewButton{
    color: #325CEB;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.loanDetailsModal .ant-modal{
    width: 60vw !important;
    height: 100vh !important;
    top: 0 !important;
}

.loanDetailsModal .ant-modal .ant-modal-content{
    height: 100vh !important;
}

.loanDetailsModalSub{
    margin-bottom: 20px;
    margin-top: 20px;
}

.callReportStepOneWrap .ant-form .ant-form-item-required{
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #586570 !important;
}

.callReportContentWrapper .ant-menu{
    color: #6E6E70 !important;
}

.savedSuccessWrapper{
    background-color: #E1F2E2;
    border-radius: 20px;
    border: none !important;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 190px;
    height: 42px;
    color: #2E605A;
}   

.savedSuccessWrapper img{
    width: 20px;
    height: 20px;
}

.confirmBackModal .ant-modal-content{
    width: 500px !important;
    height: 360px !important;
}

.confirmBackModal .ant-modal{
    top: calc(50vh - 240px) !important;
}

.confirmBackModal .ant-modal-body{
    margin-top: 60px;
    margin-bottom: 80px;
}

.confirmBackModal .contentBodyWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.confirmBackModal .contentBodyWrap .BodyTitle{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
}

.confirmBackModal .contentBodyWrap .BodyText{
    font-size: 16px;
    font-weight: 400;
}

.confirmBackModal .ant-modal-footer .noSaveButton{
    width: 180px;
    height: 50px;
    border: 1px solid #E3EAFD;
    color: #325CEB;
    font-size: 16px;
    font-weight: 600;
    background-color: #F7F8FF;
}

.confirmBackModal .ant-modal-footer .saveAndGoButton{
    width: 180px;
    height: 50px;
    background-color: #325CEB;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    border: none !important;
}

.confirmBackModal .confirmModalFooter{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.callreportTable{
    width: 97%;
}

.tableInputWithPrefix .ant-input-disabled{
    color: rgba(0, 0, 0, 0.5) !important;
}

.disabledInputColor{
    color: rgba(0, 0, 0, 0.5) !important;
}

.disabledInputColor:hover{
    border: 1px solid #ECECEC !important;
}

.closedLoanTypeLabel{
    font-weight: 600;
    color: #586570;
}

.callReportStepWrapper .ant-steps-item-finish .ant-steps-item-icon{
    background-color: #325CEB !important;
    color: #FFFFFF !important;
}

.callReportStepWrapper .ant-steps-item-finish .ant-steps-item-content{
    color: #325CEB !important;
    font-weight: 500 !important;
}

.callReportStepWrapper .ant-steps-item-finish .ant-steps-item-title{
    color: #325CEB !important;
    font-weight: 500 !important;
}

.callReportStepWrapper .ant-steps-item-process .ant-steps-item-icon{
    background-color: #D6E2FB !important;
    color: #325CEB !important;
}

.callReportStepWrapper .ant-steps-item-finish .ant-steps-icon{
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important
}

.callReportStepWrapper .ant-steps-item-process .ant-steps-item-content{
    color: #325CEB !important;
    font-weight: 500 !important;
}

.callReportStepWrapper .ant-steps-item-process .ant-steps-icon{
    font-weight: 500;
    font-size: 16px;
    color: #325CEB !important
}

.callReportStepWrapper .ant-steps-item-process .ant-steps-item-title{
    color: #325CEB !important;
    font-weight: 500;
}

.callreportTable .ant-table-header .ant-table-thead .ant-table-cell{
    border: 1px solid #DDDDDD;
    border-right: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 10px !important;
    color: #586570 !important;
}
/* ant-table-thead second tr's chidren with class name ant-table-cell */
.callreportTable .ant-table-header .ant-table-thead tr:nth-of-type(2) .ant-table-cell{
    text-align: center !important;
}

.callreportTable .ant-table-header .ant-table-thead tr:nth-of-type(1) .ant-table-cell{
    text-align: center !important;
}

.callReportStepOneWrap .ant-steps .ant-steps-item-process .ant-steps-item-icon{
    background-color: #325CEB !important;
}

.callReportStepOneWrap .ant-steps .ant-steps-item-process .ant-steps-icon{
    color: #ffffff !important;
    font-weight: 500;
}

.callReportStepOneWrap .ant-steps .ant-steps-item-process .ant-steps-item-title{
    color: #325CEB !important;
    font-weight: 500 !important;
}

.callReportStepOneWrap .ant-steps .ant-steps-item-wait .ant-steps-item-icon{
    background-color: #D9D9D9 !important;
}

.callReportStepOneWrap .ant-steps .ant-steps-item-wait .ant-steps-icon{
    color: #ffffff !important;
    font-weight: 500;
}

.callReportStepOneWrap .ant-steps .ant-steps-item-wait .ant-steps-item-title{
    color: #6E6E70 !important;
    font-weight: 500 !important;
}

