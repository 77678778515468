.compare-product-modal {
    top: 0;
    padding-bottom: 0;
}
.compare-product-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
    top: 0;
    border-radius: 0;
}
.compare-product-modal .ant-modal-content .ant-modal-body {
    font-family: Inter;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.compare-product-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 30px;
    right: 42px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-title {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 42px;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #DBDCDE;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content {
    padding: 15px 42px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-chart {

}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-chart .compare-product-chart-top {
    display: flex;
    justify-content: space-between;
    line-height: 36px;
    margin-bottom: 10px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-chart .compare-product-chart-top .compare-product-chart-title {
    color: #474849;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-chart .compare-product-chart-top .compare-product-chart-hide {
    cursor: pointer;
    padding: 0 12px;
    color: #325CEB;
    font-size: 16px;
    font-weight: 500;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-chart .compare-product-chart-box {
    height: 244px;
    padding: 0 5% 0 2%;
    border-radius: 12px;
    border: 1px solid #DBDCDE;
    background-color: #F6F6F6;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table {
    font-family: Inter;
    display: flex;
    margin-top: 32px;
    flex: 1;
    position: relative;
    overflow: auto;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-product-table-header {
    position: sticky;
    left: 0;
    z-index: 2;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column {
    flex: 0 0 20%;
    min-width: 182px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column .compare-table-column-item {
    display: flex;
    align-items: center;
    height: 42px;
    padding-left: 16px;
    background-color: #FFF;
    border-bottom: 1px solid #D9D9D9;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-product-table-header .compare-table-column-item {
    background-color: #F6F6F6;
    color: #586570;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column .compare-table-column-item.compare-table-column-item-first {
    height: 80px;
    position: sticky;
    top: 0;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-product-table-content-item:not(:last-child) .compare-table-column-item {
    border-right: 1px solid #D9D9D9;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-product-table-content-item.compare-table-column .compare-table-column-item {
    padding: 0 24px;
    color: #222;
    font-size: 14px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column-item .rate-tag-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column-item .rate-tag-bar .rate-tag {
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column-item .rate-tag-bar .rate-tag-close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column-item .compare-table-column-item-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.compare-product-modal .ant-modal-content .ant-modal-body .compare-product-content .compare-product-table .compare-table-column-item.compare-table-column-item-first .compare-table-column-item-value {
    line-height: normal;
}