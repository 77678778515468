.tab-pane.active.applybgcolorfix{
  background-color: #f7f9fc !important;
  color: #212529 !important;
}

@media (max-width: 576px) {
  .managementOptions{
    text-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-y: scroll;
    
  }
  
}