.feedback-trigger {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    background: #fff;
    width: 340px;
    height: 71px;
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 0;
    filter: drop-shadow(-4px -4px 4px rgba(71, 72, 73, 0.20));
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .feedback-trigger.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    background: #fff;
  }
  .feedback-trigger .ant-btn-text {
    font-size: 18px;
    font-weight: 600;
  }
  .feedback-trigger .ant-btn-text:hover {
    background: none !important;
  }

  .feedback-modal {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    padding-bottom: 0;
    margin: 0 !important;
    top: auto !important;
    transform: none !important;
  }

  .feedback-modal .ant-modal-content {
    padding: 0;
    border-radius: 8px 0 0 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .feedback-modal .ant-modal-header {
    margin: 0;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }

  .feedback-modal .ant-modal-body {
    padding: 0;
  }

  .feedback-type-selector {
    display: flex;
    margin-bottom: 24px;
    background: #f5f5f5;
    margin: 20px 30px 10px 24px;
    border-radius: 4px;
  }

  .feedback-type-selector .ant-radio-button-wrapper {
    flex: 1;
    text-align: center;
    border: none !important;
    background: transparent;
    height: 32px;
    line-height: 32px;
    margin: 6px;
  }

  .feedback-type-selector .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .feedback-type-selector .ant-radio-button-wrapper-checked {
    background: white !important;
    color: black !important;
    border-radius: 6px !important;
  }

  .feedback-type-selector.ant-radio-button-wrapper >.ant-radio-button {
    border-radius: 6px;
  }

  .feedback-form .form-section {
    margin-bottom: 16px;
  }

  .feedback-form .label {
    display: block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }

  .select-field,
  .textarea-field {
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .select-field:focus, .textarea-field:focus {
    background-color: #f5f5f5;
  }

  .upload-attachment {
    margin: 16px 0;
    cursor: pointer;
  }

  .upload-text {
    color: #1890ff;
    font-size: 14px;
  }

  .file-input {
    display: none;
  }

  .file-list {
    margin-top: 8px;
  }

  .file-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .file-icon {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.45);
  }

  .file-name {
    flex: 1;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .remove-file {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }


  .feedback-trigger .ant-select-selector {
    background-color: #f5f5f5 !important;
  }

  .feedback-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .feedback-form {
    padding: 0 24px;
    flex: 1;
    overflow-y: auto;
    max-height: calc(80vh - 160px);
  }

  .submit-button-wrapper {
    padding: 16px 24px;
    background: white;
    border-top: 1px solid #f0f0f0;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .submit-button-wrapper .ant-btn {
    width: auto;
    min-width: 120px;
  }

  .feedback-modal .ant-modal-close-x .anticon:hover {
    background: none;
  }

  .feedback-modal.ant-modal .ant-modal-close:hover {
    background: none;
  }