
.customer-review {
    color: #000;
    border-radius: 4px;
    font-family: ABC Ginto Normal;
    padding: 50px 6vw 46px 6vw;
}
.customer-review-title {
    font-family: "Playfair Display";
    font-size: 48px;
    line-height: 54px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
    color: #222;
}
.scroll-view {
    display: flex;
    overflow-x: auto;
   
}

.scroll-view::-webkit-scrollbar {
    -webkit-appearance: none;
}


.scroll-view::-webkit-scrollbar:horizontal {
    height: 12px;
}

.scroll-view::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.333);
}

.review-list {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    position: relative;
}

.review-list .review-item {
    /* width: 406px; */
    height: 286px;
    border-radius: 12px;
    border: 1px solid #F6F6F6;
    background-color: #FFF;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
    box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10);
}
.review-list .swiper-container {
    width: 100%;
}
.review-list .review-item .review-content .star {
    display: flex;
    gap: 8px;
    margin-bottom: 7px;
}
.review-list .review-item .review-content .content {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    overflow : hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin: 48px 0;
}
.review-list .review-item .customer {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
.more-review .google-review, .more-review .yelp-review{
    display: flex;
    align-items: center;
}
.more-review .google-review, .more-review .more-review-lebal {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 16px;
}
.more-review img ,.more-review .google-review {
    margin-right: 16px;
}
.more-review .google-review span, .more-review .yelp-review span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400px;
    text-decoration-line: underline;
    cursor: pointer;
}
.customer-review .review-list .swiper-btn {
    border-radius: 50%;
    background-color: #F1F3FA;
    padding: 12px;
    margin-top: -42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.customer-review .review-list .swiper-btn.prev {
    left: 0;
}
.customer-review .review-list .swiper-btn.prev img {
    transform: rotate(180deg);
}
.customer-review .review-list .swiper-btn.next {
    right: 0;
}
.customer-review .review-list .swiper-container {
    padding: 20px 44px;
    margin-top: 0;
}
.customer-review .review-list .voice-swiper-pagination {
    margin-top: 42px;
    text-align: center;
}

.customer-review .review-list .voice-swiper-pagination .swiper-pagination-bullet {
    padding-top: 8px;
    width: 10px !important;
    height: 10px !important;
    background-color: #DBDCDE !important;
    opacity: 1;
}

.customer-review .review-list .voice-swiper-pagination .swiper-pagination-bullet-active {
    background-color: #325CEB !important;
    font-size: 16px;
}
@media (max-width: 575px) {
    .scroll-view::-webkit-scrollbar {
        display: none;
    }
    .customer-review .review-list .swiper-container {
        padding: 20px 26px;
    }
    .customer-review .review-list .swiper-btn {
        padding: 0;
    }
}
@media (max-width: 767px) {
    .more-review .lebal-box {
        margin-bottom: 16px;
    }
    .customer-review-title {
        font-size: 32px;
    }
}


