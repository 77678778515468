.my-company-container {
  background-color: white;
  padding: 44px 30px 68px 30px;
}

.my-company-title {
  color: #081265;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  margin-bottom: 54px;
}

.my-company-content {
  display: flex;
  column-gap: 45px;
}

.logo-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 2px;
}

.company-logo {
  width: 226px;
  object-fit: contain;
}

.company-image {
  width: 227px;
  height: 114px;
  object-fit: contain;
}

.content-column {
  flex: 1;
}

.company-name {
  font-family: 'Playfair Display', serif;
  color: #081265;
  display: block;
  margin-bottom: 11px;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
}

.company-title {
  color: #6E6E70;
  display: block;
  margin-bottom: 11px;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
}

.company-description {
  color: #6E6E70;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 11px;
}

.view-company-button {
  width: 215px;
  height: 44px;
  background-color: #f7f8ff;
  color: #325CEB;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  border: 1px solid #e3eafd;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-company-button:hover {
  background-color: #E6E8FF;
}