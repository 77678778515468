@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

#alterra-chat-button {
	background-color: rgba(33, 93, 244, 0.773) !important;
	border-radius: 15px;
	bottom: 1em !important;
	right: 0.9em !important;
}

#chartcontainer div {
	width: 100% !important;
}

#clearsources {
	height: 2.1em !important;
}

#contain {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	padding-top: 20px;
}

#contain .form-label {
	font-size: 0.9em !important;
}

#dashboard td {
	padding-bottom: 6px;
	padding-top: 6px;
}

#dashboard th {
	padding-bottom: 4px;
	padding-top: 4px;
}

#ellipse {
	left: 0px;
	top: 0;
	z-index: 1;
}

#explainsteps .rcicon-check:before {
	color: white;
}

#explainsteps .rc-steps-item {
	padding-bottom: 10px !important;
	padding-right: 20px !important;
}

#explainsteps .rc-steps-item-description {
	color: #212529 !important;
	font-size: 16px !important;
}

#explainsteps .rc-steps-item-icon {
	background-color: var(--primary-color) !important;
	color: var(--primary-color-text) !important;
	font-size: 20px !important;
	height: 36px !important;
	line-height: 2 !important;
	top: -6 px !important;
	width: 36px !important;
}

#explainsteps .rc-steps-item-tail {
	left: 16px !important;
	top: 5px !important;
}

#explainsteps .rc-steps-item-title {
	color: var(--primary-color-2) !important;
	font-size: 20px !important;
	margin-top: 6px !important;
}

#highcost .card-body {
	cursor: pointer;
	margin-left: 5px;
	padding-bottom: 2px;
	padding-top: 2px;
}

#highcost .card-header {
	cursor: pointer;
	padding-bottom: 2px;
	padding-top: 2px;
}

#highcost .card-header:hover {
	text-decoration: underline;
}

#home {
	background-color: #ffffff !important;
	overflow: auto;
}

#homebutton {
	background-color: rgb(6, 9, 72) !important;
	height: 45px;
	width: 45px;
}

#homebutton:hover {
	filter: drop-shadow(1px 6px 3px rgb(0, 22, 50));
}

#homepic {
	height: 30em;
	width: 30em;
}

#iphone {
	left: 0px;
	top: 0px;
	z-index: -1;
}

#landing1 {
	display: block !important;
	margin: auto !important;
	text-align: center !important;
}

#liabilitystepper .rcicon-check:before {
	color: rgb(3, 182, 3);
	cursor: pointer;
	font-size: 18px !important;
	font-weight: 700 !important;
}

#liabilitystepper .rcicon-cross:before {
	color: rgb(255, 157, 157);
	font-size: 18px !important;
	font-weight: 700 !important;
}

#liabilitystepper .rc-steps-item {
	padding-bottom: 1px !important;
	padding-right: 20px !important;
}

#liabilitystepper .rc-steps-item-content {
	font-size: 0.8em !important;
}

#liabilitystepper .rc-steps-item-description {
	color: #212529 !important;
	font-size: 16px !important;
}

#liabilitystepper .rc-steps-item-icon {
	_background: #52679c;
	font-size: 16px !important;
	height: 30px !important;
	line-height: 2 !important;
	margin-top: 2px;
	width: 30px !important;
}

#liabilitystepper .rc-steps-item-process .rc-steps-item-icon {
	_background: #52679c;
}

#liabilitystepper .rc-steps-item-sub-step .rc-steps-item-icon {
	font-size: 0px !important;
	height: 20px !important;
	line-height: 2 !important;
	margin-top: 10px;
	width: 20px !important;
}

#liabilitystepper .rc-steps-item-tail {
	left: 16px !important;
	top: 5px !important;
}

#liabilitystepper .rc-steps-item-title {
	color: #212529 !important;
	font-size: 16px !important;
	margin-top: 6px !important;
	width: 100%;
}

#liabilitystepper .rc-steps-item-wait .rc-steps-item-icon {
	background-color: #fff !important;
}

#loans {
	table-layout: auto;
}

#more {
	border: none;
	float: left;
}

#myapp {
	height: 100%;
	width: 100%;
}

#pane {
	background-color: #F7F7F7 !important;
}

#navb {
	background-color: #F7F7F7 !important;
	/* margin-top: 17px; */
}

#losLoginNav {
	background-color: #f7f9fc !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #325CEB !important;
}

.ant-switch.ant-switch-checked .ant-switch-inner {
	background-color: #325CEB;
}

.ant-select-single.ant-select-lg .ant-select-selector {
	border-radius: 4px;
}

.ant-select-multiple.ant-select-lg .ant-select-selector {
	border-radius: 4px;
}

.ant-table-cell {
	background-color: transparent !important;
}

.ant-table-column-sorter-up.active {
	color: #325CEB;
}

.ant-table-column-sorter-down.active {
	color: #325CEB;
}

.ant-btn-primary {
	background-color: #325CEB !important;
	border-radius: 4px !important;
}

.ant-btn-primary-full-width {
	background-color: #325CEB !important;
	border-radius: 4px !important;
	width: 100% !important;
	height: 44px !important;
	color: #fff !important;
	font-weight: 500 !important;
	font-size: 16px !important;
}

.ant-btn-primary:disabled {
	background-color: #D9D9D9 !important;
	color: #FFF;
}

.ant-btn-primary.ant-btn-background-ghost {
	background-color: transparent !important;
	border-color: #325CEB !important;
	color: #325CEB !important;
}

.ant-btn-default-zeitro {
	border: 1px solid #E3EAFD;
	background: #F7F8FF;
	color:#325CEB
}
.ant-btn-border {
	border: 1px solid #E3EAFD;
	background: #F7F8FF;
	color:#325CEB;
}

/* .ant-btn-lg {
	border-radius: 4px;
	background-color: #325CEB;
	height: 44px !important;
} */

.ant-btn-link {
	color: #325CEB;
}

.ant-btn-outline {
	color: #325CEB;
	border: 1px solid #325CEB;
	background-color: #ffffff;
	border-radius: 6px;

}

.ant-btn-link-delete {
	color: #EB3232;
	font-weight: 500;
}
.ant-btn-boarder-round {
	border-radius: 24px;
	color: #325CEB;
	background-color: #fff;
	border: 1px solid #ECECEC;
}

.ant-btn-secondary {
	border-radius: 6px !important;
	color: #325CEB;
	border: 1px solid #E3EAFD;
	background-color: #F7F8FF;
	display: flex;
	align-items: center;
}

.ant-btn-secondary-selected {
	border-radius: 6px !important;
	color: #325CEB;
	border: 1px solid #325CEB;
	background-color: #F7F8FF;
	display: flex;
	align-items: center;
}

.ant-input {
	border-radius: 4px;
}

.anticon {
	vertical-align: 0;
}
.docVerificationModal {
	min-width: 800px;
	width: 90%;
}
.docVerificationModalWrap .ant-modal {
	top:0 !important;
	padding-bottom: 0 !important;
	width: 90% !important;
}

.docVerificationModal .ant-modal-content {
	top:0 !important;
	border-radius: 0 !important;
	height: 100vh;
	padding: 0 !important;
	overflow: auto;
}
.docVerificationModal .ant-modal-header {
	padding: 18px 25px;
	border-bottom: 1px solid #ECECEC;
	margin-bottom: 0 !important;
}
.docVerificationModal .ant-modal-body {
	height: calc(100% - 80px);
}

#navbb {
	background-color: #F7F9FC !important;
	border-bottom: solid 1px rgb(232, 237, 242);
}

#root {
	height: 100vh;
}

.borrowerViewWrap {
	height: calc(100vh - 200px);
}

#scaledtable {
	table-layout: auto;
}

#scaledtable {
	table-layout: auto;
}

#scaledtable td {
	padding-bottom: 0.75em !important;
	padding-top: 0.75em !important;
}

#scaledtable td {
	padding-bottom: 0.75em !important;
	padding-top: 0.75em !important;
}

#tasks td {
	padding-bottom: 6px;
	padding-top: 6px;
}

#tasks th {
	padding-bottom: 4px;
	padding-top: 4px;
}

._7ahQImyV4dj0EpcNOjnwA {
	margin-bottom: 2px !important;
}

.about {
	color: #060948 !important;
	font-size: 1.4em !important;
	font-weight: bold !important;
}

.about:hover {
	text-decoration: underline !important;
}

.acct {
	font-size: 1.1em !important;
	font-weight: bold;
}

.acct:hover {
	text-decoration: underline !important;
}

.act {
	background-color: #222B44;
	border-color: #222B44;
	color: white;
}

.act:hover {
	background-color: #aec8ed;
	border-color: #b1c0d5;
	color: #03091c;
}

.alterra-chat-tooltip-arrow {
	border-left-color: #215ef4 !important;
}

.alterra-chat-tooltip-text {
	background-color: #215ef4 !important;
}

.App {
	/* border-top: 1px solid #CCC; */
	margin: 0px;
}

.arrowdown {
	bottom: 2px;
	color: rgba(0, 48, 116, 1) !important;
	cursor: pointer;
	left: 50%;
	opacity: 0.4;
	position: fixed;
	text-align: center;
	z-index: 10000;
}

.arrowdown:hover {
	background-color: transparent !important;
	color: #215ef4 !important;
	opacity: 0.7 !important;
}

.avatar {
	border: 2px rgb(57, 79, 61);
	border-radius: 50%;
	height: 36px;
	/* margin-top: 6px; */
	width: 36px;
}

.avatartd {
	display: flex !important;
	justify-content: center !important;
	padding: 0px !important;
	vertical-align: middle !important;
}

.backimage {
	background-image: url("/housemoney.png");
	background-position: bottom 0px right 50px;
	background-repeat: no-repeat !important;
	position: relative;
}

.bigred {
	color: rgb(174, 21, 21);
	font-weight: bold;
}

.bimagex {
	background-image: url("/strip.png") !important;
	background-position: top 0px right 0px;
	background-repeat: repeat-y !important;
}

.blackish {
	color: rgb(63, 63, 63);
}

.blackongray {
	background-color: #ebebeb;
}

.blink_me {
	animation: pulse 1s linear;
	animation-delay: 1s;
	animation-iteration-count: 10;
}

.blink_me_off {
	animation: pulse 1s linear;
	animation-delay: 1.5s;
	animation-iteration-count: 10;
}

.blocklink {
	background-color: #f7f9fc;
	padding-left: 8px;
	padding-right: 8px;
	color: var(--primary-color-2)
}

.blue {
	color: #222b44 !important;
}

.blue-border {
	border-color: rgba(0, 48, 116, 1) transparent transparent !important;
}

.bluish {
	color: var(--primary-color-3);
}

.primary-color {
	color: var(--primary-color) !important;
}

.primary-dark-color {
	color: var(--primary-color-3) !important;
}

button.primary-dark-color:focus {
	box-shadow: 0 0 0 2px var(--primary-color-2) !important;
}

.primary-color-pale {
	color: var(--primary-pale) !important;
}

.bluishbackground {
	background-color: #E9F0FD !important;
}

.body {
	height: 100%;
}

.borrowercolumn {
	min-width: 6em !important;
	width: 6em !important;
}

.bottompadding {
	padding-bottom: 4em;
}

.brandtext {
	color: black !important;
	/* font-family: 'Inter' !important; */
	font-size: 1.2em;
	font-weight: 500 !important;
	margin-left: 2px;
	margin-top: 2px;
	padding: 0px;
}

.btn-circle {
	border-radius: 15px;
	bottom: 20px;
	font-size: 12px;
	height: 30px;
	line-height: 1.42857;
	padding: 6px 0px;
	position: fixed;
	right: 20px;
	text-align: center;
	width: 30px;
}

.btn-circle.btn-xl {
	border-radius: 35px;
	font-size: 24px;
	height: 70px;
	line-height: 1.33;
	padding: 10px 16px;
	width: 70px;
}

.btn-dropdown {
	background-color: var(--primary-color) !important;
	border-radius: 4px !important;
	color: var(--primary-color-text) !important;
}

.btn-dropdown:focus {
	box-shadow: 0 0 0 0.2px var(--secondary-color) !important;
}

.btn-dropdown:hover,
.btn-dropdown:active,
.btn-dropdown:visited {
	background-color: var(--primary-color-2) !important;
	border-color: var(--primary-color-3) !important;
	color: var(--primary-color-2-text) !important;
}

.btn-landing {
	background-color: rgb(233, 236, 239) !important;
	border-color: rgba(0, 0, 0, 0.1) !important;
	border-radius: 6px !important;
	border-style: solid !important;
	box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1) inset !important;
	cursor: default;
	text-align: center;
	transition: all 0.1s;
}

.btn-landing:active {
	background: #102693 !important;
	border: solid 3px #828ece !important;
	color: white !important;
}

.btn-landing:hover {
	background: #102693 !important;
	color: white !important;
}

.btn-outline-success,
.btn-outline-warning {
	background-color: white !important;
	border-radius: 4px !important;
}

.btn-secondary {
	background-color: var(--primary-color) !important;
	color: var(--primary-color-text) !important;
	border-color: var(--primary-color-2) !important;
}

.btn-success {
	border-radius: 2px !important;
	font-size: 0.97em !important;
	padding-left: 2em !important;
	padding-right: 2em !important;
}

.zeitro-radio {
	font-size: 14px;
}

.input-group-text {
	height: 44px;
	background-color: white !important;
}

.btn-zeitro-primary {
	height: 44px;
	min-width: 8rem;
	border: none !important;
	border-radius: 4px !important;
	color: #fff !important;
	background-color: #325CEB !important;
	font-size: 16px !important;
}

.btn-zeitro-outline {
	height: 44px;
	min-width: 8rem;
	border: none !important;
	border-radius: 4px !important;
	color: #325CEB !important;
	border: 1px solid #325CEB !important;
	font-size: 16px !important;
}

.btn-outline {
	border: none !important;
	border-radius: 4px !important;
	color: #325CEB !important;
	border: 1px solid #325CEB !important;
}

.btn-link {
	color: #325CEB !important;
}

.btn-zeitro-primary:disabled {
	background-color: #D9D9D9 !important;
}

.form-control[readonly] {
	background-color: #f8f8f8 !important;
}

.calcChart {
	height: 65%;
	width: 65%;
}

.capitalize {
	text-transform: capitalize !important;
}

.cardbody {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.15s ease;
}

.cards-details {
	font-size: 13px;
	margin: 0;
}

.cCseLN {
	background: white;
	border: 1px solid #dedede;
	width: 100% !important;
}

.clearfix {
	zoom: 1;
}

.clearfix:after {
	clear: both;
}

.clearfix:before,
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	overflow: hidden;
}

.closewindow {
	color: #933;
	cursor: pointer !important;
}

.closewindow:hover {
	color: #d22;
	cursor: pointer !important;
}

.color-box {
	display: inline-block;
	height: 18px;
	width: 18px;
}

.container {
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	width: 100% !important;
}

.credit-report-lo {
	border-bottom: 1px solid black;
}

.credit-report-lo ul {
	margin-bottom: 10px;
	padding-left: 20px;
	text-align: left;
	text-transform: lowercase;
}

.credit-report-lo-scores>p {
	margin-bottom: 5px;
}

.crisscrossed {
	background: repeating-linear-gradient(-45deg,
			transparent,
			transparent 10px,
			#fee 10px,
			#fee 20px),
		linear-gradient(to bottom, #fee, #edd);
}

.darkgreen {
	color: rgb(0, 109, 44);
}

.darkred {
	color: rgb(106, 0, 0);
}

.deepblue {
	color: #151a30 !important;
}

.deepestblue {
	color: #060948 !important;
}

.dis {
	background-color: rgb(247, 249, 252);
	border-color: lightgray;
}

.divlink {
	margin-left: 2px;
}

.divlink:hover {
	cursor: pointer !important;
	text-decoration: underline !important;
}

.documentDownload:hover {
	color: rgb(47, 125, 246);
	cursor: pointer;
}

.downarrow {
	color: #060948;
}

.downarrow:hover {
	box-shadow: 0 0 2px #215ef4;
	color: #52679c;
	cursor: pointer;
}

.downpayment {
	height: 20px !important;
	width: 70px !important;
}

.dropdown-menu {
	height: auto !important;
}

.dropdown-menu {
	height: 300px;
	overflow-y: scroll;
}

.dropdown-menu {
	max-height: 15em;
}

.dropdownsort button {
	border: 1px solid #ECECEC !important;
	color: #6E6E70 !important;
	background-color: #FCFCFC !important;
	border-radius: 4px;
}

.dropdownsort button:focus {
	background-color: var(--primary-color-2) !important;
	border-color: var(--primary-color) !important;
	color: var(--primary-color-2-text) !important;
}

.dropdownsort button:hover {
	background-color: white !important;
	border: 1px solid #215ef4 !important;
	color: #215ef4 !important;
}

.dropdown-toggle {
	font-size: 1em;
	font-weight: bold !important;
}

.drop-shadow {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	moz-box-shadow: 0 0 10px var(--primary-color-3);
	webkit-box-shadow: 0 0 10px var(--primary-color-3);
}

.email-column {
	min-width: 100px !important;
}

.err {
	color: red;
	font-size: 13px;
	margin-bottom: 1rem;
}

.fa-caret-down {
	margin-top: 0px !important;
}

.fahover {
	cursor: pointer !important;
	z-index: 2000;
}

.fahover:hover {
	color: rgba(0, 48, 116, 1) !important;
}

.fingerclass {
	animation: wshake 1s 8 ease;
	webkit-animation: wshake 1s 8 ease;
}

.font_rotate {
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9914448613738104, M12=-0.13052619222005157, M21=0.13052619222005157, M22=0.9914448613738104, sizingMethod='auto expand');
	moz-transform: rotate(17.5deg);
	ms-transform: rotate(17.5deg);
	o-transform: rotate(17.5deg);
	transform: rotate(17.5deg);
	webkit-transform: rotate(17.5deg);
	zoom: 1;
}

.foo {
	background-color: #a3b5ce;
}

.footer {
	bottom: -210px;
	height: 210px;
	margin-top: 0px;
	padding-top: 0px;
	position: relative;
	width: 100%;
}

.form-check-label {
	margin-top: 2px;
	cursor: pointer !important;
}

.input-group-prepend,
.input-group-append {
	font-size: 0.8rem !important;
}

.form-control {
	font-size: 0.875rem;
	height: 2.75rem !important;
}

.form-control-sm {
	font-size: 0.8rem !important;
	height: calc(1.5em + 0.5rem + 2px);
	line-height: 1.5;
	padding: 0.25rem 0.5rem;
}

.forms-download {
	display: inline-block;
	height: 100%;
}

.forms-preview {
	overflow: auto;
	position: relative;
}

.forms-preview>img {
	border-bottom: 1px solid black;
}

.fullwidth {
	margin-left: 10px;
	margin-right: 10px;
	width: 100% !important;
}

.gray-background {
	background-color: #eeeeee !important;
}

.gray-shadow {
	box-shadow: 0 0 40px #cccccc;
	moz-box-shadow: 0 0 40px #cccccc;
	webkit-box-shadow: 0 0 40px #cccccc;
}

.graytext {
	color: #bbb !important;
}

.graytext .blue {
	color: #bbb !important;
}

.greencrisscrossed {
	background: repeating-linear-gradient(-45deg, transparent, transparent 10px, #efe 10px, #efe 20px),
		linear-gradient(to bottom, #efe, #ded);
}

.gubmint:invalid {
	border-color: red;
}

.gubmint:invalid:focus {
	border-color: red;
}

.heading {
	font-size: 22px !important;
	margin-left: 40px;
	white-space: normal;
}

.subheading {
	font-size: 15px !important;
	margin-left: 40px;
	white-space: normal;
}

.ant-alert {
	border-radius: 4px;
}

.ant-alert-warning {
	border: none;
	color: #CB8600;
	font-weight: 500;
	border-radius: 6px;
	background-color: #F8E8D3;
	padding: 12px;
}

.ant-alert-info {
	border: none;
	color: #1F3988;
	font-weight: 500;
	border-radius: 6px;
	background-color: #E9F0FD;
	padding: 12px;
}

.ant-alert-info .ant-alert-icon {
	color: #325CEB;
}

.blueTips {
	background-color: #EAEFFD;
	border-radius: 4px;
	padding: 10px;
	color: #1F3988;
}

.title-1 {
	font-style: normal;
	font-weight: 600;
	font-size: 32px !important;
	line-height: 42px;
}

.title-2 {
	font-style: normal;
	font-weight: 600;
	font-size: 24px !important;
	line-height: 20x;
}

.title-3 {
	font-style: normal;
	font-weight: 500;
	font-size: 20px !important;
	line-height: 20x;
	color: #000000;
}

.title-4 {
	font-size: 20px !important;
	white-space: normal;
	color: #6E6E70;
}

.title-5 {
	font-size: 14px !important;
	white-space: normal;
	color: #6E6E70;
}

.title-5-bold {
	font-size: 14px !important;
	font-weight: 500;
	white-space: normal;
}

.title-6 {
	font-size: 10px !important;
	white-space: normal;
	color: #6E6E70;
}
.title-modal {
	font-family: "Playfair Display" !important;
	font-size: 36px !important;
	font-weight: 500 !important;
}

.text-label {
	font-size: 14px;
	font-weight: 500;
}

.border-top {
	border-top: 1px solid #ECECEC;
}

.border-bottom {
	border-top: 1px solid #ECECEC;
}

.border-right {
	border-right: 1px solid #ECECEC;
}

.border-left {
	border-left: 1px solid #ECECEC;
}

.border4 {
	border-radius: 4px !important;
}

.bold {
	font-weight: 500;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: white !important;
}

input#text-filter-column-borrowersName {
	font-size: 10px;
}

.heavyspaced {
	color: #060948 !important;
	font-size: 3.1em !important;
	font-weight: 500 !important;
	letter-spacing: 0.08em !important;
}

.high-cost-modal {
	max-width: 550px !important;
	width: 550px !important;
}

.border {
	border: 1px solid #ECECEC;
}

.bottomBorder {
	border-bottom: 1px solid #DBDCDE;
}

.home {
	background-color: #F7F9FC !important;
	min-width: 100px;
}

.rulecard {
	background-color: #eef2fa;
	border-radius: 5px;
}

.hubspotbox {
	background-color: #eef2fa;
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
}

.ib {
	display: inline-block;
	margin-right: 2px;
}

.incard {
	background-color: var(--primary-pale) !important;
	color: var(--primary-pale-text) !important;
	border-radius: 6px;
	margin-bottom: 15px;
	margin-left: 15px;
	margin-right: 20px;
	padding-bottom: 10px;
	padding-left: 10px !important;
	padding-right: 10px !important;
	padding-top: 10px;
}

.incdropdown {
	margin: 0px !important;
	padding-left: 0.0em !important;
	padding-right: 0.1em !important;
}

.input-group-prepend,
.input-group-append {
	font-size: 0.8rem !important;
	height: calc(1.5em + 0.5rem + 2px);
}

.ant-input-group-wrapper-lg .ant-input-group-addon {
	border-radius: 4px;
}

.inputSection {
	display: flex;
	flex-direction: column;
	width: calc(100% - 10px);
}

.landingbutton {
	font-size: 1.1em !important;
}

.left {
	float: left;
	height: 200px;
	width: 100px;
}

.lightblue {
	background-color: #E9F2FF;
}

.lightgreen {
	background-color: rgb(232, 242, 236);
}

.littleheading {
	font-weight: 500 !important;
}

.loader {
	animation: round 1.7s infinite ease;
	color: var(--primary-color);
	filter: drop-shadow(2px 2px 2px var(--secondary-color));
	font-size: 90px;
	margin: 0px auto;
	ms-transform: translateZ(0);
	opacity: 0.3;
	overflow: hidden;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	webkit-animation: round 1.7s infinite ease;
	webkit-filter: drop-shadow(2px 2px 2px var(--secondary-color));
	webkit-transform: translateZ(0);
	width: 54px;
}

.loancard {
	font-size: 14px;
}

.loanRates {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.loandetails {
	max-width: 90% !important;
	width: 90% !important;
}

.loandetails .close {
	color: white;
}

.loandetails .close:hover {
	color: white;
	font-weight: 600;
}

.loandetails .modal-body {
	background: var(--primary-pale);
	padding-bottom: 0px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 8px;
}

.loandetails .modal-content {
	height: 700px !important;
	min-height: 700px !important;
}

.loandetails .modal-footer {
	background: var(--primary-pale);
	padding-bottom: 4px;
	padding-top: 4px;
}

.loandetails .modal-header {
	background-color: var(--primary-color-2);
	color: var(--primary-color-2-text) !important;
	padding-bottom: 2px;
	padding-top: 2px;
}

.loandetails a {
	color: black;
}

.loandetails a:hover {
	color: black;
	cursor: pointer !important;
	text-decoration: underline !important;
}

.loginbox {
	background-color: #f7f9fc;
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	max-width: 400px;
}

.logo {
	height: 26px;
	margin-left: 10px;
	margin-top: -7px;
}

.logo-notifications {
	height: 26px;
	margin-left: 10px;
	margin-top: 4px;
}

.logoprivate {
	height: 25px;
	margin-left: 0px;
	margin-top: -8px;
}

.lpbutton,
.btn-primary {
	background-color: #325CEB !important;
	border-radius: 4px !important;
	font-size: 0.97em !important;
	padding-left: 1.5em !important;
	padding-right: 1.5em !important;
	border-color: var(--primary-color-2) !important;
	color: var(--primary-color-2-text) !important;
	height: 32px;
}

.lpbutton:hover,
.btn-primary:hover {
	background-color: #325ceb !important;
	color: var(--primary-color-2-text) !important;
}

.maxwidth-400 {
	width: 600px;
}

.mobilewide {
	padding-left: 3em !important;
	padding-right: 3em !important;
}

.modal-50w {
	max-width: 650px !important;
	width: 650px !important;
}

.modal-75w {
	max-width: 75% !important;
	width: 75% !important;
}

.modal-90w {
	max-width: 90% !important;
	width: 90% !important;
}

.modal-body {
	z-index: 8100 !important;
}

.modal-content {
	z-index: 8000 !important;
}

.modalfooter {
	margin-left: 0px !important;
	margin-right: 26px !important;
}

.modal-footer {
	z-index: 8100 !important;
}

.modal-header {
	z-index: 8100 !important;
}

.mpt-2 {
	padding-top: 0.65em !important;
}

.mpt-4 {
	padding-top: 1.3em !important;
}

.mybutton {
	background: none !important;
	border: none;
	color: rgba(0, 48, 116, 1) !important;
	padding: 0 !important;
}

.mybutton:hover {
	cursor: pointer;
	text-decoration: underline !important;
}

.mydropdown {
	background: rgb(233, 236, 239);
	border-color: rgba(0, 0, 0, .1);
	border-style: solid;
	box-shadow: -1px -1px 3px rgba(0, 0, 0, .1) inset, 1px 1px 3px rgba(255, 255, 255, .7) inset;
	cursor: default;
	text-align: center;
	transition: all 0.1s;
}

.mydropdown:active {
	background: rgb(216, 219, 222);
	box-shadow: 0 1px 3px rgba(0, 0, 0, .2) inset, -1px -1px 4px rgba(255, 255, 255, .5) inset;
}

.mydropdown:hover {
	background: rgb(221, 224, 228);
}

.hide {
	display: none !important;
}

.mymodal {
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	height: 100% !important;
	left: 0;
	overflow: auto;
	padding-top: 100px;
	position: fixed;
	top: 0;
	width: 100% !important;
	z-index: 1;
}

.mymodal-content {
	background-color: rgba(0, 255, 255, 0);
	border-width: 0px;
	height: 400pt !important;
	margin: auto;
	width: 300pt !important;
}

.myrow {
	display: block !important;
}

.navbar {
	height: 57px;
	/* margin-top: 18px; */
}

/* .navbar-brand
{
	height: 56px;
	padding-bottom: 0px !important;
	padding-top: 0px !important;
} */
/* .navbar-nav
{
	padding-bottom: 3px;
	padding-top: 3px;
}
.navbar-text
{
	padding: 0px !important;
	color: var(--primary-color) !important;
}
.nav-link
{
	color: var(--primary-color) !important;
	font-size: 0.8em;
	padding-bottom: 0.5em !important;
	padding-top: 0.5em !important;
} */

.nomargin {
	margin: 0px !important;
}

.notification-header-text {
	color: black;
	margin: auto;
}

.notification-pannel-general {
	left: 7vw;
	position: absolute;
	top: 2vh;
	z-index: 200;
}

.notification-pannel-specific {
	position: fixed;
	right: 7vw;
	top: 2vh;
	z-index: 2000000;
}

.oscillate {
	animation: osc 2s infinite linear;
}

.padrow {
	padding-left: 4em;
	padding-right: 4em;
}

.paleblue {
	color: #7F92A4;
	font-family: serif;
}

.aAsLink {
	color: #325CEB !important;
	cursor: pointer;
}

.pb-10 {
	padding-bottom: 100px !important;
}

.pop-up-notification {
	box-shadow: -1px 0px 39px -1px var(--primary-color) !important;
	moz-box-shadow: -1px 0px 39px -1px var(--primary-color) !important;
	webkit-box-shadow: -1px 0px 39px -1px var(--primary-color) !important;
}

.public-DraftEditor-content {
	background-color: white;
	border: 1px solid lightgrey;
	max-height: 15em;
	min-height: 20em;
}

.punch {
	font-family: serif;
	font-size: 1.2em !important;
}

.rcicon-check:before {
	color: white;
}

.rc-steps-item-icon {
	background-color: #52679c !important;
}

.rdw-editor-toolbar {
	border: 1px solid lightgrey !important;
}

.react-bootstrap-table table {
	table-layout: auto;
}

.react-datepicker__input-container input {
	width: 100px;
}

.rectangular {
	border-radius: 3px !important;
}

.red-background {
	background-color: #cc7777 !important;
}

.red-shadow {
	box-shadow: 0 0 10px #e9060699;
	moz-box-shadow: 0 0 10px #e9060699;
	webkit-box-shadow: 0 0 10px #e9060699;
}

.reveal {
	animation: animate 3s linear forwards;
}

.right {
	float: right;
	height: 200px;
	width: 100px;
}

.rightmobile {
	padding-right: 8px !important;
}

.roundedtab {
	background-color: var(--primary-color-3) !important;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-color: var(--primary-color-2);
	color: var(--primary-color-3-text) !important;
}

.row {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.rowlink {
	cursor: pointer;
}

.row-nowrap {
	flex-wrap: nowrap !important;
}

.secondbackimage {
	background-position: top 0px right 0px;
	background-repeat: no-repeat !important;
	background-size: 40%;
	position: relative;
}

.sectionquestion {
	font-size: 1.1em;
	font-weight: bold;
	white-space: normal;
}

.shevron {
	color: white !important;
	font-size: 1.5em !important;
}

.shevron:hover {
	color: var(--secondary-color) !important;
}

.shevronr {
	color: var(--primary-color);
	font-size: 1.5em !important;
}

.shevronr:hover {
	color: var(--primary-color-2) !important;
}

.slider {
	background-color: #ccc;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.4s;
	webkit-transition: 0.4s;
}

.slider.round {
	background-color: green;
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.slider:before {
	background-color: white;
	bottom: 1px;
	content: "";
	height: 22px;
	left: 4px;
	position: absolute;
	transition: 0.4s;
	webkit-transition: 0.4s;
	width: 22px;
}

.smallhouse {
	height: 20% important !;
	width: 20% important !;
}

.smenu {
	font-family: serif;
	font-size: 1em !important;
}

.social {
	max-width: 64px;
}

.socialicon {
	border-radius: 50%;
	cursor: pointer !important;
	width: 36px;
}

.socialicon:hover {
	border-radius: 50%;
	box-shadow: 1px 1px 5px #fff;
	moz-box-shadow: 1px 1px 5px #fff;
	webkit-box-shadow: 1px 1px 5px #fff;
}

.spaced {
	font-size: 2em !important;
	letter-spacing: 0.3em !important;
}

.spin {
	animation: round 1.7s infinite linear;
}

.spinarrowDown {
	border-color: rgba(0, 0, 0, 0.7) transparent transparent;
	border-style: solid;
	border-width: 0.5ex 0.5ex 0 0.5ex;
	height: 0;
	left: 50%;
	margin: -0.3ex 0 0 -0.56ex;
	position: absolute;
	top: 50%;
	width: 0;
}

.spinarrowUp {
	border-color: transparent transparent rgba(0, 0, 0, 0.7);
	border-style: solid;
	border-width: 0 0.5ex 0.5ex 0.5ex;
	height: 0;
	left: 50%;
	margin: -0.3ex 0 0 -0.56ex;
	position: absolute;
	top: 50%;
	width: 0;
}

.spinbtn {
	background: rgb(233, 236, 239);
	border-color: rgba(0, 0, 0, 0.1);
	border-style: solid;
	cursor: default;
	font-family: Helvetica, Arial, sans-serif !important;
	font-size: 16px !important;
	position: absolute;
	text-align: center;
	transition: all 0.1s;
	width: 20px !important;
}

.spinbtn:active {
	background: rgb(216, 219, 222);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset, -1px -1px 4px rgba(255, 255, 255, 0.5) inset;
}

.spinbtn:hover {
	background: rgb(221, 224, 228);
}

.spinbtnDown {
	border-radius: 0 0 2px 2px;
	border-width: 0 1px 1px 1px;
	bottom: -0px !important;
	top: 50%;
}

.spinbtnUp {
	border-radius: 2px 2px 0 0;
	border-width: 1px 1px 0 1px;
	bottom: 50%;
	top: 0px !important;
}

.spinwrap {
	display: "inline-block";
	position: relative;
}

.state-dropdown-menu button {
	background-color: var(--primary-color);
	border-color: var(--primary-color-2) !important;
	color: var(--primary-color-text) !important;
	overflow-y: scroll;
	width: 30px !important;
	height: 43px !important;
}

.state-dropdown-menu button:focus,
.state-dropdown-menu button:active,
.state-dropdown-menu button:visited {
	background-color: var(--primary-color-2) !important;
	border-color: var(--primary-color) !important;
	border-radius: 2px !important;
	box-shadow: 0 0 0pt 2pt var(--secondary-color);
	color: var(--primary-color-2-text) !important;
	outline: 2px solid var(--primary-color);
}

.state-dropdown-menu button:hover {
	background-color: var(--primary-color-2) !important;
	color: var(--primary-color-2-text) !important;
}

.steptop {
	margin-right: 3em;
	margin-top: 0.33em;
}

.steptop1 {
	margin-right: 5em;
	margin-top: 0.4em;
}

.subtag {
	font-size: 1em !important;
	margin-top: 23px;
}

.suggestion-active {
	background-color: rgba(0, 48, 116, 1) !important;
	color: #ffffff;
	cursor: pointer;
	font-weight: 700;
}

.suggestions {
	background-color: rgba(0, 48, 116, 1);
	border: 1px solid #999;
	border-top-width: 0;
	display: block;
	font-size: 0.8em;
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
	position: absolute;
	width: calc(300px + 1rem);
	z-index: 1;
}

.suggestions li {
	background-color: rgb(255, 255, 255);
	padding: 0.25rem;
}

.suggestions li:hover {
	background-color: #e9f2ff !important;
	color: #000000;
	cursor: pointer;
	font-weight: 700;
}

.switch {
	display: inline-block;
	height: 24px;
	position: relative;
	width: 60px;
}

.switch input {
	height: 0;
	opacity: 0;
	width: 0;
}

.tabajacent {
	border-bottom: 1px solid #222B44 !important;
}

.tabfont {
	font-size: 0.9em !important;
}

.documents-tabs {
	display: flex !important;
	flex-wrap: nowrap !important;
}

.table td,
.table th {
	vertical-align: middle !important;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
	border-color: rgb(190, 196, 206) !important;
}

.table-info,
.table-info>td,
.table-info>th {
	background-color: rgb(221, 227, 237) !important;
	border-color: rgb(190, 196, 206) !important;
}

.tab-pane {
	background-color: white !important;
}

.tagline {
	font-size: 2.6em !important;
	font-weight: 500 !important;
	margin-bottom: 0px;
	margin-top: 73px !important;
	padding-bottom: 0px;
}

.teamicon {
	border-radius: 50%;
	height: 96px;
	margin-bottom: 0px;
	margin-top: 6px;
	width: 96px;
}

.teamicon:active {
	border: solid 2px rgb(103, 115, 140) !important;
	box-shadow: 0px 0px 5px rgb(103, 115, 140);
	margin-bottom: 0px;
	margin-top: 6px;
}

.teamicon:hover {
	border: solid 1px rgb(103, 115, 140) !important;
	box-shadow: 0px 5px 5px rgb(103, 115, 140);
	margin-bottom: 3px;
	margin-top: 3px;
}

.test {
	color: #d2a83e;
}

.text-600 {
	font-weight: 600;
}

.text-800 {
	font-weight: 800;
}

.text-bold {
	font-weight: bold;
}

.text-info {
	color: #222B44 !important;
}

.text-inverted {
	background-color: #222B44 !important;
	color: white !important;
}

.text-newinfo {
	color: #222B44 !important;
}

.toolwrap {
	display: flex;
	width: 100%;
}

.toppadding {
	padding-top: 4em;
}

.transparent {
	opacity: 0;
}

.twtr-color-fill--blue-dark {
	fill: #1da1f2;
}

.twtr-icon {
	height: 24px;
	width: 24px;
}

.updown {
	background: rgb(233, 236, 239);
	border-color: rgba(0, 0, 0, 0.1);
	border-style: solid;
	box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1) inset, 1px 1px 3px rgba(255, 255, 255, 0.7) inset;
	cursor: default;
	height: 13px;
	left: 86px;
	position: absolute;
	text-align: center;
	transition: all 0.1s;
	width: 2ex;
}

.updown:active {
	background: rgb(216, 219, 222);
	box-shadow: 0 1px 3px rgba(0, 0, 0, .2) inset, -1px -1px 4px rgba(255, 255, 255, .5) inset;
}

.updown:hover {
	background: rgb(221, 224, 228)wheat;
}

.uspinbtn:active {
	background: rgb(216, 219, 222);
	box-shadow: 0 1px 3px rgba(0, 0, 0, .2) inset, -1px -1px 4px rgba(255, 255, 255, .5) inset;
}

.uspinbtn:hover {
	background: rgb(221, 224, 228);
}

.uspinbtnDown {
	border-radius: 0 0 2px 2px;
	border-width: 0 1px 1px 1px;
	bottom: 2px !important;
	top: 50%;
}

.uspinbtnUp {
	border-radius: 2px 2px 0 0;
	border-width: 1px 1px 0 1px;
	bottom: 50%;
	top: 0px !important;
}

.vertab {
	border-bottom: 2px solid #e9e9e9;
	border-bottom-left-radius: 12px;
	border-left: 2px solid #e9e9e9;
	border-right: none;
	border-top: 2px solid #e9e9e9 !important;
	border-top-left-radius: 12px;
	margin-left: 6px !important;
	position: relative;
	right: -1px;
	text-align: right;
}

.vertab:hover {
	background-color: #f7f9fca8;
	cursor: pointer !important;
	text-decoration: underline !important;
}

.vertabactive {
	background-color: #f7f9fc;
	border-bottom: 2px solid #d7d5d5;
	border-bottom-left-radius: 12px;
	border-left: 2px solid #d7d5d5;
	border-right: none;
	border-top: 2px solid #d7d5d5 !important;
	border-top-left-radius: 12px;
	position: relative !important;
	right: -1px !important;
	text-align: right;
	z-index: 1000;
}

.vertical-middle {
	vertical-align: middle !important;
}

.vertical-middle {
	vertical-align: middle !important;
}

.viewport {
	background-color: white;
	border-radius: 6px;
	margin-left: 15px;
	margin-right: 20px;
	padding-bottom: 20px;
	padding-left: 20px !important;
	padding-right: 20px !important;
	padding-top: 20px;
}

.viewwindow {
	background-color: rgb(237, 241, 245);
	border-radius: 6px;
	margin-left: 2px;
	margin-right: 2px;
	padding-bottom: 2px;
	padding-left: 2px !important;
	padding-right: 2px !important;
	padding-top: 2px;
}

.w-10 {
	height: 100% !important;
	max-width: 170px !important;
	width: 170px !important;
}

.w-50 {
	width: 50%;
}

.w-90 {
	width: 85%;
}

.w-100 {
	width: 100%;
}

.wc-header {
	background-color: rgb(166, 202, 252) !important;
	fill: rgb(166, 202, 252) !important;
}

.whitebackground {
	background-color: white;
}

.whitecolor {
	color: white;
}

.whitelink {
	color: #060948 !important;
}

.whitelink:hover {
	color: black;
	text-decoration: underline !important;
}

.yesno {
	min-width: 6em !important;
	width: 6em !important;
}

@keyframes animate {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1.0;
	}

	70% {
		opacity: 0.0;
	}

	80% {
		opacity: 0.6;
	}

	90% {
		opacity: 0.6;
	}
}

@keyframes osc {
	0% {
		opacity: 0.0;
	}

	100% {
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}
}

@keyframes pulse {
	50% {
		opacity: 0;
	}
}

@keyframes round {
	0% {
		transform: rotate(0deg);
		webkit-transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		webkit-transform: rotate(360deg);
	}
}

@keyframes wshake {
	0% {
		transform: rotate(0deg);
		webkit-transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		webkit-transform: rotate(360deg);
	}
}

@media (max-width: 500px) {
	.calcChart {
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 500px) {
	.high-cost-modal {
		max-width: 450px !important;
		width: 90vw !important;
	}
}

@media (max-width: 500px) {
	.frontimage {
		margin-top: 0px;
		width: 70%;
	}
}

@media (max-width: 900px) {
	.calcChart {
		height: 85%;
		width: 85%;
	}
}

@media (min-width: 900px) {
	.high-cost-modal {
		max-width: 700px !important;
		width: 700px !important;
	}
}

@media (min-width: 900px) {
	.myrow {
		align-items: stretch !important;
		display: flex !important;
	}
}

@media all and (max-width: 1200px) {
	.hideonphone1200 {
		display: none !important;
	}
}

@media all and (max-width: 480px) {
	.scaledimage {
		height: auto;
		min-width: 300px;
		width: 100%;
	}
}

@media all and (max-width: 520px) {
	.rightmobile {
		padding-right: 4px !important;
	}
}

@media all and (max-width: 520px) {
	.backimage {
		background-position: bottom 0px right 0px;
		background-size: 100%;
	}
}

@media all and (max-width: 520px) {
	.backimage_ {
		background-position: bottom 0px right 0px;
		background-size: 100%;
	}
}

@media all and (max-width: 520px) {
	.hideonphone {
		display: none !important;
	}
}

@media all and (max-width: 520px) {
	/* .logo
	{
		margin-left: 0px !important;
		padding-left: 0px !important;
	} */
}

@media all and (max-width: 520px) {
	.incard {
		margin-bottom: 1px;
		margin-left: 2px;
		margin-right: 2px;
		margin-top: 1px;
		padding-bottom: 4px;
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-top: 4px;
	}
}

@media all and (max-width: 520px) {
	.viewport {
		margin-left: 6px;
		margin-right: 6px;
		padding-left: 2px !important;
		padding-right: 2px !important;
	}
}

@media all and (max-width: 700px) {
	.toolwrap {
		display: block;
	}

	.vertab {
		border-bottom-right-radius: 12px;
		border-right: 2px solid #e9e9e9;
		border-top-right-radius: 12px;
		margin-right: 16px !important;
		right: 0px;
		text-align: center;
		width: 50%;
	}

	.vertabactive {
		border-bottom-right-radius: 12px;
		border-right: 2px solid #e9e9e9;
		border-top-right-radius: 12px;
		margin-right: 16px !important;
		right: 0px;
		text-align: center;
		width: 50%;
	}
}

@media all and (max-width: 700px) {
	.hideonphone700 {
		display: none !important;
	}

	.hidesmall {
		display: none;
	}

	.mobilewide {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.mpt-2 {
		padding-top: 0em !important;
	}

	.mpt-4 {
		padding-top: 0em !important;
	}
}

@media all and (max-width: 800px) {
	.tagline {
		height: auto !important;
		font-size: 1.9em !important;
		font-weight: 500 !important;
		margin-bottom: 0px;
		margin-top: 10px !important;
		padding-bottom: 0px;
	}
}

@media all and (max-width: 800px) {
	.viewport {
		margin-left: 10px;
		margin-right: 10px;
		padding-left: 6px !important;
		padding-right: 6px !important;
	}
}

@media all and (min-width: 480px) {
	.Login {}

	.Login form {
		margin: 0 auto;
		max-width: 300px;
		width: 300px;
	}
}

@-webkit-keyframes osc {
	0% {
		opacity: 0.0;
	}

	100% {
		opacity: 0.0;
	}

	20% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}
}

@-webkit-keyframes round {
	0% {
		transform: rotate(0deg);
		webkit-transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes rouwshakend {
	0% {
		transform: rotate(0deg);
		webkit-transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		webkit-transform: rotate(360deg);
	}
}

body {
	height: 100% !important;
	margin-bottom: 0px;
	padding-bottom: 0px !important;
}

h1 {
	color: var(--primary-color-2) !important;
	font-size: 38px !important;
	font-weight: bold !important;
}

h2 {
	color: var(--primary-color-2) !important;
	font-size: 26px !important;
	font-weight: bold !important;
}

h3 {
	color: var(--primary-color-2) !important;
	font-size: 26px !important;
	font-weight: bold !important;
}

h4 {
	color: var(--primary-color-2) !important;
	font-size: 24px !important;
	font-weight: bold !important;
}

h5 {
	font-size: 22px !important;
	font-weight: normal !important;
}

html {
	min-height: 100% !important;
}

input.error {
	border-color: red;
}

input:checked+.slider {
	background-color: #215ef4;
}

input:checked+.slider:before {
	ms-transform: translateX(26px);
	transform: translateX(26px);
	webkit-transform: translateX(26px);
}

input:focus+.slider {
	box-shadow: 0 0 1px #215ef4;
}

input[type="checkbox"]:required:invalid~label.in-valid {
	color: red;
}

input[type="radio"]:required:invalid~label.in-valid {
	color: red;
}

p.App {
	height: 100%;
	text-align: center;
}

tbody:tr:hover {
	background-color: #e2e2e2 !important;
}

tbody:tr:nth-child(even) {
	background-color: #e8eee3 !important;
}

tr {
	border-bottom: 1px solid black;
	border-top: 1px solid black;
}


.btn-outline-primary {
	border-color: var(--primary-color) !important;
	color: var(--primary-color) !important;
}

.btn-outline-primary:hover {
	background-color: var(--primary-color-2) !important;
	border-color: var(--primary-color) !important;
	color: var(--primary-color-2-text) !important;
}

.pale-background {
	background-color: var(--primary-pale) !important;
}

.affordability-pale-background {
	background-color: rgb(163, 163, 163);
	background: linear-gradient(to right, #00BE63, #00BE63 57%, #FFAA29 57%, #FFAA29 80%, #FF2929 80%, #FF2929);
}

select.form-control.form-control-sm:focus,
input.form-control:focus,
input.form-control.form-control-sm:focus {
	border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.nav-tabs .nav-link {
	color: var(--primary-color) !important;
	font-size: 14px;
	font-weight: bolder;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	background-color: rgb(228, 228, 228);
}


.nav-link.active {
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-bottom: 4px #0b57d0 solid !important;
	color: #0b57d0 !important;
}

button.button-link {
	color: var(--primary-color) !important;
}

.pagecard {
	background-color: var(--primary-pale) !important;
	color: var(--primary-color-text) !important;
	border-radius: 6px;
	margin-bottom: 15px;
	margin-left: 15px;
	margin-right: 20px;
	padding-bottom: 10px;
	padding-left: 10px !important;
	padding-right: 10px !important;
	padding-top: 10px;
}

.text-fixed {
	font-family: Courier !important;
}

.react-datepicker__input-container input {
	height: 44px;
}

.input-group-prepend,
.input-group-append {
	font-size: 0.8rem !important;
	height: 44px !important;
}

.form-control-sm {
	height: 44px !important;
}

.SidebarDropdown_dropdown-wrapper__FOTzq {
	overflow-x: hidden !important;
}

.comment-title {
	font-size: 20px !important;
}

.form .postBtn {
	border-radius: 2px !important;
	background-color: #325CEB !important;
}

.form .cancelBtn {
	border-radius: 2px !important;
}


.cancel {
	border-radius: 2px !important;
}

.welcomeMessage {
	font-size: 24px;
	color: #000;
	font-weight: 600;
	line-height: 44px;
	font-family: "Playfair Display" !important;
	margin-bottom: 12px;
}

.borrowerNames {
	width: 225px;
	border-right: 2px solid #ECECEC;
}

.borrowerDetails {
	flex: 1;
	padding: 60px 70px;
	overflow-y: scroll;
}


.btn-borrower {
	background-color: transparent !important;
	border-bottom: 1.5px solid #ECECEC !important;
	font-size: 16px !important;
	text-align: left !important;
	width: 100%;
	padding: 10px 0 10px 20px !important;
}

.btn-selectedBorrower {
	background-color: #D6E2FB !important;
	border-bottom: 1.5px solid #ECECEC !important;
	font-size: 16px !important;
	text-align: left !important;
	width: 100%;
	padding: 10px 0 10px 20px !important;
	border-radius: 0 !important;
}

span.ant-menu-title-content {
	font-weight: 600 !important;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
	color: #325CEB !important;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light>.ant-menu .ant-menu-item-selected {
	color: #325CEB !important;
	background-color: #D6E2FB !important;
}

.fontWeight500 {
	font-weight: 500 !important;
}

.title1003 {
	border-bottom: 3px solid #ECECEC;
	padding: 20px 30px;
	font-weight: 500;
	position: sticky;
	background-color: white !important;
	z-index: 999;
	top: 0;
}

.form1003Title {
	background-color: #F7F7F7;
	font-weight: 500;
	padding: 10px 30px;
	margin-right: 20px;
	font-size: 14px;
}

hr.form1003Divider {
	border-top: 2px solid #CFD4D9;
}

span.ant-input-wrapper.ant-input-group {
	height: 44px;
}

span.ant-input-affix-wrapper {
	height: 44px;
}

.ant-input-affix-wrapper {
	border-radius: 4px;
}

.ant-input-affix-wrapper-lg {
	border-radius: 4px;
}

.ant-input-group .ant-input-group-addon {
	border-radius: 4px;
}

.ant-input-group-wrapper-lg .ant-input-group-addon {
	border-radius: 4px;
}

.ant-input-lg {
	height: 100%;
	border-radius: 4px;
	/* height: 44px; */
}

.ant-input-number-lg input.ant-input-number-input {
	border-radius: 4px !important;
	height: 44px;
}

.ant-input-number-lg {
	border-radius: 4px 0 0 4px !important;
}
.ant-input-number-affix-wrapper-lg {
	border-radius: 4px !important;
}
.ant-input-number-group-wrapper-lg .ant-input-number-group-addon {
	border-radius: 4px;
}
.ant-input-number-affix-wrapper >div.ant-input-number {
	background-color: transparent !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-dfjnss).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
	border-color: #325CEB;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
	background-color: #325CEB;
}

.ant-picker-large {
	height: 44px;
	border-radius: 4px;
	width: 100%;
}

.ant-picker-focused {
	border-color: #325CEB !important;
}

.fontSize16 {
	font-size: 16px !important;
}

.applicationLabel {
	background: #E9F4EB;
	color: #2E6333;
	width: 150px;
	border-bottom-right-radius: 4px;
	padding: 3px 4px;
	text-align: center;

}

.pop-up-notification {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
}

@media (max-width: 575px) {
	.loansTable .react-bootstrap-table {
		overflow-y: scroll;
	}

	.officersTable .react-bootstrap-table {
		overflow-y: scroll;
	}
}


