@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url('https://fonts.googleapis.com/css?family=Inter');


.landingBackground {
    /* background: url('/images/landingBackground1.png') !important; */
    /* background-size: contain !important; */
    background-position: right 80px center!important;
    background-size: 95vmin 90% !important;
    background-repeat: no-repeat !important;
}

.landingBackgroundLo {
    /* background: url('/images/landingBackground2.png') !important; */
    /* background-size: contain !important; */
    background-position: right 0px center!important;
    background-size: 100vmin 100% !important;
    background-repeat: no-repeat !important;
}

.landingnavitem {
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 20px;
    margin-top: 11px;
}

.landingnavcontact {
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
    position: relative;
    right: 2px;
}

.btn-landingNavContact {
    border-radius: 2px;
    color: white;
    background-color: #000000;
}


.landingNavName {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-left: 50px;
    margin-top: 15px;
    margin-right: 20px;
    line-height: 28px;
    color: #070B3A
}


.navbar-nav {
    flex-direction: row !important;
}


.landingNav {
    background-color: transparent;
}

.landingProfile {
    padding: 0 24px;
}
.personalinfo {
    width: 100%;
    padding-bottom: 60px;
}

.profileImage {
    border-radius: 4px;
    margin-right: 50px;
    max-width: 200px;
    max-height: 206px;
    margin-top: -20px;
}

.profileImageMobile {
    display: none;
}

.profileTitle {
    font-weight: bold  !important;
    font-size: 16px;
    color: #1F3988;
    margin-left: 10px;
    margin-top: 100px;
}

.profileNameRe {
    font-family:'Inter' !important;
    font-weight: 500;
    font-size: 48px;
    font-style: normal;
    margin-bottom: 20px;
    margin-left: 10px;
}

.profileNameLo {
    font-family:'Inter' !important;
    font-weight: 500;
    font-size: 48px;
    font-style: normal;
    margin-bottom: 20px;
    margin-left: 10px;
}

.profileOtherInfo {
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    margin-bottom: 3px;
}
.profileOtherInfoCol {
    width: 150px;
}

.profileOtherInfoCol2 {
    width: 150px;
    text-align: left;
}

.profileIntroduction {
    margin-left: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
    max-width: 532px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #222222;

}

.profileContact {
    padding-top: 5px;
    margin: 60px 0 10px 0px;
    background-color: white;
    max-width: 850px;
    border-radius: 4px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.profileICanHelpYouRow {
    display: flex;
    margin-top: 10px;
    margin-left: 5px;
}

.profileICanHelpYouCol {
    border: 1px solid #CFD4D9;
    border-radius: 8px;
    margin-right: 15px;
    padding: 18px;
    background-color: white;
    min-width: 175px;
}

.profileICanHelpYouText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
}

.properties {
    padding: 10px 50px 10px 50px;
}

.profileFormLabel {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.profileFormInput {
    background: #F5F5F7;
    border-radius: 4px;
    border: 0ch;
}

.btn-contactButton {
    font-family: 'Inter' !important;
    background: black;
    border-radius: 4px;
    color: white;
    width: 150px;
    height: 55px;
    font-size: 18px;
    margin-top: 12px;
    /* margin-left: 10px; */
}

.profileProfessionalInfo {
    background-color: #F7F8FC;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profileProfessional {
    font-family:'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 45px;
    margin-top: 25px;
    margin-bottom: 5px;
}

.propertiesTitle1 {
    font-family:  'Inter' !important;
    font-weight: 600;
    font-size: 16px;
    color: #1F3988;
    text-align: center;
    margin-top: 50px;
}

.propertiesTitle2 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.swiper-pagination {
    position: relative !important;
    margin-top: 42px;
}

.swiper-pagination-bullet {
    padding-top: 8px;
    width: 38px !important;
    height: 38px !important;
    background-color: transparent !important;
}

.swiper-pagination-bullet-active {
    border: 1px solid #325CEB !important;
    color: #325CEB !important;
    font-size: 16px;
}

.swiper-container {
    /* overflow: visible !important; */
    margin-top: 40px;
}

.propertyInfo {
    width: 213px;
    margin-bottom: 15px;
}

.propertyImage img{
    width: 213px;
    height: 159px;
    object-fit:contain;
    background-color: #F7F8FC;
}

.propertyAddress1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-top: 5px;
}

.propertyAddress2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #6E6E70;
    margin-top: 2px;
}

.propertyRooms {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 2px;
}

.propertyPrice {
    margin-top:10px;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
    background-color: #222222;
    color: white;
    text-align: center;
}

.resourcesTitle1 {
    font-family:  'Inter' !important;
    font-weight: 600;
    font-size: 16px;
    color: #1F3988;
    text-align: center;
    margin-top: 50px;
}

.resourcesTitle2 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.resourcesTitle3 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #6E6E70;
    margin-left: 27px;
    padding-top: 10px;
}

.resourceHeader {
    margin-top: 40px;
    justify-content: space-between;
    margin-bottom: 50px;
}

.slideArrow {
    font-size: 20px;
    width: 38px;
    height: 38px;
    font-weight: 1;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background: rgba(34, 34, 34, 0.5);
    mix-blend-mode: normal;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin-right: 15px;
    color: white !important;
}

.loInfo {
    width:346px;
    height: 385px;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    margin-top: 25px;
    padding-right: 13px;
}

.loImage {
    border: 4px solid #EDF2FF;
    border-radius: 50%;
    max-width: 80px;
    max-height: 80px;

    position: relative !important;
    left: 240px;
    margin-top: -25px;
    background-color: white;
}

.loName {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-left: 30px;
    margin-top: -20px;
}

.loNMLS {
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-left: 30px;
}

.loIntroduction {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #6E6E70;
    margin-left: 30px;
    margin-top: 20px;
}


.loButtonGroup {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 80px;
}

.btn-loButton {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-left: 25px;
    margin-right: 15px;
}

.loButtonGroup2 {
    position: absolute;
    bottom: 20px;
}

.btn-loButton2 {
    background: #000000;
    border: 1px solid #000000;
    border-radius: 4px;
    font-family: 'Inter' !important;
    font-weight: 500;
    font-size: 16px;
    color: white;
    width: 300px;
    height: 42px;
    margin-left: 20px;
}

.loDivider {
    width: 310px;
    margin-left: 20px;
    position: absolute;
    bottom: 120px;
}

.landingTools {
    padding-bottom: 150px;
}

.tools {
    padding-top: 80px;
}

.toolsTitle1 {
    font-family: Roboto !important;
    font-weight: 700;
    font-size: 16px;
    color: #1630AC;
    text-align: center;
    line-height: 24px;
    margin: 50px 0 10px;
}

.toolsTitle2 {
    font-family: 'Playfair Display' !important;
    color: #6E6E70;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
    text-align: center;
}
.toolsTitle3 {
    font-family: Roboto !important;
    color: #6E6E70;
    text-align: center;
    font-size: 16px;
    line-height: normal;
}
.toolsCardHeader {
    background-color: transparent;
    
}

.toolsCard {
    background-color: transparent;
    border: none;
    border-bottom: #1F3988 solid 1px !important;
}

.toolAccordionTitle {
    display: flex;
    justify-content: space-between;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}

.toolAccordionText {
    font-family: 'Inter' !important;
    font-weight: 600;
    font-size: 24px;
}

.landingFooter {
    padding-top: 40px;
    padding-bottom: 50px;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
}

.landingFooterClaim {
    padding-top: 50px;
    /* padding-bottom: 0px; */
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
}

.callback {
    background: url('/images/callback.png');
    background-size: contain;
    padding: 120px;

    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;

}

.callbackForm {
    background-color: white;

    max-width: 414px;
    max-height: 417px;
    position: relative;
    left: 35vw;
    padding: 30px;
}

.callbackFormInModal {
    padding: 40px;
}


.callbackTitle {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.callbackInput {
    width: 350px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #CFD4D9;
    border-radius: 4px;
}

.callbackMessage{
    width: 350px;
    height: 100px !important;
    background: #FFFFFF;
    border: 1px solid #CFD4D9;
    border-radius: 4px;
}

.btn-callbackButton {
    width: 350px;
    height: 53px;
    background: #000000;
    border-radius: 4px;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}


.resouceDivider {
    width: 95%;
    margin-top: 50px;

}

.resourceInfo {
    margin-bottom: 25px;
    min-width: 200px;
    max-width: 250px;
}

.resourceName {
    font-family: 'Inter' !important;
    font-weight: 600;
    font-size: 20px;
    border-left: #1F3988 2px solid;
    line-height: 20px;
    padding-left: 12px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}

.resourceType {
    font-family: 'Inter' !important;
    font-weight: 600;
    font-size: 20px;
    /* color: #6E6E70; */
    padding-left: 15px;
}

.resourcePhone {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #6E6E70;
    padding-left: 15px;
    margin-top: 10px;
}

.resourceEmail {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #6E6E70;
    padding-left: 15px;
    overflow-wrap: break-word;
}
.landingnav1 {
    margin-top: 7px;
}

@media (max-width: 820px) {
    .landingnav1 {
        display: none;
    }
}

@media (max-width: 480px) {

    .landingBackground {
        /* background: url('/images/landingBackground1.png') !important; */
        background-position: center!important;
        background-repeat: no-repeat !important;
        background-size: auto 100% !important;
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    }
    .landingBackgroundLo {
        /* background: url('/images/landingBackground2.png') !important; */
        background-position: center!important;
        background-repeat: no-repeat !important;
        background-size: auto 100% !important;
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    }
    .landingnavitem {
        font-size: 12px !important;
        margin-left: 5px;
        margin-right: 5px;
        font-family: 'Inter' !important;
        font-weight: 400;
        margin-top: 0px;
    }
    .landingnavcontact {
        margin-left: 0px;
    }
    .btn-landingNavContact {
        margin-top: -10px;
        margin-right: 10px;
        font-size: 11px;
        padding: 6px;
    }
    .landingNavName {
        font-weight: 700;
        font-size: 17px !important;
        margin-left: 20px !important;
        margin-top: -2px;
    }
    .landingNav {
        background-color: transparent;
    }
    .landingTools {
        padding-top: 50px;
    }
    .landingRateQuoter {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .profileTitle {
        display: none;
    }
    .profileNameRe {
       color: white;
       font-size: 30px;
       margin-top: 50px;
    }
    .profileNameLo {
        color: white;
        font-size: 30px;
     }
    .profileFirstName {
        color: white !important;
    }
    .profileOtherInfo {
        color: white
    }
    .profileOtherInfoCol {
        color: white !important;
    }
    .profileImageRow {
        display: flex;
    }
    .profileImage {
        display: none;
    }
    .profileImageMobile {
        display: inline;
        border-radius: 50%;
        max-width: 56px;
        max-height: 56px;
        margin-top: 45px;
        margin-left: 10px;
    }
    .profileProfessionalInfo {
        margin-top: 0px;
    }
    .profileContact {
        display: none;
    }
    .profileProfessional {
        margin-left: 7px;
    }
    .propertiesTitle1 {
        margin-top: 30px;
        font-weight: 700;
        font-size: 14px;
    }
    .propertiesTitle2 {
        font-weight: 500;
        font-size: 35px;
        line-height: 43px;
        text-align: center;
    }
    .propertyInfo {
        width: 100%;
        margin-bottom: 10px;
        margin-left: 10px;
    }
    .propertyImage img{
        width: 100%;
        height: 159px;
    }
    .propertyAddress2 {
        font-size: 10px;
    }
    .propertyAddress1 {
        font-size: 14px;
    }
    .propertyRooms {
        font-size: 10px;
    }

    .resourceInfo {
        margin-bottom: 20px;
        margin-left: 28px;
    }
    .resourceType {
        font-size: 18px;
    }

    .resourcesTitle1  {
        margin-top: 30px;
        font-size: 14px; 
    }
    .resourcesTitle2  {
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
    }
    .resourcesTitle3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        padding-top: 0px;
    }
    .swiper-container {
        margin-top: 10px;
        margin-left: -20px !important;
    }

    .loImage {
        margin-top: 10px;
    }
    .loName {
        margin-top: -60px;
    }
    .loCardCol {
        padding-bottom: 10px;
    }
    .loInfo {
        height: 100%;
        /* margin-left: 8px; */
    }
    .loDivider {
        width: 310px;
        position: relative;
        bottom: 0px;
    }
    .loButtonGroup {
        position: relative;
        bottom: 0px;
    }
    .loButtonGroup2 {
        position: relative;
        bottom: 0px;
    }
    .loIntroduction {
        margin-right: 25px;
    }
    .resourceName {
        line-height: 28px;
        font-size: 18px;
    }
    .resourcePhone {
        margin-top: 2px;
        font-size: 14px;
    }
    .resourceEmail {
        font-size: 14px;
        line-height: 19px;
        margin-top: 5px;
    }
    .btn-showMoreResouces {
        font-family: 'Inter' !important;
        font-weight: 700;
        font-size: 16px;
    }
    .toolsTitle1 {
        margin-top: 0px;
        font-size: 14px;
    }
    .toolsTitle2 {
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
    }
    .callback {
        background-position: center!important;
        background-repeat: no-repeat !important;
        background-size: auto 100% !important;
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
        padding: 30px;
    }
    .callbackForm {
        background-color: white;
        max-width: 414px;
        max-height: 417px;
        position: relative;
        left: 0px;
        padding: 30px;
    }
    .callbackInput {
        width: 100%;
    }
    .callbackMessage{
        width: 100%;
    }
    .btn-callbackButton {
        width: 100%;
    }
    .landingFooterClaim {
        padding: 50px;
        line-height: 20px;
    }
    .landingPageCardBody {
        padding: 0px !important;
    }
    .tools {
        padding-top: 50px;
        margin-bottom: 0px;
    }
    .toolsCard {
        overflow: visible !important;
    }
    .profileICanHelpYouCol {
        background-color: #ffffffc7;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 140px;
    }
    .profileICanHelpYouText {
        font-size: 12px;
    }

    .basicinfo-apply-now-btn{
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 6px;
        padding-bottom: 32px ;
        font-size: 16px;
    }
}

@media (max-width: 400px){
    .toolsTitle2{
        font-size: 28px;
    }
}

/* new add */
.landing-nav {
    padding: 0 24px;
    background-color: #FFF;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.landingMeneButton {
    cursor: pointer;
}
.landing-nav .landing-navList {
    display: none;
}
.landing-nav .menu-left {
    display: flex;
    align-items: center;
}
.landing-nav .menu-left span {
    margin-right: 18px;
    cursor: pointer;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    display: none;
}
.landing-nav .landing-navList .contact-btn, .drawer-nav .contact-btn{
    height: 32px;
    line-height: 1;
    background-color: #0C67FF !important;
    color: #FFF !important;
    border-radius: 4px !important;
    font-weight: 500;
    border-color: #0C67FF !important;
}
.drawer-nav .contact-btn {
    margin: 30px auto;
}
.drawer-nav  .ant-drawer-body {
    padding: 0;
}
.landing-nav .nav-link.active {
    border-bottom: none !important;
}
.drawer-nav .nav {
    height: 44px;
    padding-left: 24px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.drawer-nav .ant-drawer-header-title {
    justify-content: end;
}
.drawer-nav .ant-drawer-content-wrapper {
    height: auto;
}
.drawer-nav .ant-drawer-header {
    padding: 28px 24px;
}
.drawer-nav .ant-drawer-close {
    margin-inline-end: 0px;
}
.drawer-nav .ant-drawer-content {

}
.customize-wrap {
    padding: 0 24px;
    width: 100%;
}
.landing-nav-collapse {
    background-color: #FFF;
}
/* .landing-nav-collapse .navbar-nav {
    flex-direction: column !important;
} */
.landingFooter {
    padding: 62px 6% 24px 6%;
    color: #3F3F3F;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    text-align: left;
    box-sizing: border-box;
}

.gmccspecials{
    padding: 0;
}

.calculators {
    margin-top: 60px;
}
.calculators .calculators-title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 16px;
}
.calculators-tabs .ant-tabs-tab {
    padding: 8px 0;
    line-height: 24px;
    font-size: 16px;
    color: #000;
}
.calculators-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
}
.calculators-tabs .ant-tabs-ink-bar {
    background: #000;
}
.calculators-tabs .ant-tabs-tab:hover {
    color: #000;
}
.landingFooter .rowTitle {
    font-weight: 500;
    margin-bottom: 20px;
}
.landingFooter .rowContent {
    font-weight: 300;
    margin-bottom: 40px;
}
.landingFooter .sitemap .rowContent div {
    cursor: pointer;
}
.landingFooter .security .rowContent {
    font-size: 12px;
    line-height: 24px;
}
.landingFooter .socialMedia .rowContent {
    display: flex;
    gap: 40px;
}
.landingFooter .socialMedia .rowContent img {
    cursor: pointer;
}

.landingFooter .rowContent > div:not(:last-child) {
    margin-bottom: 16px;
}
.landingFooter .copyright {
    text-align: center;
    font-weight: 300;
    line-height: 20px;
}
.landingFooter .landing-qrcode canvas{
    margin-left: -24px;
}
@media (min-width: 992px) {
    .landingProfile, .landing-nav {
        padding: 0 40px;
    }
    .landingFooter .sitemap {
        padding-left: 16px;
    }
    .landingFooter .QRCode {
        display: flex;
        flex-direction: row-reverse;
    }
    .landingMeneButton {
        display: none;
    }
    .landing-nav .landing-navList {
        display: flex;
    }
    .landing-nav .menu-left span {
        display: inherit;
    }
    .basicinfo-apply-now-btn{
        display: none;
    }
}
.landing-page-logo {
    max-height: 40px ;
    margin-right: 20px;
}
@media (max-width: 575px) {
    .landingFooter {
        padding: 50px 32px 16px 32px;
    }
    .landing-nav {
        height: 55px !important;
    }
    .landing-page-logo {
        max-height: 30px ;
    }
}

.apply-now-btn{
    margin-right: 10px;
    /* padding-left: 35px !important;
    padding-right: 35px !important; */
}

