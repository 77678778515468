.loan_details_container {
  border: 1px solid #dbdcde;
  border-radius: 1em;
  padding: 0em !important;
  margin-top: 2em;
  font-size: 16px;
}
.loan_details_title {
  justify-content: center;
  background: #081265;
  border-radius: 1em 1em 0 0;
  padding: 1em;
  color: white;
}
.loan_details_row {
  margin: 1em 1em;
}
.loan_details_label {
  text-align: left;
}
.loan_details_value {
  text-align: right;
}

.loan_details_disclaimer {
  margin: 0.5em 0 0 0;
  font-size: 14px;
  color: #6e6e70;
  text-align: left;
}

.letter_sent_container {
  text-align: center;
  justify-content: center;
  margin-bottom: 2em;

  .letter_sent_title {
    font-size: 18px;
    color: #222222;
  }
  .letter_sent_text {
    font-size: 16px;
    color: #6e6e70;
  }
}

.product_details_container {
  background-color: #f4f7fe;

  .ant-card-body {
    padding: 15px 25px !important;
  }

  .product_details_text_container {
    margin-bottom: 1em;
  }

  .loan_rates_container {
    background-color: #ffffff;

    .loan_details {
      margin: 0;
    }
    .loan_details .header {
      font-weight: light;
      text-align: left;
      font-size: 14px;
      color: #666;
    }
    .loan_details .content {
      text-align: left;
      font-size: 16px;
    }
  }
}

.loader {
  margin-right: 6px;
  opacity: 1;
  filter: "invert(100%) grayscale(1)";
  width: 20px;
  height: 20px;
}


