:root {
  --main-dark-blue: #081265;
}

.stepper-menu-container {
  height: 100%;
  /* width: 25%; */
  /* min-width: max(220px, 20%); */
  position: sticky;
  top: 0px;
  z-index: 999;
  padding-right: 10px;
  background-color: #f6f6f6;
}

.stepper-menu-container .stepInfo {
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
  color: #6e6e70;
  padding-left: 10px;
}

.stepper-menu-container .stepCircle {
  width: 24px;
  height: 24px;
  border: 1px solid #d9d9d9;
  /* fill: #E0E7FF; */
  background: #d9d9d9;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  color: #fcfcfc;
  font-size: 14px;
}

.stepper-menu-container .stepPassed {
  color: var(--main-dark-blue);
  background: #e0e7ff;
  border-color: var(--main-dark-blue);
}

.stepper-menu-container .stepCurrent {
  color: #e0e7ff;
  background: var(--main-dark-blue);
  border-color: var(--main-dark-blue);
}

.stepper-menu-container .stepLine {
  background: #d9d9d9;
  width: 2px !important;
  border-radius: 0px;
  height: 42px;
  margin-left: 9px;
}

.stepper-menu-container .stepLineCurrent {
  background: var(--main-dark-blue);
  width: 2px !important;
  border-radius: 0px;
  height: 42px;
  margin-left: 9px;
}

.stepper-menu-container .subMenuItem {
  height: 40px !important;
  font-weight: 500 !important;
  width: 100% !important;
  border: none !important;
  text-align: left;
  padding-left: 3px;
  padding-right: 8px !important;
  border-radius: 0.5em;
}

.stepper-menu-container .subMenuItem.selected {
  padding-left: 3px;
  background: #e0e7ff !important;
}

.stepper-menu-container .ant-btn {
  border: none !important;
  background: none !important;
  font-weight: 500 !important;
  height: 40px !important;
  padding: 6px 10px 6px 6px !important;
}

.stepper-menu-container .ant-btn.main-menu-item {
  font-weight: 600 !important;
}
