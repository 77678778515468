.gmccSpin .ant-spin .ant-spin-dot-item {
    background-color: white;
}

.mdaPropertyInfo {
    background-color: #D6E2FB;
    padding: 5px 10px;
    margin-bottom: 8px;
    color: #325CEB;
}

.callbackTitle-1 {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
}

.callback-lo {
    background-color: #D6E2FB;
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 8px;
}
.contactMeRequest{

    width: 100%!important;
}
.callbackTitle-3 {
    font-size: 16px;
    /* color:#325CEB; */
    font-weight: 500;
}

.callbackTitle-4 {
    font-size: 16px;
    line-height: 1.7;
}

.callback-loinfo {
    display: flex;
    justify-content: space-between;
}

.qualifierWrap {
    display: flex;
    /* padding: 0 50px; */
    padding: 40px 20px 0px 20px;
    justify-content: center;
}

.qualifierDisclaimerWrap {
    justify-content: center;
}

.disclaimerContainer {
    text-align: left;
    padding-bottom: 20px;
    flex-direction: column;
}

.gmccCalculatorTabs .ant-tabs-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 60;
    z-index: 1;
    background: white;
}

.gmccCalculatorTabs .ant-tabs-tab-btn {
    text-align: left;
}

.gmccCalculatorTabs .ant-tabs-nav-list> :first-child {
    padding-top: 25px;
}

.gmccCalculatorTabs.ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
.gmccCalculatorTabs.ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
    padding-left: 0px;
}

.gmccSpecialTabsWrap {
    padding: 0 35px 0 55px;
    border-top: 1px solid #dedede;
}

.tabList {
    padding: 0 107px 107px 107px;
    display: grid;
    grid-gap: 29px;
    grid-template-columns: 35% 35% 35%;
    background: #EAEFFD;
    justify-content: initial !important;
}

.tabList:after {
    content: '';
    width: 21%;
    border: 1px solid transparent;
}

.tabList .tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    background: #FFF;
    width: 359px;
    height: 230px;
    flex-shrink: 0;
    padding: 0px 30px 0px 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.tab-top {
    /* height: 100px; */
    display: flex;
    justify-content: space-between;

    align-items: center;
    box-sizing: border-box;
}

.key {
    color: #586570;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top: 20px;
}

.label {

    color: #1F3988;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 225% */
}

.gmccSpecialTabsWrap .ant-tabs-left>.ant-tabs-content-holder {
    margin-left: 0;
}

.specialHeaderButton {
    font-size: 16px !important;
    cursor: pointer;
    font-weight: 500;
    margin: 0 20px;
}

.programInitialGuideWrap {
    background-color: #EAEFFD;
    position: relative;
    min-height: 200px;
    align-items: center;
    padding: 80px
}

.programInitialGuideWrapSpecial {
    background-color: #EAEFFD;
    position: relative;
    min-height: 200px;
    align-items: center;
    padding: 80px
}

.hfaSubTitle {
    margin-top: 20px;
    padding: 0 40px;
    font-size: 16px;
    font-weight: 500;
}

.programInitialGuideWrap .ant-carousel .slick-dots li.slick-active button {
    background: #325CEB !important;

}

.programInitialGuideWrap .ant-carousel .slick-dots li button {
    background: #D9D9D9 !important;
}

.programInitialGuideWrap .slide {
    text-align: center;
}

.programInitialGuideWrap .slideDotsWrap {
    padding: 10px 40px;
}

.programResultWrap {
    background-color: #EAEFFD;
    text-align: center;
    position: relative;
    min-height: 200px;
    align-items: center;
    padding: 80px;
}

.programResultWrap .program {
    border: 1px solid #CFD4D9;
    padding: 20px 20px;
    text-align: left;
    width: 290px;
    cursor: pointer;
    margin: 12px 10px 0 10px;
    border-radius: 4px;
    background-color: #FCFCFC;
}

.carouselWrap {
    background-color: #F7F8FC;
    height: 100%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.gmccTabWrap {
    padding: 10px 50px;
}

.programResults {
    display: flex;
    justify-content: center;
}

.programResults img {
    height: 23px;
    width: 28px;
    margin-right: 7px;
}

.gmccFloatingLabel .adm-floating-bubble-button {
    background-color: #EAEFFD !important;
    color: #1F3988;
    border-radius: 10px 0 0 10px;
    border: 1px solid #EAEFFD;
    width: 120px;
    height: 25px;
    justify-content: start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.contactmeForm .form-control {
    font-size: 16px !important;
}

.qualifierForm .form-control {
    font-size: 16px !important;
}

.qualifierForm .input-group>.form-control {
    font-size: 16px !important;
}

.gmccDiamond {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.gmccDiamond iframe {
    flex-grow: 1;
    width: 100%;
}

.Special {
    background: #EAEFFD;
    color: #222;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "Playfair Display";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    /* 112.5% */

}

.Cover {
    background: #EAEFFD;
    text-align: center;
    color: #6E6E70;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "Playfair Display";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    padding-bottom: 50px;
}

@media (max-width: 767px) {

    .introText{
        font-size: 2vw !important;
        width: 45vw !important;
    }
    .redText{
        font-size: 3.5vw !important;
    }

    .subscribe-CTA{
        font-size: 1.8vw !important;
        width: 55vw !important;
    }

    .bannerLeftBg{
        width: 70% !important;
    }

    .bannerRightBg{
        width: 30% !important;
    }

    .specialNavBtn{
        font-size: 2vw !important;
    }

    .specialBanner{
        height: 40vh !important;
    }

    .tabList {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
    }

    .gmccCalculatorTabs .ant-tabs-nav-list> :first-child {
        padding-top: 8px;
    }

    .programResults {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .qualifierWrap {
        padding: 0 10px;
    }

    .gmccFlyer {
        margin-top: 10px;
        width: 70%;
    }

    .programInitialGuideWrap .slideDotsWrap {
        padding: 10px 0px 15px 0px;
    }

    .programResultWrap {
        margin: 0px;
        padding: 50px 20px;
    }

    .programInitialGuideWrap {
        padding: 25px 10px;

    }

    .slideDotsWrap .title-1 {
        font-size: 24px !important;
        line-height: 30px;
    }

    .carouselWrap {
        padding: 0px 18px 20px 18px;
    }

    .programResultWrap .title-3 {
        margin-top: 20px;
    }

    .hfaSubTitle {
        margin-top: 20px;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
    }

    .gmccSpecialTabsWrap {
        border-top: none;
        padding: 0 20px;
    }

    .gmccTabWrap {
        padding: 10px;
    }

    .mobileMarginTopAdjuster {
        margin-top: 15px;
    }

    .gmccMarginAdjuster {
        margin-top: 18px;
    }

    .disclaimerContainer {
        padding-bottom: 0px;
    }

    .qualifierFormWrap .ant-result-title {
        text-align: left;
    }

    .qualifierFormWrap .ant-result-subtitle {
        text-align: left;
    }
}

@media (max-width: 480) {
    .gmccFlyer {
        margin-top: 10px;
        width: 100%;
    }

    .disclaimerContainer {
        padding-bottom: 0px;
    }

}

@media (max-width: 1600px) {
    .callback-loinfo {
        display: block;
        margin-left: 30px;
    }
    .tabList{
        justify-content: center !important;
        grid-template-columns: 30% 30%;
        grid-gap: 50px;
        padding-left: 0;
        padding-right: 0;
    }
}



@media (max-width: 1450px) {
    .tabList .tab{
        width: 320px;
    }
    .label{
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .qualifierFormWrap .ant-result-title {
        text-align: left;
    }

    .qualifierFormWrap .ant-result-subtitle {
        text-align: left;
    }

}

@media (max-width: 600px) {

    .Special,
    .Cover {
        font-size: 24px;
        padding-bottom: 0px;
    }

    .tabList .tab {
        width: 100%;
       min-height: 101px!important;
        padding: 11px 21px;
    }

    .key {
        margin-top: 20px;
        font-family: "Open Sans";
        font-size: 16px;
    }
}
@media (max-width: 768px) {
    .contactmeTime-mobileAdjuster {
        flex-direction: column;
    }
}
.programHighlights {
    background-color: #EAEFFD;
    border-radius: 4px;
    padding: 10px;
    color: #1F3988;

}
@media (max-width: 1000px){

    .tabList .tab{

        width: 100%;
    }
}

.qualifier-Wraper{
    display: flex;
    justify-content: center;
    background-color: #EAEFFD;
    padding-bottom: 60px;
}
.qualifierContainer{
    background-color: #ffffff;
    width: 95%;
    padding-top: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.subscribeForm .form-label{
    font-size: 16px;
}

.subscribeForm .form-check{
    font-size: 16px;
    display: flex;
    align-items: center;
}

.subscribeForm .form-control{
    font-size: 16px !important;
}

.successText{
    font-size: 24px;
    font-weight: 600;
    color: #2E605A;
    margin-top: 20px;
}

.specialBanner{
    /* background-image: url('/images/gmccflyers/GMCCSpecial_background3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    height:55vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerLeftBg{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background-color: #DEDEDE;
}
.bannerRightBg{
    background-image: url('/images/gmccflyers/GMCCSpecial_background3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #DEDEDE;

}
.bannerHeader{
    display: flex;
    justify-content: start;
    font-size: 1vw;
    font-weight: 900;
    /* letter-spacing: 2rem; */
    color: #6E6E70;
    transform: scale(1.2);
    font-family: "Playfair Display";
}

.widthWrap{
    padding-left: 7vw;
}

.introText{
    width: 35vw;
    margin-top: 20px;
}

.redText{
    color: #325CEB;
    /* letter-spacing: 3rem !important; */
    font-size: 3vw;
    font-family: "Inter" !important;
    text-align: left;
}

.subscribe-CTA{
    margin-top: 60px;
    margin-left: 3.6vw;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    border: 4px solid #325CEB;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1vw;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35vw;
    height: 5vh;
    background-color: #325CEB;
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.subscribe-CTA:hover{
    color:#325CEB;
    background-color: white;
}

.AboutUsPane{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AboutUsTextWrap{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    font-size: 16px;
    font-weight: 500;
}

.introSubTitle{
    font-size: 24px;
    color: red;
    font-weight: 600;
    margin-top: 40px;
    text-align: center;
}

.IntroImagesWrap{
    height: 100%;
    overflow-y: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introImage{
    width: 100%;
}

.specialNavBtn{
    margin-right: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #325CEB;
    cursor: pointer;
}

.specialNavBtn:hover{
    color: black;
}