.greenBg {
  background-color: rgb(76, 166, 76);
}

.orangBg {
  background-color: rgb(231, 180, 22);
}

.redBg {
  background-color: rgb(235, 90, 70);
}

.greyBg {
  background-color: rgb(188, 195, 207);
}

.tag {
  padding: 0.25rem;
  border-radius: 5px;
}