.overview-comment {
    padding: 12px 3vw;
}
.overview-comment .overview-comment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.overview-comment .overview-comment-title .comment-title {
    color: #1F3988;
    font-family: Inter;
    font-size: 24px;
}
.overview-comment-btn {
    width: 150px;
    height: 44px;
    font-weight: 600;
    border-radius: 4px;
    padding: 0;
}

.overview-comment .comment-table .ant-table{
    border: 1px solid #ECECEC;
}

.overview-comment .comment-table .ant-table-row .ant-table-cell .table-date {
    color: #222;
    font-family: Inter;
    font-size: 16px;
}
.overview-comment .comment-table .ant-table-thead .ant-table-cell-fix-right.ant-table-cell {
    border-bottom: 2px solid #6E6E70 !important;
    background-color: #f5f5f7 !important;
}
.overview-comment .comment-table .ant-table-cell-fix-right.ant-table-cell {
    background-color: white !important;
}
.comment-table .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}
.comment-table .ant-table-thead >tr>th {
    border-bottom: 2px solid #6E6E70;
}
.viewFiles-modal .ant-modal-footer {
    text-align: center;
    margin-bottom: 74px;
}
.viewFiles-modal .comment-wrap {
    padding: 116px 0 10px;
}
@media (min-width: 576px) {
    .viewFiles-modal .comment-wrap {
        padding: 116px 40px 10px;
    } 
    .viewFiles-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
        margin-inline-start: 38px;
    }
}
@media (min-width: 768px) {
    .viewFiles-modal .comment-wrap {
        padding: 116px 76px 10px;
    }
}