.p-1{
  padding: 1rem;
}

.m-v-1{
  margin: 1rem 0;
}

.orange{
  color: #d99d4f;
}

.p{
  font-size: 1.15rem;
  text-align: left;
}

.flex-row{
  display: flex;
  flex-wrap: wrap;
}

.flex-center{
  justify-content: center;
  align-items: center;
}

.flex-row > *{
  margin-right: 0.5rem;
}

.input-width-fix{
  width: calc(100% - 2rem);
}

.button-group{
  overflow-x: scroll;
  width: 100%;
}