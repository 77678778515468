.basic-info-new {
    font-family: ABC Ginto Normal;
    width: 100%;
    /* height: 105%; */
    border-radius: 4px;
    background: #fff;
    /* padding: 44px 0 44px 7%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    text-align: left;
    padding: 44px 3.5vw 30px 3.5vw;
}

.basic_user_info {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    position: absolute;
    bottom: 10px;
    left: -160px;
    /* width: 204px;
    height: 77px; */
    flex-shrink: 0;
    border-radius: 17px;
    background: #FFF;
    box-shadow: 147px 68px 45px 0px rgba(31, 57, 136, 0.00), 94px 43px 42px 0px rgba(31, 57, 136, 0.01), 53px 24px 35px 0px rgba(31, 57, 136, 0.05), 24px 11px 26px 0px rgba(31, 57, 136, 0.09), 6px 3px 14px 0px rgba(31, 57, 136, 0.10);
}

.basic_user_name {

    display: flex;
    align-items: center;
}

.basic_NMLS {
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-left: 10px;
}

.basic_name {
    color: #0F0F0F;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    /* margin-left: 16px; */
}

.yse {
    padding: 12px 20px;
    position: absolute;

    flex-shrink: 0;
    border-radius: 17px;
    background: #FFF;
    box-shadow: 147px 68px 45px 0px rgba(31, 57, 136, 0.00), 94px 43px 42px 0px rgba(31, 57, 136, 0.01), 53px 24px 35px 0px rgba(31, 57, 136, 0.05), 24px 11px 26px 0px rgba(31, 57, 136, 0.09), 6px 3px 14px 0px rgba(31, 57, 136, 0.10);
    bottom: 35px;
    right: -95px;
    padding: 8px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.basic_NMLS {
    color: #171717;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.avatar-box-new {
    position: relative;
    width: 280px;
    height: 280px;
    margin-bottom: 32px;
}

.basic-info-avatar-new {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.basic_info {
    /* display: flex; */
    justify-content: center;
    justify-content: start;
}

.basic_info_con {
    color: #325CEB;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 175% */
    text-decoration-line: underline;
    cursor: pointer;
}

.basic_info_word {

    color: #1F3988;

    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.verified-img {
    width: 38px;
    height: 38px;
    position: absolute;
    right: 6px;
    bottom: 8px;
}

.basic-info .name-box {
    display: flex;
    align-items: center;
    line-height: normal;
    margin-bottom: 4px;
}

.basic-info .name-box .name {
    color: #000;
    font-size: 30px;
    font-weight: bolder;
    margin-right: 12px;
}

.basic-info .name-box .verified-tag {
    color: #073719;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    height: 24px;
    padding: 0 8px;
    background-color: #D2FFE2;
    border-radius: 4px;
    margin: 12px 0 8px 0;
}

.basic-info .certificate {
    color: #000;
    font-size: 14px;
    margin-bottom: 24px;
}

.basic-info .certificate span:first-child {
    margin-right: 16px;
}

.basic-info-new .tag-list-new {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.text-new {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #686868;


}



.introduction-new {
    width: 100%;
    /* height: 160px; */
    color: #3C3C3C;
    text-overflow: ellipsis;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 20px;
}

.contact-new {
    padding: 22px 0 40px 0;
    border-top: 1px solid #1F3988;
    border-bottom: 1px solid #1F3988;
    margin-top: 10px;


}

.contact-item-new {
    padding: 0 !important;
}

.contact-item-new .label {

    color: #1630AC;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.contact-item-new .value {
    color: #0F0F0F;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-decoration-line: underline;

}

.app-list {
    width: 70%;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: start;

}

.app-list .list {
    width: 200px;
    display: flex;
    justify-content: left;
    gap: 30px;
}

.basic-info .contact-btn {
    background-color: #0C67FF;
    color: #FFF;
    border-radius: 4px; 
}

.basic-info .contact-btn:hover {
    color: #FFF !important;
}

.basic_info_prefix{
    color:#325CEB;
    margin-right: 10px;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

@media (max-width: 1200px) {
    .basic-info-new {
        background: #FFF;
        /* padding: 44px 0 18px 0; */
    }

    .yse {
        right: -35px
    }

    .avatar-box-new {
        margin: 0 auto 40px;
    }

    .basic_info {
        justify-content: center;
    }
}

@media (max-width: 1500px) {
    .basic-info-new {
        background: #FFF;
        /* padding: 0px; */
    }


}

@media (max-width: 600px) {
    .basic_box {
        display: none;
    }

    .basic-info-new {
        background: #FFF;
        padding: 0px;
    }


}

@media (max-width: 780px){
    .basic_info_prefix{
        font-size: 14px;
    }
    .basic_info_con{
        font-size: 14px;
    }
}