.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding-left: 17px;
    padding-right: 23px;
}

.message-header .action-icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.message-header .chat-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    color: #222222;
    margin-left: 4px;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-header .cancel-button {
    border: none;
    background: none;
    color: #325ceb;
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
    cursor: pointer;
}

.share-chat-modal {
    width: 50% !important;
    height: calc(100vh - 35px);
    top: 17px;
}

.share-chat-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 10px;
    height: calc(100vh - 35px);
    padding: 0;
}

.share-chat-modal .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    min-height: 76px;
    padding: 0 45px 0 28px;
    margin-bottom: 0;
    border-bottom: 2px solid #ececec;
}

.share-chat-modal-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #222222;
}

.share-chat-modal .ant-modal-close {
    top: 25px;
    right: 45px;
    width: 24px;
    height: 24px;
    position: absolute;
}

.share-chat-modal .ant-modal-close-x {
    display: none;
}

.share-chat-modal .ant-modal-close::before,
.share-chat-modal .ant-modal-close::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background-color: #6e6e70;
    transform-origin: center;
}

.share-chat-modal .ant-modal-close::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.share-chat-modal .ant-modal-close::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.share-chat-modal .ant-modal-close:hover::before,
.share-chat-modal .ant-modal-close:hover::after {
    background-color: #222222;
}

.share-chat-modal .ant-modal-body {
    flex: 1;
    padding: 0 45px 0 28px;
    overflow: auto;
    min-height: 0;
}

.share-chat-content {
    height: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
}

.share-chat-content .ant-input {
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid #ececec;
    background: #fcfcfc;
    height: 56px;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    color: #6e6e70;
}

.share-chat-content .section-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #586570;
    margin-bottom: 8px;
}

.share-chat-content .messages-preview {
    flex: 1;
    border: 1px solid #ececec;
    border-radius: 16px;
    padding: 22px 40px 0 40px;
    background-color: #f6f6f6;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover::-webkit-scrollbar-thumb {
        opacity: 1;
    }

    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &:hover {
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }
}

.share-chat-modal .ant-modal-footer {
    height: 81px;
    min-height: 81px;
    padding: 0 45px 0 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #ececec;
    margin-top: auto;
}

.copy-link-button {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #325ceb !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    height: 46px;
}

.copy-link-button:hover {
    color: #1890ff;
}

.share-chat-modal .ant-modal-footer .ant-btn-primary {
    height: 46px;
    border-radius: 8px !important;
    padding: 13px 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-chat-modal .ant-modal-footer .ant-btn-primary[disabled] {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    cursor: not-allowed;
}

@media (max-width: 575px) {
    .message-header {
        height: 57px;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #dbdcde;
    }

    .message-header .chat-title {
        margin-left: 0;
        margin-right: 0;
    }
}
