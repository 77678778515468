@media (max-width: 575px) {
    .call-sidebar {
        display: none;
    }
}

.fatherStyle {
    padding-left: 16px;
    padding-right: 14px;
    cursor: pointer;
    position: relative;
    margin-top: 10px
}

.collapsedStyleF {
    padding-left: 5px;
    padding-right: 8px;
}

.collapsedStyle {
    background: #D6E2FB;
    border-radius: 3px;
    padding: 2px 12px;
    height: 40px;
    line-height: 32px
}

.IconStyle {

    background: #D6E2FB;
    border-radius: 3px;
    padding: 0 8px 0 8px;
    height: 40px;
    line-height: 38px
}

.rightLoans {
    cursor: pointer;
    position: absolute;
    z-index: 9999999;
    right: -143px;
    top: 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    width: 136px;
    /* height: 74px; */
    padding: 10px 19px;

}

.rightLoans div {
    display: flex;
    align-items: center;
    height: 35px;
    line-height: 35px;
    direction: ltr !important;
    color: #474849;

    font-family: Inter;
    font-size: 16px;
}

.loadTitleP {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #474849;
    margin-left: 8px;
}

.call-sidebar {
    position: relative;
    height: 100vh;
}

.call-sidebar .collapse-btn {
    font-size: 14px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    inset-block-start: 18px;
    inset-inline-end: -13px;
    transition: transform 0.3s;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(25, 15, 15, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}

.call-sidebar .collapse-btn:hover {
    color: rgba(0, 0, 0, 0.65);
    box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.05), 0 2px 8px -2px rgba(25, 15, 15, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.call-sidebar .main-sider {
    height: 100%;
    width: 214px !important;
    min-width: 214px !important;
    max-width: 214px !important;
}

.call-sidebar .ant-layout-sider {
    border-right: 1px solid #DBDCDE;
}

.call-sidebar .main-sider.ant-layout-sider-collapsed {
    width: 64px !important;
    min-width: 64px !important;
    max-width: 64px !important;
}

.call-sidebar .main-sider .ant-layout-sider-children {
    height: 100%;
    overflow-x: hidden;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
}

.call-sidebar .sidebar-logo {
    padding: 26px 14px 0 14px;
}

.call-sidebar .sidebar-logo .logo-box {
    color: #000;
    font-weight: 500;
    padding-bottom: 17px;
    border-bottom: 1px solid #DBDCDE;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.call-sidebar .sidebar-botttom {
    height: 64px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    border-top: 1px solid #DBDCDE;
}

.call-sidebar .sidebar-botttom.sidebar-collapsed-style {
    flex-direction: column;
    justify-content: center;
}

.call-sidebar .sidebar-botttom.sidebar-collapsed-style .header-img {
    width: 26px;
    height: 26px;
}

.call-sidebar .sidebar-botttom .header-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.call-sidebar .sidebar-botttom .load-name {
    font-family: Inter !important;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    line-height: 16px;
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.call-sidebar .sidebar-botttom .logout {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #325CEB;
    cursor: pointer;
}

.call-sidebar .sidebar-botttom .load-email {
    font-family: Inter !important;
    font-size: 12px;
    color: #6E6E70;
    line-height: 14px;
    max-width: 144px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

 .ant-menu-inline .ant-menu-item {
    line-height: 37px;
    height: 37px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu {
    flex: 1;
    font-size: 16px;
    margin-top: 10px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu .ant-menu-item .ant-menu-item-icon+span {
    margin-inline-start: 16px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 64px;
    padding-top: 40px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
    padding-inline: 16px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-inline .ant-menu-item {
    margin-block: 2px;
    margin-inline: -8px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light .ant-menu-item-selected {
    background-color: #FFF;
    color: #325CEB;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .sidebar-icon {
    width: 31px;
    height: 31px;
}

.sidebar-icon {
    width: 24px;
    height: 24px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-vertical>.ant-menu-item {
    height: inherit;
    line-height: inherit;
    margin-block: 0px;
    margin-inline: 0px;
    width: 100%;
    margin-bottom: 15px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #FFF;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: 1px solid #DBDCDE;
    /* overflow-y: auto; */
    direction: rtl;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical::-webkit-scrollbar {
    width: 5px;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.call-sidebar .main-sider .ant-layout-sider-children .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
    width: 2px;
}

.call-sidebar .main-sider .menu-line {
    height: 1px;
    background-color: #DBDCDE;
    margin: 18px 14px;
}

.hamburger-menu {
    background-color: #FFF;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #DBDCDE;
    position: sticky;
    z-index: 1000;
}

.hamburger-menu .zeitro-logo {
    width: 94px;
    height: 29px;
}

.hamburger-menu .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 0.5px solid #ECECEC;
}

.mobileMenu-Drawer .ant-drawer-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
}

.mobileMenu-Drawer .nav {
    height: 30px;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    border-bottom: 1px solid rgb(0 0 0 / 30%);
}

.mobileMenu-Drawer .ant-drawer-header {
    border-bottom: 1px solid rgb(0 0 0 / 30%);
    padding-right: 10px;
}

.mobileMenu-Drawer .ant-drawer-header .ant-drawer-header-title {
    justify-content: end;
    flex-direction: row-reverse;
}

.mobileMenu-Drawer .ant-drawer-content-wrapper {
    height: auto !important;
}
.quick-create-pop .ant-popover-content .ant-popover-inner {
    padding: 0 7px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}
.ant-popconfirm.quick-create-pop .ant-popconfirm-message .ant-popconfirm-description {
    margin-top: 0;
}
.homePage-create-loan-modal .ant-modal-content {
    padding: 0;
}
.homePage-create-loan-modal .ant-modal-content .ant-modal-body .create-loan-modal-content {
    padding: 34px 30px 44px;
}
.homePage-create-loan-modal .ant-modal-content .ant-modal-footer {
    margin-top: 0;
    border-top: 1px solid #ECECEC;
}
.homePage-create-loan-modal .ant-modal-content .ant-modal-footer .create-loan-modal-footer {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 22px;
    gap: 8px;
}
.homePage-create-loan-modal .ant-modal-content .ant-modal-footer .create-loan-modal-footer .create-loan-modal-btn {
    width: 90px;
    height: 44px;
    font-size: 16px;
    color: #325CEB;
    font-family: Inter;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #E3EAFD;
    background-color: #F7F8FF;
}
.quick-create-btn-wrap {
    display: flex;
    align-items: center;
}
.quick-create-btn-wrap.collapsedNormal {
    justify-content: center;
}
.homePage-create-loan-type-modal {
    top: 0;
    padding-bottom: 0;
}
.homePage-create-loan-type-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 10px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body {
    font-family: Inter;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.homePage-create-loan-type-modal .ant-modal-footer {
    height: 70px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ECECEC;
    margin-top: 0;
}
.homePage-create-loan-type-modal .ant-modal-footer .mismo-btn-back {
    width: 90px;
    height: 46px;
    font-family: Inter;
    color: #325CEB;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.homePage-create-loan-type-modal .ant-modal-footer .mismo-btn-confirm {
    width: 90px;
    height: 46px;
    padding: 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 6px !important;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 20px;
    right: 30px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-title {
    height: 64px;
    color: #222;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #ECECEC;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content {
    padding: 34px 30px 124px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .create-loan-type-modal-text {
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 36px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio-label {
    color: #222;
    font-family: Inter;
    font-size: 18px;
    line-height: 20px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio-label.disabled-lable {
    color: #6E6E70;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio {
    line-height: 20px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio .ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 1px solid #325CEB;
    background-color: #FFF;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio span.ant-radio+* {
    padding-inline-start: 12px;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio .ant-radio-disabled .ant-radio-inner {
    border: 1px solid #DBDCDE;
    background-color: #FFF;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio .ant-radio-inner::after {
    background-color: #325CEB;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio .ant-radio-disabled .ant-radio-inner::after {
    background-color: #DBDCDE;
}
.homePage-create-loan-type-modal .ant-modal-content .ant-modal-body .create-loan-type-modal-content .loan-type-radio .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
}