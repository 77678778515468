.los-signin-wrap {
    height: 100vh;
    display: flex !important;
}

.sign-up-box {
    flex: 1;
    display: flex;
    height: 100%;
}
.create-account {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0 30px 0;
}
.create-account-form .ant-form-item {
    margin-bottom: 20px;
}
.create-account-form input {
    background: #FCFCFC;
    border-color: #ECECEC;
    padding: 10.5px 11px;
}
.create-account-form .ant-input-password {
    background: #FCFCFC;
    border-color: #ECECEC;
}
.create-account-form input::placeholder {
    color: #6E6E70;
}
.create-account .create-account-form .ant-form-item .ant-form-item-label>label{
    font-family: Inter;
    height: auto;
    line-height: 17px;
    color: #62707C !important;
    font-size: 14px;
    font-weight: 500 !important;
}
.create-account-form .ant-form-item .ant-form-item-explain-error {
    text-align: left;
}
.create-account-form .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.create-account .sign-up-logo {
    width: 84%;
}
.create-account .company-name {
    font-size: 16px;
    color: #081265;
    font-weight: 700;
    line-height: normal;
    font-family: Inter;
}
.create-account .mobile-nav .company-name {
    font-size: 18px;
}
.create-account .create-copyright-box {
    font-family: Inter;
    font-size: 12px;
    /* border-top: 1px solid #D9D9D9; */
    padding-top: 4px;
}
.create-account .create-copyright-box .create-copyright-tips {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 32px;
}
.create-account .create-copyright-box .create-copyright-tips a {
    color: #325CEB;
    line-height: 20px;
    text-decoration-line: underline;    
}
.create-account .create-copyright-box .create-copyright {
    color: #9A9A9A;
    line-height: normal;
    letter-spacing: 0.2px;
}
.create-account .login-alert {
    width: 100%;
    text-align: center;
    border-radius: 0;
}
.create-account .create-centerBox {
    width: 89.5%;
    max-width: 400px;
    text-align: center;
}
.create-account .mobile-nav {
    height: 52px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #D6D6D6;
    position: sticky;
    top: 0;
    display: none;
}
.create-account .mobile-nav div {
    cursor: pointer;
}
.create-account .create-centerBox .login-title {
    color: #222;
    font-family: Playfair Display;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
}
.create-account .create-centerBox .login-title-tips {
    color: #6E6E70;
    margin: 7px auto 62px;
    width: 70%;
}
.recover-title {
    color: #222;
    font-family: Playfair Display;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;    
}
.create-account .create-centerBox .login-subtitle {
    margin-bottom: 24px;
}
.create-account .create-centerBox .bottom-link {
    color: #222;
    font-family: Open Sans;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-top: 16px;
}
.create-account .create-centerBox .bottom-link div {
    font-family: Inter;
    font-size: 16px;
    color: #6E6E70;
}
.create-account .create-centerBox .bottom-link span {
    cursor: pointer;
    display: inline-block;
    color: #325CEB;
    font-weight: 500;
    margin-left: 8px;
    border-bottom: 1px solid #325CEB;
    line-height: normal;
}
.create-account .create-centerBox .create-btn {
    font-family: Inter;
    font-size: 16px;
    border-radius: 4px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.create-account .create-centerBox .create-btn.sign-zeitro {
    height: 44px;
    color: #FFF;
    background: #325CEB;
    font-weight: 600;
}
.create-account .create-centerBox .other-login {
    border: 1px solid #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
}
.create-account .create-centerBox .sign-google {
    background: #F7F8FF;
}
.create-account .create-centerBox .sign-outlook {
    background: #0072C6;
}
.create-account .create-centerBox .sign-apple {
    background: #000;
}
.create-account .create-centerBox .bottom-link div {
    margin-top: 18px;
}
.create-account .create-centerBox .bottom-link a {
    color: #325CEB;
    font-weight: 600;
    cursor: pointer;
}
.carousel-box {
    width: 51%;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
}
.carousel-box .content-box {
    width: 100%;
    height: 100%;
}
.carousel-box .ant-carousel {
    height: 100%;
}
.create-carousel {
    height: 100%;
    background: #EEF2F9;
}
.create-carousel .create-carousel-1 {
    background-image: url('/images/carousel-img-2.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center bottom 3vw;
}
.create-carousel .center-box {
    width: 78%;
    text-align: center;
}
.create-carousel .center-box div {
    color: #090F1C;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 13px;
}
.create-carousel .create-carousel-2 .center-box {
    width: 100%;
    overflow: hidden;
}
.create-carousel .create-carousel-3 .center-box {
    width: 84%;
}
.create-carousel .create-carousel-3  .carousel-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 3px;
}
.create-carousel .center-box img {
    width: 100%;
    height: auto;
}
.create-carousel .slick-dots li {
    width: 16px;
    display: flex;
    justify-content: center;
}
.create-carousel .slick-dots li button{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #CDCFD5;
}
.create-carousel .slick-dots li.slick-active {
    width: 16px;
}
.create-carousel .slick-dots li.slick-active button {
    background-color: #325CEB;
}
.create-carousel .slick-dots.slick-dots-bottom {
    bottom: 42px;
}
.create-drawer-nav .drawer-btn{
    height: 32px;
    line-height: 1;
    background-color: #325CEB !important;
    color: #FFF !important;
    border-radius: 4px !important;
    font-weight: 500;
    border-color: #325CEB !important;
    font-size: 16px;
}
.create-drawer-nav .drawer-btn {
    margin: 30px auto;
}
.create-drawer-nav  .ant-drawer-body {
    padding: 0;
}

.create-drawer-nav .nav {
    height: 44px;
    padding-left: 24px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.create-drawer-nav .ant-drawer-header-title {
    justify-content: end;
}
.create-drawer-nav .ant-drawer-close {
    margin-inline-end: 0px;
}
.create-account-form .login-operate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}
.create-account-form .login-operate .remember-pwd {
    font-family: Inter;
    color: #222;
}
.create-account-form .login-operate .forget-pwd {
    cursor: pointer;
    display: inline-block;
    font-family: Inter;
    line-height: 21px;
    color: #325CEB;
    font-weight: 500;
    border-bottom: 1px solid #325CEB;
}
.create-account-form .enterPwd-email {
    display: flex;
    justify-content: space-between;
    line-height: 19px;
    margin-top: -18px;
}
.create-account-form .enterPwd-email span:first-child {
    font-family: Inter;
    font-size: 16px;
    color: #222;
}
.create-account-form .enterPwd-email span:last-child {
    font-family: Inter;
    cursor: pointer;
    font-size: 14px;
    color: #325CEB;
    display: inline-block;
    border-bottom: 1px solid #325CEB;
    font-weight: 500;
}
.chooseCompany-modal .chooseCompany-list {
    max-height: 60vh;
    overflow: auto;
}
@media (min-width: 768px) {
    .create-account .create-centerBox {
        width: 80%;
    }
    .carousel-box {
        display: block;
    }
}
@media (min-width: 992px) {
    .create-account .create-centerBox {
        width: 75%;
    }
}
@media (min-width: 1200px) {
    .create-account .create-centerBox {
        width: 65%;
    }
}
@media (max-width: 575px) { 
    .login-title, .login-subtitle {
        text-align: left;
    }
    .sign-up-logo {
        display: none;
    }
    .create-account {
        padding: 0 0 30px 0;
    }
    .create-account .mobile-nav {
        display: inherit;
    }
    .create-account .create-centerBox .login-title-tips {
        width: 100%;
    }
}

.mobile-logo {
    max-width: 30px;
}

.create-account .create-centerBox .footer-link {
    color: #222;
    font-family: Open Sans;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-top: 0;
    margin-bottom: 10px;
}
.create-account .create-centerBox .footer-link a {
    color: #325CEB;
    font-weight: 600;
    cursor: pointer;
}

.create-account .create-copyright{
    color: white !important;
}