.chat-history {
    background-color: #ffffff;
    border-left: 1px solid #dbdcde;
    height: 100vh;
}

.no-chat-text {
    background: #EAEFFD;
    color: #1F2890;
    border-radius: 8px;
    padding: 15px;
    font-size: 14px;
    font-family: Inter;
}

.chat-history-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    height: 60px;
}

.chat-history-header .new-chat-button {
    width: 95px;
    height: 42px;
    padding: 11px;
    color: #ffffff;
    background-color: #325ceb;
    border: 1px solid #ffffff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.chat-history-content {
    flex: 1;
    height: calc(100% - 60px);
    padding: 11px 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        opacity: 1;
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.group-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #6e6e70;
    margin-bottom: 8px;
    padding: 0 12px;
}

.chat-history-title {
    padding: 13px 12px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.chat-history-title:hover {
    background-color: #EEE;
}

.chat-history-current {
    padding: 13px 12px;
    cursor: pointer;
    background-color: #D6E2FB;
    transition: background-color 0.3s ease;
}

.history-item-title {
    color: #222222;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.history-item-type {
    color: #545459;
    font-size: 14px;
    font-weight: 400;
    margin-top: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
    .chat-history {
        border-left: none !important;
    }

    .chat-history-header {
        padding: 0 20px;
        height: 66px;
        border-bottom: 1px solid #dbdcde;
    }

    .chat-history-header .history-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        color: #010101;
    }

    .chat-history-content {
        height: calc(100% - 66px);
        padding: 7px 0;
    }

    .group-title {
        padding: 0 20px;
    }

    .chat-history-title {
        padding: 13px 20px;
    }

    .chat-history-current {
        padding: 13px 20px;
    }
}
