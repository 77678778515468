.auto-reminder-modal {
    top: 0;
    padding-bottom: 0;
}
.auto-reminder-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
    top: 0;
    border-radius: 0;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 64px 60px 0;
    overflow: auto;
}
.auto-reminder-modal .ant-modal-content .ant-modal-footer {
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 0 60px 64px;
}
.auto-reminder-modal .ant-modal-content .reminder-btn-cancel {
    font-family: Inter;
	font-size: 16px;
	color: #325CEB;
    width: 50%;
	font-weight: 600;
	height: 46px;
	border-radius: 6px;
	border: 1px solid #325CEB;
}
.auto-reminder-modal .ant-modal-content .reminder-btn-confirm {
    font-family: Inter;
	font-size: 16px;
	color: #FFF;
    width: 50%;
	font-weight: 500;
	height: 46px;
	border-radius: 6px !important;
	background-color: #325CEB;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-switch {
    display: flex;
    align-items: center;
    gap: 34px;
    color: #222;
    font-family: Inter;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-tip {
    color: #6E6E70;
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 52px;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-form-label {
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-form-subLabel {
    color: #6E6E70;
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
    margin: 15px 0 16px;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-frequency-select {
    width: 378px;
    max-width: 100%;
    height: 56px;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-frequency-select .ant-select-selector {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: normal;
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-frequency-select.ant-select-disabled .ant-select-selector {
    color: #6E6E70;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-input-number .ant-input-number-input {
    font-size: 16px;
    color: #222;
    padding: 7px 11px;
    text-align: center;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-date .ant-picker-input >input {
    font-family: Inter;
    font-size: 16px;
    text-align: center;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio-label {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio-label.disabled-lable {
    color: #6E6E70;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio .ant-radio-inner {
    border: 2px solid #325CEB;
    background-color: #FFF;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio .ant-radio-disabled .ant-radio-inner {
    border: 2px solid #DBDCDE;
    background-color: #FFF;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio .ant-radio-inner::after {
    background-color: #325CEB;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio .ant-radio-disabled .ant-radio-inner::after {
    background-color: #DBDCDE;
}
.auto-reminder-modal .ant-modal-content .ant-modal-body .auto-reminder-content .auto-reminder-radio .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
}