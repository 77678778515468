.history-letter-modal .ant-modal-content {
  width: 85% !important;
}

.prequal-requested-container {
  border-radius: 1em;
  padding: 2em;
  background-color: #fbfbfb;
}

.prequal-requested-header {
  padding: 1em;
  justify-content: center;
}

.prequal-requested-text {
  text-align: center;
  color: #777777;
}
