/* @media (max-width: 800px){
    .actionPanel{
        flex-direction: column;
        gap: 10px;
    }
} */

@media (max-width: 875px) {
    .hide-xs-custom {
        display: none !important;
    }

    #searchForms .ant-tabs-content #searchForms-panel-fast{
        height: 100%;
    }
    #searchForms .ant-tabs-content .fastSectionWrap{
        height: 100%;
    }
    .programEngineBody{
        padding-bottom: 300px;
        height: auto;
    }
    .scenarioSlide .ant-steps-item{
        display: none;
    }

    #searchForms{
        height: calc(100% - 70px) !important;
    }
    .generalDisclaimer{
        margin-top: 20px;
    }
    .halfWidth{
        width: 100%;
    }
    .fastResultItemLayout{
        flex-direction: column;
    }
    .programEngineSearch{
        margin-top: 10px;
    }
    .propertyInfoWrap{
        width: 100% !important;
    }
    .stepContentWrap{
        height: auto !important;
    }
    .scenarioSlide{
        height: auto !important;
    }
    .searchOptionContainer{
        width: 100%;
    }
    .programEngineWrap{
        padding-left: 5px;
        padding-right: 0px;
    }
    .tractInfoPreview{
        width: 100%;
    }
    .mobileScrollControl{
        max-height: 700px;
        overflow-y: auto;
    }
    .headingText{
        display: none;
    }
    .mobileLmargin{
        margin-left: 10px;
    }
    .chooseFliersWrap{
        flex-direction: column;
    }

    .actionPanel{
        flex-direction: column;
        gap: 10px;
    }
    .resultTabPane{
        flex-direction: column;
    }
    .resultInfoWrap{
        width: 100% !important;
        margin-bottom: 20px;
    }
    .flyerMobileView{
        width: 100% !important;
    }
    .fastResultMobileView{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .programEngineWrap input{
        font-size: 16px !important;
    }

}

/* @media (max-width: 1300px){
    .massiveResultWrapMobile{
        height: 600px;
        overflow-y: auto;
    }
} */

.gmcc_table_container .ant-table-wrapper .ant-table-cell, 
.gmcc_table_container .ant-table-wrapper .ant-table-thead>tr>th, 
.gmcc_table_container .ant-table-wrapper .ant-table-tbody>tr>th, 
.gmcc_table_container .ant-table-wrapper .ant-table-tbody>tr>td, 
.gmcc_table_container .ant-table-wrapper tfoot>tr>th, 
.gmcc_table_container .ant-table-wrapper tfoot>tr>td,

.gmcc_table_program_container .ant-table-wrapper .ant-table-cell, 
.gmcc_table_program_container .ant-table-wrapper .ant-table-thead>tr>th, 
.gmcc_table_program_container .ant-table-wrapper .ant-table-tbody>tr>th, 
.gmcc_table_program_container .ant-table-wrapper .ant-table-tbody>tr>td, 
.gmcc_table_program_container .ant-table-wrapper tfoot>tr>th, 
.gmcc_table_program_container .ant-table-wrapper tfoot>tr>td{
    padding-top: 12px;
    padding-bottom: 12px;
}


#resultTabs .ant-tabs-content{
    height: calc(100vh - 250px) !important;
    overflow-y: auto;
    padding: 10px;
    box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
    border-radius: 4px;
}

#advancedFormCarousel .slick-slider .slick-list .slick-track .slick-active{
    z-index: 100;
}

#advancedResultCarousel .slick-slider .slick-list .slick-track .slick-active {
    z-index: 100;
}

#fastFormCarousel .slick-slider .slick-list .slick-track .slick-active{
    z-index: 100;
}

#fastResultCarousel .slick-slider .slick-list .slick-track .slick-active {
    z-index: 100;
}
/* #resultTabs .ant-tabs-content::-webkit-scrollbar {
    width: 50px;
} */

#resultTabs .ant-tabs-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f1f1f1;
}
#resultTabs .ant-tabs-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    width: 2px;
}
#resultTabs .ant-tabs-content::-webkit-scrollbar {
    width: 5px;
}

#searchForms{
    /* height: 100%; */
    width: 100%;
}

#searchForms .ant-tabs-content{
    /* height: calc(100% - 20px); */
}

.programEngineWrap{
    /* background-image: url('/company.png'); */
    /* position: relative; */
    align-items: center;
    height: 100%;
    /* overflow: auto; */
}
.bannerWrap{
    border-bottom: 1px solid #DBDCDE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 64px;
}
.programEngineBody{
    background-color: #F6F6F6;
    height: calc(100% - 64px);
    /* overflow: auto; */
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    /* align-items: center; */
    padding: 5px 20px 10px 20px;
    overflow-y: auto;
}
.programEngineWrap .programCarouselWrap{
    margin-top: 20px;
    background-color: #F7F8FC;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 4px;
    min-height: 90%;
}
.programEngineSearchLong{
    background-color: #325CEB !important;
    border-color: #325CEB !important;
    color: white;
    padding: 10px 20px;
    height: 40px;
    width: 150px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin-top: 10px;
}

.flyerPreviewModal{
    position: static !important;
    width: 50% !important;
}

.listingTableRow:hover{
    background-color: #D6E2FB !important;
    cursor: pointer;
}

.listingTableRow:hover .ant-table-cell-fix-left{
    background-color: #D6E2FB !important;
}

.listingTable .ant-table-cell-fix-left{
    background-color: #ffffff !important;
}

.listingTable .ant-table-thead .ant-table-cell-fix-left{
    background-color: #F7F7F8 !important;
}

.massiveTableFilter{
    height: 42px;
    min-width: 180px;
}

.blueBulb{
    background-color: #b4f0d2;
    border-radius: 20%;
    height: 20px;
    width: 100px;
    font-weight: 500;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
}

.programEngineSearch{
    background-color: #325CEB !important;
    border-color: #325CEB !important;
    color: white;
    padding: 10px 20px;
    height: 100%;
    width: 100px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.propertyInfoWrap{
    background-color: #EAEFFD;
    border-radius: 4px;
    color:#1f3988;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
}
.tractInfoPreview{
    background-color: #EAEFFD;
    border-radius: 4px;
    color:#1f3988;
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    width: 50%;
}
.previewInfoWrap{
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.previewMSA{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.previewMSAInfo{
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}
.populationIncomeWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
}
.previewPopulation{
    width: 50%;
}
.previewIncomeLimit{
    width: 50%;
}
.fastResultCard{
    background-color: #EAEFFD;
    border-radius: 4px;
    color:#1f3988;
    padding: 20px;
    font-size: 16px;
    height: auto;
}
.fastResultLabel{
    text-align: center;
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}
/* .fastResultContent{
    width: 500px;
} */
.resultHeading{
    font-size: 24px;
    font-weight: 600;
    color: #1f3988;
}
.searchOptionContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    background-color: #F6F6F6;
    border-radius: 8px;
    border: 1px solid #EAEFFD;
    padding: 80px 40px;
    width: 65%;
    cursor: pointer;
}
.searchOptionTitle{
    font-size: 36px;
    font-weight: 600;
    color: #1f3988;
    margin-bottom: 20px;
}
.searchOptionText{
    font-size: 18px;
    font-weight: 500;
    color: #6E6E70;
    text-align: center;
}
.searchOptionContainer:hover{
    border: 1px solid #325CEB !important;
}
.mapCarousel{
    margin-left: auto;
    margin-right: auto;
}
.selectChannel{
    display: flex !important;
    flex-direction: column;
    align-items: center;
}
.scenarioSlide{
    background-color: rgb(247,248,252);
    border-radius: 8px;
    border: 1px solid #DBDCDE;
    /* padding: 10px 0px 20px 0px;
    height: calc(100vh - 120px);
    overflow-y: scroll; */
    padding: 10px 0px 0px 0px;
    height: calc(100vh - 105px);
    overflow-y: auto;
}
.scenarioSlide .scenarioTabs .ant-tabs-nav{
    display: none;
}
.slideTitle{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    color:#586570;
}
.result-scrollViewWrap{
    background-color: #DBDCDE;
    padding: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 8px;
}
.result-scrollView{
    display: flex;
    justify-content: start;
    overflow-x: auto;
    width:100%;
    padding-bottom: 10px;
}
.result-scrollView::-webkit-scrollbar {
    /* display: none; */
}
.result-scrollView::-webkit-scrollbar:horizontal {
    height: 12px;
}
.result-scrollView::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.333);
}
.programNotesWrap{
    background-color: #EAEFFD;
    padding: 10px 20px;
    padding-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}
.programEngineWrap .ant-carousel .slick-slider{
    padding-left: 10px;
    padding-right: 10px;
}
.programEngineWrap .ant-carousel .slick-slide[data-index="-1"]{
    padding-right: 5px;
}
.programEngineWrap .ant-carousel .slick-list{
    margin-left: -5px;
}
.downloadFlyerButton{
    background-color: #325CEB !important;
    border-color: #325CEB !important;
    color: white;
    padding: 10px 20px;
    height: 100%;
    width: 200px;
    margin-bottom: 20px;
}
.complianceDisclaimer{
    font-size: 10px;
    font-weight: 500;
    color: grey;
}
.resultInfoWrap{
    width: auto !important;
    margin-right: 20px;
}
.stepContentWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 280px);
    overflow-y: auto;
}
.stepContentWrap::-webkit-scrollbar {
    width: 5px;
}
.stepContentWrap::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
.stepContentWrap::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    width: 2px;
}
.fastCarouselWrap{
    display: flex;
    height: calc(100vh - 300px);
    width: 100%;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}
.fastCarouselWrap::-webkit-scrollbar {
    width: 5px;
}
.fastCarouselWrap::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
.fastCarouselWrap::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    width: 2px;
}


.fastArrow{
    height: 50px;
}
.halfWidth{
    width: 50%;
}
.fastResultItemLayout{
    display: flex;
}
.fastFormLayout{
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}
.checkModeWrap{
    display: flex;
}

.massiveResultContainer{
    border-top: 1px solid #DBDCDE;
    border-bottom: 1px solid #DBDCDE;
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.massiveTargetInfo{
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}

.modeSwitchLeft, .modeSwitchMiddle, .modeSwitchRight {
    background-color: #FCFCFC;
    border: 1px solid #DBDCDE;
    color: #6E6E70;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    width: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 3.5em;
    padding: 0px 5px;
}
.modeSwitchLeft{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.modeSwitchRight{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.modeSwitchLeft:hover{
    border: 1px solid #325CEB;
    color: #325CEB;
}
.modeSwitchMiddle:hover{
    border: 1px solid #325CEB;
    color: #325CEB;
}
.modeSwitchRight:hover{
    border: 1px solid #325CEB;
    color: #325CEB;
}
.modeSwitchLeft-active{
    border: 1px solid #325CEB;
    color: #325CEB;
}
.modeSwitchMiddle-active{
    border: 1px solid #325CEB;
    color: #325CEB;
}
.modeSwitchRight-active{
    border: 1px solid #325CEB;
    color: #325CEB;
}
.programEngineBody .ant-carousel{
    width: 100%;
}
#searchForms .ant-tabs-nav{
    display: none;
}
.checkPaneTitle{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #1f3988;
    margin-top: 5px;
    margin-bottom: 5px;
}
.fastSectionWrap .ant-carousel .slick-list .slick-track .slick-active{
    z-index: 100;
}
.resultPaneHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DBDCDE;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.chooseFliersWrap{
    display: flex;
    justify-content: end;
    align-items: center;
}
.chooseFliers{
    height:25px;
    width: 300px;
}
.downloadFlierLink{
    justify-content: center;
    width: 100px;
    color:#fff;
    margin-left: 10px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #325CEB !important;
    border: 1px solid #325CEB !important;
}
.emailFlierLink{
    text-decoration: underline;
    color:#325CEB;
    font-weight: 500;
}
#resultTabs .ant-tabs-nav{
    display: flex !important;
}
.resultTabsWrap{
    padding-left: 10px;
    padding-right: 10px;
}
.headingText{
    font-size: 18px;
    font-weight: 500;

}
.actionPanel{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.dreCTA{
    font-weight: 500;
    color: #CB8600;
    margin-bottom: 10px;
}

.flyerBtn-disabled{
    background-color: #888 !important;
    border-color: #888 !important;
    color: white !important;
    pointer-events: none;
    width: 100px;
    display: flex;
    justify-content: center;
    margin-left: 10px;
}
.fastCarouselContentBody{
    display: flex;
    justify-content: center;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 300px);
    margin-right: 5px;
    margin-left: 5px;
}
.fastCarouselContentBody::-webkit-scrollbar {
    width: 5px;
}
.fastCarouselContentBody::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
.fastCarouselContentBody::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    width: 2px;
}
.generalDisclaimer{
    width: 100%;
    display: flex;
    justify-content: start;
    color:grey;
    font-size: 12px;
    font-weight: 500;
    padding-left: 5px;
    margin-bottom: 5px;
}
.dreIDInput{
    height: 28px;
    font-size: 12px;
    padding-left: 10px;
    border: 1px solid #DBDCDE;
    border-radius: 4px;
    margin-left: 5px;
    font-weight: 500;
}
.resultHeading-sub{
    font-size: 14px;
    font-weight: 500;
}
.sendFlierInput{
    width: 100%;
    height: 40px;
    border: 1px solid #DBDCDE;
    border-radius: 4px;
}

.matchedSubProgramLabel{
    font-size: 24px;
    font-weight: 500;
    color: green;
}

.massiveResultWrapMobile{
    max-height: 700px;
    overflow-y: auto;
}

.currentInfoPane{
    width: 100%;
    display: flex;
    justify-content: center;
}

.currentInfoWrap{
    color: #1f3988;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #d4ddf7;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.currentInfoItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    width: 100%;
}

.massivePopover .ant-popover-content{
    width: 300px;
}

.realtorInfoWrap{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.realtorInfoWrap .form-control{
    height: 35px !important;
}

.realtorInfoPop{
    display: flex;
    justify-content: center;
}

.realtorInfoBtn{
    border: 1px solid #325CEB;
    background-color: #325CEB;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 30px;
}

.gpsCheckLeftPanel{
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 250px;
    width: calc((100% - 20px)/2);
    margin-right: 20px;
    background-color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpsCheckRightPanel{
    border: 1px solid #ced4da;
    height: 250px;
    border-radius: 4px;
    width: calc((100% - 20px)/2);
    background-color: rgba(255, 255, 255, 0.5);    background-image: url('/images/gmccflyers/mapBackground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comingSoon{
    font-size: 28px;
    font-weight: 600;
    color: #6E6E70
}

.gpsSearchButton{
    background-color: #325CEB;
    border: 1px solid #325CEB;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
}

.gpsCheckTable{
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    min-height: 300px;
}

.gpsCheckHeader{
    font-size: 24px;
    font-weight: 500;
    color: #1f3988;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoPanelWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.tractInfoBox{
    font-size: 14px;
    margin-top: 10px;
    gap: 30px;
}

.tractInfoTags{
    margin-top: 10px;
    gap: 30px;
}

.tractInfoTags div {
    padding:5px 20px 5px 20px;
    border: 1px solid b4f0d2;
    background-color: #D6E2FB;
    border-radius: 10%;
    color: #325CEB;
    font-weight: 500;
}

.tractValueFont{
    font-weight: 600;
    color: black;
    margin-left: 10px;
}

.addressHighlight{
    font-size: 18px;
    color: black;
    font-weight: 600;
}

.msaHighlight{
    font-size: 18px;
    color: #325CEB;
    font-weight: 600;
    width: 120px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpsSlideOne{
    background-color: rgb(247,248,252);
    border-radius: 8px;
    border: 1px solid #DBDCDE;
    padding: 10px 0px 20px 0px;
}

.gpsTableRow:hover{
    background-color: #D6E2FB !important;
    cursor: pointer;
}

.gpsTableRow:hover .ant-table-cell-fix-left{
    background-color: #D6E2FB !important;
}

.preFilterWrap{
    margin-left: 15px !important;
    margin-right: 15px !important;
    border: 1px solid #DBDCDE;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.resetFilters{
    padding-left: 15px;
    margin-top: 10px;
    color: #325CEB;
    cursor: pointer;
}
