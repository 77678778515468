.los-signup-wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.los-signup-wrap .Welcome {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
}
.los-signup-wrap .inter {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #6E6E70;
    margin-top: 10px;
}

.sign-up-content {
    display: flex;
    flex: 1;
    overflow: hidden;
}
.create-los-account {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 0 30px 0;
    flex: 1;
    overflow: auto;
}
.create-account-form .ant-form-item {
    margin-bottom: 20px;
}
.create-account-form input {
    background: #FCFCFC;
    border-color: #ECECEC;
}
.create-account-form input::placeholder {
    color: #6E6E70;
}
.create-los-account .ant-form-large .ant-form-item .ant-form-item-label>label{
    font-family: Inter;
    height: 10px;
    line-height: 17px;
    color: #586570;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 0px;
}
.create-los-account .ant-input-lg {
    height: 42px;
    border-radius: 6px;
    border-color: #ECECEC;
    background-color: #FCFCFC;
    /* color: #6E6E70; */
}

.create-account-form .ant-form-item .ant-form-item-explain-error {
    text-align: left;
}
.create-account-form .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.los-signup-wrap .sign-up-logo {
    border-bottom: 1.5px solid #CCCCCC;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
}
.sign-up-content .create-copyright {
    color: #9A9A9A;
    font-family: Inter;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.2px;
    padding-bottom: 20px;
}
.create-los-account .create-centerBox {
    width: 89.5%;
    text-align: center;
    max-width: 450px;
}
.los-signup-wrap .mobile-nav {
    height: 52px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #D6D6D6;
    position: sticky;
    top: 0;
    display: none;
}
.los-signup-wrap .mobile-nav div {
    cursor: pointer;
}
.create-los-account .create-centerBox .create-title {
    color: #222;
    font-family: Playfair Display;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}
.sign-up-logo .signIn-text {
    color: #6E6E70;
    font-family: Open Sans;
    font-size: 16px;    
}
.sign-up-logo .signIn-text .to-signIn {
    color: #325CEB;
    font-weight: 600;
    cursor: pointer;
}
.create-los-account .create-centerBox .create-btn {
    font-family: Inter;
    font-size: 16px;
    border-radius: 4px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.create-los-account .create-centerBox .create-btn.sign-zeitro {
    height: 44px;
    color: #FFF;
    background: #325CEB;
    font-weight: 600;
    border-radius: 6px;
}
.create-los-account .create-centerBox .create-btn.sign-google {
    height: 46px;
    border: 1px solid #E3EAFD;
    background: #F7F8FF;
}


.los-carousel-box {
    width: 51%;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
}
.los-carousel-box .content-box {
    width: 100%;
    height: 100%;
}
.los-carousel-box .ant-carousel {
    height: 100%;
}
.los-carousel-box .carousel-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 3px;
    color: #222222
}
.create-carousel {
    height: 100%;
    background: #EEF2F9;
}

.create-carousel .center-box {
    width: 78%;
    text-align: center;
}
.create-carousel .center-box div {
    color: #081265;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 13px;
    text-align: left;
}

.create-carousel .carousel-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 3px;
    color: #222222
}
.create-carousel .center-box img {
    width: 100%;
    height: auto;
}
.create-carousel .slick-dots li {
    width: 16px;
    display: flex;
    justify-content: center;
}
.create-carousel .slick-dots li button{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #CDCFD5;
}
.create-carousel .slick-dots li.slick-active {
    width: 16px;
}
.create-carousel .slick-dots li.slick-active button {
    background-color: #325CEB;
}
.create-carousel .slick-dots.slick-dots-bottom {
    bottom: 42px;
}
.create-drawer-nav .drawer-btn{
    height: 32px;
    line-height: 1;
    background-color: #325CEB !important;
    color: #FFF !important;
    border-radius: 4px !important;
    font-weight: 500;
    border-color: #325CEB !important;
    font-size: 16px;
}
.create-drawer-nav .drawer-btn {
    margin: 30px auto;
}
.create-drawer-nav  .ant-drawer-body {
    padding: 0;
}

.create-drawer-nav .nav {
    height: 44px;
    padding-left: 24px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.create-drawer-nav .ant-drawer-header-title {
    justify-content: end;
}
.create-drawer-nav .ant-drawer-close {
    margin-inline-end: 0px;
}
@media (min-width: 1200px) {
    .create-los-account .create-centerBox {
        width: 65%;
    }
}
@media (min-width: 992px) {
    .create-los-account .create-centerBox {
        width: 75%;
    }
}
@media (min-width: 768px) {
    .create-los-account .create-centerBox {
        width: 80%;
    }
    .los-carousel-box {
        display: block;
    }
}
@media (max-width: 575px) { 
    .create-title {
        text-align: left;
    }
    .sign-up-logo {
        display: none;
    }
    .create-los-account .mobile-nav {
        display: inherit;
    }
}

.terms-container {
    line-height: 1.8;
}

.terms-container strong {
    font-weight: 600;
}
.terms-container .bold {
    font-weight: 600;
}

.sign-up-service-agreement-modal {
    top: 0;
    padding-bottom: 0;
}
.sign-up-service-agreement-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 90vh;
    top: 0;
    border-radius: 12px;
}
.sign-up-service-agreement-modal .ant-modal-content .ant-modal-body {
    font-family: Inter;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.sign-up-service-agreement-modal .ant-modal-footer {
    height: 122px;
    text-align: left;
    padding: 12px 28px;
    border-top: 1px solid #ECECEC;
    margin-top: 0;
}
.sign-up-service-agreement-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 28px;
    right: 25px;
}
.sign-up-service-agreement-modal .ant-modal-content .ant-modal-body .service-agreement-title {
    height: 64px;
    color: #222;
    display: flex;
    padding: 26px 28px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #ECECEC;
}
.sign-up-service-agreement-modal .ant-modal-content .ant-modal-body .service-agreement-content {
    padding: 10px 20px 10px 0;
    margin: 10px 28px 20px 28px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-footer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-footer .service-agreement-btn-group {
    display: flex;
    justify-content: space-between;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-footer .service-agreement-btn {
    width: 120px;
    height: 50px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px !important;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-footer .service-agreement-btn.decline {
    color: #325CEB;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-checkbox {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-checkbox .ant-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 24px;
}
.sign-up-service-agreement-modal .ant-modal-footer .service-agreement-checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1.2) translate(-1px, -6px);
}