.creditReportCard.ant-card .ant-card-head {
    background-color: #EAEFFD;
    min-height: 36px;
    font-weight: 500;
    padding-left: 19px;
}

.setUpAccount {
    font-family: Inter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 23px 15px 20px;
    width: 100%;
    border-radius: 6px;
    background: #F8E8D3;
    margin-top: 10px;
    margin-bottom: 15px;

}

.zeitroButton {
    font-size: 16px;
    font-family: Inter;
    border-radius: 6px;
    border: 1px solid #325CEB;
    width: 102px;
    height: 39px;
    background: #FFF;
    color: #325CEB;
    font-weight: 600;
    line-height: normal;
}

.creditReportCard.ant-card .ant-card-body {
    padding: 19px;
}

.creditRportTag {
    margin: 5px 5px 5px 0;
    border-radius: 12px;
    height: 34px !important;
}

.creditRportTag.ant-tag-checkable {
    border-color: #325CEB;
    color: #325CEB;
    padding: 6px 14px;
}

.creditRportTag.ant-tag-checkable-checked {
    background-color: #325CEB;
    color: white;
}

.filterDropdown {
    width: 400px;
    height: 500px !important;
    max-height: 300px !important;
}

.filterDropdown.dropdown-menu.show {
    transform: translate(0px, 40px) !important;
}

.creditAccount {
    border-radius: 6px;
    background: #F5F5F7;
    width: 100%;
    padding: 9px 10px 9px 17px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.ratesDropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 156px;
    border: 1px solid #CFD4D9;
    border-radius: 4px;
    padding: 6px 10px 6px 12px;
    font-weight: 400;
    font-size: 16px;
}

.monthlyPaymentSwitch .ant-switch {
    border-radius: 20px !important;
}

.monthlyPaymentSwitch .ant-switch-handle {
    margin-top: 0px !important;
}

.monthlyPaymentSwitch .ant-switch.ant-switch-checked {
    background-color: #325CEB !important;
    color: #325CEB !important;
}

.CREDIT_SCORESContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;

}

.CREDIT_SCORES {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 223px;
    height: 79px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F5F5F7;
    padding: 15px;
}

/* .ant-popover-inner {
    color: #fff !important;
    border-radius: 6px !important;
    background: rgba(34, 34, 34, 0.90) !important;
} */
.reportScore {
    color: #6E6E70;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 10px;
}

.reportValue {
    color: #222;
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 32px;
}

.factorScore {
    font-family: Inter;
    margin-top: 12px;
    border-radius: 6px;
    background: #F5F5F7;
    width: 100%;
    /* height: 127px; */
    padding: 14px;
}

.FactorsTitle {
    font-family: Inter;
    color: #6E6E70;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    text-indent: 5px;
}

.CreditHistory {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 22px; */
    /* 137.5% */
}

.exclude {
    margin-left: 10px;
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

}

.ant-table-thead {


    background: #F5F5F7 !important;
}

.PopoverTitle {
    color: #586570;


    font-size: 14px;

    font-weight: 500;

}