.loansStageContainer {
  margin-left: 10px;
}
.loansStatusContainer {
  margin-left: 0px;
  margin-right: 10px;
}

.loansStageContainer,
.loansStatusContainer {
  display: flex;
  align-items: center;

  .loansStage {
    border-radius: 6px 0 0 6px;
    border: 1px solid #ececec;
    margin-right: 1px;
  }
  .loansStatus {
    border-radius: 0 6px 6px 0;
    border: 1px solid;
  }

  .loansStatus,
  .loansStage {
    display: flex;
    align-items: center;
    background-color: white;
    color: #222222;
  }

  .loansStatus .ant-tag,
  .loansStage .ant-tag {
    width: 100%;
    font-weight: 600;
    padding: 5px 6px;
    margin-right: 0px !important;
    background-color: white;
    color: #222222;
    border-radius: 0 !important;
  }

  .loansStage .ant-tag {
    border-radius: 6px 0 0 6px !important;
  }

  .loanStatusSelect,
  .loanStageSelect {
    width: 2em;
    background-color: white;
    border: none !important;
  }

  .loansStatus .ant-btn-default,
  .loansStage .ant-btn-default {
    border-radius: 0 6px 6px 0;
    padding: 0 6px;
    border: 1.2px solid #ececec;
    background-color: #fcfcfc;
  }

  .loansStatus:hover,
  .loansStage:hover {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  }

  .loansStatus .ant-tag.ant-tag-has-color,
  .loansStage .ant-tag.ant-tag-has-color {
    color: #222222;
  }
}

.loansStageContainer,
.loansStatusContainer {
  .loansStatus {
    border-radius: 0 6px 6px 0;
    .ant-select {
      border-radius: 0 6px 6px 0;
      width: 15em !important;
    }
  }
  .loansStage {
    border-radius: 6px 0 0 6px;
    .ant-select {
      border-radius: 6px 0 0 6px;
      width: 10em !important;
    }
  }

  .loansStatus,
  .loansStage {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }

    .ant-select {
      height: 2.5em !important;
    }

    .ant-select-selector {
      border: none !important;
      background-color: inherit !important;
      color: inherit !important;
      .ant-select-selection-item {
        font-size: 12px;
        font-weight: 600;
        color: inherit !important;
      }
      :hover {
        border: none !important;
      }
      :focus {
        border: none !important;
      }
    }
  }
}
