.button-custom {
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  margin: 5px;
  color: var(--primary-color-text);
  font-size: 1.2rem !important;
  border-radius: 4px;
}
.button-custom:hover,
.button-custom.active {
  background-color: var(--primary-color-2) !important;
  cursor: pointer;
  color: var(--primary-color-2-text) !important;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}
.hover:hover {
  cursor: pointer;
  color: var(--secondary-color);
}
.buttons-grouping-panel {
  background-color: var(--primary-pale);
  margin: 0.7rem 0.7rem 0;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start
}
.validationWrongField {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  border-color: #dc3545 !important;
}
.validationWrongField:focus {
  -webkit-box-shadow: 0px 0px 0px 3px rgba(220, 53, 69, 0.3) !important;
  -moz-box-shadow: 0px 0px 0px 3px rgba(220, 53, 69, 0.3) !important;
  box-shadow: 0px 0px 0px 3px rgba(220, 53, 69, 0.3) !important;
}
/*hacks for Contact Customer email text field component*/
.public-DraftEditor-content {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}
.rdw-editor-main {
  background-color: white;
  border: 1px solid lightgrey;
}
/*end of hack*/
.custom-bootstrap-validated-dropdown-fix {
	background-position: right calc(.75em + .1975rem) center !important;
}
.nav-item{
  font-size: 0.8rem;
}

/* fixes for bootstrap button group */
.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0!important;
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-primary
{
  background-color: #325ceb !important;
	border-color: #325ceb !important;
	color: var(--primary-color-2-text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* end of fix */

.flex-column-align-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}