.over-view {
    padding: 24px 3vw;
}
.over-view .over-view-address {
    color: #333;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
}
.over-view .over-view-topInfo {
    display: flex;
    align-items: center;
    gap: 40px;
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    margin-bottom: 40px;
}
.over-view .over-view-topInfo .status-select {
    height: 36px;
}
.over-view .over-view-topInfo .status-select.ant-select-single .ant-select-selector {
    color: #08979C;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #E6FFFB;
    background-color: #E6FFFB;
}
.over-view .over-view-topInfo .status-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-inline-end: 44px;
}
.over-view .over-view-topInfo .status-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border: 1px solid transparent;
}
.over-view .over-view-topInfo > div span:first-child  {
    margin-right: 6px;
}
.over-view .over-view-topInfo > div span:last-child {
    font-weight: 500;
}
.over-view .module-title {
    color: #6E6E70;
    font-family: Roboto;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 25px;
}
.over-view .general {
    margin-bottom: 56px;
}
.over-view .general .general-row {
    width: 80%;
    color: #222;
    font-family: Inter;
    display: flex;
    gap: 20%;
    line-height: normal;
}
.over-view .general .general-row > div {
    margin-bottom: 35px;
}
.over-view .general .general-row > div div:first-child {
    font-weight: 600;
    margin-bottom: 6px;
}
.over-view .general .general-row > div:first-child {
    width: 50%;
}
.over-view .team .role-list {
    display: flex;
    flex-wrap: wrap;
}
.over-view .team .role-list .role-list-item {
    display: flex;
    align-items: center;
    color: #222;
    font-family: Inter;
    flex: 0 0 50%;
    margin-bottom: 25px;
}
.over-view .team .role-list .role-list-item .role-avatar {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #F2F4F8;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.over-view .team .role-list .role-list-item .role {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 6px;
}
.over-view .team .role-list .role-list-item .name {
    font-size: 16px;
    line-height: normal;
}
.over-view .over-view-content {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}
.over-view .over-view-content .application-status {

}
.over-view .over-view-content .application-status .status-title {
    font-size: 14px;
    line-height: 20px;
    color: #222;
    font-weight: 500;
    margin-bottom: 25px;
}

.over-view .over-view-content .application-status .status-title a {
    margin-left: 10px;
    color: #1677ff;
    font-weight: 400;
}

.over-view .over-view-content .application-status .status-list {

}
.over-view .over-view-content .application-status .status-list .status-item {
    font-size: 14px;
    padding: 0 0 18px 38px;
    border-left: 1px solid #DDE1E6;
    position: relative;
    line-height: 1.1;
}
.over-view .over-view-content .application-status .status-list .status-item .operate {
    font-size: 13px;
    padding: 10px 0 4px 0;
}
.over-view .over-view-content .application-status .status-list .status-item .operate span {
    padding: 0 8px;
    cursor: pointer;
}
.over-view .over-view-content .application-status .status-list .status-item .operate span:not(:last-child) {
    border-right: 1px solid #325CEB;
}
.application-status .status-list .status-item .status-tag {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #DDE1E6;
    position: absolute;
    left: -8px;
    top: 0;
}
.over-view .over-view-content .application-status .status-list .status-item:last-child {
    padding: 0 0 0 38px;
}
.over-view .over-view-content .my-loan-terms {
    width: 390px;
    border-radius: 8px;
    background: #F4F7FE;
    padding: 0 15px;
}
.over-view .over-view-content .my-loan-terms .loan-terms-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    border-bottom: 1px solid #FFF;
}
.over-view .over-view-content .my-loan-terms .loan-terms-title span:first-child {
    font-weight: 500;
}
.over-view .over-view-content .my-loan-terms .loan-terms {
    padding: 14px 0;
    border-bottom: 1px solid #FFF;
}
.over-view .over-view-content .my-loan-terms .loan-terms .loan-terms-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    padding: 0 10px;
}
.over-view .over-view-content .my-loan-terms .loan-terms .loan-terms-item span:last-child {
    font-weight: 500;
}
.over-view .over-view-content .my-loan-terms .loan-terms:last-child {
    border-bottom: none;
}
.over-view .loan-terms .confirm-loan-title {
    display: flex;
    justify-content: space-between;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 15px;
    margin-bottom: 8px;
}
.over-view .loan-terms .confirm-loan-title .status {
    color: #00BE63;
}
.over-view .loan-terms .loan-terms-card {
    /* width: 322px;
    max-width: 90%; */
}
.over-view .loan-terms .loan-terms-card .loan-terms-item {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    padding: 18px 26px;
    border-radius: 8px;
    margin-bottom: 5px;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item > div {
    display: flex;
    justify-content: space-between;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item > div:not(:last-child) {
    margin-bottom: 12px;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item > div > div {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-align: right;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item .explain {
    font-size: 11px;
    font-weight: 500;
    color: #979797;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item.top {
    background-color: #F4F7FE;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item.middle {
    background-color: #E7FFF4;
}
.over-view .loan-terms .loan-terms-card .loan-terms-item.bottom {
    background-color: rgba(234, 234, 234, 0.60);
}
.over-view .loan-terms .loan-terms-mode {
    display: flex;
    align-items: center;
    margin-top: 14px;
    justify-content: end;
}
.over-view .loan-terms .loan-terms-mode span {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: normal;
}
@media (max-width: 767px) {
    .over-view .over-view-content {
        flex-direction: column;
    }
    .over-view .over-view-content .application-status {
        margin-bottom: 24px;
    }
    .over-view .over-view-content .my-loan-terms {
        width: 100%;
    }
}