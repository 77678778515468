.personal-info-container {
    min-width: 256px;
    width: 256px;
    flex-shrink: 0;
}

.personal-info-card {
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.personal-info-card .ant-card-body {
    padding: 20px;
}

.personal-info-avatar {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 0 auto 9px;
    display: block;
}

.personal-info-name {
    font-family: 'Playfair Display';
    font-size: 24px;
    font-weight: 400;
    line-height: 31.99px;
    color: #1630ac;
    padding-left: 16px;
    margin-bottom: 12px;
}

.personal-info-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 20px;
    padding-left: 16px;
}

.personal-info-row {
    display: flex;
    align-items: center;
}

.personal-info-verified-tag {
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: #e1f2e2;
    color: #6e6e70;
    font-size: 8px;
    font-weight: 500;
    line-height: 14px;
    padding: 2px 5px;
    border-radius: 20px;
    border: none;
}

.personal-info-verified-icon {
    width: 11px;
    height: 12px;
}

.personal-info-nmls,
.personal-info-company,
.personal-info-languages {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #6e6e70;
}

.personal-info-nmls-number,
.personal-info-company-name,
.personal-info-languages-list {
    color: #222222;
    font-size: 14px;
    font-weight: 500;
}

.personal-info-contact {
    width: 100%;
    background-color: #f7f8ff;
    border: 1px solid #eaeffd;
    border-radius: 12px;
}

.personal-info-contact-space {
    width: 100%;
    padding: 12px 8px 18px 13px;
}

.personal-info-contact-item {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.personal-info-contact-item img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
    flex-shrink: 0;
}

.personal-info-contact-item span {
    flex: 1;
    min-width: 0;
    word-wrap: break-word;
    white-space: normal;
}

.personal-info-buttons {
    width: 100%;
    margin-top: 16px;
    padding: 0 16px;
}

.personal-info-contact-btn {
    width: 100%;
    background-color: #1630ac !important;
    border-radius: 8px !important;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
}

.personal-info-prequalify-btn {
    width: 100%;
    border: 1px solid #1630ac;
    color: #1630ac;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
}

.personal-info-social {
    display: flex;
    gap: 32px;
    margin-top: 22px;
    padding: 0 16px;
}

.personal-info-social-link img {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.personal-info-social-link img:hover {
    opacity: 0.8;
}
