.share-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
}

.share-chat-logo {
    width: 94px;
    height: 29px;
    margin-bottom: 23.49px;
}

.share-chat-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    color: #222222;
    margin-bottom: 12px;
}

.share-chat-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #6e6e70;
}

.share-chat-divider {
    width: 100%;
    height: 1px;
    background-color: #dbdcde;
    margin-top: 16px;
    margin-bottom: 16px;
}

.share-chat-container {
    width: 50% !important;
}

.share-chat-messages {
    flex: 1;
    overflow: auto;
}
