.preappModalTitle {
	font-weight: 500;
	font-size: 24px;
}
.prequalBigText {
	font-family: 'Inter' !important;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 42px;
}

.prequalSmallText {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}
.chooseCompany {
	width: 100%;
    border: 1px solid #ececec;
    border-radius: 4px;
    padding: 10px 20px;
    margin: 10px 0;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
	cursor: pointer;
}
@media (max-width: 480px) {
	.preappModalTitle {
		font-weight: 500;
		font-size: 20px;
	}
}