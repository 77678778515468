.application-form .form-title {
    color: #6E6E70;
    font-size: 20px;
    margin-bottom: 30px;
}
.application-form .form-sub-title {
    color: #6E6E70;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
}
.application-form .ant-form-item {
    margin-bottom: 30px;
}
.application-form .ant-form-item-label {
    padding: 0 0 10px;
}
.application-form .ant-form-item-label >label {
    height: 20px !important;
    color: #222;
    font-weight: bold;
}
.application-form .common-btn {
    width: 188px;
    height: 44px;
    font-size: 16px;
    color: #FFF;
    font-weight: bold;
    background-color: #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}
.ant-input:hover, .ant-input:focus {
    border-color: #325CEB;
}

.ant-input-number:hover, .ant-input-number:focus {
    border-color: #325CEB;
}

.application-form .common-btn.disabled {
    background-color: #D9D9D9;
}
.ant-input-lg {
    padding: 10.5px 11px;
    border-radius: 4px;
}
.ant-picker-large {
    border-radius: 4px;
}
.ant-select-lg .ant-select-selector{
    height: 44px !important;
}
.ant-select-lg .ant-select-selector .ant-select-selection-item {
    /* line-height: 42px !important; */
}
.ant-picker:hover, .ant-picker-focused {
    border-color: #325CEB;
}
.ant-input-number-lg input.ant-input-number-input {
    height: 42px;
}
