.settings-main-content {
    margin-top: 38px;
}
.settings-main-content .settings-main-subtitle {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.2px;
}
.settings-main-content .settings-main-line {
    margin-top: 18px;
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
}
.settings-main-content .add-account-btn {
    width: 96px;
    height: 40px;
    color: #325CEB;
    border-color: #325CEB;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.settings-main-content .no-account-text {
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-indent: 20px;
    margin-top: 16px;
}
.settings-main-content .account-item {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 16px;
    margin: 30px 0 26px;
}
.settings-main-content .account-item .account-item-operate {
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
}
.settings-bottom-bar {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 54px;
    border-top: 1px solid #D9D9D9;
}
.settings-bottom-bar .settings-bottom-saveBtn {
    font-family: Inter;
    width: 122px;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px !important;
}
.VendorDetail .VendorDetail-item {
    font-family: Inter;
    font-size: 16px;
    line-height: 16px;
    display: flex;
}
.CheckBoxBureau .credit-pull-checkBox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}
.CheckBoxBureau .CheckBoxBureau-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.CheckBoxBureau .CheckBoxBureau-content .bureau-name {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
}
.CheckBoxBureau.bureau-checked {
    border: 1px solid #325CEB;
}
.credit-account-modal .ant-modal-content {
    padding: 0;
}
.credit-account-modal .ant-modal-content .ant-modal-header {
    padding: 26px 28px 16px;
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 0;
}
.credit-account-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #222;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-account-btn {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    width: 90px;
    height: 46px;
    line-height: normal;
    border-radius: 6px !important;
}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-account-cancel {

}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-account-save {
    
}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-vendor-select .ant-select-selector {
    color: #545459;
    font-family: Inter;
    font-size: 16px;
    border: 1px solid #ECECEC;
    background: #FCFCFC;
}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-vendor-input {
    color: #545459;
    font-family: Inter;
    font-size: 16px;
    border: 1px solid #ECECEC;
    background: #FCFCFC;
    border-radius: 6px;
}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-account-form-item {
    margin-bottom: 26px;
}
.credit-account-modal .ant-modal-content .ant-modal-body .credit-account-form-item .ant-form-item-label {
    color: #586570;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 0 0 10px;
}