.setupContainer {
    padding: 20px;
}

.landingPageConfig {
    display: flex;
    margin-left: 10px;
}

.landingPagePreview {
    padding-left: 10px;
    text-align: left;
}

.pac-container {
    z-index: 999999999;
}

.landingPagePreviewTitle {
    text-align: left;
    font-size: 25px;
    padding-left: 10px;
    font-weight: 700;
    display: flex;
    margin-top: 5px;
}

.btn-landingPageConfig {
    background-color: #01183c;
    color: white;
    margin-right: 10px;
    font-size: 14px;
}

.btn-landingPageConfig:hover {
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.firstTimeWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
}

.firstTimeTitle {
    color: #000;
    font-family: ABC Ginto Nord Trial;
    max-width: 934px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    padding: 10px 76px;
}

.firstTimeCarousel .slick-dots li {
    width: 16px;
    display: flex;
    justify-content: center;
}

.firstTimeCarousel .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D9D9D9;
}

.firstTimeCarousel .slick-dots li.slick-active {
    width: 16px;
}

.firstTimeCarousel .slick-dots li.slick-active button {
    background-color: #4285F4;
}

.firstTimeCarousel .slick-dots.slick-dots-bottom {
    bottom: -28px;
}

.firstTimeWrap .firstTimeBtn {
    font-size: 14px;
    color: #FFF;
    line-height: 20px;
    padding-inline-start: 16px !important;
    padding-inline-end: 16px !important;
    background-color: #4285F4 !important;
    border-radius: 4px !important;
}

.firstTimeTips {
    color: rgba(0, 0, 0, 0.80);
    font-size: 12px;
    line-height: 16px;
    margin-top: 16px;
}

/* add */
.EditModuleModal .ant-modal-content {
    padding: 60px 36px 64px;
    font-family: ABC Ginto Normal;
    border-radius: 0;
}

.EditModuleModal .ant-modal-content .moduleTitle {
    font-family: Inter;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 24px;
}

.EditModuleModal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.EditModuleModal .ant-modal-content .btn {
    font-size: 16px;
    height: 50px;
    width: 134px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditModuleModal .ant-modal-content .btn.moduleSave {
    background-color: #325CEB;
    color: #FFF;
}

.EditModuleModal .ant-modal-content .btn.moduleCancel {
    color: #325CEB;
    background-color: #F7F8FF;
    border: 1px solid #E3EAFD;
}

.EditModuleModal.add-lenders-modal {
    top: 0;
    padding-bottom: 0;
    height: 100%;
}
.EditModuleModal.add-lenders-modal .ant-modal-content {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.configure-box {
    color: #000;
    font-family: ABC Ginto Nord Trial;
    text-align: left;
    margin-bottom: 60px;
}

.configure-box .web-url {
    color: #000;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
}

.configure-box .web-url img {
    margin: 0 20px 0 8px;
    cursor: pointer;
}

.configure-box .web-url span:last-child {
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}

.configure-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 8px;
}

.configure-progress {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.configure-progress .num {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin: 0 20px 0 16px;
}

.configure-progress .publish-btn {
    width: 134px;
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
    background-color: #0C67FF !important;
    border-radius: 4px !important;
}

.configure-progress .preview-btn {
    width: 134px;
    font-size: 14px;
    line-height: 20px;

    border-radius: 4px !important;
    margin-left: 20px;
}

.configure-progress .publish-btn:disabled {
    background-color: #B8B8B8 !important;
}

.ModuleButton {
    display: flex;
    justify-content: space-between;
}

.ModalButton {
    display: flex;

}

.gray_background {
    background-color: #d6d6d6;
    padding: 30px;
}

.company {
    margin: -30px 0px 0 0px;
    background-color: #d6d6d6;
    padding: 0 30px 30px 30px;
}

.configure-subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    width: 56%;
}

.configure-progress .ant-progress .ant-progress-bg {
    height: 12px;
}

.configure-progress .ant-progress.ant-progress-status-success .ant-progress-bg {
    background-color: #34A853 !important;
}

.module-box {
    border-radius: 4px;
    background: #F6F6F6;
    text-align: left;
}

.module-box .module-title {
    color: #000;
    font-family: ABC Ginto Nord Trial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 18px;
}

.module-box .module-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 18px;
    margin-left: 15px;
}

.module-box .module-btn .ant-btn {
    background-color: #0C67FF;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.module-box .module-btn .ant-btn:hover {
    color: #FFF !important;
    background-color: #0C67FF;
}

.calculators-box {
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #ECECEC;
    /* margin-bottom: 60px; */
    position: relative;
    box-shadow: 0px 2px 8px 0px rgba(31, 57, 136, 0.06);
}

.calculators-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.80);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}

.calculators-mask div {
    width: 60%;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
}

.landing-setup {
    height: 100vh;
    overflow-y: auto;

    /* Webkit */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Firefox */
    scrollbar-width: none;

    /* IE */
    -ms-overflow-style: none;
}

.module-box .module-btn .ant-btn:not(:first-child) {
    display: none;
}

.choose-version-modal .check-group-box-with-image img {
    max-width: calc(90% - 39px);
    height: auto !important;
}

.EditCol {
    display: flex;
    overflow: hidden;

}

.index_com {
    background: #fff;
}

.module_pc .module-manage {
    padding: 11px 0 16px 20px;
    font-family: Inter;
}
.module_pc .module-manage .form-divider {
    width: calc(100% + 107px);
    height: 1px;
    background-color: #DBDCDE;
    margin: 40px -53.5px;
    position: relative;
}
.module_pc .module-manage .module-manage-top {
    margin-bottom: 21px;
}
.module_pc .module-manage .module-manage-top .module-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.module_pc .module-manage .module-manage-top .module-manage-title {
    font-size: '16px';
    font-weight: 600;
    line-height: '20px';
    color: '#222222';
    margin-bottom: '5px';
}
.module_pc .module-manage .module-manage-top .module-info-operate {
    font-size: '14px';
    font-weight: 400;
    line-height: '32px';
    color: '#6E6E70';
}

.module_pc .module-manage .module-manage-top .module-info-operate .module-info-fill-btn{
    color: #222;
    font-size: 16px;
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    margin-right: 16px;
}
.module_pc .module-manage .module-manage-subTitle {
    font-size: 14px;
    color: #6E6E70;
    margin-bottom: 14px;
}
.module_pc .module-manage .manage-card-list {
    padding: 12px 4px 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.module_pc .module-manage .manage-card-list .manage-card-item {
    color: #000;
    height: 58px;
    background-color: #FCFCFC;
    border-radius: 6px;
    border: 1px solid #ECECEC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px 0 12px;
}

.module_pc .module-manage .manage-card-list .manage-card-item .card-name {
    line-height: 32px;
    display: flex;
    align-items: center;
}
.module_pc .module-manage .manage-card-list .manage-card-item .card-name .icon-box {
    margin-right: 15px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.module_pc .module-manage .manage-card-list .manage-card-item .card-name span {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
}
.module_pc .module-manage .manage-card-list .manage-card-item .setting-bar {
    display: flex;
    align-items: center;
}
.module_pc .module-manage .manage-card-list .manage-card-item .card-name .add-lenders {
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    background-color: #325CEB;
    height: 35px;
    width: 114px;
    border-radius: 6px;
    border: 1px solid #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
}
.module_pc .module-manage .manage-card-list .manage-card-item .setting-bar .setting-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 13px;
}
.published-bar {
    display: flex;
    align-items: center;
}
.published-bar .url-wrap {
    display: flex;
    align-items: center;
    margin-right: 11px;
    min-height: 30px;
    padding: 5px 10px;
    border-radius: 10px;
    color: #6E6E70;
    font-size: 12px;
    font-weight: 400;
    background-color: #F6F6F6;
}
.published-bar .url-wrap .url-link {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
.published-bar .url-wrap .url-text {
    overflow-x: auto;
    scrollbar-width: none;
}
.published-bar .url-wrap .url-copy-btn {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: 10px;
}
.published-bar .preview-btn {
    border: 1px solid #E3EAFD;
    border-radius: 6px;
    width: 84px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7F8FF;
    color: #325CEB;
    font-size: 14px;
    font-weight: 600;
    margin-right: 11px;
}
.published-bar .publish-btn {
    border-radius: 6px;
    width: 84px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #325CEB;
    color: #FFF;
    border: none;
    font-size: 14px;
    font-weight: 600;
}
.published-bar .preview-btn:disabled,
.published-bar .publish-btn:disabled {
    background-color: #D9D9D9;
}

.website-tooltip {
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #222222;
    padding: 10px 10px;
    gap: 10px;
}

.module-progress-bar {
    display: flex;
    height: 22px;
    width: 60%;
    margin-top: 14px;
    margin-left: 5px;
}
.module-progress-bar .step-bar-item {
    display: flex;
    align-items: center;
    margin-right: 7px;
    gap: 7px;
    flex: 1;
}
.module-progress-bar .step-bar-item .step-num {
    font-weight: 600;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #D9D9D9;
}
.module-progress-bar .step-bar-item .step-name {
    color: #6E6E70;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.module-progress-bar .step-bar-item .step-num.current-num {
    background-color: #325CEB;
}
.module-progress-bar .step-bar-item .step-name.current-name {
    color: #325CEB;
}
.module-progress-bar .step-bar-item .step-separate {
    flex: 1;
    height: 2px;
    background-color: #DDD;
}
.configure-website-modal .ant-modal-content .configure-website-content .configure-website-content-url .configure-publish-btn {
    font-size: 16px;
    font-weight: 600;
    color: #FFF;
    background-color: #325CEB;
    height: 46px;
    padding: 0 30px;
    border-radius: 6px;
}
.module-progress-bar .open-tab-btn {
    color: #325CEB;
    font-weight: 600;
    margin-right: 10px;
}
.module-progress-bar .open-tab-btn span {
    text-decoration-line: underline;
}
.module-progress-bar .website-url span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #333;
}
.module-progress-bar .website-url img {
    cursor: pointer;
}
.module-progress-bar .current-module {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    color: #000;
}
.module-progress-bar .left-progress {
    flex: 1;
    margin: 0 16px;
}
.module-progress-bar .current-module-percent {
    font-size: 14px;
    font-weight: 700;
    color: #333;
    margin-right: 16px;
}
.module-progress-bar .publish-module {
    font-size: 16px;
    font-weight: 600;
    background-color: #0C67FF;
    color: #FFF;
    width: 110px;
    height: 32px;
    padding: 0;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.module-progress-bar .publish-module.publish-disable {
    background-color: #D9D9D9;
}
.module-progress-bar .publish-module.publish-disable:hover {
    color: #FFF;
}
.module_pc .browser-top {
    height: 20px;
    display: flex;
    align-items: center;
}
.ant-dropdown.publish-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: #D6E2FB;
}
.ant-dropdown.publish-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    font-size: 16px;
    color: #222;
}
.module-progress-bar .publish-module:disabled {
    background-color: #D9D9D9;
}
.module_pc .module-progress-wrap {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 200px;
}
.module_pc .module-progress-wrap::-webkit-scrollbar {
    display: none;
}
.configure-website-modal .ant-modal-content {
    font-family: Inter;
    padding: 0;
}
.configure-website-modal .ant-modal-content .configure-website-title {
    padding: 26px 30px 14px;
    color: #222;
    border-bottom: 1px solid #ECECEC;
}
.configure-website-modal .ant-modal-content .configure-website-title-main {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 6px;
}
.configure-website-modal .ant-modal-content .configure-website-title-sub {
    font-size: 16px;
    line-height: 20px;
}
.configure-website-modal .ant-modal-content .configure-website-content {
    padding: 30px;
}
.configure-website-modal .ant-modal-content .configure-website-content .configure-website-content-url {
    display: flex;
    align-items: center;
}
.configure-website-modal .ant-modal-content .configure-website-content .configure-website-content-url .url-wrap {
    flex: 1;
    padding: 0 20px;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    border-radius: 6px;
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
    display: flex;
    align-items: center;
}
.configure-website-modal .ant-modal-content .configure-website-content .configure-website-content-url .url-copy-btn {
    margin: 0 26px 0 15px;
    cursor: pointer;
}
.module_pc .module-progress-wrap .module-manage-collapse {
	border: none;
    border-radius: 0;
    background-color: transparent;
}
.module_pc .module-progress-wrap .module-manage-collapse>.ant-collapse-item {
    background-color: #FFF;
	margin-bottom: 13px;
    overflow: hidden;
    border-radius: 8px;
    border: none;
}
.module_pc .module-progress-wrap .module-manage-collapse>.ant-collapse-item .ant-collapse-header {
    height: 44px !important;
    border-radius: 8px !important;
    padding: 0 31px 0 20px;
    display: flex;
    align-items: center;
}
.module_pc .module-progress-wrap .module-manage-collapse .ant-collapse-content {
    border-top: 1px solid #DBDCDE;
}
.module_pc .module-progress-wrap .module-manage-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 44px 53.5px;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header {
	display: flex;
	align-items: center;
    justify-content: space-between;
	font-family: Inter;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header .module-name-wrap {
    display: flex;
    align-items: center;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header .module-name-index {
	font-size: 16px;
    font-weight: 500;
	line-height: 20px;
	color: #6E6E70;
    margin-right: 26px;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header .module-name {
	font-size: 16px;
    font-weight: 600;
	line-height: 20px;
	color: #222;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header .module-completed {
	display: flex;
	align-items: center;
    margin-right: 23px;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header .module-completed .module-progress .ant-progress-inner {
    font-size: 14px !important;
    font-weight: 700 !important;
    width: 30px !important;
    height: 30px !important;
}
.module_pc .module-progress-wrap .module-manage-collapse .module-collapse-header .module-completed .module-completed-num {
	margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
}
.module_pc .module-progress-wrap .module-manage-collapse .ant-collapse-expand-icon .collapse-icon {
    transition: all .3s;
    cursor: pointer;
}
.module_pc .module-progress-wrap .module-manage-collapse .ant-collapse-expand-icon .collapse-icon.expand {
    transform: rotate(180deg);
}
.module_pc .module-progress-wrap .module-manage-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
    flex: 1;
}

.EditUrlTipPopover .ant-popover-content{
    background-color: rgba(0,0,0,0.88);
    width: 400px;
}

.EditUrlTipPopover .ant-popover-inner{
    background-color: rgba(0,0,0,0.88);
}

.EditUrlTipPopover .ant-popover-inner-content{
    color: white;
}

@media (max-width:575px) {
    .module_pc .module-progress-wrap {
        height: calc(100vh - 50px);
        padding-bottom: 50px;
    }
    .module-progress-bar {
        padding: 0;
    }
}

@media (max-width:600px) {
    /* .module_pc {
        display: none;
    } */

    .calculators-box {
        display: none;
    }
    .module-box .module-btn .ant-btn:not(:first-child) {
        display: inherit;
    }
}

@media (min-width: 600px) {
    .module_mobile {
        display: none;
    }
}

@media (max-width: 991px) {
    .module_pc .module-manage {
        padding: 8px 16px 16px 16px;
    }
    .module-progress-bar {
        padding: 0 16px;
    }
}

@media (max-width: 1350px) {
    .EditCol {
        flex-direction: column;
        width: 100%;

    }
    .about-me-new {
        width: 100% !important;
    }
}

@media (min-width: 1px) {
    .EditCol .basic-info-new {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px
    }
    .EditCol .about-me-new {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px
    }
}

@media (max-width: 767px) {
    .gray_background {
        padding: 15px;
    }
    .EditModuleModal .ant-modal-content {
        padding: 60px 24px 40px;
    }
}