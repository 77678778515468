.documents-verification .document-preview-bottom-bar {
    height: 48px;
    width: 330px;
    border-radius: 8px;
    color: #FFF;
    background: rgba(0, 0, 0, 0.80);
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -165px;
    display: flex;
    align-items: center;
}
.documents-verification .document-preview-bottom-bar .document-preview-pagination {
    flex: 1;
    padding: 9px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #FFF;
}
.documents-verification .document-preview-bottom-bar .document-preview-pagination .ant-pagination-prev button, .documents-verification .document-preview-bottom-bar .document-preview-pagination .ant-pagination-next button {
    color: #FFF;
}
.documents-verification .document-preview-bottom-bar .document-preview-pagination .ant-pagination {
    color: #FFF;
}
.documents-verification .document-preview-bottom-bar .document-preview-pagination .ant-pagination .ant-pagination-slash {
    margin-inline-start: 0;
    margin-inline-end: 0;
}
.documents-verification .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: rgba(0, 0, 0, 0.90);
    width: 40px;
}
.documents-verification .document-preview-bottom-bar .document-preview-scale {
    flex: 1;
    display: flex;
    gap: 8px;
    padding: 9px 12px;
}
.documents-verification .document-preview-bottom-bar .document-preview-scale .zoom-min, .documents-verification .document-preview-bottom-bar .document-preview-scale .zoom-add {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documents-verification .document-preview-bottom-bar .document-preview-scale .zoom-value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documents-verification .preview-box {
    position: relative;
    flex: 1;
    height: 100%;
    background-color: #ececec;
    overflow: hidden;
}