.paywall-modal {
    top: 0;
    padding-bottom: 0;
}
.paywall-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 60px 44px 46px 42px;
    top: 0;
    border-radius: 12px;
}
.paywall-modal .ant-modal-content .ant-modal-body {
    font-family: Inter;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-title {
    color: #222;
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-description {
    color: #6E6E70;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-price .amount {
    color: #1F2890;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-price .unit{
    color: #545459;
    font-size: 20px;
    line-height: normal;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-divider {
    height: 1px;
    background-color: #D9D9D9;
    margin: 20px 0;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-service-list {
    margin-bottom: 32px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-service-list .paywall-service-item {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-service-list .paywall-service-item .paywall-service-item-text {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-btn {
    display: flex;
    gap: 12px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-btn .paywall-btn-primary {
    width: 50%;
    height: 60px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 8px !important;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-btn .paywall-text-btn {
    width: 50%;
    height: 60px;
    color: #325CEB;
    font-size: 20px;
    font-weight: 600;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-recommend-card {
    padding: 12px 12px 14px;
    border-radius: 8px;
    border: 1px solid #E9F0FD;
    background-color: #F7F8FF;
    cursor: pointer;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-recommend-card .paywall-recommend-card-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-recommend-card .paywall-recommend-card-title .paywall-recommend-card-title-text {
    display: flex;
    align-items: center;
    gap: 10px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-recommend-card .paywall-recommend-card-title .paywall-recommend-card-title-text span {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}
.paywall-modal .ant-modal-content .ant-modal-body .paywall-recommend-card .paywall-recommend-card-description {
    padding-left: 34px;
    color: #6E6E70;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}
.paywall-modal .ant-modal-content .ant-modal-close {
    width: 24px;
    height: 24px;
    color: #6E6E70;
    top: 20px;
    right: 20px;
}

