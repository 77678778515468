.advancedCheckSearchHistoryWrap {
  padding: 10px;
  border: 1px solid rgb(219, 220, 222) !important;
  border-radius: 8px;
  position: absolute;
  top: 0%;
  height: 100%;
  right: 0%;
  width: 30%;
  background-color: rgb(247, 248, 252);
  overflow-y: auto;
}

.advancedCheckSearchHistoryHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    height: 100%;
    color: rgb(31, 57, 136) !important;
  }
}

.advancedCheckSearchHistoryTodayness {
  font-size: 16px;
  color: rgb(31, 57, 136);
}

.advancedCheckSearchHistoryItem {
  padding: 8px;
  height: auto;
  word-wrap: break-word;
  /* border-top: 1px solid rgb(219, 220, 222); */
}

.advancedCheckSearchHistoryItem.selected {
  border: 1px solid rgb(232, 232, 232) !important;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

/* ---- Transition styles ---- */
.advancedCheckSearchHistoryWrap-enter {
  opacity: 0; /* Invisible */
  transform: translateX(100%); /* Start off-screen to the right */
}
.advancedCheckSearchHistoryWrap-enter-active {
  opacity: 1; /* Fully visible */
  transform: translateX(0); /* Slide to the final position */
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
.advancedCheckSearchHistoryWrap-exit {
  opacity: 1; /* Fully visible */
  transform: translateX(0); /* Start at the final position */
}
.advancedCheckSearchHistoryWrap-exit-active {
  opacity: 0;
  transform: translateX(100%); /* Slide back to the right */
  transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
}
