.card-modal-loboard > .modal-content {
  margin-left: -5vw !important;
}

.document {
  position: fixed;
  z-index: -100; 
  right: 3vw;
  top: 3vh;
  overflow-x: auto; 
  overflow-y: auto; 
  width: 40vw;
  height: 90vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
} 

.second-document {
  position: fixed;
  z-index: -100; 
  left: 3vw;
  top: 3vh;
  overflow-x: auto; 
  overflow-y: auto; 
  width: 40vw;
  height: 90vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
} 

.document > img{
  height: 90vh;
  width: 100%;
  border-bottom: 1px solid black;
}

.second-document > img{
  height: 90vh;
  width: 100%;
  border-bottom: 1px solid black;
}

@media screen and (min-height: 1200px) {
  .document {
    height: 70vh;
  }
   
  .document > img{
    height: 70vh;
  }  
  
  .second-document{
    height: 70vh;
  }
   
  .second-document > img{
    height: 70vh;
  }  
}

@media screen and (max-width: 576px) {
  .document {
    left: 3vw;
    width: 95vw;
  }
   
  .document > img{
    width: 95vw;
  }  

  .second-document{
    left: 3vw;
    width: 95vw;
  }
   
  .second-document > img{
    width: 95vw;
  }  
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .document {
    left: 7vw;
    width: 85vw;
  }
   
  .document > img{
    width: 85vw;
  }  

  .second-document {
    right: 7vw;
    width: 85vw;
  }
   
  .second-document > img{
    width: 85vw;
  }  
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .document {
    right: 5vw;
    width: 45vw;
  }
   
  .document > img{
    min-width: 45vw;
  }  

  .second-document {
    left: 5vw;
    width: 45vw;
  }
   
  .second-document > img{
    min-width: 45vw;
  }  
}

@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .document {
    right: 10vw;
    width: 35vw;
  }
   
  .document > img{
    min-width: 35vw;
  }  

  .second-document {
    left: 10vw;
    width: 35vw;
  }
   
  .second-document > img{
    min-width: 35vw;
  }  
}

@media screen and (min-width: 1751px) and (max-width: 2099px) {
  .document {
    right: 15vw;
    width: 30vw;
  }
   
  .document > img{
    min-width: 30vw;
  }  

  .second-document {
    left: 15vw;
    width: 30vw;
  }
   
  .second-document > img{
    min-width: 30vw;
  }  
}

@media screen and (min-width: 2100px) {
  .document {
    right: 20vw;
    width: 25vw;
  } 
  
  .document > img{
    min-width: 25vw;
  }

  .second-document {
    left: 20vw;
    width: 25vw;
  } 
  
  .second-document > img{
    min-width: 25vw;
  }
}

.document-on-top{
  z-index: 100 !important;
}

.second-document-on-top{
  z-index: 110 !important;
}

.DeleteButton{
  cursor: pointer;
}
.wrapper{
  display: grid;
  grid-template-columns: 20% 80%; 
  grid-column-gap: 5px;
}
@media screen and (max-width: 576px){
  .wrapper{
    grid-template-columns: 45% 55%; 
  }
}

.customer-info{
  display: inline-block;
  margin: 10px -5px 10px 5px;
  background-color: var(--primary-pale);
  border-radius: 3px;
  padding: 10px;
  height: 100vh;
  overflow-Y: auto;
}

.customer-info-header{
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}
.paragraph-info{
  color: var(--primary-pale-text);
  text-align: left;
}
.card-like{
  background-color: rgb(255,255,255);
  color: #000000;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 7px;
}
.card-like > p {
  text-align: left;
  border-bottom: 1px solid rgb(238,238,238);
  padding-bottom: 6px;
}

/* hacks for modal */

.modal-body
{
	z-index: initial !important;
}
.modal-content
{
	z-index: initial !important;
}
.modal-footer
{
	z-index: initial !important;
}
.modal-header
{
	z-index: initial !important;
}