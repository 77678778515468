.upload-profile-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 767px) { 
    .upload-profile-btn {
        margin: 16px 0;
        align-items: center;
    }
}